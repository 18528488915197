// APIリソース群
import responseGetListByUserIdApi from '../../apis/response/responseGetListByUserIdApi';

// component
import GensenRegisterHistoryComponent from '../../components/gensenRegister/gensenRegisterHistoryComponent';
import { GENSEN_REGISTER_HISTORY } from '../../constants/loading';
import { useLoading } from '../../hooks';

export default function GensenRegisterHistoryContainer() {
  const { addLoading, removeLoading } = useLoading();
  /**
   * @module getResponseListByUserId - 【webRes014】未処理反響一覧取得（担当社員ID指定）取得関数
   * @param {object} params - params.limit：必須
   * @param {object} params - params.offset：必須
   * @param {*} stateRef - responseリストのstateRef：必須
   */
  const getResponseListByUserId = async (params, stateRef) => {
    addLoading(GENSEN_REGISTER_HISTORY);
    await responseGetListByUserIdApi(params)
      .then((res) => {
        stateRef.current = {
          total: res.total,
          list: [...stateRef.current.list, ...res.responses],
        };
      })
      .catch(console.log)
      .finally(() => removeLoading(GENSEN_REGISTER_HISTORY));
  };

  return (
    <GensenRegisterHistoryComponent
      getResponseListByUserId={getResponseListByUserId}
    />
  );
}
