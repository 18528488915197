import { useEffect, useState } from 'react';
import { Button, Grid, makeStyles, Typography } from '@material-ui/core';
import { ArrowRight, PhonelinkErase, PhonelinkRing } from '@material-ui/icons';
import { useSelector, useDispatch } from 'react-redux';
import commonStyles from '../styles';
import commonTheme from '../styles/theme';
import SmsTalkWindow from './parts/smsTalkWindow';
import { getSmsHistoryApi } from '../../store/sms/smsHistorySlice';
import { setSmsTalkInitial } from '../../store/sms/smsTalkSlice';

const useStyle = makeStyles({
  wrapper: {
    [commonTheme.breakpoints.up('md')]: {
      minHeight: '120px',
    },
    [commonTheme.breakpoints.down('sm')]: {
      padding: '16px 0 0 16px',
      borderRadius: '12px 12px 0px 0px',
    },
  },
  itemWrap: {
    [commonTheme.breakpoints.up('md')]: {
      '&:not(:last-child)': { marginBottom: '8px' },
    },
  },
  btn: {
    textTransform: 'none',
    borderRadius: 0,
    background: '#fff',
    justifyContent: 'space-between',
    flexGrow: 1,
    [commonTheme.breakpoints.up('md')]: {
      border: '1px solid #C8C8C8',
      padding: '12px 8px',
    },
    [commonTheme.breakpoints.down('sm')]: {
      padding: '16px',
      borderBottom: '1px solid #F3F3F3',
    },
  },
  col2: {
    wordBreak: 'break-all',
    [commonTheme.breakpoints.down('sm')]: {
      '&:first-child': {
        width: 'calc(100% - 165px)',
      },
      '& + &': {
        marginLeft: '16px',
      },
    },
  },
  right: {
    '& > p': {
      textAlign: 'right',
    },
  },
  del: {
    [commonTheme.breakpoints.up('md')]: {
      padding: '24px 0 8px',
    },
    [commonTheme.breakpoints.down('sm')]: {
      padding: '16px 16px 0 16px',
    },
  },
  w20: {
    [commonTheme.breakpoints.up('md')]: {
      width: '20%',
    },
  },
  number: {
    [commonTheme.breakpoints.up('md')]: {
      width: '26%',
      color: 'rgba(6, 121, 191, 0.8)',
    },
    [commonTheme.breakpoints.down('sm')]: {
      whiteSpace: 'nowrap',
    },
  },
  w26: {
    [commonTheme.breakpoints.up('md')]: {
      width: '26%',
    },
  },
  w28: {
    [commonTheme.breakpoints.up('md')]: {
      width: '28%',
    },
  },
  nameAndMemo: {
    '& > *': {
      display: 'block',
    },
  },
  name: {
    textAlign: 'right',
  },
  statusChageBtn: {
    marginLeft: 8,
    background: '#fff',
    borderRadius: 0,
    padding: 0,
    [commonTheme.breakpoints.up('md')]: {
      border: '1px solid #C8C8C8',
    },
    [commonTheme.breakpoints.down('sm')]: {
      borderBottom: '1px solid #F3F3F3',
    },
    '& .MuiButton-label': {
      fontSize: 10,
      flexWrap: 'wrap',
      '& .MuiSvgIcon-root': {
        fontSize: 20,
        margin: '0 -4px 2px',
      },
    },
  },
});

function Item(props) {
  const { data, talk, setTalk, customer, functions, setSendParams } = props;
  const classes = useStyle();
  const common = commonStyles();
  const isSp = useSelector((state) => state.deviceTypeSlice.isSp);
  const dispatch = useDispatch();

  // 各電話番号選択時
  const selectPhoneRecord = (d) => {
    const alreadySms = data.smsCustomerTelId || false;
    const parameter = {
      smsCustomerTelId: alreadySms ? d.smsCustomerTelId : null,
      customerId: d.customerId,
      phoneNumber: d.phoneNumber,
    };
    if (alreadySms) {
      functions.getSmsTalk(parameter);
      setTalk({ ...talk, open: true, talkData: d });
    } else {
      setSendParams(parameter);
      // 新規メッセージの為トークの初期化
      dispatch(setSmsTalkInitial());
      setTalk({
        open: true,
        talkData: d,
        onCloseFunc: () => { return setTalk({ ...talk, open: false }); },
      });
    }
  };

  return (
    <Button
      className={classes.btn}
      onClick={() => { return selectPhoneRecord(data); }}
    >
      {isSp ? (
        <>
          <Grid className={classes.col2}>
            <Typography className={`${common.title2} ${classes.number}`}>{data.phoneNumber}</Typography>
            <Typography className={`${common.title6} ${classes.w28} ${classes.nameAndMemo}`}>
              {data.customerName && <span>{data.customerName} 様</span>}
              {data.memo && <span>{data.memo}</span>}
            </Typography>
          </Grid>
          <Grid className={`${classes.col2} ${classes.right}`}>
            <Typography className={`${common.title6} ${classes.w20}`}>{data.lastSendAt}</Typography>
            <Typography className={`${common.title6} ${classes.w26} ${classes.name}`}>
              {customer.userLastName}{customer.userFirstName}
            </Typography>
          </Grid>
        </>
      ) : (
        <>
          <Typography className={`${common.title2} ${classes.number}`}>{data.phoneNumber}</Typography>
          <Typography className={`${common.title6} ${classes.w28} ${classes.nameAndMemo}`}>
            {data.customerName && <span>{data.customerName} 様</span>}
            {data.memo && <span>{data.memo}</span>}
          </Typography>
          <Typography className={`${common.title6} ${classes.w26}`}>{data.lastSendAt}</Typography>
          <Typography className={`${common.title6} ${classes.w20} ${classes.name}`}>
            {customer.userLastName}{customer.userFirstName}
          </Typography>
        </>
      )}
    </Button>
  );
}

function StatusChageBtn(props) {
  const { type, onClick, data } = props;
  const classes = useStyle();
  console.log(data);
  const label = () => {
    if (type === 'cancel') {
      return (
        <>
          <PhonelinkRing />
          <ArrowRight />
          <PhonelinkErase />
          <span style={{ width: '100%' }}>使用中止</span>
        </>
      );
    }
    return (
      <>
        <PhonelinkErase />
        <ArrowRight />
        <PhonelinkRing />
        <span style={{ width: '100%' }}>使用再開</span>
      </>
    );
  };
  return (
    <Button
      onClick={onClick}
      className={classes.statusChageBtn}
      disabled={!data?.smsCustomerTelId || data?.isSuspension === null}
    >
      {label()}
    </Button>
  );
}

// SMSリストの一覧
function TargetList(props) {
  const { data, talk, setTalk, customer, functions, setSendParams } = props;
  const common = commonStyles();
  const classes = useStyle();
  const arriveNumber = [];
  const notArriveNumber = [];
  const cancelNumber = []; // 使用中止の番号

  // 削除済みと使用中止とそれ以外の切り分け
  data.map((sms) => {
    if (sms.isSuspension === 1) return cancelNumber.push(sms); // 使用中止
    if (sms.isDeletedPhoneNumber === 1) return notArriveNumber.push(sms); // 削除
    return arriveNumber.push(sms);
  });

  return (
    <>
      <Grid container>
        {arriveNumber.map((l) => (
          <Grid container className={classes.itemWrap}>
            <Item
              data={l}
              talk={talk}
              setTalk={setTalk}
              key={`key${l.smsCustomerTelId}`}
              customer={customer}
              functions={functions}
              setSendParams={setSendParams}
            />
            <StatusChageBtn
              type="cancel"
              onClick={() => functions.setSuspension(l)}
              data={l}
            />
          </Grid>
        ))}
      </Grid>
      {notArriveNumber.length ? (<Typography className={`${common.title4} ${classes.del}`}>削除された番号</Typography>) : null}
      <Grid container>
        {notArriveNumber.map((l) => (
          <Grid container className={classes.itemWrap}>
            <Item
              data={l}
              talk={talk}
              setTalk={setTalk}
              key={`key${l.smsCustomerTelId}`}
              customer={customer}
              functions={functions}
              setSendParams={setSendParams}
            />
            <StatusChageBtn
              type="cancel"
              onClick={() => functions.setSuspension(l)}
              data={l}
            />
          </Grid>
        ))}
      </Grid>
      {cancelNumber.length ? (<Typography className={`${common.title4} ${classes.del}`}>使用停止された番号</Typography>) : null}
      <Grid container>
        {cancelNumber.map((l) => (
          <Grid container className={classes.itemWrap}>
            <Item
              data={l}
              talk={talk}
              setTalk={setTalk}
              key={`key${l.smsCustomerTelId}`}
              customer={customer}
              functions={functions}
              setSendParams={setSendParams}
            />
            <StatusChageBtn
              type="restart"
              onClick={() => functions.setSuspension(l)}
              data={l}
            />
          </Grid>
        ))}
      </Grid>
    </>
  );
}

export default function SmsComponent(props) {
  const { customer, sendParams, setSendParams, functions } = props;
  const classes = useStyle();
  const dispatch = useDispatch();
  const [talk, setTalk] = useState({
    open: false,
    talkData: {},
    onCloseFunc: () => { return setTalk({ ...talk, open: false }); },
  });
  // 始めてのメッセージの場合、直後のSMS一覧が重複するので強制的に再レンダリング
  const [postFlg, setPostFlg] = useState(0);
  const list = useSelector((state) => state.smsHistory.smsHistory);
  useEffect(() => {
    dispatch(getSmsHistoryApi({}));
    functions.getSmsHistory(customer.customerId);
  }, [postFlg]);

  return (
    <Grid className={classes.wrapper}>
      {list.info !== undefined ? (
        <TargetList
          data={list.info}
          talk={talk}
          setTalk={setTalk}
          customer={customer}
          functions={functions}
          setSendParams={setSendParams}
        />
      ) : null}
      <SmsTalkWindow
        customer={customer}
        talk={talk}
        setTalk={setTalk}
        sendParams={sendParams}
        setSendParams={setSendParams}
        functions={functions}
        postFlg={postFlg}
        setPostFlg={setPostFlg}
      />
    </Grid>
  );
}
