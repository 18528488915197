import React from 'react';
import {
  Grid,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Link,
  Tooltip,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import { makeStyles } from '@material-ui/core/styles';
import commonStyles from '../../styles';
import EmptyAlert from './emptyAlert';
import Customer, { createGuidanceStatusText } from './customer';
import { URL_MAPPER } from '../../../constants/urls';

const useStyles = makeStyles((theme) => ({
  accordion: {
    backgroundColor: '#F3F3F3',
    '& .MuiAccordionDetails-root': {
      display: 'block',
      padding: 0,
    },
  },
  bodyContent: {
    '& .MuiTable-root': {
      background: '#fff',
      border: 'solid 1px C8C8C8',
    },
  },
  tableContainer: {
    maxHeight: 147,
    border: 'solid 1px #C8C8C8',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  headerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 5,
  },
  headerLinkTxt: {
    color: theme.palette.primaryColor,
  },
  buttonFlex: {
    display: 'flex',
    flexDirection: 'flex-start',
    '& .MuiAccordionSummary-root': {
      padding: 0,
      minHeight: 0,
      '& .MuiAccordionSummary-content': {
        margin: 0,
      },
    },
    '& .MuiIconButton-root': {
      padding: 0,
      '& .MuiIconButton-label': {
        color: theme.palette.primaryColor,
      },
    },
    '& .MuiIconButton-edgeEnd': {
      marginRight: 0,
    },
  },
  toggleButton: {
    padding: 0,
    textAlign: 'left',
    display: 'block',
    minWidth: 10,
  },
  tableHead: {
    '& th': {
      backgroundColor: '#fff',
    },
  },
  headerCell: {
    color: '#8C8C8C',
    textAlign: 'center',
    borderBottom: 'solid 2px #C8C8C8',
    padding: '12px 0 6px 0',
  },
  bodyTableRow: {
    '&:nth-of-type(even)': {
      backgroundColor: '#F3F3F3',
    },
    '& td': {
      fontSize: 13,
      textAlign: 'center',
      padding: '5px 0',
    },
    '&:hover': {
      background: `${theme.palette.primaryColor}29!important`,
    },
  },
}));

const headerCells = [
  { id: 'number', label: 'No' },
  { id: 'time', label: '時間' },
  { id: 'customer', label: '顧客' },
  { id: 'totalGuidanceCount', label: '案内回数' },
  // { id: 'siteName', label: '物件名' },
  { id: 'resPerson', label: '担当' },
];

function TodaysGuidanceList(props) {
  const { dataList } = props;
  const classes = useStyles();
  const baseClasses = commonStyles();

  const { guidances = [], total = 0 } = dataList.data || {};

  const tableBody = (
    <TableBody>
      {guidances.map((data, index) => {
        return (
          <TableRow className={classes.bodyTableRow} key={String(index)} hover>
            <TableCell style={{ width: '3%' }}>{index + 1}</TableCell>
            <TableCell style={{ width: '18%' }}>{`${data.startAt.slice(-8, -3)}～${data.endAt.slice(-8, -3)}`}</TableCell>
            <Customer
              id={data.customerId}
              fullName={`${[data.customerLastName, data.customerFirstName].filter(Boolean).join(' ')}`}
              rating={data.userLevel}
              status={data.memberStatusCode}
              data={data}
            >
              {createGuidanceStatusText(data)}
            </Customer>
            <TableCell style={{ width: '5%' }}>{data.guidanceCount}</TableCell>
            {/* <Tooltip title={data.siteName || ''}>
              <TableCell style={{ width: '20%' }}>{omit(data.siteName, 19)}</TableCell>
            </Tooltip> */}
            <Tooltip title={`${data.guidanceDivisionSubName} ${data.userLastName}${data.userFirstName}`}>
              <TableCell style={{ width: '25%' }}>
                {data.guidanceDivisionSubName}
                {data.userLastName}
                {data.userFirstName}
              </TableCell>
            </Tooltip>
          </TableRow>
        );
      })}
    </TableBody>
  );

  const body = (
    <TableContainer className={`${classes.tableContainer} dashbordScrollArea`}>
      <Table stickyHeader aria-label="sticky table">
        <TableHead className={classes.tableHead}>
          <TableRow>
            {headerCells.map((headerCell) => {
              return (
                <TableCell
                  className={`${baseClasses.title4} ${classes.headerCell}`}
                  key={headerCell.id}
                  colSpan={headerCell.id === 'customer' ? 3 : 1}
                >
                  {headerCell.label}
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>
        {tableBody}
      </Table>
      { !total && <EmptyAlert danger>案内の獲得・登録をしてください</EmptyAlert>}
    </TableContainer>
  );
  return (
    <Accordion
      className={classes.accordion}
      defaultExpanded
    >
      <Grid className={classes.headerContainer}>
        <Grid className={classes.buttonFlex}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon className={classes.toggleButton} />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          />
          <Grid className={classes.buttonContainer}>
            <Typography className={baseClasses.title3}>
              本日の案内 ({total})
            </Typography>
          </Grid>
        </Grid>
        <Grid>
          <Link href={URL_MAPPER.schedule}>
            <Typography className={`${baseClasses.smallStrong} ${classes.headerLinkTxt}`}>
              行動予定表で確認
            </Typography>
          </Link>
        </Grid>
      </Grid>
      <AccordionDetails>
        <Grid className={classes.bodyContent}>
          {body}
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
}

export default TodaysGuidanceList;
