import { useState } from 'react';
import { useDispatch } from 'react-redux';
import smsHistoryApi from '../../apis/sms/smsHistoryApi';
import smsPostApi from '../../apis/sms/postSmsApi';
import smsTalkGetApi from '../../apis/sms/getSmsTalkApi';
import smsCustomerUpdateApi from '../../apis/sms/updateSmsCustomerApi';
import smsCustomerCreateApi from '../../apis/sms/createSmsCustomerApi';
import SmsComponent from '../../components/sms/smsComponent';
import { getSmsHistoryApi } from '../../store/sms/smsHistorySlice';
import { getSmsTalkApi } from '../../store/sms/smsTalkSlice';
import { createSmsCustomerApi } from '../../store/sms/smsCustomerCreateSlice';
import { updateSmsCustomerApi } from '../../store/sms/smsCustomerUpdateSlice';
import { useLoading } from '../../hooks';

export default function SmsContainers(props) {
  const { customer } = props;
  const dispatch = useDispatch();
  const { addLoading, removeLoading } = useLoading();
  // 最終的にPOSTするパラメーター用
  const [sendParams, setSendParams] = useState({});

  // phoneNumberのハイフン削除
  const replacePhoneNumber = (initialObj) => {
    const obj = { ...initialObj };
    if (obj.phoneNumber && obj.phoneNumber.indexOf('-') !== -1) {
      obj.phoneNumber = obj.phoneNumber.replaceAll('-', '');
      return obj;
    }
    return obj;
  };

  // GET SMS送信履歴 取得用(リスト初期表示で使用)
  const getSmsHistory = async (customerId) => {
    addLoading('getSms');
    await smsHistoryApi(customerId)
      .then(async (res) => {
        await dispatch(getSmsHistoryApi(res.data));
        removeLoading('getSms');
      })
      .catch((err) => {
        console.error(err);
      });
  };

  // GET SMS送信履歴 取得用(各番号を選択時に使用)
  const getSmsTalk = async (historyData) => {
    addLoading('getSms');
    await smsTalkGetApi(historyData.smsCustomerTelId)
      .then(async (res) => {
        await dispatch(getSmsTalkApi(res.data));
        if (historyData) {
          // idごとに取得したデータをSMS送信用パラメーターに追加
          historyData.smsid = historyData.smsCustomerTelId;
          setSendParams(historyData);
        }
        removeLoading('getSms');
      })
      .catch((err) => {
        console.error(err);
      });
  };

  // SMSテーブルにレコード追加
  const createSmsCustomer = async (parmObj) => {
    const copyObj = replacePhoneNumber(parmObj);
    await smsCustomerCreateApi(copyObj)
      .then(async (res) => {
        await dispatch(createSmsCustomerApi(res.data));
        const parameter = {
          smsCustomerTelId: res.data.smsCustomerTelId,
          customerId: sendParams.customerId,
          phoneNumber: sendParams.phoneNumber,
        };
        getSmsTalk(parameter);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  // POST SMS送信用 右記チケット完了後、82~92のif文部分削除https://openhouse.backlog.jp/view/SFA_ASIAQUEST-2194
  const postSms = async (parmObj, onSend) => {
    addLoading('getSms');
    const copyObj = replacePhoneNumber(parmObj);
    let resSmsData;
    const resultObj = {
      smsCustomerTelId: copyObj.smsCustomerTelId,
      userId: copyObj.userId,
      divisionId: copyObj.divisionId,
      smstext: copyObj.smstext,
      customerId: copyObj.customerId,
      phoneNumber: copyObj.phoneNumber,
      lastName: copyObj.lastName,
      firstName: copyObj.firstName,
      scheduleTime: copyObj.schedule?.assignAt?.replace('T', ' '),
    };
    await smsPostApi(resultObj)
      .then(async (res) => {
        // smsCustomerTelIdをres.data.smsCustomerTelIdにする -> API待ち
        const parameter = {
          smsCustomerTelId: res.data.smsCustomerTelId,
          customerId: copyObj.customerId,
          phoneNumber: copyObj.phoneNumber,
        };
        resSmsData = res.data.smsCustomerTelId;
        await getSmsTalk(parameter);
        await getSmsHistory(copyObj.customerId);
        // メッセージ、送信開始日時初期化
        onSend();
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => removeLoading('getSms'));
    return resSmsData;
  };

  // PUT sms customer
  // FIXME: swaggerではsmsCustomerIdが必須パラメーターとなっているが、sms006で取得したデータに該当項目がない。
  // smsCustomerTelId or customerIdのどちらか要確認
  const updateSmsCustomer = async (smsCustomerTelId, customerId, parmObj) => {
    addLoading('getSms');
    const result = replacePhoneNumber(parmObj);
    await smsCustomerUpdateApi(smsCustomerTelId, result)
      .then(async (res) => {
        await dispatch(updateSmsCustomerApi(res.data));
        getSmsHistory(customerId);
        removeLoading('getSms');
      })
      .catch((err) => {
        console.error(err);
        removeLoading('getSms');
      });
  };

  // TODO: SMS使用中止/使用再開の関数
  const setSuspension = (data) => {
    const result = replacePhoneNumber(data);
    if (result.isSuspension !== null && result.smsCustomerTelId) {
      const modifyObj = {
        smsCustomerName: result.customerName,
        memo: result.memo,
        isSuspension: !result.isSuspension ? 1 : 0,
        phoneNumber: result.phoneNumber,
      };
      updateSmsCustomer(result.smsCustomerTelId, result.customerId, modifyObj);
    }
  };

  const functions = {
    getSmsHistory,
    getSmsTalk,
    postSms,
    createSmsCustomer,
    updateSmsCustomer,
    setSuspension,
  };

  return (
    <SmsComponent
      sendParams={sendParams}
      setSendParams={setSendParams}
      customer={customer}
      functions={functions}
    />
  );
}
