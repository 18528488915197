import React from 'react';
import { FormControl, Select, MenuItem, makeStyles } from '@material-ui/core';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import positionChange from '../../commonFunction/positionChange';

const useStyles = makeStyles(() => ({
  formControl: {
    width: '100%',
    '& div::before': {
      borderBottom: '1px solid transparent',
    },
  },
  selectEmpty: {
    fontFamily: 'Roboto',
    fontSize: 13,
    lineHeight: 1.6,
    fontWeight: '700',
    '& div': {
      padding: '0 24px 0 0',
      marginTop: 1,
    },
  },
}));

function CustomMainSelect(props) {
  const classes = useStyles();
  const { initial } = props;
  const menusKeys = Object.keys(initial.obj.menus);

  const [val, setVal] = React.useState(initial.state);

  const handleChange = (e) => {
    setVal(e.target.value);
  };

  React.useEffect(() => {
    setVal(initial.state);
  }, [initial.state]);

  const controlDisabled = (data) => {
    let result = false;

    // 顧客メイン 顧客詳細
    if (initial.key === 'detachedHouseBudgetMin' && initial.vals.detachedHouseBudgetMax) {
      result = Boolean(Number(data) > Number(initial.vals.detachedHouseBudgetMax));
    }
    if (initial.key === 'detachedHouseBudgetMax' && initial.vals.detachedHouseBudgetMin) {
      return Boolean(Number(data) < Number(initial.vals.detachedHouseBudgetMin));
    }
    if (initial.key === 'landBudgetMin' && initial.vals.landBudgetMax) {
      result = Boolean(Number(data) > Number(initial.vals.landBudgetMax));
    }
    if (initial.key === 'landBudgetMax' && initial.vals.landBudgetMin) {
      return Boolean(Number(data) < Number(initial.vals.landBudgetMin));
    }

    return result;
  };

  return (
    <FormControl className={`${classes.formControl} ${classes.formControl2}`}>
      <Select
        value={val}
        displayEmpty
        className={classes.selectEmpty}
        name={initial.name || initial.key}
        inputProps={{ 'aria-label': 'Without label' }}
        onChange={(e) => {
          handleChange(e);
          initial.change?.(e, initial);
        }}
        onBlur={(e) => {
          initial.set?.(e, initial);
        }}
        onOpen={positionChange}
      >
        {menusKeys.map((data) => (
          <MenuItem
            key={data}
            value={data}
            disabled={controlDisabled(data)}
          >
            {initial.obj.menus[data]}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export default React.memo(CustomMainSelect);
