import React, { useState, useMemo, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Grid,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Table,
  TableBody,
  TableRow,
  TableCell,
  makeStyles,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import moment from 'moment';
import commonStyles from '../../styles';

import customerResponsesApi from '../../../apis/customer/customerResponses';
import resHouseGetApi from '../../../apis/resHouse/resHouseGetApi';
import customerCallApi from '../../../apis/customer/customerApi';
import customerPutResponsibilitiesApi from '../../../apis/customer/customerPutResponsibilitiesApi';

import { crmPageSelector, crmPageActions } from '../../../store/crmPage';
import { changeConfirmMessage } from '../../../store/eleCommon/customConfirmMessage';
import { isOpen } from '../../../store/common/apiMessageSlice';

import Claim from './claim';
import ButtonGroup from './buttonGroup';
import ContactToResponsiblePerson from '../contactToResponsiblePerson/contactToResponsiblePerson';
import ReturnResHistory from './returnResHistory';
// eslint-disable-next-line import/no-cycle
import ListWindow from '../../common/taiouRireki/listWindow';
import UserTree from '../../common/userTreeNew';
import Modal from '../../common/modal';

import { useLoading, useStore } from '../../../hooks';

import { getEnvVariable } from '../../../commonFunction/getEnv';
import { getPromiseSettledResult } from '../../../commonFunction/format';

import {
  JOIN_DATA_TYPES,
  JOIN_DATA_FROM_TYPES,
} from '../../../constants/crm';
import {
  ALL_CUSTOMER_ADMIN,
  ALL_CUSTOMER_EDIT,
  CLAIM_CANCELLATION,
} from '../../../constants/userRole';
import { PLAYER_TO_CENTER_GROUPS } from '../../../constants/userRoleGroup';
// eslint-disable-next-line import/no-cycle
import { URL_MAPPER } from '../../../constants/urls';

const useStyles = makeStyles((theme) => ({
  section: {
    width: '100%',
    backgroundColor: '#fff',
    border: 'solid 1px #C8C8C8',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: 30,
    padding: 8,
    marginBottom: 8,
    boxSizing: 'border-box',
    '& .MuiPaper-elevation1': {
      boxShadow: 'none',
    },
  },
  buttonFlex: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'flex-start',
    '& .MuiAccordionSummary-root': {
      padding: 0,
      minHeight: 0,
      '& .MuiAccordionSummary-content': {
        margin: 0,
      },
    },
    '& .MuiIconButton-root': {
      padding: 0,
      '& .MuiIconButton-label': {
        color: theme.palette.primaryColor,
      },
    },
    '& .MuiIconButton-edgeEnd': {
      marginRight: 0,
    },
  },
  tableRow: {
    '& .MuiTableCell-root': {
      padding: 3,
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '13px',
      lineHeight: '160%',
      borderBottom: 'none',
    },
  },
  adjustMargin: {
    marginRight: 20,
    padding: 3,
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '13px',
    lineHeight: '160%',
    borderBottom: 'none',
  },
  accordingDetails: {
    padding: 0,
  },
}));

const ONE_DAY_MSEC = 86400000;

/**
 * 反響基準日effect_atが今日を含めて8日以上か
 * @param {string} effectAt
 * @param {number} day
 * @returns boolean
 */
function checkEffectAt(effectAt, day = 7) {
  if (effectAt && /^(\d{4}\/\d{1,2}\/\d{1,2})\s+/.test(effectAt)) {
    // 時分秒が外されてる
    const effectAtMsec = +new Date(RegExp.$1);
    const todayMsec = +new Date(moment().format('YYYY/MM/DD'));
    // https://openhouse.backlog.jp/view/SFA_ASIAQUEST-5142#comment-192246160
    return todayMsec - effectAtMsec >= day * ONE_DAY_MSEC;
  }
  return false;
}

function CustomerOperation(props) {
  const {
    open,
    setOpen,
    authorityCode, // テストのために使用されてる
    searchStart,
  } = props;
  const classes = useStyles();
  const baseClasses = commonStyles();

  const { responseHeader } = useStore();
  const roleIds = authorityCode ? [authorityCode] : responseHeader.roleIds;

  const { roleGroupId } = responseHeader;

  const dispatch = useDispatch();
  const { addLoading, removeLoading } = useLoading();

  // 支援権限→全顧客管理の権限ではない
  const isNotAllCustomerAdmin = useMemo(() => {
    return !roleIds.includes(ALL_CUSTOMER_ADMIN);
  }, [roleIds]);

  // プレイヤー～センター長
  const isPlayerToCenter = useMemo(() => {
    return PLAYER_TO_CENTER_GROUPS.includes(roleGroupId);
  }, [roleGroupId]);

  // 顧客IDを取得
  const { customerId, joinData, selectedCustomerRow } = useSelector(crmPageSelector);
  // <CustomerInformation>
  const [openActionHistories, setOpenActionHistories] = useState(false);
  const [openContactToResponsiblePerson, setOpenContactToResponsiblePerson] = useState(false);

  // <InformationOperation>
  const [openReturnResponseHistories, setOpenReturnResponseHistories] = useState(false);
  const [openChangeResponsiblePerson, setOpenChangeResponsiblePerson] = useState(false);
  const [selectUser, setSelectUser] = useState({ userId: 0, userName: '' });
  const [selectDivision, setSelectDivision] = useState({ divId: 0, divisionName: '' });

  // その他
  const [responses, setResponses] = useState([]);
  const [products, setProducts] = useState([]);
  const [getDataAt, setGetDataAt] = useState('');
  const [getDataAt2, setGetDataAt2] = useState('');

  const handleClickResponse = () => {
    if (responses.length || getDataAt !== '') return;
    customerResponsesApi(customerId, 1)
      .then(({ data }) => {
        setResponses(data?.responses || []);
        setGetDataAt(moment().format('YYYY/MM/DD hh:mm:ss'));
      })
      .catch((error) => console.error(error));
  };
  const handleClickResHouse = () => {
    if (products.length || getDataAt2 !== '') return;
    resHouseGetApi(customerId, 1)
      .then(({ data }) => {
        setProducts(data?.products || []);
        setGetDataAt2(moment().format('YYYY/MM/DD hh:mm:ss'));
      })
      .catch((error) => console.error(error));
  };

  /* 担当者変更 */
  const changeResponsiblePerson = async () => {
    const changeParams = {
      customerId,
    };
    // state初期化
    setSelectUser({ userId: 0, userName: '' });
    setSelectDivision({ divId: 0, divisionName: '' });
    addLoading();
    await customerPutResponsibilitiesApi({
      divisionId: selectDivision.divId,
      // ユーザーが選択された場合はuserIdを入れ、組織が選択された場合はnullを入れる
      userId: selectUser.userId ? selectUser.userId : null,
      responsibilities: [changeParams],
    });
    removeLoading();
  };

  const onCloseBg = () => {
    setGetDataAt('');
    setProducts([]);
    setGetDataAt2('');
    setResponses([]);
    setOpen(false);
  };

  // 顧客情報変更画面（GA066）に表示する
  const changeCustomerInformation = useCallback(async (cid) => {
    addLoading();

    const promises = [
      // 【customer001】顧客情報取得
      customerCallApi([cid], 0, 0, 1),
      // 【customer010】顧客反響履歴一覧取得API
      customerResponsesApi(cid, 1),
      // 【resHouse001】問合せ物件検索API
      resHouseGetApi(cid, 1),
    ];

    const [customerApi, response, inquiry] = await Promise.allSettled(promises);

    dispatch(crmPageActions.clickedCustomerUpdateButton({
      customer: getPromiseSettledResult(customerApi)?.data.customers[0],
      response: getPromiseSettledResult(response),
      inquiry: getPromiseSettledResult(inquiry),
    }));

    onCloseBg();
    removeLoading();
  }, []);

  // クリップボードへコピー
  const copyClipboard = useCallback(async (cid) => {
    const url = `https://${getEnvVariable('DOMAIN')}.openhouse-group.com${URL_MAPPER.customer}?customerId=${cid}`;
    try {
      await navigator.clipboard.writeText(url);
      dispatch(
        isOpen({
          status: 'success',
          message: 'クリップボードへコピーしました。',
        }),
      );
    } catch (e) {
      dispatch(
        isOpen({
          status: 'error',
          mmessage: 'クリップボードへのコピーに失敗しました。',
        }),
      );
    }
  }, []);

  // データ結合画面を表示
  const onOpenJoinData = useCallback(() => {
    dispatch(crmPageActions.setJoinData({
      isShow: true,
      type: JOIN_DATA_TYPES.MERGE,
      // 反響未処理（WEB）が選択されたら、childResponseIdをリセットする
      childResponseId: null,
      webResponseData: null,
    }));
    onCloseBg(false);
  }, []);

  // 再反登録OKでGA066に表示する
  const rebellionRegister = useCallback(async (cid) => {
    addLoading();
    const [customerApi, response, inquiry] = await Promise.allSettled([
      // 【customer001】顧客情報取得
      customerCallApi([cid], 0, 0, 1),
      // 【customer010】顧客反響履歴一覧取得API
      customerResponsesApi(cid, 1),
      // 【resHouse001】問合せ物件検索API
      resHouseGetApi(cid, 1),
    ]);

    dispatch(crmPageActions.clickedRebellionButton({
      customer: getPromiseSettledResult(customerApi)?.data.customers[0],
      response: getPromiseSettledResult(response),
      inquiry: getPromiseSettledResult(inquiry),
    }));

    onCloseBg();
    removeLoading();
  }, []);

  // 戻し登録OKでGA066に表示する
  const returnRegister = useCallback(async (cid) => {
    addLoading();
    const [customerApi, response, inquiry] = await Promise.allSettled([
      // 【customer001】顧客情報取得
      customerCallApi([cid], 0, 0, 1),
      // 【customer010】顧客反響履歴一覧取得API
      customerResponsesApi(cid, 1),
      // 【resHouse001】問合せ物件検索API
      resHouseGetApi(cid, 1),
    ]);

    dispatch(crmPageActions.clickedReturnButton({
      customer: getPromiseSettledResult(customerApi)?.data.customers[0],
      response: getPromiseSettledResult(response),
      inquiry: getPromiseSettledResult(inquiry),
    }));

    onCloseBg();
    removeLoading();
  }, []);

  // 再反登録確認ウィンドウ
  const confirmOpenAgainResponse = useCallback((cid) => {
    dispatch(changeConfirmMessage({
      title: '再反登録',
      msgList: ['現在編集中の顧客情報は破棄されます。よろしいですか？'],
      buttons: [
        {
          label: 'CANCEL',
          set: () => {},
          classes: baseClasses.buttonsSecondary,
        },
        {
          label: 'OK',
          set: () => rebellionRegister(cid),
          classes: baseClasses.buttonsPrimary,
        },
      ],
    }));
  }, []);

  // 戻し登録確認ウィンドウ
  const confirmOpenReturnRegister = useCallback((cid) => {
    dispatch(changeConfirmMessage({
      title: '戻し登録',
      msgList: ['現在編集中の顧客情報は破棄されます。よろしいですか？'],
      buttons: [
        {
          label: 'CANCEL',
          set: () => {},
          classes: baseClasses.buttonsSecondary,
        },
        {
          label: 'OK',
          set: () => returnRegister(cid),
          classes: baseClasses.buttonsPrimary,
        },
      ],
    }));
  }, []);

  // WEB再反登録 モーダル開閉
  const handleOpenWebAgainResponse = useCallback(() => {
    dispatch(crmPageActions.setJoinData({
      isShow: true,
      type: JOIN_DATA_TYPES.REBELLION,
    }));
    onCloseBg();
  }, []);

  // WEB戻し登録 モーダル開閉
  const handleOpenWebReturnRegister = useCallback(() => {
    dispatch(crmPageActions.setJoinData({
      isShow: true,
      type: JOIN_DATA_TYPES.RETURN,
    }));
    onCloseBg();
  }, []);

  const buttons = useMemo(() => {
    const arr = [];
    // 顧客情報
    arr.push({
      label: '顧客情報',
      items: [
        {
          label: '顧客メインを表示',
          onClick: () => window.open(`${URL_MAPPER.customer}?customerId=${customerId}`, '_blank'),
        },
        {
          label: '顧客情報変更',
          hidden: isNotAllCustomerAdmin,
          onClick: () => changeCustomerInformation(customerId),
        },
        {
          label: '担当者へ連絡',
          onClick: () => setOpenContactToResponsiblePerson(true),
        },
        {
          label: '対応履歴表示',
          hidden: !roleIds.includes(ALL_CUSTOMER_EDIT),
          onClick: () => setOpenActionHistories(true),
        },
      ],
    });
    // 情報操作
    arr.push({
      label: '情報操作',
      items: [
        {
          label: '担当者変更',
          hidden: !roleIds.includes(ALL_CUSTOMER_EDIT),
          onClick: () => setOpenChangeResponsiblePerson(true),
        },
        {
          label: 'クリップボードへコピー',
          onClick: () => copyClipboard(customerId),
        },
        {
          label: 'データ結合',
          hidden: isNotAllCustomerAdmin,
          onClick: onOpenJoinData,
        },
        {
          label: '以前の反響履歴に戻す',
          hidden: isNotAllCustomerAdmin,
          onClick: () => {
            setOpenReturnResponseHistories(true);
            handleClickResponse();
          },
        },
      ],
    });
    // 登録
    arr.push({
      label: '登録',
      items: [
        {
          label: '再反登録',
          disabled: !checkEffectAt(selectedCustomerRow?.effectAt),
          onClick: () => confirmOpenAgainResponse(customerId),
        },
        {
          label: '戻し登録',
          hidden: isPlayerToCenter,
          onClick: () => confirmOpenReturnRegister(customerId),
        },
        {
          label: 'WEB再反登録',
          hidden: isNotAllCustomerAdmin,
          disabled:
            joinData.fromType !== JOIN_DATA_FROM_TYPES.WEB_RESPONSE_LIST
              || !checkEffectAt(selectedCustomerRow?.effectAt),
          onClick: handleOpenWebAgainResponse,
        },
        {
          label: 'WEB戻し登録',
          hidden: isNotAllCustomerAdmin,
          disabled:
            joinData.fromType !== JOIN_DATA_FROM_TYPES.WEB_RESPONSE_LIST,
          onClick: handleOpenWebReturnRegister,
        },
      ],
    });
    return arr;
  }, [customerId, roleIds, selectedCustomerRow]);

  return (
    <>
      <Modal title="顧客操作画面" open={open} onClose={onCloseBg} width={800}>
        {buttons.map((item) => (
          <ButtonGroup key={item.label} data={item} />
        ))}

        {!roleIds.includes(CLAIM_CANCELLATION) ? null : (
          <Claim customerId={customerId} searchStart={searchStart} />
        )}
        <Grid className={classes.section}>
          <Accordion onChange={handleClickResponse}>
            <Grid className={classes.buttonFlex}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              />
              <Grid>
                <Typography className={baseClasses.title4}>
                  反響履歴表示
                </Typography>
              </Grid>
            </Grid>
            <AccordionDetails className={classes.accordingDetails}>
              <Table>
                <TableBody>
                  {responses.map((row1) => {
                    return (
                      <TableRow
                        className={classes.tableRow}
                        key={row1.responseId}
                      >
                        <TableCell>
                          <span className={classes.adjustMargin}>
                            {row1.effectAt}
                          </span>
                        </TableCell>
                        <TableCell>{row1.responseTypeName1}</TableCell>
                        <TableCell>{row1.responseTypeName2}</TableCell>
                        <TableCell>{row1.responseTypeName3}</TableCell>
                        <TableCell>{row1.responseTypeName4}</TableCell>
                        <TableCell>
                          <span className={classes.adjustMargin}>
                            {row1.divisionName}
                          </span>
                        </TableCell>
                        <TableCell>{row1.responsibleUserLastName}</TableCell>
                        <TableCell>{row1.responsibleUserFirstName}</TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </AccordionDetails>
          </Accordion>
        </Grid>
        <Grid className={classes.section}>
          <Accordion onChange={handleClickResHouse}>
            <Grid className={classes.buttonFlex}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              />
              <Grid>
                <Typography className={baseClasses.title4}>
                  問合せ物件表示
                </Typography>
              </Grid>
            </Grid>
            <AccordionDetails className={classes.accordingDetails}>
              <Table>
                <TableBody>
                  {products.map((row2) => {
                    return (
                      <TableRow
                        className={classes.tableRow}
                        key={row2.inquiryProductId}
                      >
                        <TableCell>
                          <span className={classes.adjustMargin}>
                            {row2.inquiredAt}
                          </span>
                        </TableCell>
                        <TableCell>{row2.productName}</TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </AccordionDetails>
          </Accordion>
        </Grid>
      </Modal>
      <ContactToResponsiblePerson
        customerId={customerId}
        open={openContactToResponsiblePerson}
        setOpen={setOpenContactToResponsiblePerson}
      />
      <ListWindow
        open={openActionHistories}
        onClose={() => setOpenActionHistories(false)}
        customerId={customerId}
      />
      <UserTree
        changeTitle="担当者変更ウィンドウ"
        isOpenTree={openChangeResponsiblePerson}
        setIsOpenTree={setOpenChangeResponsiblePerson}
        selectUser={selectUser}
        setSelectUser={setSelectUser}
        selectDivision={selectDivision}
        setSelectDivision={setSelectDivision}
        handleSelectFunction={changeResponsiblePerson}
        clickableUserAndDivision
        isDialog
        isSelectUserWindow
        isUserMaster
        doubleSearch
      />
      <ReturnResHistory
        key={customerId}
        responseHistories={responses}
        open={openReturnResponseHistories}
        setOpen={setOpenReturnResponseHistories}
        onClose={() => setOpenReturnResponseHistories(false)}
        onCloseOperation={onCloseBg}
        searchStart={searchStart}
      />
    </>
  );
}

export default CustomerOperation;
