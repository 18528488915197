import { Button, makeStyles } from '@material-ui/core';
import React, { useState } from 'react';
import Modal from '../common/modal';

const useStyles = makeStyles((theme) => ({
  child: {
    color: theme.palette.primaryColor,
    marginLeft: '16px',
    marginBottom: '8px',
    width: 'calc(100% - 16px)',
    borderRadius: '20px 0 0 20px',
    justifyContent: 'flex-start',
    paddingLeft: '16px',
    background: '#f3f3f3',
    '&:hover': {
      background: `${theme.palette.primaryColor}29`,
    },
  },
}));
export default function BtnAndDialog(props) {
  const { children, label, maxWidth, isDisabled } = props;
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const onClose = () => {
    setOpen(false);
  };
  const additionalProps = { onClose };
  const newChildren = React.cloneElement(children, additionalProps);

  return (
    <>
      <Button
        className={classes.child}
        onClick={() => { setOpen(true); }}
        disabled={isDisabled}
      >
        {label}
      </Button>
      <Modal
        open={open}
        onClose={onClose}
        title={label}
        maxWidth={maxWidth || 1200}
      >
        {newChildren}
      </Modal>
    </>
  );
}
