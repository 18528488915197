import {
  Grid,
  List,
  ListItem,
  ListItemText,
  makeStyles,
} from '@material-ui/core';
import commonTheme from '../../../styles/theme';

const useStyles = makeStyles((theme) => ({
  mainGrid: {
    width: '50%',
    height: '100%',
    margin: '0 auto',
  },
  mainGridShowOnlyFromStation: {
    width: '100%',
  },
  list: {
    width: '100%',
    height: '100%',
    overflow: 'auto',
  },
  gutters: {
    margin: '0 auto',
    [commonTheme.breakpoints.down('sm')]: {
      padding: '0 0 0 16px',
      '&.Mui-selected': {
        background: '#fff',
        color: theme.palette.primaryColor,
      },
    },
    [commonTheme.breakpoints.up('md')]: {
      width: '80%',
      padding: '8px 8px 9px 8px',
      borderBottom: '1px solid #C8C8C8',
    },
    '& > .MuiListItemText-root': {
      [commonTheme.breakpoints.down('sm')]: {
        marginTop: '0px',
        marginBottom: '0px',
        padding: '18px 32px 18px 16px',
        borderBottom: '1px solid #F3F3F3',
      },
      '& > .MuiTypography-root': {
        [commonTheme.breakpoints.down('sm')]: {
          fontSize: '16px',
        },
      },
    },
  },
  listItem: {
    width: '100%',
    padding: '0px',
    fontSize: '13px',
    fontWeight: 'bold',
    textAlign: 'left',
  },
}));

export default function StationsList(props) {
  const {
    stations = [],
    showOnlyFromStation,
    selectedStation = {},
    clickStationsFunc,
    setOpenFromStation,
    setOpenToStation,
    toStationFlg = false,
  } = props;
  const classes = useStyles();

  return (
    <Grid item className={`${classes.mainGrid} ${showOnlyFromStation && classes.mainGridShowOnlyFromStation}`}>
      <List className={classes.list}>
        {stations.map((station) => (
          <ListItem
            button
            className={classes.ListItem}
            classes={{ gutters: classes.gutters }}
            key={station.stationId}
            onClick={(() => {
              if (toStationFlg) {
                setOpenToStation(false);
              } else {
                setOpenFromStation(false);
              }
              clickStationsFunc(station, toStationFlg);
            })}
            selected={
              selectedStation.stationId === station.stationId
            }
          >
            <ListItemText
              primary={station.name}
              primaryTypographyProps={{ className: classes.listItem }}
            />
          </ListItem>
        ))}
      </List>
    </Grid>
  );
}
