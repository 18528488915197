// addAndEditDivision.js
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CreateNewFolderIcon from '@material-ui/icons/CreateNewFolder';
// 削除できる組織か判断 (組織削除は不要との事の為一時コメントアウト)
// import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
// import { changeAlertMessage } from '../../store/eleCommon/customAlertMessage';
import DriveFileRenameIcon from '../icons/fileRename';
import DivisionWindow from './divisionWindow';
import DeleteCheckDivision from './parts/deleteCheckDIvision';

const useStyles = makeStyles((theme) => ({
  createNewFolderIcon: {
    color: theme.palette.primaryColor,
  },
  renameIcon: {
    color: theme.palette.primaryColor,
    fill: theme.palette.primaryColor,
  },
  deleteIcon: {
    color: 'red',
  },
  buttonLayout: {
    padding: '0px 0px 2px 6px',
    minWidth: '0px',
    '& > svg': { fill: theme.palette.primaryColor },
  },
}));

function DivisionSetting(props) {
  const {
    selectDivision,
    getDivisionDetail,
    updateDivisionDetail,
    resetDivision,
    createDivisionDetail,
    registerHandler,
  } = props;
  const classes = useStyles();
  // const dispatch = useDispatch();
  const divisionDetailStore = useSelector(
    (state) => state.divisionsDetail.divisionDetail,
  );
  const [open, setOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [localDivision, setLocalDivision] = useState({});

  useEffect(() => {
    setLocalDivision(divisionDetailStore);
  }, [divisionDetailStore]);

  // 更新用にdivisionStateを成形
  const moldingParameter = (resultDivision, deleteFlg) => {
    const copyObj = Object.assign({}, resultDivision);
    const removeList = [
      'divisionId',
      'levelCode',
      'level1DivisionId',
      'level2DivisionId',
      'level3DivisionId',
      'level4DivisionId',
      'level5DivisionId',
      'level6DivisionId',
      'level7DivisionId',
      'parentId',
      // 'divisionTypeCode',
    ];
    for (let i = 0; removeList.length > i; i += 1) {
      delete copyObj[removeList[i]];
    }
    deleteFlg ? (copyObj.isHide = 1) : '';
    return copyObj;
  };

  // 組織新規作成画面描画
  const addDivision = async (d) => {
    await resetDivision(d || selectDivision);
    setOpen(true);
  };

  // 組織詳細情報取得後、編集画面描画
  const editDivision = async (d) => {
    await getDivisionDetail(d.divisionId || selectDivision.divisionId);
    setIsEdit(true);
    setOpen(true);
  };

  // LocalStateの初期化
  const resetLocalDivision = () => {
    setLocalDivision(divisionDetailStore);
    setOpen(false);
    setIsEdit(false);
  };

  useEffect(() => {
    // イベントを登録して、外のコンポーネントで使えるため
    if (registerHandler) {
      registerHandler.addDivision = addDivision;
      registerHandler.editDivision = editDivision;
    }
  }, []);

  return (
    <>
      {isDelete ? (
        <DeleteCheckDivision
          isDelete={isDelete}
          setIsDelete={setIsDelete}
          selectDivision={selectDivision}
          moldingParameter={moldingParameter}
          updateDivisionDetail={updateDivisionDetail}
          localDivision={localDivision}
        />
      ) : null}
      {!registerHandler ? <Button
        onClick={() => addDivision()}
        startIcon={
          <CreateNewFolderIcon className={classes.createNewFolderIcon} />
        }
        className={classes.buttonLayout}
      /> : null}
      {!registerHandler ? <Button
        onClick={() => editDivision()}
        startIcon={<DriveFileRenameIcon className={classes.renameIcon} />}
        className={classes.buttonLayout}
      /> : null}
      {/* 削除ボタンは不要との事でコメントアウト */}
      {/* <Button
        onClick={() => judgeDeleteDivision()}
        startIcon={<DeleteOutlineIcon className={classes.deleteIcon} />}
        className={classes.buttonLayout}
      /> */}
      <DivisionWindow
        open={open}
        onClose={() => resetLocalDivision()}
        divisionDetail={localDivision}
        setDivisionDetail={setLocalDivision}
        resetLocalDivision={resetLocalDivision}
        updateDivisionDetail={updateDivisionDetail}
        createDivisionDetail={createDivisionDetail}
        getDivisionDetail={getDivisionDetail}
        isEdit={isEdit}
        setIsEdit={setIsEdit}
        moldingParameter={moldingParameter}
      />
    </>
  );
}

export default DivisionSetting;
