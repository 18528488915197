/* eslint-disable max-len */
/* eslint-disable quote-props */
import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Grid,
  Drawer,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import SelectProductType from './selectProductType';
import SelectProductTable from './selectProductTable';
import CommonDialog from '../../common/modal';

// 物件検索結果取得
import { setGetProductsApi, addGetProductsApi, initializeProducts } from '../../../store/common/getProductsSlice';
import getProductsApi from '../../../apis/common/getProductsApi';
import { SEARCHABLE_PRODUCT_TYPES, SELECT_PRODUCT_TYPES, SITE_ID_MAP } from './constants';

const useStyles = makeStyles({
  drawer1: {
    zIndex: '15000!important',
    '& .MuiDrawer-paper': {
      borderRadius: '12px 12px 0 0',
    },
  },
  drawer2: {
    zIndex: '15000!important',
    '& .MuiDrawer-paper': {
      height: '100%',
    },
  },
});

/**
 * GA035 / m27 物件選択
 * @module selectProduct
 * @param {obj} props
 * @param props.open - 開閉state
 * @param props.setOpen - 開閉setState
 * @param props.onClose - selectProductを閉じる
 * @param props.product - 物件を1つだけ選択する場合に使うstate
 * @param props.setProduct - 物件を1つだけ選択する場合に使うsetState
 * @param props.multi - 複数物件flg
 * @param props.item - 複数物件一覧リストから選択した物件カードの情報が入ったオブジェクト
 * @param props.items - 複数物件を選択している際に使うstate
 * @param props.setItems - 複数物件を選択している際に使うsetState
 * @param props.showTypes - 物件種別の選択肢を格納 期待値：array ex)['OHD']
 * @returns {jsx}
 */
function SelectProduct(props) {
  const {
    open,
    setOpen,
    onClose,
    product,
    setProduct,
    multi,
    item,
    items,
    setItems,
    // 物件種別の配列、OHDのみを表示する場合['OHD']にすればいい、
    // 空の場合全ての種別が表示される
    showTypes,
  } = props;

  const dispatch = useDispatch();

  const isSP = useSelector((state) => state.deviceTypeSlice.isSp);

  // 前回の検索キーワードを保持
  const searchProductNameRef = useRef();
  // get: common004 物件情報取得
  const getProductsFunction = async (params) => {
    await getProductsApi(params)
      .then((res) => {
        if (isSP) {
          // 検索キーワードが変更された際は、新しい検索結果のみGlobalStateに保持する
          if (params.productName && params.productName !== searchProductNameRef.current) {
            dispatch(setGetProductsApi(res.data));
            searchProductNameRef.current = params.productName;
          } else {
            dispatch(addGetProductsApi(res.data));
          }
        } else {
          dispatch(setGetProductsApi(res.data));
        }
      })
      .catch((err) => console.log(err));
  };

  const classes = useStyles();
  const [openProductSearch, setOpenProductSearch] = useState(true);
  const [selectedProduct, setSelecedtProduct] = useState(null);
  const [productType, setProductType] = useState(product.productType ? product.productType : 'OHD');
  const objInitial = {
    isIncludeFinished: 0,
    limit: 20,
    offset: 0,
  };

  const limitOffsetRef = useRef(objInitial);

  useEffect(() => {
    limitOffsetRef.current = objInitial;
    dispatch(initializeProducts());
  }, []);
  const [obj, setObj] = useState(objInitial);
  const setClear = () => {
    setObj(objInitial);
  };
  const isDisabled = (productType === SELECT_PRODUCT_TYPES.OHD || productType === SELECT_PRODUCT_TYPES.ALL_PRODUCT) && selectedProduct === null;

  /**
   * OHD、全物件選択の場合...物件選択必須
   * ホーク・ワン、マイソクの場合...物件未選択でも選択可能
   */
  const condition = (productType === SELECT_PRODUCT_TYPES.OHD || productType === SELECT_PRODUCT_TYPES.ALL_PRODUCT)
            || ((productType === SELECT_PRODUCT_TYPES.HAWK_ONE || productType === SELECT_PRODUCT_TYPES.MAISOKU) && selectedProduct);

  const sendProduct = () => {
    // 物件名もしくは種別を前の画面に返す処理
    if (multi) { // 複数物件の場合
      if (condition) {
        setItems(items.map((i) => {
          if (i.id !== item.id) return i;
          return ({
            ...i,
            siteId: selectedProduct.siteId,
            productType: productType || '',
            // https://openhouse.backlog.jp/view/SFA_ASIAQUEST-4540#comment-187459192
            productName: selectedProduct ? selectedProduct.productNameInCompany : '',
          });
        }));
      } else {
        setItems(items.map((i) => {
          if (i.id !== item.id) return i;
          return {
            ...i,
            siteId: SITE_ID_MAP[productType],
            productType,
            productName: productType,
          };
        }));
      }
    }

    if (condition) {
      setProduct({
        ...product,
        productType,
        siteId: selectedProduct ? selectedProduct.siteId : '',
        productNumber: selectedProduct ? selectedProduct.productNumber : '',
        siteNumber: selectedProduct ? selectedProduct.siteNumber : '',
        // https://openhouse.backlog.jp/view/SFA_ASIAQUEST-4540#comment-187459192
        productName: selectedProduct ? selectedProduct.productNameInCompany : '',
      });
    } else {
      setProduct({
        ...product,
        productType,
        siteId: SITE_ID_MAP[productType],
        productName: productType,
        siteNumber: null,
      });
    }

    setOpen(false);
    setClear();
    setSelecedtProduct(null);
    setProductType(SELECT_PRODUCT_TYPES.OHD);
  };

  const deselect = () => {
    setOpen(false);
    setSelecedtProduct(null);
    setProductType(SELECT_PRODUCT_TYPES.OHD);
    if (item !== undefined) {
      setItems(items.filter(i => i.id !== item.id));
    }
    setProduct({ productType: '', productName: '' });
    setClear();
  };

  // FIXME: SITE_IDは仮でいれてます。このSITEIDはSFA_ASIAQUEST-2593対応時に削除
  const selectType = (val) => {
    switch (val) {
      case [SELECT_PRODUCT_TYPES.OHD]:
      case '':
        setProduct({ ...product, productType: '', productName: '', siteId: null });
        break;
      default:
        setProduct({ ...product, productType: val, productName: val, siteId: SITE_ID_MAP[val] });
        break;
    }
  };

  // 物件選択
  const [openChoice, setOpenChoice] = useState(false);
  const handleOpenChoice = () => {
    setOpenChoice(!openChoice);
  };
  const handleCloseChoice = () => {
    limitOffsetRef.current = objInitial;
    dispatch(initializeProducts());
    setOpenChoice(false);
    setObj(objInitial);
  };

  // 物件種別
  const changeKinds = () => {
    handleOpenChoice();
  };

  useEffect(() => {
    if (open) setOpenProductSearch(productType in SEARCHABLE_PRODUCT_TYPES);
  }, [open]);

  useEffect(() => {
    if (isSP && productType === '') setProduct({ productType: '', productName: '' });
  }, [productType]);

  let contents;

  if (isSP) {
    contents = (
      <Grid>
        <Drawer
          anchor="bottom"
          open={open}
          onClose={onClose}
          className={classes.drawer1}
        >
          <SelectProductType
            item={item}
            items={items}
            setItems={setItems}
            setOpenProductSearch={setOpenProductSearch}
            productType={productType}
            setProductType={setProductType}
            changeKinds={changeKinds}
            product={product}
            onClose={onClose}
            selectType={selectType}
            multi={multi}
            showTypes={showTypes}
          />
        </Drawer>
        <Drawer
          anchor="top"
          open={openChoice}
          onClose={handleCloseChoice}
          className={classes.drawer2}
        >
          {openProductSearch
            ? <SelectProductTable
                obj={obj}
                setObj={setObj}
                selectedProduct={selectedProduct}
                setSelecedtProduct={setSelecedtProduct}
                handleCloseChoice={handleCloseChoice}
                setClear={setClear}
                sendProduct={sendProduct}
                getProductsFunction={getProductsFunction}
                productType={productType}
                limitOffsetRef={limitOffsetRef}
            /> : ''}
        </Drawer>
      </Grid>
    );
  } else {
    contents = (
      <CommonDialog
        open={open}
        onClose={onClose}
        title="物件選択ウィンドウ"
        width={660}
        buttons={[
          {
            label: '選択解除',
            onClick: deselect,
            type: 'secondary',
          },
          {
            label: '選択',
            onClick: sendProduct,
            disabled: isDisabled,
          },
        ]}
      >
        <SelectProductType
          item={item}
          items={items}
          setItems={setItems}
          setOpenProductSearch={setOpenProductSearch}
          productType={productType}
          setProductType={setProductType}
          changeKinds={changeKinds}
          onClose={onClose}
          selectType={selectType}
          multi={multi}
          product={product}
          showTypes={showTypes}
        />
        {openProductSearch
          ? <SelectProductTable
              obj={obj}
              setObj={setObj}
              selectedProduct={selectedProduct}
              setSelecedtProduct={setSelecedtProduct}
              getProductsFunction={getProductsFunction}
              productType={productType}
          /> : ''}
      </CommonDialog>
    );
  }

  return (
    <Grid>
      {contents}
    </Grid>
  );
}

export default SelectProduct;

export {
  SELECT_PRODUCT_TYPES,
};
