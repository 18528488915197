import React, {
  useMemo,
} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
} from '@material-ui/core';
import { Tree, TREE_TYPES } from '../../tree';
import { createTreeList } from '../commonFunc/sortNameHelpers';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxHeight: '100%',
    minHeight: '30%',
    overflow: 'auto',
  },
  parent: {
    position: 'relative',
    padding: 0,
    maxHeight: 'unset',
    '&::after': {
      content: '""',
      height: 'calc(100% - 25px)',
      position: 'absolute',
      backgroundColor: '#c8c8c8',
      width: '1px',
      left: '7.5px',
      top: '25px',
    },
  },
  child: {
    padding: '0 0 0 20px',
  },
  icons: {
    fill: theme.palette.primaryColor,
    width: '16px',
    backgroundColor: '#ffffff',
  },
  li: {
    padding: 0,
    zIndex: 1,
  },
  icon: {
    width: '15px',
    marginLeft: '4px',
    marginRight: '4px',
  },
  treeUser: {
    padding: '0px',
    margin: '0px',
    fontSize: '12px',
  },
  checkedUser: {
    background: 'linear-gradient(90deg, #A7F1FF 0%, #C2EEFF 100%)',
  },
}));
function UserSortNameTree(props) {
  const classes = useStyles();
  const {
    list,
    setSelectUser,
    searchKeyword,
  } = props;

  // TreeのValueのみ複製
  const treeList = useMemo(() => {
    return createTreeList(list, searchKeyword);
  }, [list, searchKeyword]);

  const handleSelectUser = (item) => {
    setSelectUser({
      userId: item.userId,
      userName: item.fullName,
    });
  };

  const onItemClick = (item) => {
    handleSelectUser(item);
  };

  return (
    <Grid id="user-tree-list-parent" className={`${classes.root}`}>
      <Tree
        list={treeList}
        onChange={onItemClick}
        defaultOpenTypes={searchKeyword ? [TREE_TYPES.division] : []}
      />
    </Grid>
  );
}

export default UserSortNameTree;
