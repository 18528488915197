/* eslint-disable max-len */
import { useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import UnsentMailComponent from '../../components/unsentMail/unsentMailComponent';
import UnsentMailComponentSp from '../../components/unsentMail/sp/unsentMailComponent';
import sendmailGetUnsendApi from '../../apis/sendmail/sendmailGetUnsendApi';
import sendmailUpdateApi from '../../apis/sendmail/sendmailUpdateApi';
import sendmailCancelApi from '../../apis/sendmail/sendmailCancelApi';
import smsGetUnsendApi from '../../apis/sms/smsGetUnsendApi';
import cancelSmsApi from '../../apis/sms/cancelSmsApi';

export default function UnsentMailContainer(props) {
  const { open, onClose } = props;
  const isSp = useSelector((state) => state.deviceTypeSlice.isSp);
  /**
   * @param {{
   *   offset: number;
   *   limit: number;
   * } | undefined} params デフォルト値として直前のリクエストの値を再利用する
   */
  const initialParam = useRef({
    offset: 0, // 開始位置
    limit: 20, // 取得数
    mailCode: 0, // 個別=0, 一斉=1
  });

  const [list, setList] = useState({
    individual: [], // 個別メール
    all: [], // 一斉メール
  });
  const [smsList, setSmsList] = useState({
    sms: [], // 個別SMS
  });
  const [mailCount, setMailCount] = useState({
    broadCastMailCount: 0,
    individuallyMailCount: 0,
  });
  const [smsCount, setSmsCount] = useState({
    individuallySmsCount: 0,
  });
  const [isSmsTab, setIsSmsTab] = useState(false);
  // 未送信APIをCallし、個別と一斉でsetList
  const getUnSendList = async (params) => {
    console.log('getUnSendList_start');
    await sendmailGetUnsendApi(params)
      .then(async (res) => {
        console.log({ res });
        // 個別(mailCode = 1 or 2)
        const individual = res.data.mails.filter(r => {
          return r.mailCode < 3;
        });
        // 一斉(mailCode = 3 or 4)
        const all = res.data.mails.filter(r => {
          return r.mailCode > 2;
        });

        setMailCount(res.data.mailCount);

        if (params.offset < 1) {
          setList({
            individual,
            all,
          });
        } else {
          // FIXKAI: ここ多分おかしい
          const initialData = Object.assign([], list);
          const concatIndividual = initialData.concat(individual);
          const concatAll = initialData.concat(all);
          setList({
            individual: concatIndividual,
            all: concatAll,
          });
        }
        // if (params.offset > 1)
      })
      .catch(error => console.log({ error }));
  };

  // 未送信SMSAPIをCallし、setSmsList
  const getUnSendSmsList = async (params) => {
    console.log('getUnSendSmsList_start');
    // TODO OH_KDSFA-1230
    await smsGetUnsendApi(params)
      .then(async (res) => {
        setSmsCount({ individuallySmsCount: res.data.count });
        setSmsList({
          sms: res.data.messages,
        });
        console.log({ res });
      })
      .catch(error => console.log({ error }));
    console.log('getUnSendSmsList_end');
  };

  // PUT [/sendmail/update/]API
  const updateUnSendMail = async (params, options) => {
    await sendmailUpdateApi(params)
      .then(async () => {
        await getUnSendList(options);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // 【sendmail005】メール送信停止
  const cancelSendMail = async (params, options) => {
    await sendmailCancelApi(params)
      .then(async (res) => {
        await getUnSendList(options);
        console.log(res);
      })
      .catch((err) => console.log(err));
  };

  // 【sms010】SMS送信キャンセル
  const cancelSms = async (params, options) => {
    await cancelSmsApi(params)
      .then(async (res) => {
        await getUnSendSmsList(options);
        console.log(res);
      })
      .catch((err) => console.log(err));
  };

  // 更新で/sendmail/getunsendmail/を再取得
  const refreshFunc = () => {
    if (isSmsTab) {
      getUnSendSmsList(initialParam.current);
    } else {
      getUnSendList(initialParam.current);
    }
  };

  const functions = {
    getUnSendList,
    updateUnSendMail,
    cancelSendMail,
  };
  const smsFunctions = {
    getUnSendSmsList,
    cancelSms,
  };

  return (
    <div>
      {isSp ? (
        <UnsentMailComponentSp
          list={list}
          refresh={refreshFunc}
          open={open}
          onClose={onClose}
          functions={functions}
          mailCount={mailCount}
          isSp={isSp}
        />
      ) : (
        <UnsentMailComponent
          list={list}
          smsList={smsList}
          refresh={refreshFunc}
          functions={functions}
          smsFunctions={smsFunctions}
          mailCount={mailCount}
          smsCount={smsCount}
          initialParam={initialParam}
          isSp={isSp}
          isSmsTab={isSmsTab}
          setIsSmsTab={setIsSmsTab}
        />
      )}
    </div>
  );
}
