import { useSelector } from 'react-redux';
import { Drawer, Grid, Typography, Button } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import commonTheme from '../../styles/theme';
import CommonDialog from '../../common/modal';

import SearchBox from './searchBox';
import SearchResult from './searchResult';
import commonStyles from '../../styles';
import SearchIcon from '../../icons/searchIcon';
import ZipTextField from './parts/zipTextField';

const useStyles = makeStyles({
  title: {
    /* Auto layout */
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '24px 24px 8px',

    /* OH-White */
    background: '#FFFFFF',

    /* Inside auto layout */
    flex: 'none',
    order: '0',
    alignSelf: 'stretch',
    flexGrow: '0',
    margin: '0px 0px',
  },
  headline: {
    filter: 'drop-shadow(0px 0px 8px rgba(51, 51, 51, 0.08))',
    borderBottom: '1px solid rgba(51, 51, 51, 0.04);',
  },
  textWrap: {
    [commonTheme.breakpoints.down('sm')]: {
      textAlign: 'center',
      paddingTop: '40px',
    },
  },
  wrap: {
    cursor: 'pointer',
    marginRight: 'auto',
    borderRight: '1px #c8c8c8 solid',
    padding: '4px 26px 4px 20px',
  },
  root: {
    /* Auto layout */
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    [commonTheme.breakpoints.down('sm')]: {
      height: '100%',
      padding: '0',
      overflowY: 'auto',
    },
  },
  mainGrid: {
    background: '#FFFFFF',
    border: '1px solid #C8C8C8',
    boxSizing: 'border-box',
    width: '100%',
    [commonTheme.breakpoints.up('md')]: {
      padding: '8px',
      margin: '8px 0px',
      '& > .MuiGrid-root': {
        width: '100%',
      },
    },
    [commonTheme.breakpoints.down('sm')]: {
      backgroundColor: 'transparent',
      border: 'none',
      width: '100%',
    },
  },
  subGrid: {
    position: 'relative',
    [commonTheme.breakpoints.down('sm')]: {
      display: 'block',
      background: '#fff',
      marginTop: '16px',
    },
    [commonTheme.breakpoints.up('md')]: {
      height: '40px',
    },
  },
  tableWrap: {
    [commonTheme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  buttonWrap: {
    [commonTheme.breakpoints.down('sm')]: {
      padding: '0 0 0 16px',
    },
  },
  button: {
    [commonTheme.breakpoints.down('sm')]: {
      textTransform: 'none',
      width: '100%',
      padding: '18px 22px 18px 16px',
      borderBottom: '1px solid #F3F3F3',
      flexWrap: 'nowrap',
      alignItems: 'center',
    },
    '& .MuiButton-label': {
      [commonTheme.breakpoints.down('sm')]: {
        display: 'flex',
        justifyContent: 'space-between',
      },
      '& .MuiTypography-root': {
        fontSize: '13px',
      },
    },
  },
  label: { flexShrink: 0, marginRight: 'auto' },
  drawer: {
    position: 'relative',
    [commonTheme.breakpoints.down('sm')]: {
      zIndex: '1302!important',
      '& .MuiPaper-root': {
        width: 'calc(100% - 50px)',
        overflow: 'visible',
        boxShadow: 'none',
      },
    },
    '& > .MuiBackdrop-root': {
      backgroundColor: 'rgba(0, 0, 0, 0.15)',
    },
  },
  close: {
    position: 'absolute',
    left: '-41px',
    top: '6px',
    width: '35px',
    height: '35px',
    fill: '#fff',
  },
  footer: {
    [commonTheme.breakpoints.down('sm')]: {
      /* Auto layout */
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'absolute',
      left: '0',
      bottom: '0',
      width: '100%',
      background: '#fff',
      padding: '24px 0',
    },
  },
  footButton: {
    padding: '8px 22px',
    margin: '0px 4px',
    minWidth: '86px',
  },
});

export default function SearchZips(props) {
  const { resultList = [],
    onClickTr,
    getAddressList,
    isClickSearchButton,
    addressOpen,
    onAddressClose,
    open2,
    setOpen2,
    clickSelectFunc,
    clickClearFunc,
    initialParam,
    setInitialParam,
  } = props;
  const common = commonStyles();
  const classes = useStyles();
  const isSp = useSelector((state) => state.deviceTypeSlice.isSp);

  // サイドバーの表示
  const toggleOpen2 = () => {
    setOpen2(!open2);
  };

  const dataList = [
    { key: 'code', label: '郵便番号', rule: '前方一致', md: 3, text: initialParam.code },
    { key: 'address', label: '住所', rule: '部分一致', md: 9, text: initialParam.address },
  ];

  return (
    <CommonDialog
      open={addressOpen}
      onClose={onAddressClose}
      title={isSp ? '郵便番号検索' : '郵便番号ウィンドウ'}
      width={660}
    >
      <Grid className={classes.root}>
        <Grid className={classes.mainGrid}>
          {isSp ? (
            <>
              <Grid className={`${classes.tableWrap}`}>
                {resultList.length === 0 ? (
                  <Grid className={classes.textWrap}>
                    <Typography className={common.bass}>0件<br />検索条件を見直してください</Typography>
                  </Grid>
                ) : (
                  isClickSearchButton && <SearchResult
                    resultList={resultList}
                    onClickTr={onClickTr}
                    onClose={onAddressClose}
                  />
                )}
              </Grid>
              {/* 検索のサイドバー用モーダル */}
              <Grid
                item
                className={`${classes.wrap} ${common.onlySp}`}
              >
                <Grid
                  container
                  alignItems="center"
                >
                  <Drawer
                    anchor="right"
                    open={open2}
                    onClose={toggleOpen2}
                    className={`${classes.drawer} ${common.onlySp}`}
                  >
                    <Close
                      className={classes.close}
                      onClick={toggleOpen2}
                    />
                    <Grid className={`${classes.title} ${classes.headline}`}>
                      <Typography className={`${common.title2}`}>検索</Typography>
                    </Grid>
                    <Grid container className={`${classes.subGrid}`}>
                      {dataList.map((data) => (
                        <Grid container className={`${classes.buttonWrap} ${classes.button}`} key={data.key}>
                          <Typography className={`${common.title4} ${classes.label}`}>
                            {data.label}（{data.rule}）
                          </Typography>
                          <ZipTextField
                            textField={data}
                            getAddressList={getAddressList}
                            initialParam={initialParam}
                            setInitialParam={setInitialParam}
                          />
                        </Grid>
                      ))}
                    </Grid>
                    <Grid className={classes.footer}>
                      <Button
                        className={common.buttonsSecondary}
                        onClick={() => clickClearFunc()}
                      >
                        全クリア
                      </Button>
                      <Button
                        className={common.buttonsPrimary}
                        onClick={() => clickSelectFunc()}
                      >
                        完了
                      </Button>
                    </Grid>
                  </Drawer>
                </Grid>
              </Grid>
            </>
          ) : (
            <Grid>
              <SearchBox
                getAddressList={getAddressList}
                initialParam={initialParam}
                setInitialParam={setInitialParam}
              />
              {resultList.length ? (
                <SearchResult
                  resultList={resultList}
                  onClickTr={onClickTr}
                  onClose={onAddressClose}
                />
              ) : null}
            </Grid>
          )}
        </Grid>
      </Grid>
      {/* 検索ボタン */}
      <Button onClick={toggleOpen2} className={`${common.searchButton} ${common.onlySp}`}>
        <SearchIcon />
      </Button>
    </CommonDialog>
  );
}
