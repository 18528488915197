import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import ItemCheckBoxAll from './itemCheckBoxAll';
import ItemCheckBox from './itemCheckBox';
import Modal from './../../modal';

import LABEL_COLUMNNAME_MAP from '../../../../constants/infoChangeHistory';

const useStyles = makeStyles((theme) => ({
  dialog: {
    '& .MuiDialog-paperWidthSm': {
      maxWidth: 'none',
    },
  },
  paper: {
    width: 1024,
    background: '#fff',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: 16,
    borderBottom: '1px solid #C8C8C8',
  },
  btn: {
    fontFamily: 'Roboto',
    fontSize: 15,
    fontWeight: '700',
    lineHeight: 1.6,
    letterSpacing: 0,
    padding: '8px 22px',
  },
  btnClear: {
    color: theme.palette.primaryColor,
  },
  content: {
    background: '#F3F3F3',
    padding: 32,
  },
  list: {
    display: 'flex',
    alignItems: 'center',
    border: '1px solid #C8C8C8',
    padding: '10px 0 10px 10px',
    background: '#fff',
    '&:not(:last-child)': {
      marginBottom: 10,
    },
    '&:nth-child(8) > div > div > div': {
      minWidth: 120,
      marginRight: 0,
    },
    '&:nth-child(8) > div > div:nth-child(3) > div': {
      marginRight: 16,
    },
    '&:nth-child(8) > div > div:nth-child(4) > div': {
      marginRight: 16,
    },
    '&:nth-child(11) > div > div > div': {
      minWidth: 108,
    },
    '&:nth-child(12) > div > div > div': {
      minWidth: 108,
    },
  },
  all: {
    width: 92,
    '& .MuiTypography-root': {
      fontWeight: 700,
    },
  },
  item: {
    display: 'flex',
    alignItems: 'center',
    '&:not(:last-child)': {
      marginBottom: 12,
    },
  },
  itemBox: {
    minWidth: 102,
    marginRight: 16,
  },
  checkBox: {
    '& .MuiFormControlLabel-root': {
      margin: 0,
    },
    '& .MuiCheckbox-root': {
      padding: '0',
      marginRight: 2,
    },
    '& .MuiTypography-root': {
      fontFamily: 'Roboto',
      fontSize: 12,
      lineHeight: 1.2,
      letterSpacing: 0,
      marginTop: 2,
    },
    '& .Mui-checked': {
      color: theme.palette.primaryColor,
    },
    '& .MuiSvgIcon-root': {
      fontSize: 22,
    },
  },
  footer: {
    padding: '20px 0',
    borderTop: '1px solid #C8C8C8',
  },
  footerBtn: {
    margin: '0 auto',
  },
}));

export default function Item(props) {
  const { open, onClose, values, setValues } = props;

  const classes = useStyles();

  const [row, setRow] = useState({
    no: 0,
    check: 0,
  });
  const [selectedItmes, setSelectedItmes] = useState(values);

  const clickCheckFunc = (e, item) => {
    const isChecked = e.target.checked;
    if (isChecked) {
      setSelectedItmes([...selectedItmes, item]);
    } else {
      setSelectedItmes(
        selectedItmes.filter((item1) => item.label !== item1.label),
      );
    }
  };

  const clickAllCheckFunc = (e, items) => {
    const isChecked = e.target.checked;
    let tmpItems = selectedItmes.filter(
      (selectedItme) => !items.rows.some((item) => item.label !== selectedItme.label),
    );
    if (isChecked) {
      for (const row1 of items.rows) {
        tmpItems = tmpItems.concat(row1.objs);
      }
    }
    setSelectedItmes(tmpItems);
  };

  const onChoice = () => {
    setValues(selectedItmes);
    console.log('selectedItmes', selectedItmes);
    onClose();
  };

  const body = (
    <Grid className={classes.box}>
      {LABEL_COLUMNNAME_MAP.map((data1, i) => (
        <Grid key={data1.label} className={classes.list}>
          <Grid className={`${classes.all} ${classes.checkBox}`}>
            <ItemCheckBoxAll
              label={data1.label}
              flg="0"
              index={i}
              row={row}
              setRow={setRow}
              clickCheckFunc={(e) => clickAllCheckFunc(e, data1)}
            />
          </Grid>
          <Grid className={classes.items}>
            {data1.rows.map((data2) => (
              <Grid
                key={data2.label}
                className={`${classes.item} ${classes.checkBox}`}
              >
                {data2.objs.map((data3) => (
                  <Grid
                    key={data3.label}
                    className={`${classes.itemBox} ${classes.checkBox}`}
                  >
                    <ItemCheckBox
                      item={data3}
                      flg={
                        selectedItmes.some((selectedItme) => selectedItme.label === data3.label)
                      }
                      index={i}
                      row={row}
                      selectedItmes={selectedItmes}
                      clickCheckFunc={clickCheckFunc}
                    />
                  </Grid>
                ))}
              </Grid>
            ))}
          </Grid>
        </Grid>
      ))}
    </Grid>
  );

  return (
    <Modal
      open={open}
      onClose={onClose}
      maxWidth={1024}
      title="項目ウインドウ"
      buttons={[{
        onClick: onChoice,
        label: '選択',
      }]}
    >
      {body}
    </Modal>
  );
}
