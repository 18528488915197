import { useState, useMemo, useCallback, useEffect } from 'react';
import { Grid, Tabs, Tab, makeStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { Store as StoreIcon } from '@material-ui/icons';
import commonTheme from '../../styles/theme';
import { useStoreGroupsContext } from './context';
// import { Tree } from '../../common/tree';

import SearchInput from '../common/searchInput';
import CheckboxList from '../common/checkboxList';

const useStyles = makeStyles((theme) => ({
  root: {
    background: '#fff',
    color: '#333',
    fontFamily: 'Roboto',
    fontSize: 13,
    lineHeight: 1.6,
    letterSpacing: 0,
    [commonTheme.breakpoints.down('sm')]: {
      padding: '0 16px',
    },
    '& .MuiTabs-flexContainer': {
      [commonTheme.breakpoints.down('sm')]: {
        justifyContent: 'space-between',
      },
    },
    '& .MuiTab-root': {
      minWidth: 'auto',
      padding: 8,
      [commonTheme.breakpoints.down('sm')]: {
        width: '20%',
      },
    },
    '& .MuiTab-root.Mui-selected': {
      color: theme.palette.primaryColor,
      fontWeight: '700',
    },
    '& .MuiTabs-indicator': {
      background: theme.palette.primaryColor,
    },
    '& .MuiTab-wrapper': {
      fontSize: 13,
    },
  },
  panel: {
    background: '#fff',
    position: 'absolute',
    top: 123,
    bottom: 24,
    left: 24,
    right: 24,
    overflowY: 'auto',
    padding: 20,
  },
  icon: {
    fill: '#666',
    fontSize: 15,
  },
  contentBtn: {
    background: '#fff',
    borderRadius: 4,
    width: 148,
    color: theme.palette.primaryColor,
    fontFamily: 'Roboto',
    fontWeight: '700',
    fontSize: 15,
    lineHeight: 1.6,
    letterSpacing: 0,
    padding: '9px 8px 7px',
    marginRight: 8,
    marginBottom: 8,
  },
}));

export default function Content() {
  const {
    areaList,
    storeGroupsData,
    onChange,
    defaultSelected,
    // onCancel,
    currentArea,
  } = useStoreGroupsContext();

  const classes = useStyles();

  const [index, setIndex] = useState(0);
  const [focusedStores, setFocusedStores] = useState(defaultSelected);

  const areaCode = useMemo(() => {
    let result;
    for (const d of areaList) {
      if (d.areaCode === currentArea?.areaCode) {
        result = d.areaCode;
      }
    }
    return result;
  }, [areaList, index]);

  const handleChange = useCallback((e, val) => {
    setIndex(val);
  }, []);

  const singleArea = () => {
    const result = [];
    for (const area of areaList) {
      if (area.areaCode === currentArea?.areaCode) {
        result.push(area);
      }
    }
    return result;
  };

  const storeList = storeGroupsData[areaCode];
  // const allList = flatLists(storeGroupsData);

  const [clearCount, setClearCount] = useState(0);

  const clearAllCheckbox = () => {
    // 一連のクリア処理を発動させる
    setClearCount(clearCount + 1);
  };

  // 検索時、対象が一番上になるようにスクロール
  useEffect(() => {
    if (focusedStores !== defaultSelected) {
      const target = document.getElementById(focusedStores[0].id);
      target?.scrollIntoView();
    }
  }); [focusedStores];

  return (
    <>
      <Grid>
        <SearchInput list={storeList} setSearchResult={setFocusedStores} />
      </Grid>
      <Button
        className={classes.contentBtn}
        onClick={clearAllCheckbox}
      >
        すべて選択解除
      </Button>
      <Grid>
        <Tabs value={index} onChange={handleChange} className={classes.root}>
          {singleArea().map((item) => (
            <Tab key={item.areaCode} label={item.areaName} />
          ))}
        </Tabs>
        <Grid className={classes.panel}>
          <CheckboxList
            list={storeList}
            onChange={onChange}
            icon={<StoreIcon className={classes.icon} />}
            defaultSelected={focusedStores}
            clearCount={clearCount}
          />
        </Grid>
      </Grid>
      {/* <Button onClick={() => onCancel()}>全て解除する</Button> */}
    </>
  );
}
