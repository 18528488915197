import React from 'react';
import { FormControl, Select, MenuItem, makeStyles } from '@material-ui/core';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import positionChange from '../../commonFunction/positionChange';

const useStyles = makeStyles(() => ({
  formControl: {
    width: '100%',
    '& div::before': {
      borderBottom: '1px solid transparent',
    },
  },
  selectEmpty: {
    fontFamily: 'Roboto',
    fontSize: 13,
    lineHeight: 1.6,
    fontWeight: '700',
    '& div': {
      padding: '0 24px 0 0',
      marginTop: 1,
    },
  },
}));

function CustomMainSelect(props) {
  const classes = useStyles();
  const { initial } = props;
  const menusKeys = Object.keys(initial.obj.menus);

  const [val, setVal] = React.useState(initial.state);

  const handleChange = (e) => {
    setVal(e.target.value);
  };

  React.useEffect(() => {
    setVal(initial.state);
  }, [initial.state]);

  return (
    <FormControl
      className={`${classes.formControl} ${classes.formControl2}`}
      disabled={initial.disabled}
    >
      <Select
        value={val}
        displayEmpty
        name={initial.name}
        className={classes.selectEmpty}
        inputProps={{ 'aria-label': 'Without label' }}
        onChange={(e) => {
          const cancelUpdate = initial.change?.(e, initial);
          if (!cancelUpdate) handleChange(e);
        }}
        onBlur={(e) => {
          initial.set?.(e, initial);
        }}
        onOpen={positionChange}
      >
        {menusKeys.map((data) => (
          <MenuItem key={data} value={data}>
            {initial.obj.menus[data]}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export default React.memo(CustomMainSelect);
