import {
  useState,
  useEffect,
  useCallback,
  createContext,
  useContext,
  useRef,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
// [/schedules/actionListDay]API用
import schedulesGetActionDateApi from '../../apis/schedules/schedulesGetActionDateApi';
import { getSchedulesActionDateApi } from '../../store/schedules/schedulesGetActionDateSlice';
// [/schedules/actionListWeek]API用
import schedulesGetActionWeekApi from '../../apis/schedules/schedulesGetActionWeekApi';
import { getSchedulesActionWeekApi } from '../../store/schedules/schedulesGetActionWeekSlice';
// [/schedules/actionDetail]API用
import schedulesGetActionDetailApi from '../../apis/schedules/schedulesGetActionDetailApi';
import { getSchedulesDetailApi } from '../../store/schedules/schedulesGetActionDetailSlice';
// [/schedules/actionUpdate]API用(PUT)
import schedulesUpdateApi from '../../apis/schedules/schedulesUpdateApi';
import { setSchedulesUpdateApi } from '../../store/schedules/schedulesUpdateSlice';
// [/schedules/actionUpdate]API用(POST)
import schedulesPostApi from '../../apis/schedules/schedulesPostApi';
import { setSchedulesPostApi } from '../../store/schedules/schedulesPostSlice';
// [/schedules/actionUpdate]API用(DELETE)
import schedulesDeleteApi from '../../apis/schedules/schedulesDeleteApi';
import { setSchedulesDeleteApi } from '../../store/schedules/schedulesDeleteSlice';
// [/schedules/submission]API用(POST) 行動予定提出
import schedulesSubmissionApi from '../../apis/schedules/schedulesSubmissionApi';
import { setSchedulesSubmissionApi } from '../../store/schedules/schedulesSubmissionSlice';
// 案内情報更新
import schedulesPutGuidanceByGuidanceIdApi from '../../apis/schedules/schedulesPutGuidanceByGuidanceIdApi';
// SPで使用(SPではツリーが初期表示で読み込まれないため、APIを通してデフォルト設定を行う)
import divisionsGetTreeApi from '../../apis/divisions/divisionsGetTreeApi';
import { getUserDivListApi } from '../../store/user/userDivListSlice';
import getTreeObject from '../../components/common/userTreeNew/commonFunc/getTreeObject';
import { useQuery, useRerender } from '../../hooks';
import { createTreeList, getTreeItemByDivisionId } from '../../components/common/userTreeNew/commonFunc/helpers';

import store from '../../store';

const ActionScheduleContext = createContext({});

export function ActionScheduleProvider(props) {
  const {
    children,
  } = props;
  console.count('ActionScheduleProvider');

  // ログイン中のユーザーの情報を取得
  const resHeader = useSelector((state) => state.responseHeader);
  // ログイン情報
  const initLoginData = {
    mainDivision: resHeader.mainDivision, // ログインユーザーのメイン所属組織
  };

  const userDiv = useSelector((state) => state.userDivList.userDivList);

  const { rerender, rerenderKey } = useRerender();

  // パラメータからIDを取得
  const [
    areaId,
    tenpoId,
    kaId,
  ] = useQuery(['area', 'tenpo', 'ka']);

  // SP・PCでの分岐用
  const isSp = useSelector((state) => state.deviceTypeSlice.isSp);
  // 現在日時
  const [nowDate] = useState(new Date());

  // 対象日時
  const [actionScheduleDate, setActionScheduleDate] = useState(new Date());

  // 日付変換 yyyymmdd
  const dateConversion = (d) => {
    const year = String(d.getFullYear());
    const month = ('0' + String(d.getMonth() + 1)).slice(-2);
    const day = ('0' + String(d.getDate())).slice(-2);
    const ymd = year + month + day;
    return ymd;
  };

  // 日付変換 yyyy/mm/dd
  const dateConversion2 = (d) => {
    const year = String(d.getFullYear());
    const month = ('0' + String(d.getMonth() + 1)).slice(-2);
    const day = ('0' + String(d.getDate())).slice(-2);
    const ymd = year + '/' + month + '/' + day;
    return ymd;
  };

  // 検索条件
  const [searchCount, setSearchCount] = useState(0);

  const chekedSearchInitial = {
    atractingCustomers: false, // 源泉
    pursuit: false, // 追客
    business: false, // 業務
    guidance: false, // 案内
    achievementsRegistered: false, // 実績登録済み
    contracted: false, // 契約済み
    others: false, // その他
    isPairCheck: false, // 揃い
  };
  const [chekedSearch, setChekedSearch] = useState(chekedSearchInitial);

  const searchInitial = {
    atractingCustomers: false, // 源泉
    pursuit: false, // 追客
    business: false, // 業務
    guidance: false, // 案内
    achievementsRegistered: false, // 実績登録済み
    contracted: false, // 契約済み
    others: false, // その他
    guidanceType: [], // 案内種別
    numberOfGuidesMin: 0, // 案内回数
    numberOfGuidesMax: 0, // 案内回数
    isPairCheck: false, // 揃い
  };
  const searchConditionsRef = useRef(searchInitial);

  const listSearchFunction = (array) => {
    const searchArray = [];
    let listSearch = array[0].actions;

    if (searchConditionsRef.current.atractingCustomers) {
      // 源泉
      searchArray.push(1, 2, 6, 7);
    }
    if (searchConditionsRef.current.pursuit) {
      // 追客
      searchArray.push(3);
    }
    if (searchConditionsRef.current.business) {
      // 業務
      searchArray.push(4);
    }
    if (searchConditionsRef.current.others) {
      // その他
      searchArray.push(5);
    }

    // 行動予定が選択されている場合
    if (
      searchConditionsRef.current.atractingCustomers
      || searchConditionsRef.current.pursuit
      || searchConditionsRef.current.business
      || searchConditionsRef.current.guidance
      || searchConditionsRef.current.achievementsRegistered
      || searchConditionsRef.current.contracted
      || searchConditionsRef.current.others
    ) {
      if (searchConditionsRef.current.guidance) { // 案内にチェックが入っている場合
        if (searchConditionsRef.current.achievementsRegistered) { // 実績登録済みにチェックが入っている場合
          if (searchConditionsRef.current.contracted) { // 契約済みにチェックが入っている場合
            listSearch = listSearch.filter((item) => {
              return (
                searchArray.includes(item.actionScheduleCode)
                || item.guidanceId
                || (item.guidanceId
                  && (item.customerStatusCode === 10 || item.isResultRegister === 1))
              );
            });
          } else {
            listSearch = listSearch.filter((item) => {
              return (
                searchArray.includes(item.actionScheduleCode)
                || item.guidanceId
                || (item.guidanceId && item.isResultRegister === 1)
              );
            });
          }
        } else if (searchConditionsRef.current.contracted) { // 契約済みにチェックが入っている場合
          listSearch = listSearch.filter((item) => {
            return (
              searchArray.includes(item.actionScheduleCode)
              || item.guidanceId
              || (item.guidanceId && item.customerStatusCode === 10)
            );
          });
        } else {
          listSearch = listSearch.filter((item) => {
            return (
              searchArray.includes(item.actionScheduleCode)
              || item.guidanceId
            );
          });
        }
      } else if (searchConditionsRef.current.contracted) { // 契約済みにチェックが入っている場合
        if (searchConditionsRef.current.achievementsRegistered) { // 実績登録済みにチェックが入っている場合
          if (searchConditionsRef.current.contracted) { // 契約済みにチェックが入っている場合
            listSearch = listSearch.filter((item) => {
              return (
                searchArray.includes(item.actionScheduleCode)
                || (item.guidanceId && (item.customerStatusCode === 10
                  || item.isResultRegister === 1))
              );
            });
          } else {
            listSearch = listSearch.filter((item) => {
              return (
                searchArray.includes(item.actionScheduleCode)
                || (item.guidanceId && item.isResultRegister === 1)
              );
            });
          }
        } else {
          listSearch = listSearch.filter((item) => {
            return (
              searchArray.includes(item.actionScheduleCode)
              || (item.guidanceId && item.customerStatusCode === 10)
            );
          });
        }
      } else if (searchConditionsRef.current.achievementsRegistered) { // 実績登録済みにチェックが入っている場合
        listSearch = listSearch.filter((item) => {
          return (
            searchArray.includes(item.actionScheduleCode)
            || (item.guidanceId && item.isResultRegister === 1)
          );
        });
      } else {
        listSearch = listSearch.filter((item) => {
          return (
            searchArray.includes(item.actionScheduleCode)
          );
        });
      }
    }

    // 案内種別が選択されている場合
    if (searchConditionsRef.current.guidanceType.length > 0
      && !(searchConditionsRef.current.guidanceType.length === 1
        && searchConditionsRef.current.guidanceType.includes(9))) {
      listSearch = listSearch.filter((item) => {
        return (
          (item.guidanceId
            && searchConditionsRef.current.guidanceType.includes(item.guidanceStatusCode))
          || !item.guidanceId
        );
      });
    }

    // 揃いが選択されている場合
    if (searchConditionsRef.current.isPairCheck) {
      listSearch = listSearch.filter((item) => {
        return (
          (item.guidanceId && item.isPairCheck === 1)
          || !item.guidanceId
        );
      });
    }

    // 案内回数
    switch (Number(searchConditionsRef.current.numberOfGuidesMin)) {
      case 0:
        switch (Number(searchConditionsRef.current.numberOfGuidesMax)) {
          case 0:
          case 7:
            break;
          default:
            // ～ 案内回数（最大）
            listSearch = listSearch.filter((item) => {
              return (
                (item.guidanceId
                  && item.guidanceCount
                  && item.guidanceCount
                    <= (Number(searchConditionsRef.current.numberOfGuidesMax) - 1))
                || !item.guidanceId
              );
            });
            break;
        }
        break;
      case 7:
        // 6回以上 ～
        listSearch = listSearch.filter((item) => {
          return (
            (item.guidanceId
              && item.guidanceCount
              && item.guidanceCount >= 6)
            || !item.guidanceId
          );
        });
        break;
      default:
        switch (Number(searchConditionsRef.current.numberOfGuidesMax)) {
          case 0:
          case 7:
            // 案内回数（最小）～
            listSearch = listSearch.filter((item) => {
              return (
                (item.guidanceId
                  && item.guidanceCount
                  && item.guidanceCount
                    >= (Number(searchConditionsRef.current.numberOfGuidesMin) - 1))
                || !item.guidanceId
              );
            });
            break;
          default:
            // 案内回数（最小） ～ 案内回数（最大）
            listSearch = listSearch.filter((item) => {
              return (
                (item.guidanceId
                  && item.guidanceCount
                  && item.guidanceCount
                    >= (Number(searchConditionsRef.current.numberOfGuidesMin) - 1)
                  && item.guidanceCount
                    <= (Number(searchConditionsRef.current.numberOfGuidesMax) - 1))
                || !item.guidanceId
              );
            });
            break;
        }
        break;
    }

    return listSearch;
  };

  // 検索
  const searchStart = () => {
    console.log('検索');
    setSearchCount(searchCount + 1);
    rerender();
  };

  // リセット
  const resteStart = () => {
    searchConditionsRef.current = searchInitial;
    setChekedSearch(chekedSearchInitial);
    setSearchCount(searchCount + 1);
    rerender();
  };

  const searchInput = {
    searchStart,
    resteStart,
    listSearchFunction,
    searchConditionsRef,
    chekedSearch,
    setChekedSearch,
    searchCount,
  };

  // 組織ツリー
  const [isOpenTree, setIsOpenTree] = useState(false);
  const [selectUser, setSelectUser] = useState({ userId: 0, userName: '' });
  const [selectDivision, setSelectDivision] = useState({ divId: 0, divisionName: '-' });

  // ツリー選択時、選択配下のすべての情報がsetされる 参照先：【division004】組織ツリー取得
  const [targetDivisionTree, setTargetDivisionTree] = useState(null);

  // 【division004】組織ツリー取得
  const userDivList = useSelector((state) => state.userDivList.userDivList);

  // /schedules/actions/day/{divisionId}
  // 【schedule008】行動予定一覧取得(日)
  const actionsDay = useSelector((state) => state.schedulesGetActionDate.schedulesGetActionDate);

  const dispatch = useDispatch();

  // /schedules/week/{divisionId}
  // 【schedule009】行動予定一覧取得(週)
  const actionsWeeks = useSelector((state) => state.schedulesGetActionWeek.schedulesGetActionWeek);

  // /schedules/actions/day/{divisionId}
  // 【schedule008】行動予定一覧取得(日)
  const getSchedulesDateFunction = async (path, date) => {
    await schedulesGetActionDateApi(path, date)
      .then((res) => {
        console.log(res.data);
        dispatch(getSchedulesActionDateApi(res.data));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // 案内情報を取得(日)
  const getSchedulesDate = () => {
    getSchedulesDateFunction(
      selectDivision.divId,
      dateConversion(actionScheduleDate),
    );
  };

  // /schedules/week/{divisionId}
  // 【schedule009】行動予定一覧取得(週)
  const getSchedulesWeekFunction = async (path, date) => {
    await schedulesGetActionWeekApi(path, date)
      .then((res) => {
        console.log(res.data);
        dispatch(getSchedulesActionWeekApi(res.data));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // 案内情報を取得(週)
  const getSchedulesWeek = () => {
    getSchedulesWeekFunction(
      selectDivision.divId,
      dateConversion(actionScheduleDate),
    );
  };

  // /schedules/actionDetail
  // 【schedule010】行動予定詳細取得
  const getSchedulesDetailFunction = async (path) => {
    await schedulesGetActionDetailApi(path)
      .then((res) => {
        dispatch(getSchedulesDetailApi(res.data));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // 再取得の制御
  const [reacquisitionFlg, setReacquisitionFlg] = useState(false);

  // PUT Update schedulesAction
  // 【schedule014】行動予定更新
  const schedulesUpdateApiFunction = async (scheduleId, modifyObj) => {
    await schedulesUpdateApi(scheduleId, modifyObj)
      .then(async (res) => {
        dispatch(setSchedulesUpdateApi(res.data));
        console.log(res.data);
        if (reacquisitionFlg) {
          getSchedulesDate();
          setReacquisitionFlg(false);
        }
      })
      .catch((err) => {
        console.log(err);
        return false;
      });
  };

  // POST Update schedulesAction
  // 【schedule011】行動予定登録
  const schedulesPostApiFunction = async (modifyObj) => {
    // ログインしているユーザをPOSTする
    const { responseHeader } = store.getState();
    const { userId } = responseHeader;
    await schedulesPostApi({ ...modifyObj, userId })
      .then(async (res) => {
        dispatch(setSchedulesPostApi(res.data));
        console.log(res.data);
        // GET APIの呼び出し（reacquisitionFlgで制御）
        if (reacquisitionFlg) {
          getSchedulesDate();
          setReacquisitionFlg(false);
        }
      })
      .catch((err) => {
        console.log(err);
        return false;
      });
  };

  // Delete API
  const schedulesDeleteApiFunction = async (path) => {
    await schedulesDeleteApi(path)
      .then((res) => {
        dispatch(setSchedulesDeleteApi(res.data));
        // GET APIの呼び出し（reacquisitionFlgで制御）
        if (reacquisitionFlg) {
          getSchedulesDate();
          setReacquisitionFlg(false);
        }
      })
      .catch((err) => {
        console.log(err);
        return false;
      });
  };

  // POST Update schedulesSubmission
  // 【schedule012】行動予定提出
  const schedulesSubmissionApiFunction = async (modifyObj) => {
    await schedulesSubmissionApi(modifyObj)
      .then(async (res) => {
        await dispatch(setSchedulesSubmissionApi(res.data));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // put: 案内情報更新 schedule004
  const putGuidanceByGuidanceIdFunction = useCallback(async (guidanceID, data) => {
    console.log({ schedule004Request: data });
    await schedulesPutGuidanceByGuidanceIdApi(guidanceID, data)
      .then(async (res) => {
        console.log({ updatedRes: res });
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  // 組織のツリーリスト取得
  const userDivListFunction = async () => {
    await divisionsGetTreeApi()
      .then((res) => {
        dispatch(getUserDivListApi(res.data));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (areaId) {
      setSelectDivision({ ...selectDivision, divId: areaId });
    } else if (tenpoId) {
      setSelectDivision({ ...selectDivision, divId: tenpoId });
    } else if (kaId) {
      setSelectDivision({ ...selectDivision, divId: kaId });
    } else if (Object.keys(initLoginData.mainDivision).length) {
      // ログインユーザーのメイン所属組織IDをセット
      setSelectDivision({ ...selectDivision, divId: initLoginData.mainDivision.id });
    }
    // SP：組織ツリー用APIを読み込み
    if (isSp) {
      const userDivParams = {
        isIgnoreExpire: 1,
        userStatusCode: [1, 2],
      };
      userDivListFunction(userDivParams);
    }
  }, [initLoginData.mainDivision.id]);

  // SP：組織ツリーのリストが読み込まれたら、初期描画をセット
  useEffect(() => {
    if (isSp) {
      // 初期表示する階層を参照
      const defaultSearchObj = getTreeObject(
        userDivList,
        selectDivision.divId,
        null,
        true,
      );
      if ('divisions' in defaultSearchObj) {
        const targetDivision = defaultSearchObj.divisions[0].treeObj;
        setTargetDivisionTree(targetDivision);
      }
    }
  }, [userDivList]);

  useEffect(() => {
    if (selectDivision.divId !== 0 && userDiv.length > 0) {
      if (isSp) {
        const resultTree = createTreeList(
          userDiv,
          null,
          null,
          false,
          { divisionFlg: true },
          {},
          false,
          setTargetDivisionTree,
        );
        if (areaId) {
          setTargetDivisionTree(getTreeItemByDivisionId(resultTree, areaId));
        } else if (tenpoId) {
          setTargetDivisionTree(getTreeItemByDivisionId(resultTree, tenpoId));
        } else if (kaId) {
          setTargetDivisionTree(getTreeItemByDivisionId(resultTree, kaId));
        } else {
          setTargetDivisionTree(getTreeItemByDivisionId(resultTree, selectDivision.divId));
        }
      }
    }
  }, [userDiv, selectDivision.divId]);

  // API用の関数をまとめたオブジェクト
  const functions = {
    getSchedulesDateFunction,
    getSchedulesWeekFunction,
    getSchedulesDetailFunction,
    schedulesUpdateApiFunction,
    schedulesPostApiFunction,
    schedulesDeleteApiFunction,
    schedulesSubmissionApiFunction,
    putGuidanceByGuidanceIdFunction,
  };

  // eslint-disable-next-line react/jsx-no-constructed-context-values
  const value = {
    functions,
    actionScheduleDate,
    setActionScheduleDate,
    actionsDay,
    actionsWeeks,
    getSchedulesDate,
    getSchedulesWeek,
    searchInput,
    setReacquisitionFlg,
    dateConversion,
    dateConversion2,
    isOpenTree,
    setIsOpenTree,
    selectUser,
    setSelectUser,
    selectDivision,
    setSelectDivision,
    targetDivisionTree,
    setTargetDivisionTree,
    nowDate,
    rerender,
    rerenderKey,
  };

  return (
    <ActionScheduleContext.Provider value={value}>
      {children}
    </ActionScheduleContext.Provider>
  );
}

export function useActionSchedule() {
  return useContext(ActionScheduleContext);
}
