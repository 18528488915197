import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, Button } from '@material-ui/core';
import { format } from 'date-fns';
import commonStyles from '../../../styles';

import ResponseRegisterTable1 from './responseRegisterTable1';
import ResponseRegisterTable2 from './responseRegisterTable2';
import Calendar from '../../calendar';
import SelectWindow from '../../../responseList/selectWindow';
import ResponseTypeModal from '../../responseType/responseTypeModal';

import store from '../../../../store';

import { dateFormatter } from '../../../../commonFunction';

import {
  ACQUISITION_CODE,
  MEMBER_STATUS_CODE,
  MEMBER_STATUS_NON_MEMBER,
  ISNOT_RESPONSE,
} from '../../../../constants';

import ResisterHouseWindow from '../../../common/resHouse/resisterHouseWindow/resisterHouseWindow';
import SelectAcquisitionWindow from './selectAcquisitionWindow';
import { RESPONSE_TYPE_ACQUISITION } from '../../../../constants/responseType';
import { UPDATE_TYPE_CODE } from '../../../../constants/apiParameterCode';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 8,
    marginBottom: 8,
  },
  response: {
    background: '#fff',
    border: '1px solid #C8C8C8',
    padding: 8,
    marginTop: 8,
  },
  responseBox: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '&:not(:last-child)': {
      marginBottom: 8,
    },
  },
  responseBox2: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 8,
  },
  responseBlock: {
    display: 'flex',
    alignItems: 'center',
  },
  responseBlock1: {
    display: 'flex',
    alignItems: 'center',
    width: '49.3%',
    padding: '3px 0',
  },
  responseBlock2: {
    display: 'flex',
    alignItems: 'center',
    width: '24%',
    padding: '3px 0',
  },
  responseBlock3: {
    display: 'flex',
    alignItems: 'center',
    width: '24%',
    background: '#C8C8C8',
    padding: '3px 0',
    height: 32,
  },
  responseTtl: {
    lineHeight: 1.6,
  },
  responseTxt: {
    fontSize: 13,
    lineHeight: 1.6,
    letterSpacing: 0,
    padding: 0,
    marginLeft: 4,
  },
  ttl: {
    whiteSpace: 'nowrap',
    marginRight: 8,
    '& > span': {
      color: '#D83420',
    },
  },
  calendar: {
    marginTop: '-6px',
    '& div': {
      border: 'none',
      padding: 0,
    },
    '& span': {
      padding: 0,
      fontSize: 13,
      fontWeight: 400,
      lineHeight: '1.6',
    },
  },
  button: {
    padding: 0,
    minHeight: 20,
    width: '100%',
    '& .MuiButton-label': {
      display: 'block',
      fontFamily: 'Roboto',
      fontSize: 13,
      lineHeight: 1.6,
      textAlign: 'left',
      letterSpacing: 0,
    },
    '&:hover': {
      background: `${theme.palette.primaryColor}29`,
    },
    '& .MuiTouchRipple-root': {
      display: 'none',
    },
  },
  select: {
    '& .MuiSelect-select.MuiSelect-select': {
      fontWeight: 400,
    },
    '& .MuiSelect-select': {
      '&:focus': {
        background: 'none',
      },
    },
  },
  responseTable: {
    borderTop: '1px solid #C8C8C8',
    paddingTop: 10,
  },
  addButton: {
    position: 'relative',
    display: 'block',
    padding: '0 0 0 20px',
    marginBottom: 10,
    '&::before': {
      content: '""',
      display: 'block',
      width: 12,
      height: 2,
      background: theme.palette.primaryColor,
      position: 'absolute',
      top: 9,
      left: 3,
    },
    '&::after': {
      content: '""',
      display: 'block',
      width: 2,
      height: 12,
      background: theme.palette.primaryColor,
      position: 'absolute',
      top: 4,
      left: 8,
    },
    '& .MuiButton-label': {
      display: 'block',
      fontFamily: 'Roboto',
      fontSize: 13,
      fontWeight: 600,
      color: theme.palette.primaryColor,
      lineHeight: 1.6,
      textAlign: 'left',
      letterSpacing: 0,
    },
    '&:hover': {
      background: `${theme.palette.primaryColor}29`,
    },
    '& .MuiTouchRipple-root': {
      display: 'none',
    },
  },
  txt: {
    color: '#8C8C8C',
    fontSize: 13,
    lineHeight: 1.6,
    letterSpacing: 0,
    padding: 0,
    marginLeft: 12,
  },
  textField: {
    display: 'block',
    '& > div::before': {
      borderBottom: '1px solid transparent',
    },
    '& > div::after': {
      borderBottom: `2px solid ${theme.palette.primaryColor}`,
    },
    '& > div:hover:not(.Mui-disabled):before': {
      borderBottom: `2px solid ${theme.palette.primaryColor}`,
    },
    '& input': {
      fontFamily: 'Roboto',
      fontSize: 13,
      lineHeight: 1.6,
      textAlign: 'left',
      padding: 0,
      height: 'auto',
      letterSpacing: 0,
    },
    '& div': {
      margin: 0,
      width: '100%',
    },
  },
}));

export default function ResponseRegisterResponse(props) {
  const classes = useStyles();
  const baseClasses = commonStyles();

  const {
    user,
    detail,
    setDetail,
    inquiry,
    response,
    getComponent,
    onInquiryRemove,
    isAllCustomerAdmin,
    updateType,
    searchByWebMemberId,
    registerInquiryProduct,
    isNew,
    reactionDay,
    setReactionDay,
    customerId,
  } = props;

  const { responseHeader } = store.getState();

  // 獲得者、獲得者種を編集できるか
  // 条件: 戻し以外 かつ 反響媒体（大）に「源泉」「チラシ」「投函」「紹介」のいずれかが指定されていること
  const isAllowToEditAcquisition = updateType !== UPDATE_TYPE_CODE.RETURN
    && RESPONSE_TYPE_ACQUISITION.includes(detail.largeResponseTypeId);

  // 再反または戻しの場合、反響日を今日に設定
  if ((updateType === UPDATE_TYPE_CODE.REBELLION || updateType === UPDATE_TYPE_CODE.RETURN)
    && dateFormatter(new Date(detail.effectAt), 'YYYY/MM/DD') !== dateFormatter(new Date(reactionDay.effectAt), 'YYYY/MM/DD')) {
    setDetail({ ...detail, effectAt: reactionDay.effectAt });
  }

  // 反響日
  const effectAtCalendar = {
    id: 'effectAt', // String - labelとinputを紐づけます
    label: '', // String - ラベル
    require: false, // Boolean - 必須かどうか
    type: 'date', // String 'date'|'time'|'multitime'|'multiple'
    state: detail.effectAt ? dateFormatter(new Date(detail.effectAt), 'YYYY/MM/DD') : '', // state
    setState: (val) => {
      const currentTime = format(new Date(), 'HH:mm:ss') ?? '00:00:00';
      const effectAt = val ? `${val} ${currentTime}` : null; // 削除ボタン押下時考慮
      setDetail((prev) => ({ ...prev, effectAt }));
      setReactionDay((prev) => ({ ...prev, effectAt }));
    },
  };

  const webStatus = user.webs[0] ?? {
    memberStatusCode: MEMBER_STATUS_NON_MEMBER,
    memberId: '',
  };
  const isNotResponseBlur = (e) => setDetail({ ...detail, isNoCustomer: Number(e.target.value) });
  const responseSelectList = {
    isNotResponseItem: {
      type: 'select',
      obj: {
        menus: ISNOT_RESPONSE,
      },
      state: detail.isNoCustomer,
      set: isNotResponseBlur,
    },
  };

  // モーダル開閉用のローカルステート
  const [selectProductOpen, setSelectProductOpen] = useState(false);
  // モーダルを開くsetState
  const handleOpen = () => {
    setSelectProductOpen(true);
  };
  // モーダルを閉じるsetState
  const handleClose = () => {
    setSelectProductOpen(false);
  };

  const [product, setProduct] = useState({});
  // OHD 選択された物件のsiteId
  // ホーク・ワン siteId=111111
  // マイソク siteId=222222
  // 本命物件・案内物件無し siteId=333333
  // 物件データ無し siteId=undefined
  const completeSelectProductWindow = (value) => {
    setProduct(value);
    registerInquiryProduct({
      siteNumber: value.siteNumber,
      productName: value.productName,
      siteId: value.siteId,
      inquiredAt: value.inquiredAt,
    });
  };

  const [isOpenSelectWindow, setIsOpenSelectWindow] = useState(false);
  const openSelectWindow = () => {
    setIsOpenSelectWindow(true);
  };
  // 獲得者選択ウィンドウ
  const [isOpenSelectAcquisitionWindow, setIsOpenSelectAcquisitionWindow] = useState(false);
  const openSelectAcuisitionWindow = () => {
    setIsOpenSelectAcquisitionWindow(true);
  };
  const userNameDefault = () => {
    // 新規作成の場合はログインユーザーを表示
    // https://openhouse.backlog.jp/view/SFA_ASIAQUEST-3799#comment-184792896
    // 再反の場合は空欄
    // https://openhouse.backlog.jp/view/SFA_ASIAQUEST-6059
    if (isNew) return responseHeader.userName || '-'; // 新規作成、描画時にログイン者の担当者にセット
    if (updateType === UPDATE_TYPE_CODE.REBELLION) return '-'; // 再反時はブランクで表示する
    if (detail.userLastName) return `${detail.userLastName} ${detail.userFirstName}`;
    if (detail.divisionSubName) return detail.divisionSubName; // 担当者がいなければ、表示を営業所名に
    return '-';
  };

  const [userName, setUserName] = useState(userNameDefault());
  const [acquisitionName, setAcquisitionName] = useState(detail.acquisitionName);

  useEffect(() => {
    // https://openhouse.backlog.jp/view/SFA_ASIAQUEST-4817
    // userNameDefault内で処理すると、親のstate変更により無限ループするので、useEffectで処理
    if (isNew) {
      const { userId, mainDivision } = responseHeader;
      // 獲得者、獲得者種を設定する
      setDetail({
        ...detail,
        userId,
        divisionId: mainDivision?.id,
      });
    } else if (updateType === UPDATE_TYPE_CODE.REBELLION) {
      setDetail({ ...detail, userId: 0, divisionId: undefined });
    }
  }, [isNew]);

  const completeSelectWindow = (selectedUser) => {
    const { userId, divisionId, name } = selectedUser;
    setDetail({ ...detail, userId, divisionId });
    setUserName(name);
    setIsOpenSelectWindow(false);
  };

  const completeSelectAcquisitionWindow = (selectedUser) => {
    const { acquisitionId, name, acquisitionCode } = selectedUser;
    setDetail({
      ...detail,
      acquisitionId,
      acquisitionCode,
      acquisitionMemo: !acquisitionId ? name : null,
    });
    setAcquisitionName(name);
    setIsOpenSelectAcquisitionWindow(false);
  };

  const [isOpenResponseType, setIsOpenResponseType] = useState(false);
  const responseType = [
    {
      responseTypeId: detail.largeResponseTypeId,
      responseTypeName: detail.largeResponseTypeName,
    },
    {
      responseTypeId: detail.mediumResponseTypeId,
      responseTypeName: detail.mediumResponseTypeName,
    },
    {
      responseTypeId: detail.smallResponseTypeId,
      responseTypeName: detail.smallResponseTypeName,
    },
    {
      responseTypeId: detail.fineResponseTypeId,
      responseTypeName: detail.fineResponseTypeName,
    },
  ];

  const setResponseType = (res) => {
    const responseList = {
      largeResponseTypeId: res[0]?.responseTypeId || null,
      largeResponseTypeName: res[0]?.responseTypeName,
      mediumResponseTypeId: res[1]?.responseTypeId || null,
      mediumResponseTypeName: res[1]?.responseTypeName,
      smallResponseTypeId: res[2]?.responseTypeId || null,
      smallResponseTypeName: res[2]?.responseTypeName,
      fineResponseTypeId: res[3]?.responseTypeId || null,
      fineResponseTypeName: res[3]?.responseTypeName,
    };
    // 反響媒体セット時、獲得者をブランクにする
    setDetail({
      ...detail,
      ...responseList,
      acquisitionId: null,
      acquisitionCode: null,
      acquisitionMemo: null,
    });
    setAcquisitionName('');
  };

  return (
    <Grid className={classes.root}>
      <Typography className={baseClasses.title4}>反響情報</Typography>
      <Grid className={classes.response}>
        <Grid className={classes.responseBox}>
          <Grid
            className={
              updateType === UPDATE_TYPE_CODE.RETURN && !isAllCustomerAdmin
                ? classes.responseBlock3
                : classes.responseBlock1
            }
          >
            <Typography className={`${baseClasses.title4} ${classes.ttl}`}>
              反響基準日<span>＊</span>
            </Typography>
            {
              // 戻し登録 AND 営業権限なら編集できない
              updateType === UPDATE_TYPE_CODE.RETURN && !isAllCustomerAdmin ? (
                <Typography className={classes.responseTxt}>
                  {dateFormatter(new Date(detail.effectAt), 'YYYY/MM/DD')}
                </Typography>
              ) : (
                <Grid className={classes.calendar}>
                  <Calendar key={effectAtCalendar.id} data={effectAtCalendar} />
                </Grid>
              )
            }
          </Grid>
          <Grid
            className={updateType === UPDATE_TYPE_CODE.RETURN
              ? classes.responseBlock3 : classes.responseBlock1}
          >
            <Typography className={`${baseClasses.title4} ${classes.ttl}`}>
              担当者<span>＊</span>
            </Typography>
            {
              // 戻し登録なら編集できない
              updateType === UPDATE_TYPE_CODE.RETURN ? (
                <Typography className={classes.responseTxt}>{userName}</Typography>
              ) : (
                <Button className={classes.button} onClick={openSelectWindow}>
                  {userName}
                </Button>
              )
            }
            <SelectWindow
              isOpen={isOpenSelectWindow}
              setIsOpen={setIsOpenSelectWindow}
              onSelect={completeSelectWindow}
            />
          </Grid>
        </Grid>
        <Grid className={classes.responseBox}>
          <Grid
            className={updateType === UPDATE_TYPE_CODE.RETURN
              ? classes.responseBlock3 : classes.responseBlock2}
          >
            <Typography className={`${baseClasses.title4} ${classes.ttl}`}>
              反響媒体(大)<span>＊</span>
            </Typography>
            {
              // 戻し登録なら編集できない
              updateType === UPDATE_TYPE_CODE.RETURN ? (
                <Typography className={classes.responseTxt}>{detail.largeResponseTypeName ? detail.largeResponseTypeName : '-'}</Typography>
              ) : (
                <Button
                  className={classes.button}
                  onClick={() => setIsOpenResponseType(true)}
                >
                  {detail.largeResponseTypeName ? detail.largeResponseTypeName : '-'}
                </Button>
              )
            }
          </Grid>
          <Grid
            className={updateType === UPDATE_TYPE_CODE.RETURN
              ? classes.responseBlock3 : classes.responseBlock2}
          >
            <Typography className={`${baseClasses.title4} ${classes.ttl}`}>
              反響媒体(中)
            </Typography>
            {
              // 戻し登録なら編集できない
              updateType === UPDATE_TYPE_CODE.RETURN ? (
                <Typography className={classes.responseTxt}>{detail.mediumResponseTypeName ? detail.mediumResponseTypeName : '-'}</Typography>
              ) : (
                <Button
                  className={classes.button}
                  onClick={() => setIsOpenResponseType(true)}
                >
                  {detail.mediumResponseTypeName ? detail.mediumResponseTypeName : '-'}
                </Button>
              )
            }
          </Grid>
          <Grid
            className={updateType === UPDATE_TYPE_CODE.RETURN
              ? classes.responseBlock3 : classes.responseBlock2}
          >
            <Typography className={`${baseClasses.title4} ${classes.ttl}`}>
              反響媒体(小)
            </Typography>
            {
              // 戻し登録なら編集できない
              updateType === UPDATE_TYPE_CODE.RETURN ? (
                <Typography className={classes.responseTxt}>{detail.smallResponseTypeName ? detail.smallResponseTypeName : '-'}</Typography>
              ) : (
                <Button
                  className={classes.button}
                  onClick={() => setIsOpenResponseType(true)}
                >
                  {detail.smallResponseTypeName ? detail.smallResponseTypeName : '-'}
                </Button>
              )
            }
          </Grid>
          <Grid
            className={updateType === UPDATE_TYPE_CODE.RETURN
              ? classes.responseBlock3 : classes.responseBlock2}
          >
            <Typography className={`${baseClasses.title4} ${classes.ttl}`}>
              反響媒体(細)
            </Typography>
            {
              // 戻し登録なら編集できない
              updateType === UPDATE_TYPE_CODE.RETURN ? (
                <Typography className={classes.responseTxt}>{detail.fineResponseTypeName ? detail.fineResponseTypeName : '-'}</Typography>
              ) : (
                <Button
                  className={classes.button}
                  onClick={() => setIsOpenResponseType(true)}
                >
                  {detail.fineResponseTypeName ? detail.fineResponseTypeName : '-'}
                </Button>
              )
            }
          </Grid>
        </Grid>
        <Grid className={classes.responseBox}>
          <Grid
            className={classes.responseBlock3}
            onClick={searchByWebMemberId}
          >
            <Typography className={`${baseClasses.title4} ${classes.ttl}`}>
              Web会員ID
            </Typography>
            <Typography className={classes.responseTxt}>
              {user.webs.map((data, index) => (
                <span key={data.memberId}>
                  {data.memberId}
                  {((index + 1) !== user.webs.length) && '、'}
                </span>
              ))}
            </Typography>
          </Grid>
          <Grid className={classes.responseBlock3}>
            <Typography className={`${baseClasses.title4} ${classes.ttl}`}>
              Web会員状態
            </Typography>
            <Typography className={classes.responseTxt}>
              {MEMBER_STATUS_CODE[webStatus.memberStatusCode]}
            </Typography>
          </Grid>
          <Grid
            className={isAllowToEditAcquisition ? classes.responseBlock2 : classes.responseBlock3}
          >
            <Typography className={`${baseClasses.title4} ${classes.ttl}`}>
              源泉獲得者{isAllowToEditAcquisition ? <span>＊</span> : ''}
            </Typography>
            {isAllowToEditAcquisition ? (
              <Button className={classes.button} onClick={openSelectAcuisitionWindow}>
                {detail.acquisitionId ? acquisitionName : detail.acquisitionMemo}
              </Button>
            ) : (
              <Typography className={classes.responseTxt}>
                {detail.acquisitionId ? acquisitionName : detail.acquisitionMemo}
              </Typography>
            )}
            <SelectAcquisitionWindow
              isOpen={isOpenSelectAcquisitionWindow}
              setIsOpen={setIsOpenSelectAcquisitionWindow}
              onSelect={completeSelectAcquisitionWindow}
              detail={detail}
            />
          </Grid>
          <Grid className={classes.responseBlock3}>
            <Typography className={`${baseClasses.title4} ${classes.ttl}`}>
              獲得者種
            </Typography>
            <Typography className={classes.responseTxt}>
              {ACQUISITION_CODE[detail.acquisitionCode]}
            </Typography>
          </Grid>
        </Grid>
        <Grid className={classes.responseBox}>
          <Grid className={classes.responseBlock3}>
            <Typography className={`${baseClasses.title4} ${classes.ttl}`}>
              区分
            </Typography>
            <Typography className={classes.responseTxt}>
              {updateType == null && '新規'}
              {updateType === UPDATE_TYPE_CODE.CUSTOMER_UPDATE && '更新'}
              {updateType === UPDATE_TYPE_CODE.RETURN && '戻し'}
              {updateType === UPDATE_TYPE_CODE.REBELLION && '再反'}
            </Typography>
          </Grid>
        </Grid>
        {/* 新規の際、問い合わせ物件、反響履歴を表示しない */}
        {isAllCustomerAdmin && updateType ? (
          <Grid className={classes.responseTable}>
            <Button className={classes.addButton} onClick={handleOpen}>
              お問い合わせ物件を追加
            </Button>
            <ResisterHouseWindow
              open={selectProductOpen}
              setOpen={setSelectProductOpen}
              onClose={handleClose}
              product={product}
              completeSelectProductWindow={completeSelectProductWindow}
              customerId={customerId}
              fromCrm={Boolean(true)}
            />
            <ResponseRegisterTable1
              rows={inquiry.data.products}
              onRemove={onInquiryRemove}
            />
            <ResponseRegisterTable2 rows={response.data.responses} />
          </Grid>
        ) : null}
        {isAllCustomerAdmin && (
          <Grid className={classes.responseBox2}>
            <Typography className={`${baseClasses.title4} ${classes.ttl}`}>
              非顧客フラグ
            </Typography>
            <Grid className={classes.select}>
              {getComponent(responseSelectList.isNotResponseItem)}
            </Grid>
            <Typography className={classes.txt}>
              ※SFAに表示したくない場合は「非顧客」を選択
            </Typography>
          </Grid>
        )}
      </Grid>
      <ResponseTypeModal
        open={isOpenResponseType}
        onClose={() => setIsOpenResponseType(false)}
        setState={setResponseType}
        state={responseType}
      />
    </Grid>
  );
}
