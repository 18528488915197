import React, { useEffect, useState, useMemo, useRef } from 'react';
import {
  TextField,
  Grid,
  Typography,
  Button,
  Select,
  FormControl,
  MenuItem,
  Checkbox,
  FormControlLabel,
} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';
import { format } from 'date-fns';
import commonStyles from '../../styles';
import AreaSentaku from '../../common/areaSentaku';
import { changeAlertMessage } from '../../../store/eleCommon/customAlertMessage';
import { ACQUISITION_CODE, IS_SHAIN, ISNOT_RESPONSE, USE_AQCUISITION_TREE_GROUP } from '../../../constants';
import { JOIN_DATA_TYPES } from '../../../constants/crm';
import Calendar from '../../common/calendar';
import UserTree from '../../common/userTreeNew';
import { classNames, dateFormatter } from '../../../commonFunction';

import { getChildField } from './helpers';
import { useStore, useRerender } from '../../../hooks';

import {
  convertForm1,
  convertFormKana,
  convertFormNumber,
} from '../../../commonFunction/convertors';
import {
  validateFormString1,
  validateFormInt1,
  validateFormFloat1,
  validateFormPostalCode,
  validateFormEmail,
  validateFormTelNum,
  validateFormUrl,
} from '../../../commonFunction/validations';
import { TextBaseField } from '../../eleCommon/validation';
import { RESPONSE_TYPE_ACQUISITION } from '../../../constants/responseType';

const useStyles = makeStyles((theme) => ({
  inner: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'stretch',
    marginBottom: 16,
    '& .MuiButton-root': {
      minWidth: 0,
    },
  },
  elementLeft: {
    width: 450,
    backgroundColor: '#fff',
    display: 'flex',
    alignItems: 'center',
    border: 'solid 1px #C8C8C8',
    padding: 8,
    boxSizing: 'border-box',
    position: 'relative',
    '& input, & textarea': {
      padding: '6px 0',
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '13px',
      lineHeight: '160%',
    },
    '& label': { width: '100%', textAlign: 'left' },
  },
  elementRight: {
    width: 450,
    backgroundColor: '#fff',
    display: 'flex',
    alignItems: 'center',
    border: 'solid 1px #C8C8C8',
    padding: 8,
    whiteSpace: 'pre-wrap',
    overflowY: 'auto',
    maxHeight: '16.3em',
    '& input, & textarea': {
      padding: 0,
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '13px',
      lineHeight: '160%',
    },
  },
  label: {
    width: 130,
    marginRight: 30,
    flex: '0 0 130px',
    '& + *': {
      flex: 1,
    },
  },
  errorLabel: {
    width: 130,
    marginRight: 30,
    color: '#D83420',
  },
  reqired: {
    color: '#D83420',
    marginRight: 'auto',
  },
  arrow: {
    color: theme.palette.primaryColor,
  },
  input: {
    opacity: 0,
    position: 'absolute',
    right: 0,
    top: 0,
    width: '100%',
    zIndex: 0,
    cursor: 'pointer',
  },
  modalBtn: {
    '&:hover': {
      background: `${theme.palette.primaryColor}29`,
    },
  },
  selectBox: {
    padding: 0,
  },
  selectStyle: {
    maxHeight: 500,
  },
  disabled: {
    backgroundColor: '#F3F3F3',
    pointerEvents: 'none',
  },
  blankOption: {
    color: 'transparent',
    height: '2em',
  },
  textField: {
    '& > .MuiInput-underline:before': { borderBottom: 'none' },
    '& input': {
      color: 'rgba(0, 0, 0, 0.87)!important',
    },
  },
  id: {
    '& .MuiInputBase-root.Mui-disabled': {
      color: 'inherit',
      '&::before': { borderBottom: 'none' },
    },
  },
}));

function DataElement(props) {
  const {
    data,
    diffOnly,
    register,
    setFormValue,
    operation,
    customersDataParent,
    customersDataChild,
    changeUserLevel,
    nowUserLevel,
    formData,
    changeTelStatus,
    changeEmailStatus,
    nowTelStatus,
    nowEmailStatus,
    errorCallback,
    watchResponseType,
    watchAcquisitionCodeName,
  } = props;
  const classes = useStyles();
  const baseClasses = commonStyles();
  const { responseHeader } = useStore();
  const { userId, userName } = responseHeader;
  const [parentElement, setParentElement] = useState(data.parentContent);
  // 表示名と取得したい値が違う際に使うstate(担当者など)
  const [displayName, setDisplayName] = useState(data.parentDisplayName);
  // 組織ツリー選択ユーザー
  const [selectUser, setSelectUser] = useState({ userId: 0, userName: '' });
  // 組織ツリー選択組織
  const [selectDivision, setSelectDivision] = useState({ divId: 0, divisionName: '' });
  const [openAreaSentaku, setOpenAreaSentaku] = useState(false);
  // 希望地域初期値
  const [initialParamArea, setInitialParamArea] = useState({
    wishArea: data.parentContent,
    wishAreaIds: customersDataParent.wishAreaIds || [],
    wishAreaCode: customersDataParent.wishAreaCode,
  });
  const [openTreeWindow, setOpenTreeWindows] = useState(false);
  const [isRequiredError, setRequiredError] = useState(false);

  const [isAcquirer, setIsAcquirer] = useState(false);
  const [initialAcquirer, setInitialAcquirer] = useState('1');
  const [displayNameInTree, setDisplayNameInTree] = useState(customersDataParent.acquisitionMemo);

  const isAcquisitionTree = USE_AQCUISITION_TREE_GROUP.includes(Number(initialAcquirer));

  const { rerender, rerenderKey } = useRerender();

  const isFirstRender = useRef(true);

  useEffect(() => {
    if (!isFirstRender.current) {
      // 画面再描画
      rerender();
    } else {
      isFirstRender.current = false;
    }
    if (data.variableName === 'emailStatusCode') {
      setFormValue('emailStatusCode', nowEmailStatus);
    }
  }, [nowEmailStatus]);

  const dispatch = useDispatch();
  const { customerStatusCode, telStatusCode } = customersDataParent;
  // 親データの電話ステータスコードをセットする
  changeTelStatus(telStatusCode);

  const judgeEditable = () => {
    // 共通している判定処理
    if (data.label === '顧客ID') return false;
    if (customerStatusCode === 11 && data.label === '状態') return false;
    if (operation === JOIN_DATA_TYPES.RETURN && data.label === '担当者') return false;
    if (customerStatusCode === 11 && data.label === '担当者' && operation !== JOIN_DATA_TYPES.REBELLION) return false;
    if (customerStatusCode === 11 && data.label === 'メール許可') return false;
    if (customerStatusCode === 11 && data.label === '電話ステータス') return false;
    if (customerStatusCode === 11 && data.label === '郵便番号') return false;
    if (customerStatusCode === 11 && data.label === '都道府県') return false;
    if (customerStatusCode === 11 && data.label === '市区町村') return false;
    if (customerStatusCode === 11 && data.label === 'それ以降の住所') return false;
    if (customerStatusCode === 11 && data.label === 'マンション名') return false;
    if (customerStatusCode === 11 && data.label === '頭金') return false;
    if (customerStatusCode === 11 && data.label === '援助') return false;
    if (customerStatusCode === 11 && data.label === '月々の支払') return false;
    if (customerStatusCode === 11 && data.label === 'ボーナス') return false;
    if (customerStatusCode === 11 && data.label.indexOf('勤務') !== -1) return false;
    if (customerStatusCode === 11 && data.label.indexOf('職業') !== -1) return false;
    if (customerStatusCode === 11 && data.label.indexOf('年収') !== -1) return false;
    if ((customerStatusCode === 11 || nowTelStatus === 0) && data.label.indexOf('電話番号') !== -1) return false;
    if ((customerStatusCode === 11 || nowEmailStatus === 0) && data.label.indexOf('メールアドレス') !== -1) return false;
    if (data.label === '獲得者種') return false;
    if (operation === JOIN_DATA_TYPES.RETURN && data.variableName === 'acquisitionId') return false;
    if (!RESPONSE_TYPE_ACQUISITION.includes(Number(watchResponseType ?? customersDataParent.largeResponseTypeId)) && data.variableName === 'acquisitionId') return false;
    return true;
  };

  // 戻しかどうか判定フラグ（INITIALはこの画面がそもそも描画されない想定）
  const isTypeReturn = Boolean(
    operation === JOIN_DATA_TYPES.INITIAL || operation === JOIN_DATA_TYPES.RETURN,
  );

  const convertCheckBoxValue = (v) => {
    if (v === null || v === undefined) return false;
    // switch (data.label) {
    //   case '非反響フラグ':
    //     return !v;
    //   default:
    //     return !!v;
    // }
    // https://openhouse.backlog.jp/view/SFA_ASIAQUEST-5013
    // 非反響フラグのフラグが逆になっているので修正
    return !!v;
  };

  const isArrow = useMemo(() => {
    if (parentElement === data.childContent) {
      // おそらく獲得者名と獲得者メモの場合漏れるのでそこを拾う
      if (data.variableName === 'acquisitionId') {
        if (isTypeReturn) return false;
        if (!RESPONSE_TYPE_ACQUISITION.includes(Number(watchResponseType))) return false;
        return displayName !== data.childDisplayName;
      }
      return false;
    }
    // 要素が変更元と参照先で違う場合
    if (!judgeEditable()) return false; // 変更不可は非表示
    if (data.inputType === 'checkbox') {
      const parentChecked = convertCheckBoxValue(data.parentContent);
      const chiledChecked = convertCheckBoxValue(data.childContent);
      return parentChecked !== chiledChecked;
    }
    // 参照先にデータがない場合
    if ((data.childContent === '' || data.childContent === undefined || data.childContent === null) && !data.childDisplayName) return false;

    if (data.variableName === 'isNoCustomer') return ISNOT_RESPONSE[+parentElement] !== data.childContent;
    return true;
  }, [
    parentElement,
    nowTelStatus,
    nowEmailStatus,
    data.childContent,
    displayName,
    data.childDisplayName,
    watchResponseType,
    customersDataParent,
  ]);

  // 変更不可設定
  const isDisabled = useMemo(() => {
    if (!judgeEditable()) return true;
    if ((customerStatusCode !== 11 && nowTelStatus !== '電話NG') && data.label.indexOf('電話番号') !== -1) return false;
    if ((customerStatusCode !== 11 && nowEmailStatus !== 'ダメ全部NG') && data.label.indexOf('メールアドレス') !== -1) return false;
    return false;
  }, [nowTelStatus, nowEmailStatus, watchResponseType, customersDataParent]);

  useEffect(() => {
    // 獲得者の場合はスキップ
    if (data.variableName === 'acquisitionId') return;
    // TODO: 担当者の場合もいずれ要確認
    if (selectUser.userId) {
      setParentElement(selectUser.userId);
      setDisplayName(selectUser.userName);
    }
    if (!selectUser.userId && selectDivision.divId) {
      setDisplayName(selectDivision.divisionName);
    }
  }, [selectUser, selectDivision]);

  useEffect(() => {
    if (parentElement !== data.parentContent) {
      setParentElement(data.parentContent);
    }
    if (displayName !== data.parentDisplayName) {
      setDisplayName(data.parentDisplayName);
    }
  }, [data.parentContent, data.parentDisplayName]);

  // 制御のための準備
  useEffect(() => {
    if (data.label === 'ランク（課員）') changeUserLevel(parentElement);
    if (data.label === 'メール許可') changeEmailStatus(parentElement);
    if (data.label === '電話ステータス') changeTelStatus(parentElement);
  }, []);
  // 必須項目が入力されてない時エラーを出力
  useEffect(() => {
    if (formData && data.required) {
      if (formData[data.variableName] === '') {
        setRequiredError(true);
      } else {
        setRequiredError(false);
      }
    }
  }, [formData]);

  const handleChangeParent = (e) => {
    setParentElement(e.target.value);
  };

  const handleChangeUserLevel = (e) => {
    if (
      (Number(nowEmailStatus) === 1 || Number(nowEmailStatus) === 3)
        && Number(e.target.value) === 2) {
      changeEmailStatus(5);
      setFormValue('userLevel', e.target.value);
      dispatch(changeAlertMessage({
        msgList: [], // 必須
        title: 'メール許可を「代行もすべてOK」に変更しました。', // 変更時のみ。設定しなければデフォルトの「入力された内容に誤りがあります」
        isNotice: true, // エラーではない場合。設定しなければ文字色、背景が赤くなる
      }));
    }
    changeUserLevel(e.target.value);
    setParentElement(e.target.value);
  };
  const handleChangeTelStatus = (e) => {
    changeTelStatus(e.target.value);
    setParentElement(e.target.value);
  };
  // メール許可用関数
  const handleChangeEmailStatus = (e) => {
    if (
      (Number(e.target.value) === 1 || Number(e.target.value) === 3)
        && Number(nowUserLevel) === 2) {
      dispatch(
        changeAlertMessage({
          title: 'ランクを変更してください',
          msgList: [
            'メール許可を「個別のみOK」「一斉・個別OK」にする場合は、ランクを変更してください。',
          ],
        }),
      );
      return;
    }
    setParentElement(e.target.value);
    changeEmailStatus(e.target.value);
  };
  // 矢印クリックの際に発火
  const handleChange = () => {
    if (data.variableName === 'emailStatusCode') {
      if (
        (Number(data.childContent) === 1 || Number(data.childContent) === 3)
          && Number(nowUserLevel) === 2) {
        dispatch(
          changeAlertMessage({
            title: 'ランクを変更してください',
            msgList: [
              'メール許可を「個別のみOK」「一斉・個別OK」にする場合は、ランクを変更してください。',
            ],
          }),
        );
        return;
      }
      changeEmailStatus(data.childContent);
    }
    if (
      data.variableName === 'userLevel'
        && (Number(nowEmailStatus) === 1 || Number(nowEmailStatus) === 3)
        && Number(data.childContent) === 2) {
      changeEmailStatus(5);
      dispatch(changeAlertMessage({
        msgList: [],
        title: 'メール許可を「代行もすべてOK」に変更しました。',
        isNotice: true,
      }));
    }
    setParentElement(data.childContent);
    setFormValue(data.variableName, data.childContent);
    if (displayName !== undefined) {
      setDisplayName(data.childDisplayName);
    }
    // 希望地域
    if (data.variableName === 'wishArea') {
      setFormValue('wishAreaIds', customersDataChild.wishAreaIds || []);
      setFormValue('wishAreaCode', customersDataChild.wishAreaCode);
    }
    // 獲得者
    if (data.label === '源泉獲得者') {
      if (data.childDisplayName && !data.childContent) {
        setDisplayName(data.childDisplayName);
        setFormValue('acquisitionMemo', data.childDisplayName);
        setFormValue('acquisitionId', '0');
      }
      // 獲得者種を設定
      setFormValue('acquisitionCode', ACQUISITION_CODE[Number(data.childAcquisitionCode)]);
      setInitialAcquirer(String(data.childAcquisitionCode));
    }
  };

  const areaSet = (areaData) => {
    // setParentElementの内容はareaDataの名前が入るように変更
    setParentElement(areaData.wishArea);
    setFormValue('wishArea', areaData.wishArea);
    setFormValue('wishAreaIds', areaData.wishAreaIds || []);
    setFormValue('wishAreaCode', areaData.wishAreaCode);
    setInitialParamArea(areaData);
  };

  /**
   * Selectコンポーネントを作成
   * @param {Record<number, string>} options src\constants\index.js
   * @param {(e) => void} onChangeHandler
   * @param {boolean} ブランクが必要か
   * @returns {ReactNode}
   */
  const getSelectComponent = (options, onChangeHandler) => {
    return (
      <Grid className={`${baseClasses.inputWrap} ${classes.selectBox} ${isDisabled ? classes.disabled : ''}`} key={rerenderKey}>
        <Select
          {...register(data.variableName)}
          value={data.variableName === 'emailStatusCode' ? nowEmailStatus : parentElement}
          onChange={onChangeHandler}
          inputProps={{ readOnly: isDisabled }}
          className={`${baseClasses.small} ${classes.select}`}
          MenuProps={{
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'left',
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'left',
            },
            getContentAnchorEl: null,
            classes: {
              list: classes.selectStyle,
            },
          }}
        >
          <MenuItem value="" style={{ display: 'none' }} />
          <MenuItem value="null" style={{ display: 'none' }} />
          {Object.keys(options).map((k) => (
            <MenuItem value={k} key={k}>
              {options[k]}
            </MenuItem>
          ))}
        </Select>
      </Grid>
    );
  };

  const switchModalBtn = (label) => {
    switch (label) {
      case '希望地域':
        return true;
      case '担当者':
        return true;
      case '源泉獲得者':
        if (parentElement || !displayName) {
          return true;
        } else {
          return false;
        }
      default:
        return false;
    }
  };

  const getInputComponent = (onClick, text) => {
    return (
      <span className={classes.inputBtn}>
        <span>{text}</span>
        <input
          {...register(data.variableName)}
          type="text"
          defaultValue={parentElement}
          onClick={onClick}
          className={classes.input}
          disabled={isDisabled}
        />
      </span>
    );
  };

  const getValidateObj = (label) => {
    const result = {};
    switch (label) {
      case '姓':
      case '名':
      case '関係':
      case '副姓':
      case '副名':
      case '市区町村':
      case 'マンション名':
      case '入居者の予定構成（誰が〕':
      case '勤務先2':
      case '勤務地住所1':
      case '勤務地住所2':
      case '職業種1':
      case '職業種2':
      case 'これまでに見た物件':
      case '入居希望時期':
      case '購入目的':
      case '借入銀行':
        result.convertor = convertForm1;
        result.validator = validateFormString1({ maxLengthInt: 50 });
        break;
      case 'カナ姓':
      case 'カナ名':
      case '副カナ姓':
      case '副カナ名':
        result.convertor = convertFormKana;
        result.validator = validateFormString1({ maxLengthInt: 50 });
        break;
      case '年齢':
      case '副年齢':
        result.convertor = convertFormNumber;
        result.validator = validateFormInt1({ minFloat: 0.0, maxFloat: 999.0 });
        break;
      case '国籍':
      case '副国籍':
      case 'FAX':
        result.convertor = convertForm1;
        result.validator = validateFormString1({ maxLengthInt: 20 });
        break;
      case '電話番号1':
      case '電話番号2':
      case '電話番号3':
      case '電話番号4':
        result.validator = validateFormTelNum();
        break;
      case '電話番号補足1':
      case '電話番号補足2':
      case '電話番号補足3':
      case '電話番号補足4':
      case 'メールアドレス補足1':
      case 'メールアドレス補足2':
      case 'メールアドレス補足3':
      case 'メールアドレス補足4':
        result.convertor = convertForm1;
        result.validator = validateFormString1({ maxLengthInt: 10 });
        break;
      case 'メールアドレス1':
      case 'メールアドレス2':
      case 'メールアドレス3':
      case 'メールアドレス4':
        result.validator = validateFormEmail();
        break;
      case '郵便番号':
        result.validator = validateFormPostalCode();
        break;
      case 'それ以降の住所':
        result.convertor = convertForm1;
        result.validator = validateFormString1({ maxLengthInt: 250 });
        break;
      case '家族構成 大人':
      case '家族構成 子供':
      case '子供の年齢1':
      case '子供の年齢2':
      case '入居予定 人数':
      case '勤務年数1':
      case '勤務年数2':
        result.convertor = convertFormNumber;
        result.validator = validateFormInt1({ minFloat: 0.0, maxFloat: 99.0 });
        break;
      case '勤務先1':
        result.convertor = convertForm1;
        result.validator = validateFormString1({ maxLengthInt: 80 });
        break;
      case '本人年収（万円）':
      case '合算者年収（万円）':
      case '頭金（万円）':
      case '援助（万円）':
      case '月々の支払（万円）':
      case 'ボーナス（万円）':
      case 'これまでに見た物件数':
        result.convertor = convertFormNumber;
        result.validator = validateFormInt1({ minFloat: 0.0, maxFloat: 9999999999.0 });
        break;
      case '希望学区1':
      case '希望学区2':
        result.convertor = convertForm1;
        result.validator = validateFormString1({ maxLengthInt: 255 });
        break;
      case '検討のきっかけ':
        result.convertor = convertForm1;
        result.validator = validateFormString1({ maxLengthInt: 100 });
        break;
      case '重視するポイント':
        result.convertor = convertForm1;
        result.validator = validateFormString1({ maxLengthInt: 200 });
        break;
      case '面積（㎡）':
        result.convertor = convertFormNumber;
        result.validator = validateFormFloat1({
          minFloat: 0.0,
          maxFloat: 9999.0,
          precisionInt: 3,
        });
        break;
      case '当時の価格':
      case '希望売却価格':
        result.convertor = convertFormNumber;
        result.validator = validateFormInt1({ minFloat: 0.0, maxFloat: 99999.0 });
        break;
      case '居住期間':
      case '家賃':
      case '購入残年数':
        result.convertor = convertFormNumber;
        break;
      case '当時の価格（万円）':
      case '希望売却価格（万円）':
        result.convertor = convertFormNumber;
        result.validator = validateFormInt1({ minFloat: 0.0, maxFloat: 99999.0 });
        break;
      case '居住期間（年）':
      case '購入残年数（年）':
        result.convertor = convertFormNumber;
        result.validator = validateFormFloat1({
          minFloat: 0.0,
          maxFloat: 99.0,
          precisionInt: 4,
        });
        break;
      case '家賃（万円）':
        result.convertor = convertFormNumber;
        result.validator = validateFormFloat1({
          minFloat: 0.0,
          maxFloat: 99999.0,
          precisionInt: 4,
        });
        break;
      case '借入額':
      case '借入額（万円）':
        result.convertor = convertFormNumber;
        result.validator = validateFormInt1({ minFloat: 0.0, maxFloat: 999999.0 });
        break;
      case 'ドライブURL1':
      case 'ドライブURL2':
      case 'ドライブURL3':
        result.validator = validateFormUrl();
        break;
      default:
        break;
    }
    return result;
  };

  const getParentField = () => {
    if (data.map) {
      switch (data.label) {
        case '電話ステータス':
          if (isDisabled && !parentElement) {
            // 編集不可 かつ 親データが存在しない 場合: フィールドごと表示しない
            return null;
          }
          // それ以外(フィールドは表示し、編集可または不可となる)
          return (
            <FormControl>
              {getSelectComponent(data.map, handleChangeTelStatus)}
            </FormControl>
          );
        case 'メール許可':
          if (isDisabled && !parentElement) {
            // 編集不可 かつ 親データが存在しない 場合: フィールドごと表示しない
            return null;
          }
          // それ以外(フィールドは表示し、編集可または不可となる)
          return (
            <FormControl>
              {getSelectComponent(data.map, handleChangeEmailStatus)}
            </FormControl>
          );
        case 'ランク（課員）':
          if (isDisabled && !parentElement) {
            // 編集不可 かつ 親データが存在しない 場合: フィールドごと表示しない
            return null;
          }
          // それ以外(フィールドは表示し、編集可または不可となる)
          return (
            <FormControl>
              {getSelectComponent(data.map, handleChangeUserLevel)}
            </FormControl>
          );
        default:
          if (isDisabled && !parentElement) {
            // 編集不可 かつ 親データが存在しない 場合: フィールドごと表示しない
            return null;
          }
          // それ以外(フィールドは表示し、編集可または不可となる)
          return (
            <FormControl>
              {getSelectComponent(data.map, handleChangeParent)}
            </FormControl>
          );
      }
    }
    if (data.inputType === 'checkbox') {
      return (
        <FormControlLabel
          control={
            <Checkbox
              checked={convertCheckBoxValue(parentElement)}
              onChange={(e) => {
                const checked = convertCheckBoxValue(e.target.checked);
                setParentElement(+checked);
                // https://openhouse.backlog.jp/view/SFA_ASIAQUEST-5013
                setFormValue(data.variableName, Number(checked));
              }}
            />
          }
        />
      );
    }
    switch (data.label) {
      case '顧客ID':
        return (
          <TextField
            {...register(data.variableName)}
            value={parentElement}
            inputProps={{ readOnly: true }}
            disabled
            className={classes.id}
          />
        );
      case '希望地域':
        return getInputComponent(() => setOpenAreaSentaku(true), parentElement);
      case '担当者':
        if (displayName === 0) {
          return getInputComponent(() => {
            if (!isDisabled) setOpenTreeWindows(true);
          }, null);
        }
        return getInputComponent(() => setOpenTreeWindows(true), displayName);
      case '源泉獲得者': {
        // 獲得者の表示は、ツリーとする。
        return getInputComponent(
          isDisabled ? () => {} : () => {
            // 獲得者経由でツリーを開くことを設定
            setIsAcquirer(true);
            setOpenTreeWindows(true);
          },
          displayName,
        );
      }
      case '誕生日':
      case '副誕生日':
        return (
          <Calendar
            {...register(data.variableName)}
            data={{
              id: data.variableName,
              label: '',
              require: false,
              type: 'date',
              state: parentElement,
              setState: (val) => {
                setParentElement(val);
                setFormValue(data.variableName, val);
              },
            }}
            noBorder
          />
        );
      case '反響基準日':
        return (
          <Calendar
            {...register(data.variableName)}
            data={{
              id: data.variableName,
              label: '',
              require: false,
              type: 'date',
              state: parentElement?.substr(0, 10),
              setState: (val) => {
                const currentTime = format(new Date(), 'HH:mm:ss') ?? '00:00:00';
                const effectAt = val ? `${val} ${currentTime}` : null; // 削除ボタン押下時考慮
                setParentElement(effectAt);
                setFormValue(data.variableName, effectAt);
              },
            }}
            noBorder
          />
        );
      case '購入年月':
        return (
          <Calendar
            {...register(data.variableName)}
            data={{
              id: data.variableName,
              label: '',
              require: false,
              type: 'date',
              state: parentElement?.substr(0, 7),
              setState: (val) => {
                setParentElement(val);
                setFormValue(data.variableName, val);
              },
            }}
            noBorder
          />
        );
      case '獲得者種': {
        return (
          <TextField
            {...register(data.variableName)}
            value={watchAcquisitionCodeName ?? ''}
            onChange={handleChangeParent}
            inputProps={{ readOnly: !!isDisabled }}
            className={classes.textField}
            disabled
          />
        ); }
      default:
        if (isDisabled && !parentElement) {
          // 編集不可 かつ 親データに値がない 場合: フィールドごと表示しない
          return null;
        }
        // それ以外(「編集不可 かつ 親データに値がある」場合: フィールドは表示するがread only、 「編集可能」の場合: フィールドを表示し編集も可)
        return (
          <TextBaseField
            register={register}
            variableName={data.variableName}
            {...register(data.variableName)}
            defaultValue={parentElement ?? ''}
            onChange={handleChangeParent}
            inputProps={{ readOnly: !!isDisabled }}
            className={classes.textField}
            {...getValidateObj(data.label)}
            errorCallback={errorCallback(data.variableName)}
            multiline={data.inputType === 'textarea'}
            minRows={8}
            maxRows={8}
          />
        );
    }
  };

  const isClaimTextTarget = (label) => {
    if (label.indexOf('電話番号') !== -1) return true;
    if (label.indexOf('メールアドレス') !== -1) return true;
    if (label === '郵便番号') return true;
    if (label === '都道府県') return true;
    if (label === '市区町村') return true;
    if (label === 'それ以降の住所') return true;
    if (label === 'マンション名') return true;
    if (label === '頭金') return true;
    if (label === '援助') return true;
    if (label === '月々の支払') return true;
    if (label === 'ボーナス') return true;
    if (label.indexOf('勤務') !== -1) return true;
    if (label.indexOf('職業') !== -1) return true;
    if (label.indexOf('年収') !== -1) return true;
    return false;
  };

  const convertAcquisitionCode = (acquisitionCode) => {
    if (acquisitionCode === 1) {
      return '社員';
    } else if (acquisitionCode === 2) {
      return '内定者';
    } else if (acquisitionCode === 3) {
      return '研修生';
    } else if (acquisitionCode === 4) {
      return 'アルバイト';
    } else if (acquisitionCode === 6) {
      return 'トライアル';
    }
    return null;
  };

  const getChildFieldWrap = (childData, checked = false) => {
    let displayResult = getChildField(childData, checked);
    if (childData.label === '獲得者種') {
      displayResult = convertAcquisitionCode(displayResult);
    } else if (childData.label === '反響基準日') {
      displayResult = displayResult?.substr(0, 10);
    }
    return displayResult;
  };

  const body = (
    <>
      <Grid className={classes.inner}>
        <Typography
          className={classNames(
            baseClasses.title6,
            classes.elementLeft,
            switchModalBtn(data.label) ? classes.modalBtn : null,
            isDisabled ? classes.disabled : null,
          )}
        >
          <span
            className={`${baseClasses.title4} ${
              isRequiredError ? classes.errorLabel : classes.label
            }`}
          >
            {data.label}
            {data.required ? <span style={{ color: '#D83420' }}>＊</span> : ''}
          </span>
          {getParentField()}
        </Typography>
        {isArrow ? (
          <Button onClick={handleChange}>
            <ArrowBackIcon className={classes.arrow} />
          </Button>
        ) : null}
        <Typography
          className={classNames(
            baseClasses.title6,
            classes.elementRight,
            isArrow ? null : classes.disabled,
          )}
        >
          {getChildFieldWrap(data, convertCheckBoxValue(data.childContent))}
        </Typography>
      </Grid>
      {
        (isClaimTextTarget(data.label)) && customerStatusCode === 11
        && <Typography className={baseClasses.small}>クレーム中は閲覧不可</Typography>
      }
      {
        data.label.indexOf('電話番号') !== -1 && nowTelStatus === '電話NG' && customerStatusCode !== 11
        && <Typography className={baseClasses.small}>電話NG中は閲覧不可</Typography>
      }
      {
        data.label.indexOf('メールアドレス') !== -1 && nowEmailStatus === 'ダメ全部NG' && customerStatusCode !== 11
        && <Typography className={baseClasses.small}>ダメ全部NG中は閲覧不可</Typography>
      }
    </>
  );
  const judgeDifference = (elem1, elem2) => {
    if (elem1 !== elem2 && (elem1 && elem2)) {
      return body;
    }
    return '';
  };

  const handleSelectFunction = () => {
    if (data.variableName === 'userId') {
      if (!selectUser.userId && selectDivision.divId) {
        setFormValue('userId', `d${selectDivision.divId}`);
      } else {
        setFormValue('userId', selectUser.userId);
        setFormValue('divisionId', selectDivision.divId);
      }
    }
    if (data.variableName === 'acquisitionId') {
      // acquisitionIdに値をセットする
      setFormValue('acquisitionCode', ACQUISITION_CODE[Number(initialAcquirer)]);
      setFormValue('acquisitionId', isAcquisitionTree ? selectUser.userId : '0');
      setFormValue('acquisitionMemo', isAcquisitionTree ? null : displayNameInTree);
      setDisplayName(isAcquisitionTree ? selectUser.userName : displayNameInTree);
    }
  };

  const handleClearFunction = () => {
    if (data.variableName === 'acquisitionId') {
      setDisplayName('');
      setFormValue('acquisitionId', '0');
      setFormValue('acquisitionCode', null);
      setFormValue('acquisitionMemo', null);
    }
  };

  useEffect(() => {
    // 反響媒体（大）が変更された場合は、源泉獲得者をブランクにする
    if (watchResponseType && !RESPONSE_TYPE_ACQUISITION.includes(watchResponseType)) {
      handleClearFunction();
    }
  }, [watchResponseType]);

  useEffect(() => {
    // 結合以外のとき、反響基準日を今日にセット
    if (operation !== JOIN_DATA_TYPES.MERGE && data.variableName === 'effectAt') {
      const today = new Date();
      setParentElement(dateFormatter(today, 'YYYY/MM/DD hh:mm:ss'));
      setFormValue(data.variableName, dateFormatter(today, 'YYYY/MM/DD hh:mm:ss'));
    }
    // disable時にformValueが初期化されないためここで初期化（再反を除く）
    if (operation !== JOIN_DATA_TYPES.REBELLION && data.variableName === 'userId') {
      setFormValue(data.variableName, data.parentContent);
    }
    // 画面表示時に獲得者周りを設定
    if (data.variableName === 'acquisitionId') {
      // 自分→選択されているユーザ の順にデフォルト表示のユーザを設定する
      let user = userId;
      if (customersDataParent.acquisitionId && user !== customersDataParent.acquisitionId) {
        user = customersDataParent.acquisitionId;
        setSelectUser({ userId: user, userName: customersDataParent.acquisitionName });
      } else {
        setSelectUser({ userId: user, userName });
      }
      setFormValue('acquisitionId', customersDataParent.acquisitionId);
      setDisplayName(customersDataParent.acquisitionName ?? customersDataParent.acquisitionMemo);
      setInitialAcquirer(customersDataParent.acquisitionCode
        ? String(customersDataParent.acquisitionCode)
        : String(IS_SHAIN));
      // acquisitionMemo形式の場合、値をセットする
      if (customersDataParent.acquisitionMemo) {
        setFormValue('acquisitionMemo', customersDataParent.acquisitionMemo);
      }
    }
    if (data.variableName === 'acquisitionCode') {
      setFormValue('acquisitionCode', customersDataParent.acquisitionCode ? ACQUISITION_CODE[Number(customersDataParent.acquisitionCode)] : null);
    }
  }, [customersDataParent]);

  return (
    <>
      {diffOnly ? judgeDifference(data.parentContent, data.childContent) : body}
      <AreaSentaku
        open={openAreaSentaku}
        onClose={() => setOpenAreaSentaku(false)}
        onUpdate={areaSet}
        initialParam={initialParamArea}
      />
      <UserTree
        isOpenTree={openTreeWindow}
        setIsOpenTree={setOpenTreeWindows}
        selectDivision={selectDivision}
        setSelectDivision={setSelectDivision}
        selectUser={selectUser}
        setSelectUser={setSelectUser}
        isDialog
        isSelectUserWindow={isAcquirer}
        doubleSearch={!isAcquirer}
        clickableUserAndDivision={!isAcquirer}
        isAcquirer={isAcquirer}
        initialAcquirer={initialAcquirer}
        setInitialAcquirer={setInitialAcquirer}
        handleSelectFunction={handleSelectFunction}
        handleClearFunction={handleClearFunction}
        setDisplayName={setDisplayNameInTree}
        defaultDisplayName={displayNameInTree}
      />
    </>
  );
}

export default DataElement;
