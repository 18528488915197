import { Box, Grid, makeStyles } from '@material-ui/core';
import { convertForm1, convertFormKana } from '../../commonFunction/convertors';
import {
  validateFormString1,
  validateFormInt1,
  validateFormFloat1,
  validateFormPostalCode,
  validateFormEmail,
  validateFormTelNum,
  validateFormUrl,
} from '../../commonFunction/validations';
import { TextBaseField } from '../eleCommon/validation';

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    background: '#F3F3F3',
    height: '50px',
    margin: '0 auto',
    '& > .MuiGrid-container': {
      position: 'fixed',
      zIndex: 9,
      top: 0,
      left: 0,
    },
  },
});

function ValidationTest() {
  const classes = useStyles();

  return (
    <Box
      component="form"
      className={classes.root}
      sx={{
        '& .MuiTextField-root': { m: 1, width: '25ch' },
      }}
      noValidate
      autoComplete="off"
    >
      <Grid>
        <TextBaseField
          label="convertForm1"
          convertor={convertForm1}
          validator={() => []}
        />
        <TextBaseField
          label="convertFormKana"
          convertor={convertFormKana}
          validator={() => []}
        />
      </Grid>
      <Grid>
        <TextBaseField
          label="validateFormEmail"
          type="email"
          validator={validateFormEmail()}
        />
        <TextBaseField
          type="number"
          label="validateFormFloat1"
          validator={validateFormFloat1({
            minFloat: -1.0,
            maxFloat: 0.99,
            precisionInt: 4,
          })}
        />
        <TextBaseField
          type="number"
          label="validateFormInt1"
          validator={validateFormInt1({
            minFloat: -100.0,
            maxFloat: 99.0,
          })}
        />
        <TextBaseField
          label="validateFormPostalCode"
          validator={validateFormPostalCode()}
        />
        <TextBaseField
          label="validateFormString1"
          convertor={convertFormKana}
          validator={validateFormString1({ maxLengthInt: 5 })}
        />
        <TextBaseField
          type="tel"
          label="validateFormTelNum"
          validator={validateFormTelNum()}
        />
        <TextBaseField label="validateFormUrl" validator={validateFormUrl()} />
      </Grid>
    </Box>
  );
}

export default ValidationTest;
