import { Grid, makeStyles } from '@material-ui/core';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import commonTheme from '../../../styles/theme';
import AreaSentakuCheckBoxAll from './areaSentakuCheckBoxAll';
import AreaSentakuCheckBox from './areaSentakuCheckBox';
import AreaSentakuLabel from './areaSentakuLabel';

const useStyles = makeStyles((theme) => ({
  areaBox: {
    [commonTheme.breakpoints.up('md')]: {
      display: 'flex',
      alignItems: 'center',
      border: '1px solid #C8C8C8',
      padding: '4px 0',
    },
    '&:not(:last-child)': {
      [commonTheme.breakpoints.up('md')]: {
        marginBottom: 8,
      },
    },
    [commonTheme.breakpoints.down('sm')]: {
      '&.lower': {
        '& > .MuiGrid-root': {
          display: 'none',
          '&.active': {
            display: 'block',
            '& + .MuiGrid-root': {
              display: 'block',
            },
            '& + .MuiGrid-root + .MuiGrid-root': {
              display: 'block',
            },
          },
        },
      },
    },
  },
  areaBoxAll: {
    [commonTheme.breakpoints.down('sm')]: {
      background: '#fff',
      padding: '0 0 0 16px',
      margin: '16px 0',
      display: 'none',
    },
    [commonTheme.breakpoints.up('md')]: {
      width: '12%',
    },
    '& .MuiCheckbox-root': {
      padding: '5px 7px 5px 9px',
      [commonTheme.breakpoints.down('sm')]: {
        order: 2,
      },
    },
    '& .MuiTypography-body1': {
      fontFamily: 'Roboto',
      fontSize: 13,
      lineHeight: 1.2,
      letterSpacing: 0,
      marginTop: 2,
      [commonTheme.breakpoints.down('sm')]: {
        order: 1,
        fontSize: 16,
        fontWeight: '700',
      },
    },
    '& .MuiFormControlLabel-root': {
      margin: 0,
      [commonTheme.breakpoints.down('sm')]: {
        justifyContent: 'space-between',
        width: '100%',
        padding: '12px 11px 12px 16px',
        borderBottom: '1px solid #F3F3F3',
      },
      '& .Mui-checked': {
        color: theme.palette.primaryColor,
      },
      '& .Mui-checked + .MuiTypography-root': {
        [commonTheme.breakpoints.down('sm')]: {
          color: theme.palette.primaryColor,
        },
      },
      '& .MuiSvgIcon-root': {
        [commonTheme.breakpoints.down('sm')]: {
          fontSize: 22,
        },
      },
    },
  },
  areaBoxList: {
    [commonTheme.breakpoints.down('sm')]: {
      display: 'none',
    },
    [commonTheme.breakpoints.up('md')]: {
      display: 'flex',
      alignItems: 'center',
      flexWrap: 'wrap',
      width: '88%',
    },
  },
  areaBoxItem: {
    [commonTheme.breakpoints.down('sm')]: {
      background: '#fff',
      padding: '0 0 0 16px',
    },
    [commonTheme.breakpoints.up('md')]: {
      width: '14.25%',
    },
    '& .MuiFormControlLabel-root': {
      margin: 0,
      [commonTheme.breakpoints.down('sm')]: {
        justifyContent: 'space-between',
        width: '100%',
        padding: '12px 11px 12px 16px',
        borderBottom: '1px solid #F3F3F3',
      },
      '& .Mui-checked': {
        color: theme.palette.primaryColor,
      },
      '& .Mui-checked + .MuiTypography-root': {
        [commonTheme.breakpoints.down('sm')]: {
          color: theme.palette.primaryColor,
        },
      },
      '& .MuiSvgIcon-root': {
        [commonTheme.breakpoints.down('sm')]: {
          fontSize: 22,
        },
      },
    },
    '& .MuiCheckbox-root': {
      padding: '5px 7px 5px 9px',
      [commonTheme.breakpoints.down('sm')]: {
        order: 2,
      },
    },
    '& .MuiTypography-body1': {
      fontFamily: 'Roboto',
      fontSize: 13,
      lineHeight: 1.2,
      letterSpacing: 0,
      marginTop: 2,
      [commonTheme.breakpoints.down('sm')]: {
        order: 1,
        fontSize: 16,
        fontWeight: 700,
      },
    },
  },
}));

function AreaSentakuItem(props) {
  const classes = useStyles();
  const {
    list,
    index,
    lower,
    setLower,
    onChangeChecked,
    wish,
    isChecked,
    areaTabIndex,
    isCheckedArea,
  } = props;

  const getLabel = (text) => {
    const windowWidth = window.innerWidth;
    const breakPoint = 768;
    const isSP = (windowWidth < breakPoint);
    let lbl = text;
    if (isSP) {
      lbl += ' すべて';
    }
    return (lbl);
  };

  // 選択された地域を取得
  const getWish = (i) => {
    return wish
      .filter(o => o.index === i)
      .map(o => o.label)
      .join('/');
  };

  return (
    <Grid
      className={`${classes.areaBox} ${lower && 'lower'}`}
    >
      <AreaSentakuLabel
        label={list.state.data.areas[0].wishRoughAreaName}
        index={index}
        lower={lower}
        setLower={setLower}
        chose={getWish(index)}
      />
      <Grid className={classes.areaBoxAll}>
        <AreaSentakuCheckBoxAll
          label={getLabel(list.state.data.areas[0].wishRoughAreaName)}
          index={index}
          areas={list.state.data.areas}
          onChangeChecked={onChangeChecked}
          checked={isCheckedArea({ areaTabIndex, index })}
        />
      </Grid>
      <Grid className={classes.areaBoxList}>
        {list.state.data.areas.map((list3) => (
          // <Grid key={list3.cityJisCode} className={classes.areaBoxItem}>
          <Grid key={list3.city} className={classes.areaBoxItem}>
            <AreaSentakuCheckBox
              id={list3.id}
              index={index}
              label={list3.city}
              cityJisCode={list3.cityJisCode}
              onChangeChecked={onChangeChecked}
              checked={isChecked({ areaTabIndex, id: list3.id })}
            />
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
}

export default AreaSentakuItem;
