import React from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { theme } from '../styles/theme';
import BrowsingHistory from '../browsingHistory';

const useStyles = makeStyles({
  container: {
    '& > div': {
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
  },
  root: {
    flexGrow: 1,
    [theme.breakpoints.down('sm')]: {
      overflow: 'hidden',
    },
    [theme.breakpoints.up('md')]: {
      width: 1920,
      minWidth: 1920,
      margin: '0 auto',
      background: '#fff',
    },
  },
});

function BrowsingHistoryPage() {
  const classes = useStyles();
  const isSp = useSelector((state) => state.deviceTypeSlice.isSp);

  const body = () => {
    if (isSp) {
      return (
        <Grid container className={classes.container}>
          <BrowsingHistory />
        </Grid>
      );
    }
    return (
      <Grid className={classes.main}>
        <BrowsingHistory />
      </Grid>
    );
  };

  return (
    <div className={classes.root}>
      { body() }
    </div>
  );
}

export default BrowsingHistoryPage;
