import { Grid, makeStyles } from '@material-ui/core';
import DivisionUpdateRow from './divisionUpdateRow';
import CustomTable from '../../browsingHistory/parts/customTable';
import { headCells, defaultLabelDisplayedRows } from './divisionUpdateCommon';
import { dateFormatter } from '../../../commonFunction';

const useStyles = makeStyles({
  box: {
    border: '1px solid #C8C8C8',
  },
  dateWrap: {
    display: 'inline-flex',
    background: '#fff',
    border: '1px solid #C8C8C8',
    alignItems: 'center',
    marginRight: 16,
    padding: 8,
  },
  date: {
    fontWeight: 700,
    marginLeft: 16,
  },
});

export default function DivisionUpdateConfirm(props) {
  const {
    setting,
    tentativeUserList,
    currentPageNumber,
    onPageChange,
    onRowsPerPageChange,
  } = props;

  const classes = useStyles();

  return (
    <>
      <Grid container alignItems="center">
        <Grid className={classes.dateWrap}>
          <span>一括取り込み適用日</span>
          <span className={classes.date}>{dateFormatter(new Date(setting.data.applyingAt), 'YYYY/MM/DD hh:mm')}</span>
        </Grid>
      </Grid>
      <CustomTable
        propLimit={20}
        headCells={headCells}
        list={tentativeUserList.data.users}
        total={tentativeUserList.data.total}
        labelDisplayedRows={defaultLabelDisplayedRows}
        rowsPerPageOptions={[]}
        onPageChange={onPageChange}
        onRowsPerPageChange={onRowsPerPageChange}
        isNoSlice
      >
        <DivisionUpdateRow currentPageNumber={currentPageNumber} />
      </CustomTable>
    </>
  );
}
