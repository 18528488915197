import { Grid, TableCell, Link, makeStyles } from '@material-ui/core';
import MemberStatusCode from '../../eleCommon/memberStatusCode';

import { URL_MAPPER } from '../../../constants/urls';
import { deleteHyphenFromPhoneNumber } from '../../../commonFunction';

const useStyles = makeStyles({
  cell: {
    fontSize: '13px',
    padding: '15px 0 16px 4px',
  },
  memberStatus: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '0 auto',
    '& .MuiTypography-root': {
      color: '#000;',
      fontSize: '10px',
      fontWeight: '700',
      textAlign: 'right',
    },
  },
  center: {
    textAlign: 'center',
  },
  rightBorder: {
    borderRight: '1px solid #C8C8C8',
    minHeight: 20,
    display: 'flex',
    alignItems: 'center',
    paddingRight: 4,
  },
  existing: { color: '#D83420' },
});

function CustomCell(props) {
  const { obj, row, onClick } = props;
  const classes = useStyles();
  let className = classes.cell;
  if (obj.checkKey && row[obj.checkKey]) {
    className += ` ${classes.existing}`;
  }
  switch (obj.type) {
    case 'center':
      className += ` ${classes.center}`;
      break;
    default:
  }

  let text = '';
  switch (obj.type) {
    case 'memberStatus':
      text = !row.memberId
        ? (
          <Grid className={classes.memberStatus}>
            <MemberStatusCode code={row[obj.key]} />
          </Grid>
        )
        : (
          <Link
            href={`${URL_MAPPER.web}?memberId=${row.memberId}`}
            target="_blank"
            className={classes.memberStatus}
          >
            <MemberStatusCode code={row[obj.key]} />
          </Link>
        );
      break;
    default:
    {
      text = row[obj.key] ?? '';
      if (/phoneNumber/.test(obj.key)) {
        text = deleteHyphenFromPhoneNumber(text);
      }
    }
  }
  return (
    <TableCell className={className} size="small" onClick={onClick}>
      <Grid
        className={!obj.lastCell ? classes.rightBorder : ''}
        style={{
          fontSize: obj.fontSize || '13px',
          fontWeight: obj.fontWeight || '400',
        }}
      >
        {text}
      </Grid>
    </TableCell>
  );
}

export default CustomCell;
