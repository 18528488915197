import React from 'react';
import { useSelector } from 'react-redux';
import {
  Grid,
  Typography,
  List,
  ListItem,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import commonStyles from '../../../styles';
import commonTheme from '../../../styles/theme';
import Calendar from '../../calendar';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    border: '1px solid #C8C8C8',
    background: '#fff',
    padding: '6px 8px 5px',
  },
  ttl: {
    width: 85,
    marginRight: 8,
    fontFamily: 'Roboto',
    fontSize: 13,
    lineHeight: 1.6,
    fontWeight: '700',
    letterSpacing: 0,
    flexShrink: 0,
    '& span': {
      color: '#D83420',
    },
  },
  cal: {
    display: 'flex',
    alignItems: 'center',
    [commonTheme.breakpoints.down('sm')]: {
      justifyContent: 'center',
    },
    [commonTheme.breakpoints.up('md')]: {
      width: '100%',
    },
    '& > div': {
      position: 'relative',
      border: 'none',
      padding: 0,
      display: 'flex',
      alignItems: 'center',
      [commonTheme.breakpoints.down('sm')]: {
        width: 136,
      },
      [commonTheme.breakpoints.up('md')]: {
        width: 150,
      },
      '&:first-child': {
        marginRight: 32,
      },
      '&:first-child::after': {
        content: '"〜"',
        display: 'block',
        fontFamily: 'Roboto',
        fontSize: 13,
        lineHeight: 1.6,
        position: 'absolute',
        top: '1px',
        [commonTheme.breakpoints.down('sm')]: {
          left: '145px',
        },
        [commonTheme.breakpoints.up('md')]: {
          left: '160px',
        },
      },
      '& > p': { flexShrink: 0 },
      '& label': {
        width: '100%',
      },
      '& label > span': {
        fontFamily: 'Roboto',
        fontSize: 13,
        lineHeight: 1.6,
        padding: 0,
        [commonTheme.breakpoints.down('sm')]: {
          fontWeight: 700,
        },
        [commonTheme.breakpoints.up('md')]: {
          fontWeight: 400,
        },
      },
      '& > h6:empty': { display: 'none' },
    },
  },
  // SP
  list: {
    background: '#fff',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '10px 8px 10px 16px',
    borderBottom: '1px solid #F3F3F3',
    width: '100%',
  },
  listItem: {
    padding: 0,
    justifyContent: 'space-between',
  },
  listTtl: {
    fontSize: 13,
    lineHeight: 1.2,
    fontWeight: 700,
    '& span': {
      color: '#D83420',
    },
  },
  listTxt: {
    fontSize: 13,
    fontWeight: 700,
    lineHeight: 1.2,
    textTransform: 'none',
    '& span': {
      margin: '0 16px',
    },
  },
}));

function InfoRegisterCalendar(props) {
  const { values } = props;
  const classes = useStyles();
  const baseClasses = commonStyles();

  // SP・PCでの分岐用
  const isSp = useSelector((state) => state.deviceTypeSlice.isSp);
  let contents;

  const data1 = {
    id: 'id1', // String - labelとinputを紐づけます
    label: '', // String - ラベル
    require: false, // Boolean - 必須かどうか
    type: 'time',
    state: values.state1,
    setState: values.setState1,
    onBlur: values.onblur,
  };

  const data2 = {
    id: 'id2', // String - labelとinputを紐づけます
    label: '', // String - ラベル
    require: false, // Boolean - 必須かどうか
    type: 'time',
    state: values.state2,
    setState: values.setState2,
    onBlur: values.onblur,
  };

  if (isSp) {
    contents = (
      <Grid>
        <List className={classes.list}>
          <ListItem className={classes.listItem}>
            <Typography className={classes.listTtl}>
              {values.label}
              {values.required && <span>＊</span>}
            </Typography>
            <Grid className={classes.cal}>
              <Calendar data={data1} />
              <Calendar data={data2} />
            </Grid>
          </ListItem>
        </List>
      </Grid>
    );
  } else {
    contents = (
      <Grid className={classes.root}>
        <Typography className={`${baseClasses.title6} ${classes.ttl}`}>
          {values.label}
          {values.required && <span>＊</span>}
        </Typography>
        <Grid className={classes.cal}>
          <Calendar data={data1} />
          <Calendar data={data2} />
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid>
      {contents}
    </Grid>
  );
}

export default InfoRegisterCalendar;
