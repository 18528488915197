/* eslint-disable object-shorthand */
import React, { useState } from 'react';
import { Grid, Typography, makeStyles } from '@material-ui/core';
import commonStyles from '../../../styles';
import {
  phoneHistorySelect,
  mailSendHistorySelect,
  guidanceCountSelect,
  budgetSelect,
  wishAreaSelect,
  stationSelect,
  keyWordSelect,
} from '../../../../constants/kanniKensaku';
import { canCallTime, rank, MAIL_OPEN_HISTORY_CODE } from '../../../../constants/search/detailSearch';
import AreaSentaku from '../../../common/areaSentaku';
import SelectRailwaysAndStationsContainer from '../../../../containers/search/railwayAndStations/selectRailwayAndStations';
import { AREA_CODES } from '../../../../constants/apiParameterCode';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  formControl: {
    margin: theme.spacing(1),
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  selectHidden: {
    visibility: 'hidden',
  },
  width_100: {
    width: '100%',
  },
  inputWrap: {
    'margin-top': 0,
  },
  centerMark: {
    width: 23,
    display: 'flex',
    marginBottom: '-12px',
    'justify-content': 'center',
    'align-items': 'flex-end',
    '&>p': {
      display: 'inline-block',
    },
    '&.center': {
      alignItems: 'center',
      marginBottom: 0,
    },
  },
  item: {
    marginBottom: 18,
  },
  itemTop: {
    marginTop: 10,
  },
  columnSelect: {
    width: 96.5,
    '& .MuiInput-underline:hover:not(.Mui-disabled)::before': {
      borderBottom: `2px solid ${theme.palette.primaryColor}`,
    },
  },
  select: {
    '& .MuiFormLabel-root': {
      fontFamily: 'Roboto',
      color: '#333',
      fontSize: 13,
      lineHeight: 1.6,
      letterSpacing: 0,
      top: '-10px',
      '&.MuiInputLabel-shrink': {
        transform: 'translate(0, 9px) scale(0.7)',
      },
    },
    '& .MuiSelect-root': {
      fontFamily: 'Roboto',
      color: '#333',
      fontSize: 13,
      lineHeight: 1.6,
      letterSpacing: 0,
      textAlign: 'left',
    },
    '& .MuiSelect-select': {
      padding: '0 24px 0 0',
    },
  },
  calendar: {
    '& > div': {
      flexWrap: 'nowrap',
      alignItems: 'center',
      border: 'none',
      fontWeight: 700,
      fontSize: 13,
      '& > div': {
        padding: 0,
      },
    },
    '& label': {
      minWidth: 96,
      textAlign: 'left',
      lineHeight: 1,
      padding: '6px 0',
      '& > span': {
        fontWeight: 400,
        color: '#333',
        padding: 0,
      },
      '&::before, &::after': {
        position: 'absolute',
        display: 'block',
        content: '""',
        boxSizing: 'inherit',
        left: 0,
        right: 0,
        bottom: 0,
        pointerEvents: 'none',
      },
      '&::before': {
        borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
      },
      '&::after': {
        transition: 'transform 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        transform: 'scaleY(0)',
        borderBottom: `2px solid ${theme.palette.primaryColor}`,
      },
      '&:hover': {
        backgroundColor: 'transparent',
        '&::before': {
          borderColor: 'transparent',
        },
        '&::after': {
          transform: 'scaleY(1)',
        },
      },
      '&>span': {
        fontSize: 13,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
      },
    },
  },
  label: {
    transform: 'scale(0.7)',
    color: '#333',
    fontSize: 13,
    position: 'absolute',
    left: 0,
    top: -10,
    transformOrigin: 'left',
  },
}));

/**
 * 年月日フォーマット
 * @param {string} input YYYY-MM-DD
 * @returns YYYY/MM/DD
 */
function formatDate(input) {
  if (!input || typeof input !== 'string') return '';
  return input.replace(/-/g, '/');
}

function SortSelectMain(props) {
  const classes = useStyles();
  const baseClasses = commonStyles();
  const { searchConditions, setSearchConditions, getComponent, isCustomSelects } = props;

  // 沿線・駅選択ウィンドウ用
  const [isOpenStation, setIsOpenStation] = useState(false);
  const onOpenStation = () => setIsOpenStation(true);
  const onCloseStation = () => setIsOpenStation(false);
  const onSelectStation = (data) => {
    console.log('gotten data:', data);
    const copyObj = Object.assign({}, searchConditions);
    copyObj.wishRailwayId = data.railway.railwayId;
    copyObj.wishRailway = data.railway.railwayName;
    copyObj.wishStationFrom = data.fromStation.stationId;
    copyObj.wishStationFromName = data.fromStation.name;
    copyObj.wishStationTo = data.toStation.stationId;
    copyObj.wishStationToName = data.toStation.name;
    setSearchConditions(copyObj);
  };

  // 地域選択
  const [openArea, setOpenArea] = useState(false);
  const [areaInitialParam, setAreaInitialParam] = useState({});
  const handleClose = () => {
    setOpenArea(false);
  };
  // 地域選択実行時
  const onSelectArea = (params) => {
    console.log(params);
    const copyObj = Object.assign({}, searchConditions);
    copyObj.wishArea = params.wishArea;
    copyObj.wishAreaCode = params.wishAreaCode ? [params.wishAreaCode] : null;
    copyObj.wishAreaIds = params.wishAreaIds;
    copyObj.isNoneWishArea = params.isNoneWishArea ? 1 : null;
    setSearchConditions(copyObj);
  };

  const handleAreaOpen = () => {
    setAreaInitialParam({
      id: null,
      wishAreaCode: searchConditions.wishAreaCode,
      wishAreaIds: searchConditions.wishAreaIds,
      isNoneWishArea: searchConditions.isNoneWishArea,
    });
    setOpenArea(true);
  };

  const handleChange = (e, data) => {
    const val = e.target.value;
    switch (data.key) {
      case 'convenientTimes':
        setSearchConditions({ ...searchConditions, convenientTimes: val === '' ? val : [val] });
        break;
      case 'phoneSupportStatusCode':
        setSearchConditions({ ...searchConditions, phoneSupportStatusCode: val === '' ? val : Number(val) });
        break;
      case 'mailSendStatusCode':
        setSearchConditions({ ...searchConditions, mailSendStatusCode: val === '' ? val : Number(val) });
        break;
      case 'mailOpenHistoryCode':
        setSearchConditions({ ...searchConditions, mailOpenHistoryCode: val === '' ? val : Number(val) });
        break;
      case 'guidanceCountMin':
        setSearchConditions({ ...searchConditions, guidanceCountMin: val === '' ? val : Number(val) });
        break;
      case 'guidanceCountMax':
        setSearchConditions({ ...searchConditions, guidanceCountMax: val === '' ? val : Number(val) });
        break;
      case 'keyword':
        setSearchConditions({ ...searchConditions, keyword: String(val) });
        break;
      default:
        break;
    }
  };

  const levelChange = (e) => {
    setSearchConditions({
      ...searchConditions,
      userLevelMin: e[0] === null || e[0] === '' ? '' : Number(e[0]),
      userLevelMax: e[1] === null || e[1] === '' ? '' : Number(e[1]),
    });
  };
  const budgetChange = (e) => {
    setSearchConditions({
      ...searchConditions,
      budgetMin: e[0] === null || e[0] === '' ? '' : Number(e[0]),
      budgetMax: e[1] === null || e[1] === '' ? '' : Number(e[1]),
    });
  };

  const dateChange = (value1, value2) => {
    setSearchConditions(
      {
        ...searchConditions,
        effectFrom: formatDate(value1),
        effectTo: formatDate(value2),
      },
    );
  };

  const sortSelectList = {
    canCallTime: {
      title: '電話が繋がりやすい時間帯',
      required: false,
      key: 'convenientTimes',
      type: 'select',
      obj: canCallTime,
      searchConditions: searchConditions,
      state: searchConditions.convenientTimes,
      tooltip: '',
      set: handleChange,
    },
    userLevel: {
      title: rank.title,
      required: false,
      key: 'userLevel',
      type: 'rangeSelect',
      obj: rank.menus,
      searchConditions: searchConditions,
      state: [searchConditions.userLevelMin, searchConditions.userLevelMax],
      tooltip: '',
      set: levelChange,
      label: [rank.title, rank.title],
      classes: classes.columnSelect,
    },
    phoneSupportStatusCode: {
      title: 'TEL歴',
      required: false,
      key: 'phoneSupportStatusCode',
      type: 'select',
      obj: phoneHistorySelect,
      searchConditions: searchConditions,
      state: searchConditions.phoneSupportStatusCode,
      tooltip: '',
      set: handleChange,
    },
    mailSendStatusCode: {
      title: 'Mail歴',
      required: false,
      key: 'mailSendStatusCode',
      type: 'select',
      obj: mailSendHistorySelect,
      searchConditions: searchConditions,
      state: searchConditions.mailSendStatusCode,
      tooltip: '',
      set: handleChange,
    },
    mailOpenHistoryCode: {
      title: 'メール開封',
      required: false,
      key: 'mailOpenHistoryCode',
      type: 'select',
      obj: MAIL_OPEN_HISTORY_CODE,
      searchConditions: searchConditions,
      state: searchConditions.mailOpenHistoryCode,
      tooltip: '',
      set: handleChange,
    },
    effectDate: {
      title: '',
      required: false,
      key: 'effectDate',
      type: 'calendar',
      searchConditions: searchConditions,
      tooltip: '反響日',
      inputData: {
        label: [], // typeが'multiple'のときのみ配列
        require: false,
        inputType: 'date',
        type: 'multiple',
        startState: searchConditions.effectFrom,
        endState: searchConditions.effectTo,
        placeholder: ['反響日(From)', '反響日(To)'],
        setState: (start, end) => { return dateChange(start, end); },
      },
    },
    guidanceCountMin: {
      title: '案内回数',
      required: false,
      key: 'guidanceCountMin',
      type: 'select',
      obj: guidanceCountSelect,
      searchConditions: searchConditions,
      state: searchConditions.guidanceCountMin,
      tooltip: '案内回数',
      set: handleChange,
    },
    guidanceCountMax: {
      title: '',
      required: false,
      key: 'guidanceCountMax',
      type: 'select',
      obj: guidanceCountSelect,
      searchConditions: searchConditions,
      state: searchConditions.guidanceCountMax,
      tooltip: '',
      set: handleChange,
    },
    budget: {
      title: '予算（万円）',
      required: false,
      key: 'budgetMin',
      type: 'rangeSelect',
      obj: budgetSelect.menus,
      searchConditions: searchConditions,
      state: [searchConditions.budgetMin, searchConditions.budgetMax],
      tooltip: '戸建価格＋土地価格',
      set: budgetChange,
      label: ['予算（万円）', '予算（万円）'],
      classes: classes.columnSelect,
    },
    budgetMin: {
      title: '予算（万円）',
      required: false,
      key: 'budgetMin',
      type: 'select',
      obj: budgetSelect,
      searchConditions: searchConditions,
      state: searchConditions.budgetMin,
      tooltip: '戸建価格＋土地価格',
      set: handleChange,
    },
    budgetMax: {
      title: '',
      required: false,
      key: 'budgetMax',
      type: 'select',
      obj: budgetSelect,
      searchConditions: searchConditions,
      state: searchConditions.budgetMax,
      tooltip: '',
      set: handleChange,
    },
    wishArea: {
      title: '希望地域',
      required: false,
      key: 'wishArea',
      type: 'clickBtn',
      wishFlg: true,
      obj: wishAreaSelect,
      searchConditions: searchConditions,
      state: searchConditions.wishArea,
      tooltip: '',
      set: handleAreaOpen,
    },
    wishStationFrom: {
      title: '希望駅',
      required: false,
      readOnly: true,
      key: 'wishStationFrom',
      type: 'hidden',
      className: 'terms__wishStationFrom',
      searchConditions: searchConditions,
      state: searchConditions.wishStationFrom,
      tooltip: '',
      set: (e) => {
        return setSearchConditions({ ...searchConditions, wishStationFrom: e });
      },
    },
    wishStationFromName: {
      title: '希望駅(From)',
      required: false,
      key: 'wishStationFromName',
      type: 'clickBtn',
      obj: stationSelect,
      searchConditions: searchConditions,
      state: searchConditions.wishStationFromName,
      tooltip: '',
      set: () => {
        onOpenStation();
      },
    },
    wishStationTo: {
      title: '希望駅',
      required: false,
      readOnly: true,
      key: 'wishStationTo',
      type: 'hidden',
      className: 'terms__wishStationTo',
      searchConditions: searchConditions,
      state: searchConditions.wishStationTo,
      tooltip: '',
      set: (e) => {
        return setSearchConditions({ ...searchConditions, wishStationTo: e });
      },
    },
    wishStationToName: {
      title: '希望駅(To)',
      required: false,
      key: 'wishStationToName',
      type: 'clickBtn',
      obj: stationSelect,
      searchConditions: searchConditions,
      state: searchConditions.wishStationToName,
      tooltip: '',
      set: () => {
        onOpenStation();
      },
    },
    keyword: {
      title: 'キーワード',
      required: false,
      key: 'keyword',
      type: 'str',
      obj: keyWordSelect,
      searchConditions: searchConditions,
      state: searchConditions.keyword,
      tooltip: '',
      set: handleChange,
    },
  };

  return (
    <Grid container>
      {isCustomSelects ? (
        <>
          <Grid container className={classes.calendar}>
            {getComponent(sortSelectList.effectDate)}
          </Grid>

          <Grid container item xs={12} className={classes.item}>
            <Grid item className={`${classes.select} ${classes.columnSelect}`}>
              {getComponent(sortSelectList.guidanceCountMin)}
            </Grid>
            <Grid item className={classes.centerMark}>
              <Typography className={baseClasses.title4}>～</Typography>
            </Grid>
            <Grid item className={`${classes.select} ${classes.columnSelect}`}>
              {getComponent(sortSelectList.guidanceCountMax)}
            </Grid>
          </Grid>
        </>
      ) : (
        <>
          <Grid container item className={classes.item}>
            <Grid item xs={12} className={classes.select}>
              {getComponent(sortSelectList.canCallTime)}
            </Grid>
          </Grid>
          <Grid container item xs={12}>
            <Grid item className={`${classes.select}`}>
              {getComponent(sortSelectList.userLevel)}
            </Grid>
          </Grid>

          <Grid container item xs={12} className={classes.item}>
            <Grid item className={`${classes.select} ${classes.columnSelect}`}>
              {getComponent(sortSelectList.phoneSupportStatusCode)}
            </Grid>
            <Grid item className={classes.centerMark} />
            <Grid item className={`${classes.select} ${classes.columnSelect}`}>
              {getComponent(sortSelectList.mailSendStatusCode)}
            </Grid>
          </Grid>

          <Grid container item xs={12} className={classes.item}>
            <Grid item xs={12} className={classes.select}>
              {getComponent(sortSelectList.mailOpenHistoryCode)}
            </Grid>
          </Grid>
        </>
      )}

      <Grid container item xs={12}>
        <Grid item className={`${classes.select}`}>
          {getComponent(sortSelectList.budget)}
        </Grid>
      </Grid>

      <Grid container item xs={12} className={classes.itemTop}>
        <Grid item xs={12} style={{ position: 'relative' }}>
          {searchConditions.wishArea ? <span className={classes.label}>希望地域</span> : ''}
          {getComponent(sortSelectList.wishArea)}
          <AreaSentaku
            initialParam={areaInitialParam}
            open={openArea}
            onClose={handleClose}
            onUpdate={onSelectArea}
            isFromSearch
            isNoneWishAreaInit={searchConditions.isNoneWishArea}
          />
        </Grid>
      </Grid>

      <Grid container item xs={12} className={classes.itemTop}>
        <Grid item className={classes.selectHidden}>
          {getComponent(sortSelectList.wishStationFrom)}
        </Grid>
        <Grid item className={`${classes.select} ${classes.columnSelect}`} style={{ position: 'relative' }}>
          {searchConditions.wishStationFromName ? <span className={classes.label}>希望駅(From)</span> : ''}
          {getComponent(sortSelectList.wishStationFromName)}
        </Grid>
        <Grid item className={`${classes.centerMark} center`}>
          <Typography className={baseClasses.title4}>～</Typography>
        </Grid>
        <Grid item className={classes.selectHidden}>
          {getComponent(sortSelectList.wishStationTo)}
        </Grid>
        <Grid item className={`${classes.select} ${classes.columnSelect}`} style={{ position: 'relative' }}>
          {searchConditions.wishStationToName ? <span className={classes.label}>希望駅(To)</span> : ''}
          {getComponent(sortSelectList.wishStationToName)}
        </Grid>
        <SelectRailwaysAndStationsContainer
          initialParam={{ areaCode: AREA_CODES.KANTO }}
          onUpdate={onSelectStation}
          open={isOpenStation}
          onClose={onCloseStation}
        />
      </Grid>

      <Grid container item xs={12} className={classes.itemTop}>
        <Grid item xs={12} className={classes.text}>
          {getComponent(sortSelectList.keyword)}
        </Grid>
      </Grid>
    </Grid>
  );
}

export default SortSelectMain;
