import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useState, useEffect } from 'react';
import Calendar from '../../../common/calendar';
import commonStyles from '../../../styles';
import commonTheme from '../../../styles/theme';

const useStyles = makeStyles({
  cal: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    position: 'relative',
    '& > div': {
      width: '47%',
      '& > div:has(> label)': {
        border: 'none',
        width: '100% !important',
        position: 'relative',
        '& > p': { flexShrink: 0 },
        '& label': {
          width: '100%',
          borderBottom: '1px #8c8c8c solid',
        },
        '&::after': {
          content: '""',
          display: 'block',
          background: 'transparent center/cover no-repeat',
          backgroundImage: 'url(\'data:image/svg+xml;utf-8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path fill="%23333333" d="M20 3h-1V1h-2v2H7V1H5v2H4c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 18H4V10h16v11zm0-13H4V5h16v3z"/></svg>\')',
          width: '16px',
          height: '16px',
          position: 'absolute',
          top: '26px',
          right: '14px',
          pointerEvents: 'none',
        },
        '& > h6:empty': { display: 'none' },
      },
    },
    '& > div:has(> label)': {
      border: 'none',
      position: 'relative',
      '& > p': { flexShrink: 0 },
      '& label': {
        width: '100%',
        borderBottom: '1px #8c8c8c solid',
      },
      '&::after': {
        content: '""',
        display: 'block',
        background: 'transparent center/cover no-repeat',
        backgroundImage: 'url(\'data:image/svg+xml;utf-8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path fill="%23333333" d="M20 3h-1V1h-2v2H7V1H5v2H4c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 18H4V10h16v11zm0-13H4V5h16v3z"/></svg>\')',
        width: '16px',
        height: '16px',
        position: 'absolute',
        top: '26px',
        right: '14px',
        pointerEvents: 'none',
      },
      '& > h6:empty': { display: 'none' },
    },
  },
  mouseOn: {
    transform: 'translate(0, 1.5px) scale(0.75)',
    transformOrigin: 'top left',
  },
  mouseLeave: {
    transform: 'translate(10px, 17px) scale(1)',
  },
  cal2: {
    '&.disabled': {
      [commonTheme.breakpoints.up('md')]: {
        opacity: 0.3,
        pointerEvents: 'none',
      },
    },
  },
});

function SearchPartsCalendar(props) {
  const { values } = props;
  const common = commonStyles();
  const classes = useStyles();
  const [mouseOn, setMouseOn] = useState(false);

  const mouseEnterHandler = () => {
    setMouseOn(true);
  };

  const mouseLeaveHandler = () => {
    setMouseOn(false);
  };

  const from = {
    label: '',
    type: 'date',
    className: values.className1 || null,
    state: values.state,
    setState: values.setState,
  };
  const to = {
    label: '',
    type: 'date',
    className: values.className2 || null,
    state: values.stateto,
    setState: values.setStateto,
  };

  const dateCreate = (value) => {
    const year = value.getFullYear();
    const month = ('00' + (value.getMonth() + 1)).slice(-2);
    const day = ('00' + value.getDate()).slice(-2);
    const newDate = year + '/' + month + '/' + day;
    return newDate;
  };

  useEffect(() => {
    // 対応日時のみの処理
    if (values.key === 'actionCalendar') {
      if (values.state) {
        const startDate = new Date(values.state);
        startDate.setFullYear(startDate.getFullYear() + 1);
        if (!values.stateto) {
          // ToにFromの1年後の日付をセット
          values.setStateto(dateCreate(startDate));
        } else {
          const endDate = new Date(values.stateto);
          if (startDate < endDate) { // FromにToの1年以上前の日付が選択された場合
            // ToにFromの1年後の日付をセット
            values.setStateto(dateCreate(startDate));
          }
        }
      } else {
        // Toの日付をリセット
        values.setStateto('');
      }
    }
  }, [values.state]);

  useEffect(() => {
    // 対応日時のみの処理
    if (values.key === 'actionCalendar') {
      if (values.stateto) {
        const startDate = new Date(values.state);
        const endDate = new Date(values.stateto);
        endDate.setFullYear(endDate.getFullYear() - 1);
        if (startDate < endDate) { // ToにRromの1年以上前の日付が選択された場合
          // FromにToの1年後の日付をセット
          values.setState(dateCreate(endDate));
        }
      } else {
        // Fromの日付をリセット
        values.setState('');
      }
    }
  }, [values.stateto]);

  return (
    <div className={classes.cal}>
      <Typography className={`${common.small} ${values.className3} ${mouseOn || from.state ? classes.mouseOn : classes.mouseLeave}`}>{values.label}</Typography>
      <div onMouseEnter={mouseEnterHandler} onMouseLeave={mouseLeaveHandler}>
        <Calendar data={from} />
      </div>
      <span>～</span>
      <div className={`${classes.cal2} ${values.disabled && 'disabled'}`}>
        <Calendar data={to} />
      </div>
    </div>
  );
}

export default SearchPartsCalendar;
