import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import MainColumnWrapper from './parts/mainColumn/mainColumnWrapper';
import SideBarWrapper from './parts/sideBar/sideBarWrapper';

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    overflow: 'hidden',
    height: 'calc(100vh - 52px)',
  },
  header: {
    height: 52,
    borderBottom: '1px solid rgba(51, 51, 51, 0.4)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  main: {
    display: 'flex',
  },
  sideMenu: {
    width: 260,
  },
  contents: {
    width: 'calc(100vw - 260px)',
    overflow: 'hidden',
    height: 'calc(100vh - 52px)',
  },
});

function ProspectsListWrapper(props) {
  const { value } = props;
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid className={classes.main}>
        <Grid className={classes.sideMenu}>
          <SideBarWrapper value={value} />
        </Grid>
        <Grid className={classes.contents}>
          <MainColumnWrapper value={value} />
        </Grid>
      </Grid>
    </div>
  );
}

export default ProspectsListWrapper;
