import { Select, MenuItem, makeStyles } from '@material-ui/core';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import positionChange from '../../commonFunction/positionChange';

const useStyles = makeStyles((theme) => ({
  select: {
    height: 20,
  },
  openEvent: {
    '&>.MuiSelect-select.Mui-disabled': {
      cursor: 'pointer',
    },
    '&.MuiInput-underline.Mui-disabled:before': {
      borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
      cursor: 'pointer',
    },
    '&:hover.MuiInput-underline.Mui-disabled:before': {
      borderBottom: `2px solid ${theme.palette.primaryColor}`,
    },
    '&>.MuiSelect-icon.Mui-disabled': {
      color: 'rgba(0, 0, 0, 0.54)',
    },
  },
}));

function CustomSidebarSelectMulti(props) {
  const classes = useStyles();
  const { initial } = props;
  const menusKeys = Object.keys(initial.obj.menus);

  return (
    <Select
      className={classes.select}
      labelId="mutiple-select-label"
      multiple
      value={initial.state}
      defaultValue=""
      onChange={(e) => {
        const valueList = e.target.value;
        initial.set(e, initial, valueList);
      }}
      label={initial.obj.title}
      onOpen={positionChange}
    >
      {menusKeys.map((data) => (
        <MenuItem
          value={data}
          defaultValue=""
          key={data}
        >
          {initial.obj.menus[data]}
        </MenuItem>
      ))}
    </Select>
  );
}

export default CustomSidebarSelectMulti;
