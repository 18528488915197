import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
  Grid,
  Button,
  Paper,
  Link,
} from '@material-ui/core';
import Draggable from 'react-draggable';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import StopIcon from '@material-ui/icons/Stop';
import RoomIcon from '@material-ui/icons/Room';
import { makeStyles } from '@material-ui/core/styles';
import DriveFileRenameIcon from '../../icons/fileRename';
import commonStyles from '../../styles';
import commonTheme from '../../styles/theme';
import Memo from './parts/memo';
import Achievment from './parts/achievment';
import ActRegistWindow from './actRegistWindow';
import Modal from '../../common/modal';
import img from './img/img.png';
import CheckBoxLabel from '../../eleCommon/checkBoxLabel';
import { changeConfirmMessage } from '../../../store/eleCommon/customConfirmMessage';

import setLocation from '../../../commonFunction/geolocation';

import {
  ACTION_TYPE,
  ACTION_TYPE_FLYER_SALES,
} from '../../../constants';

const useStyles = makeStyles((theme) => ({
  dialog: {
    zIndex: '1200!important',
    '& .MuiDialog-paperWidthSm': {
      maxWidth: 'none',
      [commonTheme.breakpoints.down('sm')]: {
        borderRadius: 0,
        margin: 0,
        width: '100%',
        height: '100%',
        maxHeight: 'none',
      },
    },
    '& .MuiPaper-elevation1': {
      boxShadow: 'none',
    },
    '& .MuiDialog-paper': {
      [commonTheme.breakpoints.down('sm')]: {
        background: '#F3F3F3',
      },
    },
  },
  dialogHeader: {
    background: '#fff',
    padding: '24px 24px 9px 24px',
    boxSizing: 'border-box',
    [commonTheme.breakpoints.up('md')]: {
      width: 640,
    },
  },
  dialog2: {
    '& .MuiDialog-paperWidthSm': {
      [commonTheme.breakpoints.down('sm')]: {
        margin: 16,
      },
    },
  },
  finish: {
    width: '100%',
    backgroundColor: '#C8C8C8',
    padding: 16,
    textAlign: 'center',
    color: '#333333',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '16px',
    lineHeight: '120%',
    boxSizing: 'border-box',
  },
  dialogContent: {
    backgroundColor: '#F3F3F3',
    [commonTheme.breakpoints.down('sm')]: {
      padding: 0,
      flex: 'none',
    },
    [commonTheme.breakpoints.up('md')]: {
      width: 640,
      borderTop: '1px solid #C8C8C8',
      borderBottom: '1px solid #C8C8C8',
      boxSizing: 'border-box',
    },
  },
  head: {
    margin: '20px 0',
    [commonTheme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  dialogFooter: {
    [commonTheme.breakpoints.down('sm')]: {
      padding: '16px 16px 10px',
      backgroundColor: '#F3F3F3',
    },
    [commonTheme.breakpoints.up('md')]: {
      display: 'flex',
      justifyContent: 'space-around',
      padding: '16px 200px',
    },
  },
  dialogFooterTxt: {
    [commonTheme.breakpoints.down('sm')]: {
      textAlign: 'center',
      marginBottom: 10,
    },
    [commonTheme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  releaseButton: {
    [commonTheme.breakpoints.down('sm')]: {
      color: theme.palette.primaryColor,
      marginRight: 22,
    },
    [commonTheme.breakpoints.up('md')]: {
      color: '#D83420',
    },
    '& .MuiSvgIcon-root': {
      fontSize: 18,
    },
    '& .MuiTypography-root': {
      [commonTheme.breakpoints.down('sm')]: {
        fontWeight: 700,
        fontSize: 15,
      },
    },
  },
  selectButton: {
    height: '32px',
    color: '#fff',
    [commonTheme.breakpoints.down('sm')]: {
      width: '100%',
    },
    [commonTheme.breakpoints.up('md')]: {
      width: '116px',
    },
  },
  scheduleContainer: {
    height: 'auto',
    backgroundColor: '#fff',
    borderLeft: 'solid 8px #64CAF5',
    padding: '12px 8px',
    boxSizing: 'border-box',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    [commonTheme.breakpoints.up('md')]: {
      width: 584,
      marginBottom: 20,
    },
  },
  scheduleContainerBox: {
    [commonTheme.breakpoints.up('md')]: {
      width: 420,
    },
    [commonTheme.breakpoints.down('sm')]: {
      marginLeft: 8,
      width: '100%',
    },
  },
  playCircleOutlineIconWrap: {
    width: 30,
  },
  playCircleOutlineIcon: {
    color: theme.palette.primaryColor,
    width: 30,
    height: 30,
  },
  littleContent: {
    display: 'flex',
    alignItems: 'baseline',
  },
  heading: {
    fontWeight: 700,
    marginRight: 10,
    [commonTheme.breakpoints.down('sm')]: {
      fontSize: 10,
      flexShrink: 0,
    },
  },
  area: {
    marginRight: 20,
    [commonTheme.breakpoints.down('sm')]: {
      fontSize: 12,
      minWidth: 'calc(50% - 50px)',
      wordBreak: 'break-all',
    },
  },
  scene: {
    [commonTheme.breakpoints.down('sm')]: {
      fontSize: 12,
    },
  },
  memo: {
    [commonTheme.breakpoints.down('sm')]: {
      fontSize: 12,
    },
  },
  editButtonContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    [commonTheme.breakpoints.down('sm')]: {
      padding: '6px 10px 6px 16px',
    },
  },
  editButtton: {
    display: 'flex',
  },
  finishAlertContainer: {
    padding: 24,
    [commonTheme.breakpoints.up('md')]: {
      width: 515,
    },
    '& .MuiDialogTitle-root': {
      padding: 0,
    },
  },
  title: {
    textAlign: 'center',
    [commonTheme.breakpoints.down('sm')]: {
      marginBottom: 8,
    },
    [commonTheme.breakpoints.up('md')]: {
      marginBottom: 30,
    },
  },
  alert: {
    textAlign: 'center',
    [commonTheme.breakpoints.down('sm')]: {
      marginBottom: 38,
    },
    [commonTheme.breakpoints.up('md')]: {
      marginBottom: 50,
    },
  },
  buttons: {
    display: 'flex',
    justifyContent: 'center',
    [commonTheme.breakpoints.up('md')]: {
      gap: 150,
    },
    '& Button': {
      color: theme.palette.primaryColor,
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '15px',
      lineHeight: '160%',
      '&:first-child': {
        [commonTheme.breakpoints.down('sm')]: {
          marginRight: 80,
        },
      },
    },
  },
  googleMap: {
    color: theme.palette.primaryColor,
  },
  drawerHeader: {
    textAlign: 'center',
    fontSize: '24px',
    padding: '16px 16px 8px 16px',
    width: '100%',
    boxSizing: 'border-box',
    background: '#fff',
  },
  lbl: {
    [commonTheme.breakpoints.down('sm')]: {
      padding: 16,
    },
    [commonTheme.breakpoints.up('md')]: {
      marginBottom: 10,
    },
  },
  icon: { fill: theme.palette.primaryColor },
  txt: { color: theme.palette.primaryColor },
  confirm: {
    '& .confirmHeader': {
      fontSize: 16,
    },
    '& .confirmHeader button': {
      display: 'none',
    },
    '& .confirmBody': {
      background: '#fff',
    },
  },
  confirmText: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '10px',
    lineHeight: '160%',
    textAlign: 'center',
    margin: '20px 0 4px',
  },
  confirmImage: {
    display: 'block',
    width: '80%',
    margin: '0 auto',
  },
  confirmCheck: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 10,
    '& .MuiFormControlLabel-label': {
      fontSize: 10,
      marginTop: 1,
    },
    '& .MuiSvgIcon-root': {
      fontSize: 16,
    },
  },
}));

const START_CODE = 1;
const END_CODE = 2;

function PaperComponent(props) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  );
}

function EditableWindow(props) {
  const {
    open, onClose, setReacquisitionFlg,
    localUpdateData, setLocalUpdateData, setOpenCustomertree,
    editableFlag, schedulesUpdateApiFunction, schedulesDeleteApiFunction, actionScheduleCode,
  } = props;

  const [openEditWindow, setOpenEditWindow] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const classes = useStyles();
  const baseClasses = commonStyles();
  const dispatch = useDispatch();

  const [checked, setChecked] = useState(false);

  const [errorSet, setErrorSet] = useState(new Set());

  const errorCallback = (key) => (hasError) => {
    setErrorSet((prev) => {
      if (hasError) return new Set(prev.add(key));
      prev.delete(key);
      return new Set(prev);
    });
  };

  // 目標
  const [targetObj, setTargetObj] = useState({
    targetTotalCard: '',
    targetSearchCard: '',
    targetTelAppoint: '',
    targetGuidance: '',
    targetImmediateGuidance: '',
    targetVisit: '',
  });
  // 実績
  const [archieveObj, setArchieveObj] = useState({
    archieveTotalCard: '',
    archieveSearchCard: '',
    archieveTelAppoint: '',
    archieveGuidance: '',
    archieveImmediateGuidance: '',
    archieveVisit: '',
  });
  const [selectDivision, setSelectDivision] = useState({
    divId: 0,
    divisionName: '-',
  });
  // ラベルの更新制御
  const [updateRndFlg, setUpdateRndFlg] = useState(false);

  // 源泉中
  const [isSource, setIsSource] = useState(false);

  // 源泉終了
  const [isFinished, setIsFinished] = useState(false);

  // 編集中 (編集ボタン押下時)
  const [isEdit, setIsEdit] = useState(false);

  // 源泉開始用の位置情報
  const [position, setPosition] = useState({});

  // SP・PCでの分岐用
  const isSp = useSelector((state) => state.deviceTypeSlice.isSp);

  // レスポンスで送る更新データをコピー
  const moldingParameter = (updateObj) => {
    const copyObj = Object.assign({}, updateObj);
    copyObj.longitude = Number(copyObj.longitude);
    copyObj.latitude = Number(copyObj.latitude);
    Object.keys(copyObj).forEach(key => {
      // nullの場合削除
      if (copyObj[key] === null) {
        delete copyObj[key];
      }
    });
    return copyObj;
  };

  const handleEditableWindowOpen = () => {
    setIsEdit(true);
    setOpenEditWindow(true);
  };

  const handleClose = () => {
    setIsEdit(false);
    setOpenEditWindow(false);
  };

  const handleDelete = () => {
    dispatch(changeConfirmMessage({
      title: '削除されます',
      msgList: ['本当に削除しますか？'],
      buttons: [
        {
          label: 'CANCEL',
          set: () => { return false; },
          classes: baseClasses.buttonsSecondary,
        },
        {
          label: 'OK',
          set: () => {
            // ラベルの再取得
            setUpdateRndFlg(true);
            setReacquisitionFlg(true);
            onClose();
          },
          classes: baseClasses.buttonsPrimary,
        },
      ],
    }));
  };

  // 源泉開始アラート
  const [openStartAlert, setOpenStartAlert] = useState(false);
  const handleStartAlert = async () => {
    setIsSource(true);
    setOpenStartAlert(!openStartAlert);
    const updateSchedule = moldingParameter(localUpdateData);
    updateSchedule.flyerSalesCode = START_CODE;
    updateSchedule.longitude = position.longitude || '';
    updateSchedule.latitude = position.latitude || '';
    onClose();
    // PUT
    await schedulesUpdateApiFunction(localUpdateData.scheduleId, updateSchedule);
  };

  // 源泉終了アラート
  const [openFinishAlert, setOpenFinishAlert] = useState(false);
  const handleFinishAlert = async () => {
    setIsFinished(true);
    setOpenFinishAlert(!openFinishAlert);
    const updateSchedule = moldingParameter(localUpdateData);
    updateSchedule.flyerSalesCode = END_CODE;
    updateSchedule.longitude = position.longitude || '';
    updateSchedule.latitude = position.latitude || '';
    onClose();
    // PUT
    await schedulesUpdateApiFunction(localUpdateData.scheduleId, updateSchedule);
  };

  // 確認画面
  const finishConfirmOpen = () => {
    dispatch(changeConfirmMessage({
      title: '源泉を終了しますか？',
      msgList: [`${localUpdateData.centerDivisionName}のGoogle Chatグループへ通知します`],
      buttons: [
        {
          label: 'CANCEL',
          set: () => { setOpenFinishAlert(false); },
          classes: baseClasses.buttonsSecondary,
        },
        {
          label: 'OK',
          set: handleFinishAlert,
          classes: baseClasses.buttonsPrimary,
        },
      ],
    }));
  };
  const startConfirmOpen = () => {
    setOpenConfirm(true);
  };

  const startButton = (
    <>
      <Typography className={`${baseClasses.title6} ${classes.dialogFooterTxt}`}>
        目標を登録して開始してください
      </Typography>
      <Button
        onClick={startConfirmOpen}
        className={`${baseClasses.buttonsPrimary} ${classes.selectButton}`}
        startIcon={<PlayArrowIcon />}
      >
        <span>源泉開始</span>
      </Button>
    </>
  );

  const finishButton = (
    <>
      <Typography className={`${baseClasses.title6} ${classes.dialogFooterTxt}`}>
        実績を登録して終了してください
      </Typography>
      <Button
        onClick={finishConfirmOpen}
        className={`${baseClasses.buttonsPrimary} ${classes.selectButton}`}
        startIcon={<StopIcon />}
        disabled={errorSet.size !== 0}
      >
        <span>源泉終了</span>
      </Button>
    </>
  );

  const getButton = () => {
    switch (isSp) {
      case true:
        if (!(ACTION_TYPE_FLYER_SALES.includes(actionScheduleCode))) {
          return null;
        }
        switch (isSource) {
          case true:
            return finishButton;
          default:
            return startButton;
        }
      default:
        return finishButton;
    }
  };

  const playCircleOutlineIcon = (
    <PlayCircleOutlineIcon className={classes.playCircleOutlineIcon} />
  );

  const getIcon = () => {
    switch (isSp) {
      case true:
        switch (isSource) {
          case true:
            return playCircleOutlineIcon;
          default:
            return '';
        }
      default:
        return playCircleOutlineIcon;
    }
  };

  const googleMapLink = (
    <Link href={`https://www.google.com/maps?q=${localUpdateData.latitude},${localUpdateData.longitude}`} target="_blank">
      <Button startIcon={<RoomIcon />} className={classes.googleMap}>開始地点</Button>
    </Link>
  );

  useEffect(() => {
    setIsSource(localUpdateData.flyerSalesCode >= START_CODE);
    setIsFinished(localUpdateData.flyerSalesCode === END_CODE);
    setLocation(setPosition);
  }, [localUpdateData.scheduleId]);

  useEffect(() => {
    // モーダルは開いている時は画面を拡大できるようにする
    const viewportMetaTag = document.querySelector('meta[name="viewport"]');
    if (openConfirm) {
      viewportMetaTag.setAttribute('content', 'width=device-width, initial-scale=1.0');
    } else {
      viewportMetaTag.setAttribute('content', 'width=device-width, initial-scale=1.0, maximum-scale=1.0');
    }
  }, [openConfirm]);

  // 削除処理
  useEffect(() => {
    if (updateRndFlg) {
      // delete処理
      schedulesDeleteApiFunction(localUpdateData.scheduleId);
      // 初期化
      setUpdateRndFlg(false);
    }
  }, [updateRndFlg]);

  const createLabel = () => {
    if (ACTION_TYPE_FLYER_SALES.includes(actionScheduleCode)) {
      return '源泉';
    } else {
      return ACTION_TYPE[actionScheduleCode];
    }
  };

  const createTargetOrResult = () => {
    if (isSp && (ACTION_TYPE_FLYER_SALES.includes(actionScheduleCode))) {
      if (!isSource) {
        return (
          <Typography className={`${baseClasses.title4} ${classes.lbl}`}>目標</Typography>
        );
      } else {
        return (
          <Typography className={`${baseClasses.title4} ${classes.lbl}`}>実績/目標</Typography>
        );
      }
    }
    if (isSp) {
      if (ACTION_TYPE_FLYER_SALES.includes(actionScheduleCode)) {
        if (!isSource) {
          return (
            <Typography className={`${baseClasses.title4} ${classes.lbl}`}>目標</Typography>
          );
        } else {
          return (
            <Typography className={`${baseClasses.title4} ${classes.lbl}`}>実績/目標</Typography>
          );
        }
      }
      return null;
    } else {
      return (
        <Typography className={`${baseClasses.title4} ${classes.lbl}`}>実績/目標</Typography>
      );
    }
  };

  const createAchivement = () => {
    if (isSp) {
      if (ACTION_TYPE_FLYER_SALES.includes(actionScheduleCode)) {
        return (
          <Achievment
            editableFlag={editableFlag}
            archieveObj={archieveObj}
            setArchieveObj={setArchieveObj}
            targetObj={targetObj}
            setTargetObj={setTargetObj}
            values={localUpdateData}
            setUpdateData={setLocalUpdateData}
            isFinished={isFinished}
            isSource={isSource}
            errorCallback={errorCallback}
          />
        );
      }
      return null;
    } else {
      return (
        <Achievment
          editableFlag={editableFlag}
          archieveObj={archieveObj}
          setArchieveObj={setArchieveObj}
          targetObj={targetObj}
          setTargetObj={setTargetObj}
          values={localUpdateData}
          setUpdateData={setLocalUpdateData}
          isFinished={isFinished}
          isSource={isSource}
          errorCallback={errorCallback}
        />
      );
    }
  };

  const showDay = () => {
    if (actionScheduleCode === 3 || actionScheduleCode === 4 || actionScheduleCode === 5) {
      return (
        <Typography className={baseClasses.title2}>
          {localUpdateData.startAt && localUpdateData.startAt.substr(0, 10)}
        </Typography>
      );
    }
    return null;
  };

  const showPlace = () => {
    if (isSp
        && (actionScheduleCode === 3 || actionScheduleCode === 4 || actionScheduleCode === 5)) {
      return null;
    }
    return (
      <>
        <Typography className={`${baseClasses.small} ${classes.heading}`}>現場</Typography>
        <Typography className={classes.scene}>{localUpdateData.siteName}</Typography>
      </>
    );
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        PaperComponent={PaperComponent}
        className={classes.dialog}
      >
        {isSp ? (
          <Grid className={`${baseClasses.title2} ${classes.drawerHeader}`}>
            {createLabel()}
            <Button
              className={baseClasses.closeButton}
              onClick={onClose}
            />
          </Grid>
        ) : (
          <DialogTitle
            id="draggable-dialog-title"
            className={classes.dialogHeader}
          >
            <Typography className={baseClasses.title3}>
              行動登録ウィンドウ
            </Typography>
          </DialogTitle>
        )}
        {isFinished ? <Grid className={classes.finish}>終了</Grid> : ''}
        <DialogContent className={classes.dialogContent}>
          {isFinished ? '' : <Typography className={`${baseClasses.title6} ${classes.head}`}>実績を登録して終了してください</Typography>}
          <Grid className={classes.editButtonContainer}>
            <Typography style={{ marginBottom: 10 }} className={baseClasses.title4}>予定</Typography>
            <Grid>
              <Button
                onClick={handleDelete}
                className={`${baseClasses.onlySp} ${baseClasses.strong} ${classes.releaseButton}`}
              >
                <DeleteOutlineIcon />
                <Typography>
                  削除
                </Typography>
              </Button>
              <Button
                startIcon={<DriveFileRenameIcon className={classes.icon} />}
                onClick={handleEditableWindowOpen}
              >
                <Typography className={`${baseClasses.strong} ${classes.txt}`}>
                  編集
                </Typography>
              </Button>
            </Grid>
          </Grid>
          <Grid className={classes.scheduleContainer}>
            <Grid>
              {showDay()}
              <Typography className={baseClasses.title2}>
                {localUpdateData.startAt && localUpdateData.startAt.substr(11, 5)}
              </Typography>
              <Typography className={baseClasses.title4}>
                -{localUpdateData.endAt && localUpdateData.endAt.substr(11, 5)}
              </Typography>
            </Grid>
            <Grid className={classes.scheduleContainerBox}>
              <Typography className={baseClasses.strong}>
                {ACTION_TYPE[localUpdateData.actionScheduleCode]}
              </Typography>
              <Grid className={classes.littleContent}>
                <Typography className={`${baseClasses.small} ${classes.heading}`}>エリア</Typography>
                <Typography className={classes.area}>{localUpdateData.divisionName}</Typography>
                {showPlace()}
              </Grid>
              <Grid className={classes.littleContent}>
                <Typography className={`${baseClasses.small} ${classes.heading}`}>メモ</Typography>
                <Typography className={classes.memo}>{localUpdateData.memo !== '' ? localUpdateData.memo : '-'}</Typography>
              </Grid>
              {isFinished ? googleMapLink : ''}
            </Grid>
            {isFinished ? '' : (
              <Grid className={classes.playCircleOutlineIconWrap}>
                {getIcon()}
              </Grid>
            )}
          </Grid>
          {createTargetOrResult()}
          {createAchivement()}
          {(isSp || isFinished) ? '' : (
            <Memo
              editableFlag={editableFlag}
              values={localUpdateData}
              setUpdateData={setLocalUpdateData}
              errorCallback={errorCallback}
            />
          )}
        </DialogContent>
        <Grid className={classes.dialogFooter}>
          <Button
            onClick={handleDelete}
            className={`${baseClasses.onlyPc} ${baseClasses.strong} ${classes.releaseButton}`}
          >
            <DeleteOutlineIcon />
            <Typography>
              削除
            </Typography>
          </Button>
          {isFinished ? '' : getButton()}
        </Grid>
      </Dialog>
      <ActRegistWindow
        open={openEditWindow}
        onClose={handleClose}
        onCloseParent={() => {
          onClose();
          handleClose();
        }}
        setReacquisitionFlg={setReacquisitionFlg}
        values={localUpdateData}
        setUpdateData={setLocalUpdateData}
        setOpenCustomertree={setOpenCustomertree}
        editableFlag={Boolean(true)}
        actRegistFlag={Boolean(false)}
        selectDivision={selectDivision}
        setSelectDivision={setSelectDivision}
        archieveObj={archieveObj}
        setArchieveObj={setArchieveObj}
        targetObj={targetObj}
        setTargetObj={setTargetObj}
        isFinished={isFinished}
        isSource={isSource}
        isEdit={isEdit}
        createUpdateFunc={(obj) => schedulesUpdateApiFunction(localUpdateData.scheduleId, obj)}
        schedulesDeleteApiFunction={schedulesDeleteApiFunction}
      />
      <Modal
        open={openConfirm}
        onClose={() => { setOpenConfirm(false); }}
        title="源泉を開始しますか？"
        maxWidth="100%"
        className={classes.confirm}
        headerClassName="confirmHeader"
        bodyClassName="confirmBody"
        buttons={[{
          label: 'CANCEL',
          onClick: () => { setOpenConfirm(false); },
          className: baseClasses.buttonsSecondary,
        }, {
          label: 'OK',
          onClick: () => {
            handleStartAlert();
            setOpenConfirm(false);
          },
          className: baseClasses.buttonsPrimary,
          disabled: !checked,
        }]}
      >
        <Grid className={classes.confirmContent}>
          <Typography className={classes.confirmText}>
            源泉憲章を理解したら、チェックを入れて源泉を開始してください。<br />
            {localUpdateData.centerDivisionName}のChatグループへ通知します。
          </Typography>
          <img src={img} alt="源泉憲章" className={classes.confirmImage} />
          <Grid className={classes.confirmCheck}>
            <CheckBoxLabel
              label="源泉憲章を理解しました。"
              initial={checked}
              set={(check) => { setChecked(check); }}
            />
          </Grid>
        </Grid>
      </Modal>
    </>
  );
}

export default EditableWindow;
