import {
  Popper,
  Paper,
  Grid,
  Typography,
  Button,
  makeStyles,
} from '@material-ui/core';
import NoteIcon from '@material-ui/icons/Note';
import AssignmentIcon from '@material-ui/icons/Assignment';
import DeleteIcon from '@material-ui/icons/Delete';
import { getMrCheck } from '../../../commonFunction/getEnv';

const useStyles = makeStyles((theme) => ({
  main: {
    display: 'flex',
    alignItems: 'center',
  },
  button: {
    display: 'flex',
    justifyContent: 'flex-start',
  },
  blue: {
    color: theme.palette.primaryColor,
  },
}));

function ButtonPopover(props) {
  const { open, anchorEl, onClickRegister, onClickDelete, onClickSorting } = props;
  const classes = useStyles();

  return (
    <Popper
      open={open}
      anchorEl={anchorEl}
      placement="left"
      role={undefined}
      transition
      disablePortal
    >
      <Paper id="idButtonPopover">
        <Grid className={classes.main}>
          <Button
            className={`${classes.button} ${classes.blue}`}
            onClick={onClickRegister}
          >
            <NoteIcon size="small" />
            <Typography>登録</Typography>
          </Button>
          { !getMrCheck() && (
            <Button
              className={`${classes.button} ${classes.blue}`}
              onClick={onClickSorting}
            >
              <AssignmentIcon size="small" />
              <Typography>振り分け</Typography>
            </Button>
          )}
          <Button
            className={`${classes.button} ${classes.blue}`}
            onClick={onClickDelete}
          >
            <DeleteIcon size="small" />
            <Typography>削除</Typography>
          </Button>
        </Grid>
      </Paper>
    </Popper>
  );
}

export default ButtonPopover;
