import { useState, useEffect } from 'react';
import { Button } from '@material-ui/core';
import UserTree from '../common/userTreeNew';

export default function UserTreeSelectTest() {
  // ツリー表示/非表示
  const [isOpen, setIsOpen] = useState(false);
  // 組織ツリー選択ユーザー
  const [selectUser, setSelectUser] = useState({ userId: 5192, userName: '渡邊 界' });
  // 組織ツリー選択組織
  const [selectDivision, setSelectDivision] = useState([]);

  useEffect(() => {
    console.log('------ 選択されたユーザー ------');
    console.log({ selectUser });
    console.log('------ 選択された組織 ------');
    console.log({ selectDivision });
  }, [selectUser, selectDivision]);

  const handleCheck = () => {
    console.log('checkState');
    console.log(selectUser);
    console.log(selectDivision);
  };

  return (
    <>
      <Button onClick={() => { setIsOpen(true); }}>
        担当者選択ウィンドウ（1人）
      </Button>
      <Button onClick={() => handleCheck()}>CheckState</Button>
      <UserTree
        isOpenTree={isOpen}
        setIsOpenTree={setIsOpen}
        selectDivision={selectDivision}
        setSelectDivision={setSelectDivision}
        selectUser={selectUser}
        setSelectUser={setSelectUser}
        isDefaultLoginUser
        clickableUserAndDivision
        isDialog
        doubleSearch
      />
    </>
  );
}
