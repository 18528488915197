import { isObject, isArray, joinStr } from '../../../../commonFunction';
import changeBtnText from './constTreeKeys';

function createCustomersArray(customers, parentUniqueId) {
  return customers.map((item) => {
    return {
      ...item,
      id: item.customerId,
      type: 'customer',
      uniqueId: `${parentUniqueId}_customer${item.customerId}`,
      label: joinStr(item.lastName, item.firstName),
      selectable: true,
    };
  });
}

// {2022年: {…}}
// -- {totalCount: 3, 12月: {…}, 11月: {…}, 7月: {…}}
// {2022年: {…}, claim: {…}}
// {contracted: {…}}
/**
 * formatUserChildren
 * @param {object | Array<*>} customers - 顧客情報
 * @param {string} parentUniqueId 親UniqueId
 * @param {object} options
 * @returns {Array<{
 * type: string;
 * label: string;
 * children: Array<*>;
 * uniqueId: string;
 * selectable: boolean;
 * customers?: Array<*>;
 * }>}
 */
function formatUserChildren(parent, customers, parentUniqueId, options) {
  if (!isObject(customers)) return [];
  const arr = [];
  let item;
  let temp;
  let isFinaly;
  let uniqueId;
  let base;
  Object.keys(customers).forEach((key) => {
    // totalCountが対象外
    if (key === 'totalCount') return;
    item = customers[key];
    if (typeof item.totalCount === 'undefined') return;
    // customersが存在した場合はEND
    isFinaly = isArray(item.customers);

    base = {
      type: key,
      name: changeBtnText(key),
    };

    uniqueId = `${parentUniqueId}_${base.type}`;

    temp = {
      ...base,
      label: `${base.name}(${item.totalCount})`,
      selectable: options.customerFlg,
      uniqueId,
      parentId: parent.id,
      parentName: parent.name,
      parentType: parent.type,
      children: isFinaly ? null : formatUserChildren(base, item, uniqueId, options),
    };

    if (isFinaly) {
      temp.customers = item.customers;
      // 顧客も表示する
      if (options.displayCustomer) {
        temp.children = createCustomersArray(item.customers, uniqueId);
      }
    }

    arr.push(temp);
  });
  return arr;
}

/**
 * formatUsers
 * @param {Array<{
 * userId: 2,
 * divisionId: 16,
 * lastName: "佐藤",
 * firstName: "一郎",
 * isMainDivision: 0,
 * count: 0,
 * customers: {}
 * }>} users
 * @param {string} parentUniqueId
 * @param {object} options
 * @returns {Array<{
 * type: string;
 * label: string;
 * children: Array<*>;
 * uniqueId: string;
 * selectable: boolean;
 * userId: number;
 * divisionId: number;
 * lastName: string;
 * firstName: string;
 * }>}
 */
function formatUsers(parent, users, parentUniqueId, options) {
  const type = 'user';
  const arr = [];
  let uniqueId;
  let base;
  users.forEach((item) => {
    const lastN = item.lastName ? item.lastName : '';
    const firstN = item.firstName ? item.firstName : '';
    uniqueId = `${parentUniqueId}_${type}${item.userId}`;
    base = {
      id: item.userId,
      // eslint-disable-next-line no-nested-ternary
      name: lastN + ' ' + firstN,
      type,
    };
    arr.push({
      ...base,
      parentId: parent.id,
      parentName: parent.name,
      parentType: parent.type,
      label: `${base.name}${item.isMainDivision === 1 ? '' : '(サブ)'}(${item.count})`,
      lastName: item.lastName ? item.lastName : '',
      firstName: item.firstName ? item.firstName : '',
      selectable: options.userFlg,
      uniqueId,
      isMainDivision: item.isMainDivision,
      children: formatUserChildren(
        base,
        item.customers,
        uniqueId,
        options,
      ),
    });
  });
  return arr;
}

// division children and users
function formatDivisionChildren(parent, divs, users, customers, parentUniqueId, options) {
  const arr = [];
  if (isArray(divs)) {
    // eslint-disable-next-line no-use-before-define
    arr.push(...createTreeList(divs, parentUniqueId, options, parent));
  }
  if (isArray(users)) {
    arr.push(...formatUsers(parent, users, parentUniqueId, options));
  }
  if (isObject(customers)) {
    if (parent?.type === 'division') {
      // 担当無し用のcount作成
      let count = 0;
      for (const key in customers) {
        count += Number(customers[key].totalCount);
      }
      const notUserCustomers = {
        id: '担当無し',
        name: '担当無し',
        label: `担当無し(${String(count)})`,
        type: '',
        parentId: parent?.id,
        parentName: parent?.name,
        parentType: 'division',
        selectable: true,
        uniqueId: parentUniqueId + '_担当無し',
        children: [],
      };
      // eslint-disable-next-line max-len, max-len
      notUserCustomers.children.push(...formatUserChildren(notUserCustomers, customers, notUserCustomers.uniqueId, options));
      arr.push(notUserCustomers);
    } else {
      arr.push(...formatUserChildren(parent, customers, parentUniqueId, options));
    }
  }
  if (isArray(customers)) {
    arr.push({ id: '担当無し', label: '担当無し(0)', type: '', selectable: false, uniqueId: 'noCustomer', children: [] });
  }
  return arr;
}

function onlyUsersFormatUsers(users, options) {
  const type = 'user';
  const arr = [];
  let uniqueId;
  let base;
  users.forEach((item) => {
    uniqueId = `${type}${item.userId ? item.userId : '0'}`;
    const fullName = !item.firstName || !item.lastName ? '課担当無し' : item.lastName + item.firstName;
    base = {
      id: item.userId,
      name: fullName,
      type,
    };
    arr.push({
      ...base,
      label: `${base.name}(${item.count})`,
      lastName: item.lastName ? item.lastName : fullName,
      firstName: item.firstName ? item.firstName : '',
      selectable: options.userFlg,
      uniqueId,
      children: formatUserChildren(
        base,
        item.customers,
        uniqueId,
        options,
      ),
    });
  });
  return arr;
}

// eslint-disable-next-line no-irregular-whitespace
// {levelCode: 5, divisionId: 11, groupName: '渋谷', subName: '渋谷営業3課', count: 0, …}
/**
 * API divisions/customer-treeの情報を加工し、ツリー用データに変更
 * @param {Array} list - APIデータ
 * @param {string} parentUniqueId 親のUniqueId
 * @param {object} options - 組織、社員、顧客にて検索、選択をするか判定(object)
 * @returns {Array<{
 * label: string;
 * uniqueId: string;
 * id: number;
 * name: string;
 * type: string;
 * parentId?: number;
 * parentName?: string;
 * parentType?: string;
 * selectable: boolean;
 * children: Array<*>;
 * }>}
 */
export function createTreeList(list, parentUniqueId = '', options = {}, parent = {}) {
  const type = 'division';
  // パラメーターチェック
  if (options?.unitCode === 5) return onlyUsersFormatUsers(list, options);
  const arr = [];
  const uniqueIdPrefix = parentUniqueId ? `${parentUniqueId}_` : '';
  list.forEach((item) => {
    const uniqueId = `${uniqueIdPrefix}${type}${item.divisionId}`;
    // 組織
    arr.push({
      type,
      id: item.divisionId,
      name: item.subName,
      parentId: parent.id,
      parentName: parent.name,
      parentType: parent.type,
      uniqueId,
      label: `${item.subName}(${item.count})`,
      selectable: options.divisionFlg,
      children: formatDivisionChildren(
        {
          type,
          id: item.divisionId,
          name: item.subName,
        },
        item.children,
        item.users,
        item.customers,
        uniqueId,
        options,
      ),
    });
  });
  return arr;
}

export function join(...args) {
  return args.filter(Boolean).join(' ');
}

/**
 * @module - removeDuplicateCustomerArray
 * @description - selectCustomerとcustomerIdArrayを見比べて重複を消す
 * @param {
 *  selectCustomers: [{customerId: 1, customerName: 'じろのすけ'}],
 *  customerIds: [1,3,5]
 * }
 */
export function removeDuplicateCustomerArray(selectCustomers, customerIds) {
  const duplicateCustomer = [...customerIds];
  const result = [];
  const checkble = [];
  for (const custom of selectCustomers) {
    if (duplicateCustomer.includes(custom.customerId)) {
      if (!checkble.includes(custom.customerId)) {
        checkble.push(custom.customerId);
        result.push(custom);
      }
    } else {
      result.push(custom);
    }
  }
  return result;
}

/**
 * 選択データから顧客の配列を取得する
 * @param {{
 * children: Array;
 * customers?: Array;
 * }} data ツリーから選択されたデータ
 * @returns Array<{customerName: string, customerId: number, ...}>
 */
export function getCustomers(data) {
  // 顧客が選択された場合
  if (data?.type === 'customer') {
    data.customerName = join(data.lastName, data.firstName);
    return [data];
  }

  if (isArray(data.customers)) {
    return data.customers.map((item) => {
      return {
        ...item,
        customerName: join(item.lastName, item.firstName),
      };
    });
  }
  const arr = [];
  for (let i = 0; i < data.children?.length; i += 1) {
    arr.push(getCustomers(data.children[i]));
  }
  return arr.flat();
}

// selectCustomerからuniqueIdを元にdivisionIdを抽出
export const getDivisionIdFlat = (customerList) => {
  const result = [];
  for (const c of customerList) {
    const divisionIds = c.uniqueId.split('_').filter((h) => h.includes('division'));
    divisionIds.length ? result.push(Number(divisionIds[divisionIds.length - 1].replace('division', ''))) : null;
  }
  const set = new Set(result.flat());
  const newArr = [...set];
  return newArr;
};

// 複数選択時のsetCustomers処理
export const setCustomersMultiple = (customerData) => {
  const customerList = [];
  const pushCustomers = (data) => {
    if (isArray(data?.customers)) {
      for (let i = 0; data.customers.length > i; i += 1) {
        if (data.customers[i].customerId) {
          customerList.push({
            customerId: data.customers[i].customerId,
            customerName: data.customers[i].lastName + ' ' + data.customers[i].firstName,
            uniqueId: data.uniqueId,
          });
        }
      }
    }
  };
  pushCustomers(customerData);
  return customerList;
};

// 複数選択の顧客設定
export function getCustomersIsMulti(data) {
  const customers = [];
  for (let i = 0; data.length > i; i += 1) {
    const newCustomers = setCustomersMultiple(data[i]);
    newCustomers.length > 0 ? customers.push(newCustomers) : null;
  }
  return customers.flat();
}

// Customers をEffectAtでSortする。
export const sortCustomers = (customers, type) => {
  let result;
  switch (type) {
    case 'effectAt':
      result = customers.sort((a, b) => {
        return (a.effectAt < b.effectAt) ? 1 : -1;
      });
      break;
    case 'rank':
      result = customers.sort((a, b) => {
        if (a.userLevel < b.userLevel) return 1;
        if (a.userLevel > b.userLevel) return -1;
        return (a.effectAt < b.effectAt) ? 1 : -1;
      });
      break;
    default:
      break;
  }
  return result;
};
