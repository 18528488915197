import React, { useEffect, useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, Button } from '@material-ui/core';
import UpdateIcon from '@material-ui/icons/Update';
import commonStyles from '../../../styles';
import ProspectsListTable from './prospectsListTable';
import { getLocalStorage, setLocalStorage } from '../../../../commonFunction';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    padding: 16,
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 12,
  },
  printingBtn: {
    marginLeft: 40,
  },
  updateBtn: {
    marginLeft: 46,
  },
  updateBtnIcon: {
    color: theme.palette.primaryColor,
    fontSize: 18,
    margin: '0 2px 2px 0',
  },
  updateBtnTxt: {
    fontFamily: 'Roboto',
    color: theme.palette.primaryColor,
    fontSize: 13,
    fontWeight: 700,
    lineHeight: 1.6,
    letterSpacing: 0,
  },
  emptyMessage: {
    textAlign: 'center',
    paddingTop: 40,
  },
}));

function MainColumnWrapper(props) {
  const { value } = props;

  const pageCacheKey = 'prospectsListRowsPerPage';
  const listTableRef = useRef();
  const classes = useStyles();
  const baseClasses = commonStyles();

  const [page, setPage] = useState(0);
  // 表示件数 Localstorageのデータを取得して設定 なければ20
  const rowsPerPageInitial = getLocalStorage(pageCacheKey, 20);
  const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageInitial);
  // ページ読み込み用カウント
  const [refreshPageCount, setRefreshPageCount] = useState(0);
  const getFunction = () => {
    value.getProspectsList(value.selectCustomerUnique, page, rowsPerPage);
  };

  // ユーザー変更時, 表示件数変更時
  useEffect(() => {
    if (value.selectCustomerUnique === '') {
      return;
    }
    // Localstorageに保存
    setLocalStorage(pageCacheKey, rowsPerPage);
    if (page === 0) {
      setRefreshPageCount(refreshPageCount + 1);
    } else {
      setPage(0);
    }
  }, [value.selectCustomerUnique, rowsPerPage]);

  // ページ変更時
  useEffect(() => {
    if (value.selectCustomerUnique === '') {
      return;
    }
    setRefreshPageCount(refreshPageCount + 1);
  }, [page]);

  // ページ読み込み
  useEffect(() => {
    if (refreshPageCount === 0) {
      return;
    }
    getFunction();
  }, [refreshPageCount]);

  return (
    <Grid className={classes.root}>
      <Grid className={classes.header}>
        <Typography className={baseClasses.title2}>見込み案件管理表</Typography>
        {/* <ReactToPrint
          // eslint-disable-next-line react/no-unstable-nested-components
          trigger={() => (
            <Button
              className={`${baseClasses.buttonsPrimary} ${classes.printingBtn}`}
            >
              印刷
            </Button>
          )}
          content={() => listTableRef.current}
          documentTitle="見込み案件管理表"
        /> */}
        <Button className={classes.updateBtn} onClick={getFunction}>
          <UpdateIcon className={classes.updateBtnIcon} />
          <Typography className={classes.updateBtnTxt}>
            更新
          </Typography>
        </Button>
      </Grid>
      {value.list.total > 0 ? (
        <Grid innerRef={listTableRef}>
          {/* <style type="text/css" media="print">{getPrintPageStyle()}</style> */}
          <ProspectsListTable
            list={value.list}
            page={page}
            setPage={setPage}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            total={value.selectCustomerUnique.length}
          />
        </Grid>
      ) : (
        <Typography className={`${baseClasses.title1} ${classes.emptyMessage}`}>
          対象のデータが見つかりません。<br />検索条件をご確認ください。
        </Typography>
      )}
    </Grid>
  );
}

export default MainColumnWrapper;
