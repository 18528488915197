import { Dialog, Grid, Button, makeStyles, Typography } from '@material-ui/core';
import commonStyles from '../../../styles';
import SmsTalkEditDialogTextField from './smsTalkEditDialogTextField';

const useStyles = makeStyles({
  root: {
    padding: '32px 16px',
  },
});

export default function SmsTalkEditDialog(props) {
  const {
    values,
    handleOpenEditWindow,
    setHandleOpenEditWindow,
    onClose,
    updateFunction,
  } = props;

  const classes = useStyles();
  const common = commonStyles();

  return (
    <Dialog
      open={handleOpenEditWindow}
      onClose={() => {
        setHandleOpenEditWindow(!handleOpenEditWindow);
      }}
      className={`${common.dialog} ${common.maxWidthDialog} ${classes.dialog}`}
    >
      <Grid className={common.drawerHeader}>
        <Typography
          className={common.title3}
        >
          {values.label}
        </Typography>
        <Button
          className={common.closeButton}
          onClick={() => { setHandleOpenEditWindow(!handleOpenEditWindow); }}
        />
      </Grid>
      <Grid className={classes.root}>
        <SmsTalkEditDialogTextField
          values={values}
          onClose={onClose}
          updateFunction={updateFunction}
        />
      </Grid>
    </Dialog>
  );
}
