/* eslint-disable max-len */
import React, { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import {
  Grid,
  TextField,
  Typography,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  Checkbox,
  Button,
  FormControlLabel,
  Drawer,
} from '@material-ui/core';
import FormGroup from '@material-ui/core/FormGroup';
import SearchIcon from '@material-ui/icons/Search';
import { makeStyles } from '@material-ui/core/styles';
import commonStyles from '../../styles';
import commonTheme from '../../styles/theme';
import SelectProductConfirm from './selectProductConfirm';
import SearchTextField from '../../../components/eleCommon/searchTextField';
import { SEARCHABLE_PRODUCT_TYPES, SELECT_PRODUCT_TYPES } from '../../../components/pages/selectProduct/constants';
import store from '../../../store';
import LoadingEllipsis, { loadingHeight } from '../../common/pullRefComponent/parts/loadingEllipsis';

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    backgroundColor: '#fff',
    zIndex: '15000!important',
    [commonTheme.breakpoints.down('sm')]: {
      height: '100%',
    },
    [commonTheme.breakpoints.up('md')]: {
      border: '1px solid #C8C8C8',
      padding: 8,
      boxSizing: 'border-box',
    },
    '& .MuiTablePagination-toolbar': {
      '& .MuiSelect-icon': {
        color: theme.palette.primaryColor,
      },
    },
  },
  searchLargeContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 20,
  },
  searchWrap: {
    display: 'flex',
    width: '55%',
  },
  textField: {
    [commonTheme.breakpoints.down('sm')]: {
      width: '100%',
    },
    [commonTheme.breakpoints.up('md')]: {
      marginRight: 10,
    },
  },
  searchSmallContainer: {
    [commonTheme.breakpoints.up('md')]: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    '& > .MuiFormGroup-root': {
      [commonTheme.breakpoints.down('sm')]: {
        paddingLeft: 16,
        width: '100%',
      },
    },
  },
  formControlLabel: {
    [commonTheme.breakpoints.down('sm')]: {
      margin: 0,
      padding: '18px 20px 18px 16px',
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
      borderBottom: '1px solid #F3F3F3',
    },
    [commonTheme.breakpoints.up('md')]: {
      marginLeft: 0,
    },
    '& .MuiTypography-body1': {
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      lineHeight: '160%',
      color: '#33333',
      [commonTheme.breakpoints.down('sm')]: {
        order: 1,
        fontWeight: 800,
        fontSize: '16px',
      },
      [commonTheme.breakpoints.up('md')]: {
        fontWeight: 400,
        fontSize: '13px',
      },
    },
    '& .MuiButtonBase-root': {
      [commonTheme.breakpoints.down('sm')]: {
        order: 2,
      },
    },
    '& .MuiCheckbox-colorPrimary.Mui-checked': {
      color: theme.palette.primaryColor,
    },
  },
  searchButton: {
    [commonTheme.breakpoints.down('sm')]: {
      position: 'fixed',
      right: 8,
      bottom: 24,
      minWidth: 'auto',
      width: 56,
      height: 56,
      background: theme.palette.primaryColor,
      borderRadius: '50%',
      boxShadow:
        '0px 6px 6px rgba(51, 51, 51, 0.26), 0px 10px 20px rgba(51, 51, 51, 0.19)',
    },
    [commonTheme.breakpoints.up('md')]: {
      width: 70,
      height: 29,
    },
  },
  searchIcon: {
    [commonTheme.breakpoints.down('sm')]: {
      color: '#fff',
      fontSize: 24,
    },
    [commonTheme.breakpoints.up('md')]: {
      fontSize: 18,
    },
  },
  searchTxt: {
    fontSize: 13,
  },
  tableContainer: {
    [commonTheme.breakpoints.down('sm')]: {
      padding: '0 8px',
      height: 'calc(100vh - 51px)',
      overflowY: 'auto',
    },
    [commonTheme.breakpoints.up('md')]: {
      maxHeight: '400px',
    },
  },
  headerTableCell: {
    color: 'rgba(51, 51, 51, 0.8)',
    '&:first-child': {
      [commonTheme.breakpoints.down('sm')]: {
        width: '20%',
      },
      [commonTheme.breakpoints.up('md')]: {
        width: '15%',
      },
    },
    '&:last-child': {
      [commonTheme.breakpoints.down('sm')]: {
        width: '80%',
      },
      [commonTheme.breakpoints.up('md')]: {
        width: '85%',
      },
    },
  },
  tableHead: {
    '& th': {
      borderTop: 'solid 1px #ccc',
      borderBottom: 'solid 2px #ccc',
      backgroundColor: '#fff',
      padding: '16px 0 9px 8px',
    },
  },
  tableBody: {
    '& .MuiTableCell-root': {
      padding: 8,
    },
  },
  bodyTableRow: {
    position: 'relative',
    width: '100%',
    transition: 'all .3s ease',
    '&:nth-of-type(even)': {
      backgroundColor: '#F3F3F3',
    },
    '&:hover': {
      background: `${theme.palette.primaryColor}29!important`,
    },
  },
  row: {
    display: 'block',
    width: 'auto',
  },
  isNotApplicable: {
    textAlign: 'left',
    padding: 8,
    borderBottom: 'solid 1px #C8C8C8',
    [commonTheme.breakpoints.down('sm')]: {
      paddingLeft: 80,
    },
    [commonTheme.breakpoints.up('md')]: {
      paddingLeft: 100,
    },
  },
  header: {
    [commonTheme.breakpoints.down('sm')]: {
      position: 'relative',
      padding: '19px 0 8px',
      textAlign: 'center',
      background: '#fff',
    },
  },
  drawer: {
    zIndex: '15000!important',
    '& .MuiDrawer-paper': {
      width: '84%',
      right: 0,
      left: 'auto',
    },
  },
  searchDrawerHeader: {
    padding: '19px 8px 8px',
    borderBottom: '1px solid rgba(51, 51, 51, 0.04)',
  },
  searchDrawerList: {
    paddingLeft: 16,
  },
  searchDrawerItem: {
    padding: '24px 8px 13px 16px',
    borderBottom: '1px solid #F3F3F3',
  },
  searchDrawerBtnWrap: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderTop: '1px solid rgba(51, 51, 51, 0.04)',
    position: 'fixed',
    right: 0,
    bottom: 0,
    padding: '24px 0',
    width: '84%',
  },
  searchDrawerBtn: {
    margin: '0 13px',
  },
}));

function SelectProductTable(props) {
  const {
    obj,
    setObj,
    selectedProduct,
    setSelecedtProduct,
    handleCloseChoice,
    setClear,
    sendProduct,
    getProductsFunction,
    productType,
    limitOffsetRef,
  } = props;

  const [newDataList, setNewDataList] = useState([]);
  // ページネーション 表示ラベル(ex. 21-25/73)
  const defaultLabelDisplayedRows = ({ from, to, count }) => {
    return `${from}-${to} / ${count !== -1 ? count : `more than ${to}`}`;
  };
  // ページネーション
  const [page, setPage] = useState(0);
  const [checked, setChecked] = useState(false);
  const [rowsPerPage, setRowsPerpage] = useState(20);
  const classes = useStyles();
  const baseClasses = commonStyles();
  const changeProductName = (e) => {
    delete obj.productName;
    delete obj.siteNumber;
    setObj({ ...obj, productName: e.target.value });
  };
  const setProductName = (e) => {
    // まず、既存のproductName, siteNumberを削除する
    delete obj.productName;
    delete obj.siteNumber;
    // 入力値をobjにセットする
    const inputConvertedValue = Number(e.target.value);
    if (inputConvertedValue) {
      setObj({ ...obj, siteNumber: inputConvertedValue });
    } else {
      setObj({ ...obj, productName: e.target.value });
    }
  };
  const handleChange = (e) => {
    console.log(e.target.checked);
    setObj({ ...obj, isIncludeFinished: e.target.checked ? 1 : 0 });
  };

  const handleChangePage = (event, newPage) => {
    console.log({ event, newPage });
    setPage(newPage);
    setObj({ ...obj, offset: Number(newPage * rowsPerPage) });
    getProductsFunction({ ...obj, offset: Number(newPage * rowsPerPage) });
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerpage(parseInt(event.target.value, 10));
    setPage(0);
    setObj({ ...obj, limit: parseInt(event.target.value, 10) });
    // 1ページあたりの表示件数を変更した場合は、先頭に戻る
    getProductsFunction({ ...obj, limit: parseInt(event.target.value, 10), offset: 1 });
  };

  // SP・PCでの分岐用
  const windowWidth = window.innerWidth;
  const breakPoint = 768;
  const isSP = (windowWidth < breakPoint);
  let contents;

  const prodSearchResults = useSelector((state) => state.getProducts);

  useEffect(() => {
    console.log({ prodSearchResults, products: prodSearchResults.getProducts });
    if (prodSearchResults.getProducts.products !== undefined) {
      setNewDataList(prodSearchResults.getProducts.products.map((i) => {
        return i;
      }));
    }
  }, [prodSearchResults]);

  useEffect(() => {
    // 初期化
    if (!obj.productName) {
      setNewDataList([]);
      setPage(0);
    }
  }, []);

  useEffect(() => {
    // 検索対象(siteSearchType)を設定する
    const siteSearchType = productType in SEARCHABLE_PRODUCT_TYPES
      ? SEARCHABLE_PRODUCT_TYPES[productType]
      : 0;
    setObj({ ...obj, siteSearchType });
  }, [productType]);

  const handleClick = async () => {
    await getProductsFunction(obj);
  };

  const handleClickParamed = async (param) => {
    await getProductsFunction(param);
  };

  // 確認ウインドウ
  const [textConfirm, setTextConfirm] = useState('');
  const [openConfirm, setOpenConfirm] = useState(false);
  const handleOpenConfirm = (text) => {
    setTextConfirm(text);
    setOpenConfirm(true);
  };
  const handleCloseConfirm = () => {
    setOpenConfirm(false);
  };
  const handleClick1 = () => {
    console.log('CANCEL');
  };
  const handleClick2 = () => {
    console.log('OK');
    sendProduct();
    handleCloseChoice();
  };
  const datas = [
    {
      ttl: '確認',
      txt: [textConfirm, 'この物件でよろしいですか？'],
      set1: handleClick1,
      set2: handleClick2,
    },
  ];

  const selectProduct = (e) => {
    setSelecedtProduct(e);
    setChecked(true);
    if (isSP) {
      // https://openhouse.backlog.jp/view/SFA_ASIAQUEST-4540#comment-187459192
      handleOpenConfirm(e.siteNumber + ' ' + e.productNameInCompany);
    }
  };
  // 検索
  const [openSearch, setOpenSearch] = useState(false);
  const handleOpenSearch = () => {
    setOpenSearch(!openSearch);
  };
  const handleCloseSearch = () => {
    setOpenSearch(false);
  };

  // scroll時の位置情報を格納
  const rootRef = useRef(null);

  const isAllLoaded = () => {
    const { getProducts } = store.getState();
    const totalCount = getProducts.getProducts.total;
    const { limit, offset } = limitOffsetRef.current;
    return (limit + offset) > totalCount;
  };

  // lodingの表示/非表示の切り替え
  const [isLoadingActive, setIsLoadingActive] = useState(false);
  // スクロール時の処理
  const handleScroll = async () => {
    if (!isSP) return;
    if (isLoadingActive) return;
    const {
      clientHeight,
      scrollHeight,
      scrollTop,
    } = rootRef.current;
    const diff = clientHeight - scrollHeight;
    if (diff - scrollTop > loadingHeight || isAllLoaded()) return;
    const { limit, offset } = limitOffsetRef.current;
    limitOffsetRef.current = { limit, offset: offset + limit };
    setIsLoadingActive(true);
    await handleClickParamed({ ...obj, limit, offset: offset + limit });
    setIsLoadingActive(false);
  };

  const table = (
    <TableContainer className={classes.tableContainer} ref={rootRef} onScroll={handleScroll}>
      <Table stickyHeader aria-label="sticky table">
        <TableHead className={classes.tableHead}>
          <TableRow>
            <TableCell
              className={`${baseClasses.title4} ${classes.headerTableCell}`}
            >
              物件番号
            </TableCell>
            <TableCell
              className={`${baseClasses.title4} ${classes.headerTableCell}`}
            >
              物件名
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody className={classes.tableBody}>
          {isSP && (productType === SELECT_PRODUCT_TYPES.HAWK_ONE || productType === SELECT_PRODUCT_TYPES.MAISOKU) ? (
            <TableRow
              key="no-select-product"
              className={classes.bodyTableRow}
              onClick={() => handleClick2()}
            >
              <TableCell>　</TableCell>
              <TableCell className={`${baseClasses.smallStrong}`}>
                選択しない
              </TableCell>
            </TableRow>
          ) : ''}
          {newDataList.map((data) => {
            return (
              <TableRow
                style={{ background: checked && selectedProduct === data ? 'rgba(6, 121, 191, 0.16)' : '' }}
                className={classes.bodyTableRow}
                key={data.siteNumber}
                onClick={() => selectProduct(data)}
              >
                <TableCell className={baseClasses.smallStrong}>
                  {data.siteNumber}
                </TableCell>
                <TableCell className={`${baseClasses.smallStrong} ${classes.productNameContainer}`}>
                  {data.productNameInCompany}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      {(!isSP || productType === SELECT_PRODUCT_TYPES.OHD) && newDataList.length === 0
        ? <Typography className={`${baseClasses.smallStrong} ${classes.isNotApplicable}`}>該当なし</Typography>
        : ''}
      {isLoadingActive && <LoadingEllipsis />}
    </TableContainer>
  );

  if (isSP) {
    contents = (
      <Grid className={classes.container}>
        <Typography
          className={`${baseClasses.title2} ${classes.header}`}
        >
          物件選択
          <Button
            className={baseClasses.closeButton}
            onClick={handleCloseChoice}
          />
        </Typography>
        {table}
        <Button
          className={classes.searchButton}
          onClick={handleOpenSearch}
        >
          <SearchIcon className={classes.searchIcon} />
        </Button>
        <Drawer
          anchor="right"
          open={openSearch}
          onClose={handleCloseSearch}
          className={classes.drawer}
        >
          <Grid className={classes.searchDrawer}>
            <Typography
              className={`${baseClasses.title2} ${classes.searchDrawerHeader}`}
            >
              物件の検索
            </Typography>
            <Grid className={classes.searchDrawerList}>
              <Grid className={classes.searchDrawerItem}>
                <TextField
                  value={obj.productName}
                  placeholder="具体的な物件名"
                  className={classes.textField}
                  onChange={(e) => changeProductName(e)}
                  onBlur={(e) => setProductName(e)}
                />
              </Grid>
            </Grid>
            <Grid className={classes.searchSmallContainer}>
              <FormGroup row>
                <FormControlLabel
                  control={
                    <Checkbox
                      color="primary"
                      checked={Boolean(obj.isIncludeFinished)}
                      onChange={handleChange}
                      className={classes.checkbox}
                    />
                  }
                  label="済物件（1年以内）を含む"
                  className={classes.formControlLabel}
                />
              </FormGroup>
              <Grid className={classes.searchDrawerBtnWrap}>
                <Button
                  className={`${baseClasses.buttonsSecondary} ${classes.searchDrawerBtn}`}
                  onClick={setClear}
                >
                  全クリア
                </Button>
                <Button
                  className={`${baseClasses.buttonsPrimary} ${classes.searchDrawerBtn}`}
                  onClick={(e) => {
                    handleClick(e);
                    handleCloseSearch();
                  }}
                >
                  OK
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Drawer>
        <SelectProductConfirm
          open={openConfirm}
          onClose={handleCloseConfirm}
          data={datas[0]}
        />
      </Grid>
    );
  } else {
    contents = (
      <Grid className={classes.container}>
        <Grid className={classes.searchLargeContainer}>
          <Grid className={classes.searchWrap}>
            <SearchTextField
              value={obj.productName || obj.siteNumber}
              placeholder="具体的な物件名または物件番号"
              onChange={(e) => changeProductName(e)}
              onBlur={(e) => setProductName(e)}
              className={classes.textField}
            />
          </Grid>
          <Grid className={classes.searchSmallContainer} style={{ width: '45%' }}>
            <FormGroup row>
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    onChange={handleChange}
                    className={classes.checkbox}
                  />
                }
                label="済物件（1年以内）を含む"
                className={classes.formControlLabel}
              />
            </FormGroup>
            <Button
              className={`${baseClasses.buttonsPrimary} ${classes.searchButton}`}
              onClick={(e) => handleClick(e)}
            >
              <SearchIcon className={classes.searchIcon} />
              <Typography className={classes.searchTxt}>検索</Typography>
            </Button>
          </Grid>
        </Grid>
        {newDataList !== undefined && table}
        <TablePagination
          rowsPerPageOptions={[5, 20, 40, 60]}
          component="div"
          count={newDataList.length === 0 ? newDataList.length : prodSearchResults.getProducts.total}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage="表示件数"
          labelDisplayedRows={defaultLabelDisplayedRows}
        />
      </Grid>
    );
  }

  return (
    <Grid>
      {contents}
    </Grid>
  );
}

export default SelectProductTable;
