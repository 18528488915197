import { useRef } from 'react';
import {
  Select,
  FormControl,
  MenuItem,
  InputLabel,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import commonTheme from '../../../styles/theme';

const useStyles = makeStyles({
  select: {
    minWidth: '160px',
    width: '100%',
    '& .MuiSelect-select': {
      fontSize: '13px',
    },
    '& .MuiSelect-icon': {
      width: '17px',
    },
  },
  wrap: {
    [commonTheme.breakpoints.up('md')]: {
    },
    [commonTheme.breakpoints.down('sm')]: {
      width: '100%',
    },
    '& .MuiInputLabel-formControl': {
      fontSize: '13px',
    },
  },
});

function SearchPartsSelect(props) {
  const { values } = props;
  const menusKeys = Object.keys(values.constants.menus);
  const classes = useStyles();
  // 最低値・金額の下限上限など複数選択肢がある際に、上限<下限にならないよう制限
  const lowerState = useRef(0);
  const upperState = useRef(99999999);

  const setSelect = (e) => {
    const val = e.target.value;
    if (val === '') {
      values.setState(val);
    } else {
      values.strValue
        ? values.setState(values.constants.menus[val])
        : values.setState(Number(val));
    }
  };

  // 他選択肢より大きい値にするべきの場合に設定
  if (values?.lowerState) {
    lowerState.current = values.lowerState;
  }

  // 他選択肢より小さい値にするべきの場合に設定
  if (values?.upperState) {
    upperState.current = values.upperState;
  }

  return (
    <FormControl className={classes.wrap}>
      <InputLabel>{values.label}</InputLabel>
      <Select
        label={values.label}
        value={values.state}
        onChange={(e) => setSelect(e)}
        className={`${classes.select} ${values.className || ''}`}
      >
        <MenuItem
          value=""
        >
          &nbsp;
        </MenuItem>
        {menusKeys.map(e => (
          <MenuItem
            key={`select${e}`}
            value={e}
            disabled={Number(e) < lowerState.current || Number(e) > upperState.current}
          >
            {values.constants.menus[e]}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export default SearchPartsSelect;
