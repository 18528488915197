// crm
/**
 * データ結合のタイプ定数
 */
export const JOIN_DATA_TYPES = {
  INITIAL: 0,
  /**
   * データ結合 1
   */
  MERGE: 1,
  /**
   * WEB戻し登録 2
   */
  RETURN: 2,
  /**
   * WEB再反登録 3
   */
  REBELLION: 3,
};

/**
 * データ結合
 * どこからの反響情報タイプ定数
 */
export const JOIN_DATA_FROM_TYPES = {
  INITIAL: 0,
  /**
   * 検索一覧
   */
  SEARCH_LIST: 1,
  /**
   * 反響未処理（WEB）一覧
   */
  WEB_RESPONSE_LIST: 2,
  /**
   * 源泉未処理一覧
   */
  FLYER_SALES_LIST: 3,
};

/**
 * 表示するパネル
 */
export const DISPLAY_PANEL_LIST = {
  register: true,
  search: true,
  response: true,
  source: true,
  merge: true,
  height: 485,
};

/**
 * 反響振り分けアプリの接続先(URLのbase部分)
 */
export const SORT_APP_URL_BASES = {
  development: 'https://script.google.com/a/macros/openhouse-group.com/s/AKfycbzMWlYBUUC1nzLSJDK5QO6q5gr_TIC64NxcEpkPRCAG9KXXAMPUM3RYa1xjJMAW61Tu/exec?flg=1&resimpid=',
  production: 'https://script.google.com/a/macros/openhouse-group.com/s/AKfycbyOFIktdMZWY324cCk_jR44nOomGdYb-wYTuDHAtUdxmJmuyJu-VTjVsF6HlwnbMa9Y/exec?flg=1&resimpid=',
};

/**
 * 新SFA 環境名
 */
export const SORT_APP_ENV_NAME = {
  staging: 'STAGING',
  production: 'PRODUCTION',
};
