import { useSelector } from 'react-redux';
import InfoRegisterContainers from '../../../containers/common/infoRegister/infoRegisterContainers';
import { useCustomerMain } from '../../../containers/customerMain/customerMainContext';

/**
 * GA004 案内登録画面 実装ドキュメント (https://openhouse.backlog.jp/alias/wiki/1144646)
 * @module infoRegister
 * @param {Boolean} open - 必須：(Boolean) infoRegisterの表示非表示
 * @param {function} onClose - 必須：(function) infoRegisterを閉じる関数
 * @param {number} guidanceId - 呼び出したい案内情報のguidanceId or null(nullの場合、新規登録になる)
 * @param {object} customer - 必須：customerのオブジェクト
 * @param {function} [onCloseTypesDrawer] - 任意：<SP>対応履歴の種別選択ドロワーのonClose関数
 * @param {function} [setClickInfoRegister] - 任意：infoRegisterモーダルの予約登録完了時に実行する関数
 * @return {jsx} - 案内登録モーダル
 */
function InfoRegister(props) {
  const {
    open, // 必須：(Boolean) infoRegisterの表示非表示
    onClose, // 必須：(function) infoRegisterを閉じる関数
    guidanceId = null, // ＜新規登録の場合：nullでOK＞＜新規登録以外：必須(integer)＞
    customer, // 必須：(object) customerIdなど、common001のレスを参照
    onCloseTypesDrawer = null, // 任意項目：<SP>対応履歴の種別選択ドロワーのonClose関数
    setClickInfoRegister = null, // 任意項目：infoRegisterモーダルの予約登録完了時に実行する関数
    actionId,
    getActionHistoryListSp = null,
    actionHistorySearchSp = null,
    rerenderSearch = () => {},
    actionHistorySearch = null,
    getActionHistoryList = null,
    setIndivisualOpen = () => {}, // infoRegisterを別モーダルで開く際に使用
    taioRirekiFlg = false,
  } = props;

  const isSp = useSelector((state) => state.deviceTypeSlice.isSp);

  const {
    functions,
  } = useCustomerMain();

  return (
    <div>
      <InfoRegisterContainers
        open={open}
        onClose={onClose}
        guidanceId={guidanceId}
        onCloseTypesDrawer={onCloseTypesDrawer}
        setClickInfoRegister={setClickInfoRegister}
        customer={customer}
        actionId={actionId}
        getActionHistoryList={isSp ? getActionHistoryListSp
          : getActionHistoryList || functions?.getActionHistoryList}
        actionHistorySearch={isSp ? actionHistorySearchSp
          : actionHistorySearch || functions?.actionHistorySearch}
        rerenderSearch={rerenderSearch}
        setIndivisualOpen={setIndivisualOpen}
        taioRirekiFlg={taioRirekiFlg}
      />
    </div>
  );
}

export default InfoRegister;
