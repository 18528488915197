import { TableCell, TableRow, makeStyles } from '@material-ui/core';

import commonStyles from '../../styles';

import { RESPONSE_LEVEL_MAP } from '../../../constants';

const useStyles = makeStyles((theme) => ({
  row: {
    margin: '0px',
    '&:nth-of-type(even)': {
      background: '#F3F3F3',
    },
  },
  cell: {
    padding: '6px 5px',
    margin: '0px 4px',
  },
  selected: {
    background: `${theme.palette.primaryColor}29 !important`,
  },
}));

const convertCode2TextSafe = (code, mapInfo) => {
  return mapInfo[String(code)] ? mapInfo[String(code)] : '';
};

function ResponseTypeRow(props) {
  const { typeInfo, responseTypeList, selected, onClick } = props;

  const commonClasses = commonStyles();
  const classes = useStyles();

  const getParentTypeName = (parentTypeId) => {
    let parentTypeName = '';
    if (responseTypeList) {
      responseTypeList.some((responseType) => {
        if (responseType.responseTypeId === parentTypeId) {
          parentTypeName = responseType.responseTypeName;
          return true;
        }
        return false;
      });
    }
    return parentTypeName;
  };

  return (
    <TableRow
      className={classes.row}
      selected={selected}
      classes={{ selected: classes.selected }}
      onClick={(e) => onClick(e, typeInfo)}
    >
      <TableCell className={`${classes.cell} ${commonClasses.small}`}>
        {typeInfo.responseTypeId}
      </TableCell>
      <TableCell className={`${classes.cell} ${commonClasses.small}`}>
        {typeInfo.responseTypeName}
      </TableCell>
      <TableCell className={`${classes.cell} ${commonClasses.small}`}>
        {convertCode2TextSafe(
          typeInfo.responseTypeLevelCode,
          RESPONSE_LEVEL_MAP,
        )}
      </TableCell>
      <TableCell className={`${classes.cell} ${commonClasses.small}`}>
        {typeInfo.isAllowSales}
      </TableCell>
      <TableCell className={`${classes.cell} ${commonClasses.small}`}>
        {typeInfo.displayOrder}
      </TableCell>
      <TableCell className={`${classes.cell} ${commonClasses.small}`}>
        {getParentTypeName(typeInfo.responseTypeParentId)}
      </TableCell>
    </TableRow>
  );
}

export default ResponseTypeRow;
