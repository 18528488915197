import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  Typography,
  Button,
  List,
  ListItem,
  Select,
  MenuItem,
} from '@material-ui/core';
import commonStyles from '../../styles';
import commonTheme from '../../styles/theme';
import {
  FLOOR_PLAN,
} from '../../../constants';
import {
  priceSelect,
} from '../../../constants/customerMain';
import AreaSentaku from '../../common/areaSentaku';
import RangeSelect from '../../common/rangeSelect';
import { useUpdate, isChanged } from '../../../containers/customerMain';
import inputNumber from '../../../commonFunction/inputNumber';
import { convertFormNumber } from '../../../commonFunction/convertors';
import { validateFormFloat1 } from '../../../commonFunction/validations';
import { TextBaseField } from '../../eleCommon/validation';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    background: '#fff',
    border: '1px solid #C8C8C8',
    padding: '4px 8px',
    marginBottom: 6,
  },
  block: {
    width: '18%',
  },
  box: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  textField: {
    display: 'block',
    '& label': {
      fontFamily: 'Roboto',
      color: '#333',
      right: 0,
      margin: '0 auto',
      textAlign: 'center',
      transformOrigin: 'top center',
      fontSize: 13,
      lineHeight: 1.2,
      transform: 'translate(0, 2px) scale(1)',
      position: 'relative',
      letterSpacing: 0,
      [commonTheme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
    '& input': {
      fontFamily: 'Roboto',
      fontSize: 16,
      zoom: 0.81,
      lineHeight: 1.6,
      textAlign: 'center',
      fontWeight: '700',
      padding: 0,
      height: 'auto',
      [commonTheme.breakpoints.down('sm')]: {
        textAlign: 'left',
      },
    },
    '& div': {
      margin: 0,
      [commonTheme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
    '& div::before': {
      [commonTheme.breakpoints.up('md')]: {
        borderBottom: '1px solid transparent',
      },
    },
  },
  lbl: {
    textAlign: 'center',
  },
  lbl2: {
    textAlign: 'center',
    fontWeight: '700',
  },
  formControl: {
    '& div::before': {
      borderBottom: '1px solid transparent',
    },
  },
  selectEmpty: {
    fontFamily: 'Roboto',
    fontSize: 13,
    lineHeight: 1.6,
    fontWeight: '700',
    '& div': {
      padding: '0 24px 0 0',
      marginTop: 1,
    },
  },
  ttl: {
    padding: 16,
  },
  list: {
    background: '#fff',
    padding: '0 0 0 16px',
  },
  listItem: {
    padding: 0,
    '&:first-child': {
      marginBottom: 4,
      '&::after': {
        content: '""',
        display: 'block',
        background: '#F3F3F3',
        width: '150%',
        height: 4,
        position: 'absolute',
        zIndex: 1,
        right: 0,
        bottom: '-4px',
      },
    },
    '&:last-child': {
      marginBottom: 4,
      '&::after': {
        content: '""',
        display: 'block',
        background: '#F3F3F3',
        width: '150%',
        height: 4,
        position: 'absolute',
        zIndex: 1,
        right: 0,
        bottom: '-4px',
      },
    },
  },
  listBtn: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '16px 8px 16px 16px',
    borderBottom: '1px solid #F3F3F3',
    width: '100%',
  },
  listLbl: {
    textTransform: 'none',
  },
  listVal: {
    textTransform: 'none',
    maxWidth: '60%',
    overflow: 'hidden',
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: '2',
  },
  selectBtn: {
    width: '100%',
    padding: '18px 0',
    textAlign: 'left',
    borderBottom: '1px solid #F3F3F3',
    '&.select': {
      color: theme.palette.primaryColor,
    },
    '& .MuiButton-label': {
      display: 'block',
      paddingLeft: 16,
    },
  },
  selectMulti: {
    width: 'auto!important',
  },
}));

const menus = Object.keys(priceSelect.menus);
const menus2 = Object.keys(FLOOR_PLAN);

function CustomerInfo1(props) {
  const { input } = props;
  const { current, original, update, isSp } = useUpdate(input.customer);

  const classes = useStyles();
  const baseClasses = commonStyles();

  useEffect(() => {
    inputNumber();
  }, []);

  let contents;

  // 希望地域開閉用のローカルステート
  const [openArea, setOpenArea] = useState(false);

  // 希望地域パラメーターを設定
  const [initialParamArea, setInitialParamArea] = useState({});

  // 希望地域を開く
  const handleAreaOpen = () => {
    setInitialParamArea({
      id: 1,
      wishAreaCode: current.wishAreaCode,
      wishAreaIds: current.wishAreaIds,
    });
    setOpenArea(true);
  };

  // 希望地域を閉じる
  const handleCloseArea = () => {
    setOpenArea(false);
  };

  // 希望地域を更新
  const handleUpdateArea = (params) => {
    const newData = {
      wishArea: params.wishArea,
      wishAreaCode: params.wishAreaCode,
      wishAreaIds: params.wishAreaIds,
    };
    // 更新処理
    isChanged(newData, original) && update(newData);
  };

  // SP 決定押下時に更新
  const onDecision = (values, keys) => {
    const newData = { [keys]: values };
    if (isChanged(newData, original)) {
      update(newData);
    }
  };

  const onChange = (e) => {
    const { name, value } = e.target;
    if (original[name] === value) return;
    update({ [name]: value });
  };

  const onPriceChange = (key, [min, max]) => {
    const newData = {
      [key + 'Min']: min,
      [key + 'Max']: max,
    };
    update(newData);
  };

  const customerSelectList = {
    floorPlanCodeItem: {
      type: 'select',
      name: 'floorPlanCode',
      obj: {
        menus: FLOOR_PLAN,
      },
      state: current.floorPlanCode || '',
      change: onChange,
    },
  };

  const getBox = (key) => {
    return (
      <>
        <Select
          value={current[key + 'Min']}
          defaultValue={current[key + 'Min'] !== undefined
            ? current[key + 'Min'] : 0}
          className={classes.selectMulti}
        >
          {menus.map((data) => {
            return (
              <MenuItem
                value={data}
                key={data}
                onClick={() => {
                  onDecision(data, key + 'Min');
                }}
              >
                {priceSelect.menus[data]}
              </MenuItem>
            );
          })}
        </Select>
        <span>～</span>
        <Select
          value={current[key + 'Max']}
          defaultValue={current[key + 'Max'] !== undefined
            ? current[key + 'Max'] : 0}
          className={classes.selectMulti}
        >
          {menus.map((data) => {
            return (
              <MenuItem
                value={data}
                key={data}
                onClick={() => {
                  onDecision(data, key + 'Max');
                }}
                disabled={Number(data) < Number(current[key + 'Min'])}
              >
                {priceSelect.menus[data]}
              </MenuItem>
            );
          })}
        </Select>
      </>
    );
  };

  const getBox3 = () => {
    return (
      <TextBaseField
        label="戸建て広さ（平米）"
        defaultValue={current.livingSpace}
        className={classes.textField}
        type="number"
        inputProps={{ min: '0' }}
        name="livingSpace"
        onBlur={onChange}
        validator={validateFormFloat1({
          minFloat: 0.0,
          maxFloat: 999999.0,
          precisionInt: 5,
        })}
        convertor={convertFormNumber}
      />
    );
  };

  const getBox4 = () => {
    return (
      <Select
        value={current.floorPlanCode}
        defaultValue={current.floorPlanCode !== undefined
          ? current.floorPlanCode : 99}
      >
        <MenuItem disabled value={99}>未選択</MenuItem>
        {menus2.map((data) => {
          return (
            <MenuItem
              value={data}
              key={data}
              onClick={() => {
                onDecision(data, 'floorPlanCode');
              }}
            >
              {FLOOR_PLAN[data]}
            </MenuItem>
          );
        })}
      </Select>
    );
  };

  const getBox5 = () => {
    return (
      <TextBaseField
        label="土地広さ（平米）"
        defaultValue={current.wishLandArea}
        className={classes.textField}
        type="number"
        inputProps={{ min: '0' }}
        name="wishLandArea"
        onBlur={onChange}
        validator={validateFormFloat1({
          minFloat: 0.0,
          maxFloat: 999999.0,
          precisionInt: 5,
        })}
        convertor={convertFormNumber}
      />
    );
  };

  // SP 表示用
  const list = [
    {
      type1: 'noModal',
      type2: 'multiSelect',
      lbl: '戸建て価格（万円）',
      val1: priceSelect.menus[current.detachedHouseBudgetMin],
      val2: priceSelect.menus[current.detachedHouseBudgetMax],
      keys: ['detachedHouseBudgetMin', 'detachedHouseBudgetMax'],
      fontSize: '16px',
      content: () => { return getBox('detachedHouseBudget'); },
      hideButton: true,
    },
    {
      type1: 'noModal',
      type2: 'multiSelect',
      lbl: '土地価格（万円）',
      val1: priceSelect.menus[current.landBudgetMin],
      val2: priceSelect.menus[current.landBudgetMax],
      keys: ['landBudgetMin', 'landBudgetMax'],
      fontSize: '16px',
      content: () => { return getBox('landBudget'); },
      hideButton: true,
    },
    {
      type1: 'noModal',
      type2: 'text',
      lbl: '戸建て広さ（平米）',
      val: current.livingSpace,
      keys: 'livingSpace',
      fontSize: '16px',
      content: getBox3,
    },
    {
      type1: 'noModal',
      type2: 'select',
      lbl: '間取り',
      val: FLOOR_PLAN[current.floorPlanCode],
      keys: 'floorPlanCode',
      content: getBox4,
      hideButton: true,
    },
    {
      type1: 'noModal',
      type2: 'text',
      lbl: '土地広さ（平米）',
      val: current.wishLandArea,
      keys: 'wishLandArea',
      fontSize: '16px',
      content: getBox5,
    },
  ];

  if (isSp) {
    contents = (
      <Grid>
        <Typography className={`${baseClasses.title4} ${classes.ttl}`}>
          希望
        </Typography>
        <List className={classes.list}>
          <ListItem
            className={classes.listItem}
          >
            <Button
              className={classes.listBtn}
              onClick={handleAreaOpen}
            >
              <span className={`${baseClasses.title6} ${classes.listLbl}`}>
                希望地域
              </span>
              <span
                className={`${baseClasses.title4} ${classes.listVal}`}
              >
                {current.wishArea}
              </span>
            </Button>
            <AreaSentaku
              // open={open}
              // onClose={handleClose}
              initialParam={initialParamArea}
              open={openArea}
              onClose={handleCloseArea}
              onUpdate={handleUpdateArea}
            />
          </ListItem>
          {list.map((item) => {
            return (
              <ListItem
                key={item.lbl}
                className={classes.listItem}
              >
                {input.parts(item)}
              </ListItem>
            );
          })}
        </List>
      </Grid>
    );
  } else {
    contents = (
      <Grid className={classes.root}>
        <Grid className={classes.block}>
          <Typography className={`${baseClasses.title6} ${classes.lbl}`}>
            戸建て価格（万円）
          </Typography>
          <RangeSelect
            defaultValue={[current.detachedHouseBudgetMin, current.detachedHouseBudgetMax]}
            options={priceSelect.menus}
            onChange={(val) => onPriceChange('detachedHouseBudget', val)}
            center
            blod
            height={18}
            equalable
          />
        </Grid>
        <Grid className={classes.block}>
          <Typography className={`${baseClasses.title6} ${classes.lbl}`}>
            土地価格（万円）
          </Typography>
          <RangeSelect
            defaultValue={[current.landBudgetMin, current.landBudgetMax]}
            options={priceSelect.menus}
            onChange={(val) => onPriceChange('landBudget', val)}
            center
            blod
            height={18}
            equalable
          />
        </Grid>
        <Grid className={classes.block}>
          {getBox3()}
        </Grid>
        <Grid className={classes.block}>
          <Typography className={`${baseClasses.title6} ${classes.lbl}`}>
            間取り
          </Typography>
          {input.component(customerSelectList.floorPlanCodeItem)}
        </Grid>
        <Grid className={classes.block}>
          {getBox5()}
        </Grid>
      </Grid>
    );
  }

  return contents;
}

export default React.memo(CustomerInfo1);
