import { useState } from 'react';
import {
  TableContainer,
  Table,
  TableBody,
  makeStyles,
} from '@material-ui/core';

import TableHeaders from './parts/tableHeaders';
import ResponseTypeRow from './parts/responseTypeRow';

import { RESPONSE_TYPE_ID } from '../../constants/responseTypeMaster';

const useStyles = makeStyles({
  tableSearchRow: {
    background: '#F3F3F3',
  },
  tableSearchRowCell: {
    margin: '0px',
    padding: '4px',
  },
  menuItem: {
    padding: '6px',
  },
});

const getParentTypeName = (parentTypeId, responseTypeList) => {
  let parentTypeName = '';
  if (responseTypeList) {
    responseTypeList.some((responseType) => {
      if (responseType.responseTypeId === parentTypeId) {
        parentTypeName = responseType.responseTypeName;
        return true;
      }
      return false;
    });
  }
  return parentTypeName;
};

const filteredResponseType = (row, searchParam, responseTypeList) => {
  const checkFunc = (key) => {
    if (key === 'responseTypeParentId') {
      const searchValueParent = RegExp(searchParam.responseTypeParentName);
      return searchValueParent.test(
        getParentTypeName(row.responseTypeParentId, responseTypeList),
      );
    }
    const searchValue = RegExp(searchParam[key]);
    return searchParam[key] ? searchValue.test(row[key]) : true;
  };

  return RESPONSE_TYPE_ID.every((checkKey) => checkFunc(checkKey));
};
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function ResponseTypeMasterTable(props) {
  const {
    responseTypeList = [],
    selectedResponseType,
    setSelectedResponseType,
    setIsNewEntry,
    onOpenModal,
  } = props;
  const classes = useStyles();

  const [searchParam, setSearchParm] = useState({});

  // 並び替え
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('responseTypeId');
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  // 編集ボタン制御
  const handleOpen = (e, typeInfo) => {
    e.preventDefault();
    setSelectedResponseType(typeInfo);
  };

  return (
    <TableContainer className={classes.tableContainer}>
      <Table stickyHeader aria-label="sticky table">
        <TableHeaders
          setIsNewEntry={setIsNewEntry}
          setSearchParm={setSearchParm}
          openModalFun={onOpenModal}
          order={order}
          orderBy={orderBy}
          onRequestSort={handleRequestSort}
        />
        <TableBody className={classes.tableBody}>
          {stableSort(responseTypeList, getComparator(order, orderBy)).map((row) => {
            return filteredResponseType(row, searchParam, responseTypeList) ? (
              <ResponseTypeRow
                key={row.responseTypeId}
                typeInfo={row}
                onClick={handleOpen}
                selected={selectedResponseType.responseTypeId === row.responseTypeId}
                responseTypeList={responseTypeList}
              />
            ) : null;
          })}
        </TableBody>
      </Table>
      {/* 左側の編集ボタントリ */}
      {/* <ButtonPopover
        open={isOpenPopover}
        anchorEl={anchorEl}
        clickFunc={clickEditButtonFunc}
      /> */}
    </TableContainer>
  );
}

export default ResponseTypeMasterTable;
