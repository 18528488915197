import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import UpdateIcon from '@material-ui/icons/Update';
import SearchIcon from '@material-ui/icons/Search';
import commonStyles from '../../../styles';
import commonTheme from '../../../styles/theme';
import TaiouRirekiItem from './taiouRirekiItem';
import TaiouRirekiSearch from './taiouRirekiSearch';

import { ASC, DESC } from '../../../../constants';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '17px 8px 0',
  },
  paper: {
    position: 'relative',
    width: '100%',
    marginBottom: 0,
    '& .MuiTablePagination-root': {
      marginBottom: 12,
    },
    '& .MuiTablePagination-toolbar': {
      minHeight: 'auto',
    },
    '& .MuiTablePagination-caption': {
      fontSize: 13,
      lineHeight: 1.6,
      letterSpacing: 0,
    },
    '& .MuiTablePagination-spacer + .MuiTablePagination-caption': {
      display: 'none',
    },
    '& .MuiTablePagination-input': {
      display: 'none',
    },
    '& .MuiTablePagination-actions .MuiIconButton-root': {
      padding: 0,
      color: theme.palette.primaryColor,
    },
    '& .MuiTablePagination-actions .MuiIconButton-root.Mui-disabled': {
      color: '#C8C8C8',
    },
    '& .MuiTablePagination-actions .MuiSvgIcon-root': {
      fontSize: 20,
    },
  },
  button: {
    color: theme.palette.primaryColor,
  },
  buttonWrap: {
    position: 'absolute',
    zIndex: '9',
    top: '-3px',
    left: 2,
  },
  buttonIcon: {
    fontSize: 18,
    margin: '0 2px 2px 0',
  },
  buttonTxt: {
    fontFamily: 'Roboto',
    fontSize: 13,
    fontWeight: 700,
    lineHeight: 1.6,
    letterSpacing: 0,
  },
  buttonAllDisplayWrap: {
    textAlign: 'center',
    padding: '8px 0 7px',
  },
  buttonAllDisplay: {
    color: theme.palette.primaryColor,
    display: 'inline-block',
  },
  table: {
    display: 'block',
    '& .MuiSvgIcon-root': {
      fontSize: 12,
      color: theme.palette.primaryColor,
      marginBottom: 2,
    },
    '& thead': {
      display: 'block',
      borderBottom: '2px solid #C8C8C8',
    },
    '& thead tr': {
      display: 'flex',
      justifyContent: 'space-between',
    },
    '& thead th': {
      display: 'block',
      padding: '0 0 4px',
      lineHeight: '1',
      border: 'none',
    },
    '& thead th:nth-child(1)': {
      width: 52,
      textAlign: 'center',
      paddingLeft: 17,
    },
    '& thead th:nth-child(2)': {
      width: 171,
      textAlign: 'center',
      pointerEvents: 'none',
      '& .MuiTableSortLabel-icon': {
        display: 'none',
      },
    },
    '& thead th:nth-child(3)': {
      width: 68,
      textAlign: 'center',
      pointerEvents: 'none',
      '& .MuiTableSortLabel-icon': {
        display: 'none',
      },
    },
    '& thead th:nth-child(4)': {
      width: 46,
      textAlign: 'center',
      pointerEvents: 'none',
      '& .MuiTableSortLabel-icon': {
        display: 'none',
      },
    },
    '& tbody': {
      display: 'block',
      [commonTheme.breakpoints.up('md')]: {
        height: 520,
        overflowY: 'auto',
      },
    },
    '& th': {
      padding: 0,
    },
    '& .MuiTableRow-root.Mui-selected': {
      background: 'none',
    },
  },
  headTtl: {
    color: '#8C8C8C!important',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}));

const headCells = [
  { id: 'startAt', numeric: false, disablePadding: true, label: '日時' },
  { id: 'content', numeric: true, disablePadding: false, label: '種類・反応 / 対応内容' },
  { id: 'userFullName', numeric: true, disablePadding: false, label: '担当' },
  { id: 'guidanceId', numeric: true, disablePadding: false, label: '案内' },
];

function EnhancedTableHead(props) {
  const baseClasses = commonStyles();
  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
              className={`${baseClasses.title4} ${classes.headTtl}`}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export default function TaiouRirekiItems(props) {
  const {
    state,
    setState,
    updateHistory,
    deleteHistory,
    getActionHistoryList,
    downloadAttachment,
    customerId,
    customer,
    rerenderSearch,
    setInfoOpen,
  } = props;

  // 連打防止フラグ
  const [isDisabled, setIsDisabled] = useState(false);

  const [search, setSearch] = useState({
    offset: 0,
    limit: 20,
  });

  const [searchAll, setSearchAll] = useState({
    offset: 0,
    limit: state.total,
  });

  useEffect(() => {
    setSearchAll({ ...searchAll, limit: state.total });
  }, [state]);

  const classes = useStyles();

  const page = Math.floor(search.offset / search.limit);
  const [rowsPerPage, setRowsPerPage] = useState(search.limit);
  const order = search.sortOrder === ASC ? 'asc' : 'desc';
  const orderBy = search.sortField;

  useEffect(() => {
    setRowsPerPage(state.histories.length > search.limit ? state.histories.length : search.limit);
  }, [state]);

  const getActionHistoryFunction = async (params) => {
    if (isDisabled) return;
    setIsDisabled(true);
    await getActionHistoryList(customerId, params);
    setIsDisabled(false);
  };

  const getActionHistoryAllFunction = async () => {
    if (isDisabled) return;
    setIsDisabled(true);
    await getActionHistoryList(customerId, searchAll);
    setSearch({ ...search, offset: 0 });
    setIsDisabled(false);
  };

  const handleRequestSort = (event, property) => setSearch((s) => {
    const { sortField, sortOrder } = s;
    const resSortField = property;

    const orderCode = order === 'asc' ? ASC : DESC;
    const resSortOrder = sortField === property && sortOrder === orderCode
      ? DESC
      : ASC;
    const res = {
      ...s,
      sortOrder: resSortOrder,
      sortField: resSortField,
      offset: 0,
      limit: 20,
    };
    getActionHistoryFunction(res);
    return res;
  });

  const handleChangePage = (event, newPage) => setSearch((s) => {
    const res = { ...s, offset: s.limit * newPage };
    getActionHistoryFunction(res);
    return res;
  });

  const handleChangeRowsPerPage = (event) => setSearch((s) => {
    const res = { ...s, offset: 0, limit: parseInt(event.target.value, 20) };
    getActionHistoryFunction(res);
    return res;
  });

  useEffect(() => {
    // 日付
    let date = '';
    const historyDate = document.getElementsByClassName(`historyDate-${customerId}`);
    for (let i = 0; i < historyDate.length; i += 1) {
      const id = 'enhanced-table-checkbox-' + customerId + '-' + i;
      if (date === historyDate[i].textContent && document.getElementById(id) !== null) {
        document.getElementById(id).style.display = 'none';
      }
      date = historyDate[i].textContent;
    }
  }, [state, page]);

  // モーダル開閉用のローカルステート
  const [open, setOpen] = useState(false);
  // モーダルを開くsetState
  const handleOpen = () => {
    setOpen(true);
  };
  // モーダルを閉じるsetState
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <Grid className={classes.buttonWrap}>
          <Button
            className={classes.button}
            onClick={() => getActionHistoryFunction(search)}
            disabled={isDisabled}
          >
            <UpdateIcon className={classes.buttonIcon} />
            <Typography className={classes.buttonTxt}>更新</Typography>
          </Button>
          <Button className={classes.button} onClick={handleOpen}>
            <SearchIcon className={classes.buttonIcon} />
            <Typography className={classes.buttonTxt}>検索</Typography>
          </Button>
          <TaiouRirekiSearch
            open={open}
            onClose={handleClose}
            search={search}
            setSearch={(newState) => {
              setSearch(newState);
              setSearchAll({ ...newState, limit: searchAll.limit });
              getActionHistoryFunction(newState);
            }}
          />
        </Grid>
        <TablePagination
          rowsPerPageOptions={[20, 40, 60]}
          component="div"
          count={state.total}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelDisplayedRows={({ from, to, count }) => `${from}-${to} / ${count}`}
          SelectProps={{
            disabled: isDisabled,
          }}
          backIconButtonProps={
            isDisabled
              ? {
                disabled: isDisabled,
              }
              : undefined
          }
          nextIconButtonProps={
            isDisabled
              ? {
                disabled: isDisabled,
              }
              : undefined
          }
        />
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={classes}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
            />
            <TableBody className="ab">
              {state.histories.map((row, index) => {
                const labelId = `enhanced-table-checkbox-${customerId}-${index}`;
                return (
                  <TaiouRirekiItem
                    key={row.actionId}
                    state={state}
                    setState={setState}
                    row={row}
                    index={index}
                    labelId={labelId}
                    refleshHistory={() => getActionHistoryFunction(search)}
                    updateHistory={updateHistory}
                    deleteHistory={deleteHistory}
                    customerId={customerId}
                    customer={customer}
                    rerenderSearch={rerenderSearch}
                    getActionHistoryList={getActionHistoryList}
                    downloadAttachment={downloadAttachment}
                    setInfoOpen={setInfoOpen}
                  />
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <Grid className={classes.buttonAllDisplayWrap}>
          <Button
            className={classes.buttonAllDisplay}
            onClick={getActionHistoryAllFunction}
            disabled={isDisabled}
          >
            <Typography className={classes.buttonTxt}>全件表示</Typography>
          </Button>
        </Grid>
      </Paper>
    </div>
  );
}
