import { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { Grid, makeStyles, Typography, Button } from '@material-ui/core';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import commonStyles from '../../styles';
import commonTheme from '../../styles/theme';
import { SMS_IMMEDIATE_SEND, SMS_RECEIVE, SMS_SEND_STATUS_CANCELED, SMS_SEND_STATUS_FAILED, SMS_SEND_STATUS, SMS_TIMER_SEND } from '../../../constants/sms';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    padding: 8,
    overflowY: 'auto',
    [commonTheme.breakpoints.up('md')]: {
      height: 350,
    },
    [commonTheme.breakpoints.down('sm')]: {
      height: 'calc(100vh - 150px)',
      borderBottom: '1px #c8c8c8 solid',
      borderTop: '1px #c8c8c8 solid',
    },
  },
  dateTime: {
    textAlign: 'center',
    marginBottom: '16px',
  },
  col1Wrap: {
    maxWidth: '248px',
  },
  col1: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textAlign: 'right',
  },
  col2: {
    color: '#D83420',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textAlign: 'right',
  },
  myMessage: {
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginBottom: '16px',
    '&.receive': {
      justifyContent: 'flex-start',
    },
  },
  myMessageWrap: {
    background: theme.palette.primaryColor,
    color: '#fff',
    padding: '8px',
    marginLeft: '8px',
    borderRadius: '12px 12px 2px 12px',
    maxWidth: '240px',
    whiteSpace: 'pre-line',
    '&.receive': {
      background: '#fff',
      color: '#333',
      borderRadius: '12px 12px 12px 2px',
      marginLeft: 0,
    },
  },
  myMessageWrapFailed: {
    background: '#D83420',
    color: '#fff',
    padding: '8px',
    marginLeft: '8px',
    borderRadius: '12px 12px 2px 12px',
    maxWidth: '240px',
    whiteSpace: 'pre-line',
    '&.receive': {
      background: '#fff',
      color: '#333',
      borderRadius: '12px 12px 12px 2px',
      marginLeft: 0,
    },
  },
  messageWrap: {
    background: '#fff',
    padding: '8px',
    borderRadius: '12px 12px 12px 2px',
    maxWidth: '240px',
  },
  message: {
    marginBottom: '16px',
  },
  arrow: {
    minWidth: 'auto',
    padding: '0',
    position: 'absolute',
    bottom: '16px',
    right: '50%',
    transform: 'translateX(50%)',
    width: '40px',
    height: '40px',
    backgroundColor: '#fff',
    borderRadius: '50%',
    boxShadow: '0px 6px 6px rgba(51, 51, 51, 0.26), 0px 10px 20px rgba(51, 51, 51, 0.19)',
    transition: 'all 0.3s ease',
    // 初期設定は非表示
    opacity: 0,
    visibility: 'hidden',
    [commonTheme.breakpoints.up('md')]: {
      '&:hover': {
        background: '#fff',
      },
    },
    [commonTheme.breakpoints.down('sm')]: {
      bottom: '75px',
      position: 'fixed',
    },
    // クラスを追加して表示
    '&.active': {
      opacity: 1,
      visibility: 'visible',
    },
  },
}));

function TalkData(props) {
  const { talk } = props;
  const common = commonStyles();
  const classes = useStyles();

  const isSameDate = (date1, date2) => {
    if (!date2) {
      return null;
    }
    const reDate1 = new Date(date1);
    const reDate2 = new Date(date2);

    return reDate1.getFullYear() === reDate2.getFullYear()
       && reDate1.getMonth() === reDate2.getMonth()
       && reDate1.getDate() === reDate2.getDate();
  };

  const smsSendStatusFailedOrCanceled = [SMS_SEND_STATUS_FAILED, SMS_SEND_STATUS_CANCELED];
  const sendAndReceiveImmediateOrTimer = [SMS_IMMEDIATE_SEND, SMS_TIMER_SEND];

  return (
    <>
      {talk.info.map((t, index) => {
        return (
          <>
            {(index === 0 || !(isSameDate(t.lastSentAt, talk.info[index - 1].lastSentAt))
              || t.sendAndReceiveCode === SMS_TIMER_SEND) && (
              <Typography className={`${common.title6} ${classes.dateTime}`}>{t.lastSentAt}</Typography>
            )}
            <Grid container className={`${classes.myMessage} ${t.sendAndReceiveCode === SMS_RECEIVE ? 'receive' : ''}`} key={t.replyId}>
              {sendAndReceiveImmediateOrTimer.includes(t.sendAndReceiveCode) && (
                <Grid className={classes.col1Wrap}>
                  <Typography className={`${common.title6} ${classes.col1}`}>{t.divisionName}</Typography>
                  <Typography className={`${common.title6} ${classes.col1}`}>{`${t.userLastName} ${t.userFirstName}`}</Typography>
                  {smsSendStatusFailedOrCanceled.includes(t.sendStatusCode) && (
                    <Typography className={`${common.title6} ${classes.col2}`}>{SMS_SEND_STATUS[t.sendStatusCode]}</Typography>
                  )}
                </Grid>
              )}
              <Typography className={`${common.strong} ${smsSendStatusFailedOrCanceled.includes(t.sendStatusCode) ? classes.myMessageWrapFailed : classes.myMessageWrap} ${t.sendAndReceiveCode === SMS_RECEIVE ? 'receive' : ''}`}>{t.smsBody}</Typography>
            </Grid>
          </>
        );
      })}
    </>
  );
}

export default function SmsTalkBody() {
  const common = commonStyles();
  const classes = useStyles();
  const element = useRef(null); // スクロール要素
  const [active, setActive] = useState(false);
  const isSp = useSelector((state) => state.deviceTypeSlice.isSp);
  const bodyMaxHeight = isSp ? 'none' : (window.innerHeight - 246) + 'px';

  // スクロール量が800px以上になったらアンカーリンクボタンを表示
  const handleScroll = () => {
    const scroll = element.current.scrollTop;
    const elH = element.current.clientHeight;
    const max = element.current.scrollHeight;
    const bottom = max - elH;

    if (bottom - scroll > 800) {
      setActive(true);
    } else {
      setActive(false);
    }
  };

  // アンカーマークをクリック時、最新のトークまでアンカーで移動
  const handleScrollBottom = () => {
    const max = element.current.scrollHeight;

    // Safariではbehavior: 'smooth'が効かないため、条件分岐
    if (isSp) {
      element.current.scrollTo({
        top: max,
      });
    } else {
      element.current.scrollTo({
        top: max,
        behavior: 'smooth',
      });
    }
  };

  const talk = useSelector((state) => state.smsTalk.smsTalk);
  // トークデータが更新されたら、最新のトークまでアンカーで移動
  useEffect(() => {
    const max = element.current.scrollHeight;
    element.current.scrollTo(0, max);
  }, [talk]);

  return (
    <Grid
      className={classes.root}
      style={{ maxHeight: bodyMaxHeight }}
      ref={element}
      onScroll={handleScroll}
    >
      <TalkData talk={talk} />
      {/* アンカーリンク要素 */}
      <Button className={`${classes.arrow} ${active ? 'active' : ''}`} onClick={handleScrollBottom}>
        <ArrowDownwardIcon className={common.icon} />
      </Button>
    </Grid>
  );
}
