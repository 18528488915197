import { getEnvVariable } from './getEnv';

function getQueryString(targetCustomer) {
  // キマリス連携対象項目
  const keysToInclude = [
    'responseAt',
    'lastName',
    'firstName',
    'prefecture',
    'address1',
    'address2',
    'building',
    'largeResponseTypeId',
    'mediumResponseTypeId',
    'smallResponseTypeId',
    'fineResponseTypeId',
    'wishStationFrom1',
    'detachedHouseBudgetMin',
    'responseId',
  ];
  const queryString = keysToInclude
    .filter(key => Object.prototype.hasOwnProperty.call(targetCustomer, key))
    .map(key => `&${encodeURIComponent(key)}=${encodeURIComponent(targetCustomer[key])}`)
    .join('');
  return queryString;
}

export default function openKeymalisContractAdd(targetCustomer) {
  const queryString = getQueryString(targetCustomer);
  const url = `${getEnvVariable('KEYMALIS_BASE_URL')}contract/add?marketingCustomerId=${targetCustomer.customerId}${queryString}`;
  window.open(url, '_blank');
}
