import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import { useSelector } from 'react-redux';
import commonStyles from '../styles';
import commonTheme from '../styles/theme';
import MemberInfo from './parts/memberInfo';
import ProcessTable from './parts/processTable';
import ChanceTable from './parts/chanceTable';
import LoanTable from './parts/loanTable';
import BrowsingTable from './parts/browsingTable';
import SearchTable from './parts/searchTable';
import ContentsTable from './parts/contentsTable';
import OneclickTable from './parts/oneclickTable';
import FavoritesTable from './parts/favoritesTable';
import BrowsingHistoryList from './parts/sp/browsingHistoryList';
import UpdateButton from '../eleCommon/updateButton';
import { MEMBERS_REDUCER_KEYS } from '../../containers/browsingHistory/browsingHistoryReducer';
import { useLoading } from '../../hooks';
import Modal from '../common/modal';
import { WEB_BROWSING_HISTORY } from '../../constants';

const useStyles = makeStyles({
  root: {
    position: 'relative',
    // [commonTheme.breakpoints.up('md')]: {
    //   paddingTop: 52,
    // },
    [commonTheme.breakpoints.down('sm')]: {
      paddingTop: 102,
      paddingBottom: 160,
      minHeight: 'calc(100vh - 60px)',
    },
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    padding: '14px 16px 6px',
  },
  headerTtl: {
    marginRight: 46,
  },
  content: {
    background: '#F3F3F3',
    [commonTheme.breakpoints.up('md')]: {
      padding: 16,
      display: 'flex',
      justifyContent: 'space-between',
    },
  },
  main: {
    width: 1040,
  },
  side: {
    width: 832,
  },
  box: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '0 -4px',
    '& > *': {
      flex: 1,
      margin: '0 4px 8px',
    },
  },
});

function BrowsingHistory(props) {
  const {
    customerId,
    state,
    setState,
    onUpdate,
    updateStatusChange,
    getApiDataFromListApi,
    isQuit,
  } = props;

  const classes = useStyles();
  const baseClasses = commonStyles();
  const { hasLoading } = useLoading();

  const isSP = useSelector((s) => s.deviceTypeSlice.isSp);

  const [open, setOpen] = useState(false);
  const [code, setCode] = useState(null);

  /**
   * APIから取得したデータを表示すると\nがそもまま出てしまうため、フロントエンドで対応を行う。
   * @param {*} price APIから取得された価格のデータ
   * @returns 改行された価格のテキスト
   */
  // 3桁カンマ区切り
  const comma = (num) => {
    return String(num).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,');
  };
  const adjustPriceText = (price) => {
    if (!price) {
      return null;
    }
    const array = price.split('\\n');
    return (
      <>
        <div>{comma(array[0])}</div>
        <div>{array[1]}</div>
      </>
    );
  };

  const handleOpenModal = (val) => {
    setCode(val);
    setOpen(true);
  };

  const getTable = () => {
    switch (code) {
      case WEB_BROWSING_HISTORY.PROCESS:
        return (
          <ProcessTable
            data={state.histories}
            getApiDataFromListApi={getApiDataFromListApi}
          />
        );
      case WEB_BROWSING_HISTORY.CHANCE:
        return (
          <ChanceTable
            data={state.notifications}
            getApiDataFromListApi={getApiDataFromListApi}
          />
        );
      case WEB_BROWSING_HISTORY.LOAN:
        return (
          <LoanTable
            data={state.loan}
            getApiDataFromListApi={getApiDataFromListApi}
          />
        );
      case WEB_BROWSING_HISTORY.BROWSING:
        return (
          <BrowsingTable
            data={state.productsHistories}
            getApiDataFromListApi={getApiDataFromListApi}
            adjustPriceText={adjustPriceText}
          />
        );
      case WEB_BROWSING_HISTORY.SEARCH:
        return (
          <SearchTable
            data={state.searchConditions}
            getApiDataFromListApi={getApiDataFromListApi}
          />
        );
      case WEB_BROWSING_HISTORY.CONTENTS:
        return (
          <ContentsTable
            data={state.contentsHistories}
            getApiDataFromListApi={getApiDataFromListApi}
          />
        );
      case WEB_BROWSING_HISTORY.ONECLICK:
        return (
          <OneclickTable
            data={state.oneclickSearchConditions}
            getApiDataFromListApi={getApiDataFromListApi}
          />
        );
      case WEB_BROWSING_HISTORY.FAVORITES:
        return (
          <FavoritesTable
            data={state.favorites}
            getApiDataFromListApi={getApiDataFromListApi}
            adjustPriceText={adjustPriceText}
          />
        );
      default:
        return null;
    }
  };

  const getModalWidth = () => {
    switch (code) {
      case WEB_BROWSING_HISTORY.PROCESS:
        return 800;
      case WEB_BROWSING_HISTORY.CHANCE:
        return 800;
      case WEB_BROWSING_HISTORY.LOAN:
        return '90%';
      case WEB_BROWSING_HISTORY.BROWSING:
        return '80%';
      case WEB_BROWSING_HISTORY.SEARCH:
        return 900;
      case WEB_BROWSING_HISTORY.CONTENTS:
        return 900;
      case WEB_BROWSING_HISTORY.ONECLICK:
        return 900;
      case WEB_BROWSING_HISTORY.FAVORITES:
        return 900;
      default:
        return null;
    }
  };

  return (
    <Grid className={classes.root}>
      {isSP ? (
        <Grid className={`${classes.content} ${hasLoading('webHistory') ? baseClasses.loading : ''}`}>
          <BrowsingHistoryList
            state={state}
            setState={setState}
            updateStatusChange={updateStatusChange}
            adjustPriceText={adjustPriceText}
          />
        </Grid>
      ) : (
        <>
          <Grid className={classes.header}>
            <Typography
              className={`${baseClasses.title2} ${classes.headerTtl}`}
            >
              Web閲覧履歴
            </Typography>
            <UpdateButton
              onClick={onUpdate}
            />
          </Grid>
          <Grid className={classes.content}>
            <Grid className={classes.main}>
              <MemberInfo
                customerId={customerId}
                data={state.member}
                webs={state.webs}
                setWebs={(arr) => {
                  setState({
                    type: MEMBERS_REDUCER_KEYS.webs,
                    payload: arr,
                  });
                }}
                updateStatusChange={updateStatusChange}
                isQuit={isQuit}
              />
              <Grid className={classes.box}>
                <ProcessTable
                  data={state.histories}
                  getApiDataFromListApi={getApiDataFromListApi}
                  handleOpenModal={() => handleOpenModal(WEB_BROWSING_HISTORY.PROCESS)}
                />
                <ChanceTable
                  data={state.notifications}
                  getApiDataFromListApi={getApiDataFromListApi}
                  handleOpenModal={() => handleOpenModal(WEB_BROWSING_HISTORY.CHANCE)}
                />
              </Grid>
              <LoanTable
                data={state.loan}
                getApiDataFromListApi={getApiDataFromListApi}
                handleOpenModal={() => handleOpenModal(WEB_BROWSING_HISTORY.LOAN)}
              />
              <BrowsingTable
                data={state.productsHistories}
                getApiDataFromListApi={getApiDataFromListApi}
                adjustPriceText={adjustPriceText}
                handleOpenModal={() => handleOpenModal(WEB_BROWSING_HISTORY.BROWSING)}
              />
            </Grid>
            <Grid className={classes.side}>
              <SearchTable
                data={state.searchConditions}
                getApiDataFromListApi={getApiDataFromListApi}
                handleOpenModal={() => handleOpenModal(WEB_BROWSING_HISTORY.SEARCH)}
              />
              <ContentsTable
                data={state.contentsHistories}
                getApiDataFromListApi={getApiDataFromListApi}
                handleOpenModal={() => handleOpenModal(WEB_BROWSING_HISTORY.CONTENTS)}
              />
              <OneclickTable
                data={state.oneclickSearchConditions}
                getApiDataFromListApi={getApiDataFromListApi}
                handleOpenModal={() => handleOpenModal(WEB_BROWSING_HISTORY.ONECLICK)}
              />
              <FavoritesTable
                data={state.favorites}
                getApiDataFromListApi={getApiDataFromListApi}
                adjustPriceText={adjustPriceText}
                handleOpenModal={() => handleOpenModal(WEB_BROWSING_HISTORY.FAVORITES)}
              />
            </Grid>
          </Grid>
          {open && (
            <Modal
              open={open}
              onClose={() => {
                setOpen(false);
              }}
              className={classes.dialog}
              bodyClassName={classes.body}
              width={getModalWidth()}
            >
              {getTable()}
            </Modal>
          )}
        </>
      )}
    </Grid>
  );
}

export default BrowsingHistory;
