import React, { useState, useEffect } from 'react';
import {
  Typography,
  TextField,
  ListItem,
  Dialog,
  List,
  Button,
  Grid,
} from '@material-ui/core';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import { v4 as uuid } from 'uuid';
import { makeStyles } from '@material-ui/core/styles';
import commonStyles from '../../styles';
import commonTheme from '../../styles/theme';
import SelectProduct from '../selectProduct/selectProduct';
import CustomEditDialog from '../../eleCommon/sp/customEditDialog';
import { SELECT_PRODUCT_TYPES, SITE_ID_MAP } from '../../../components/pages/selectProduct/constants';

const useStyles = makeStyles((theme) => ({
  list: {
    [commonTheme.breakpoints.down('sm')]: {
      padding: 0,
    },
  },
  listItem: {
    width: '100%',
    [commonTheme.breakpoints.down('sm')]: {
      display: 'block',
      padding: 0,
    },
    [commonTheme.breakpoints.up('md')]: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'stretch',
      gap: 5,
      backgroundColor: '#fff',
      padding: 8,
      marginBottom: 8,
    },
  },
  forms: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    border: 'solid 1px #C8C8C8',
    padding: '10.5px 8px',
  },
  productType: {
    flexGrow: '1',
    display: 'flex',
    alignItems: 'center',
  },
  productName: {
    display: 'flex',
    [commonTheme.breakpoints.down('sm')]: {
      marginLeft: 'auto',
    },
    '&>span': {
      marginRight: '5px',
      display: 'flex',
      alignItems: 'center',
    },
    '& p': {
      fontWeight: 'bold',
      margin: '0',
    },
  },
  deleteOutLineIcon: {
    color: '#D83420',
  },
  deleteButton: {
    minWidth: 0,
  },
  textField: {
    [commonTheme.breakpoints.down('sm')]: {
      width: '100%',
    },
    '& input': {
      padding: 0,
      [commonTheme.breakpoints.down('sm')]: {
        width: '100%',
      },
      [commonTheme.breakpoints.up('md')]: {
        width: 400,
      },
    },
  },
  addButton: {
    [commonTheme.breakpoints.down('sm')]: {
      background: '#fff',
      width: 'calc(100% - 32px)',
      margin: 16,
      '& .MuiButton-label': {
        color: theme.palette.primaryColor,
        fontFamily: 'Roboto',
        fontSize: 15,
        fontWeight: 700,
        lineHeight: 1.6,
        letterSpacing: 0,
      },
    },
    [commonTheme.breakpoints.up('md')]: {
      width: 104,
      height: 40,
    },
  },
  dialog: {
    '& .MuiDialog-paperWidthSm': {
      maxWidth: 'none',
      [commonTheme.breakpoints.down('sm')]: {
        margin: 16,
        width: 'calc(100% - 32px)',
        maxHeight: 'calc(100% - 32px)',
      },
    },
  },
  root: {
    boxSizing: 'border-box',
    [commonTheme.breakpoints.up('md')]: {
      width: 640,
    },
  },
  title: {
    textAlign: 'center',
    padding: '24px 24px 8px',
  },
  body: {
    borderTop: '1px solid #C8C8C8',
    borderBottom: '1px solid #C8C8C8',
    [commonTheme.breakpoints.down('sm')]: {
      padding: 24,
    },
    [commonTheme.breakpoints.up('md')]: {
      textAlign: 'center',
      padding: '20px 60px 40px 60px',
    },
  },
  inputButton: {
    padding: 0,
    lineHeight: 1,
    fontWeight: 700,
    minHeight: '1.3rem',
    [commonTheme.breakpoints.up('md')]: {
      width: 'calc(100% - 5.5em)',
    },
    '& .MuiButton-label': {
      justifyContent: 'flex-start',
      [commonTheme.breakpoints.up('md')]: {
        '& > *': {
          width: '50%',
          textAlign: 'left',
        },
      },
    },
    ...theme.overrides.btn.typeModal,
  },
  btnWrap: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '0 auto',
    padding: '16px 0',
  },
  btn1: {
    color: theme.palette.primaryColor,
    fontFamily: 'Roboto',
    fontSize: 15,
    fontWeight: 700,
    lineHeight: 1.6,
    letterSpacing: 0,
    padding: '9px 22px 7px',
    marginRight: 16,
    '&:hover': {
      background: `${theme.palette.primaryColor}29`,
    },
  },
  btn2: {
    fontSize: 15,
    padding: '8px 22px 6px',
    minWidth: 89,
  },
  disabledButton: {
    color: '#AFAFAF',
    background: '#DEDEDE',
    fontWeight: 700,
    [commonTheme.breakpoints.down('sm')]: {
      width: 'calc(100% - 32px)',
      margin: 16,
    },
    [commonTheme.breakpoints.up('md')]: {
      width: 104,
      height: 40,
    },
  },
  // SP
  propertyBox: {
    display: 'flex',
    alignItems: 'center',
    padding: 16,
  },
  propertyBoxLbl: {
    marginRight: 8,
  },
  propertyBoxBtn: {
    background: '#fff',
    '& .MuiButton-label': {
      color: theme.palette.primaryColor,
      fontFamily: 'Roboto',
      fontSize: 15,
      fontWeight: 700,
      lineHeight: 1.6,
      letterSpacing: 0,
    },
  },
  propertyList: {
    background: '#fff',
    padding: 0,
  },
  propertyListItem: {
    padding: '0 0 0 16px',
  },
  propertyListBtn1: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    padding: '16px 8px 16px 16px',
    borderBottom: '1px solid #F3F3F3',
  },
  propertyListBtn2: {
    display: 'block',
    width: '100%',
    padding: '16px 8px 16px 16px',
    borderBottom: '1px solid #F3F3F3',
  },
  propertyListLbl1: {
    fontSize: 13,
    lineHeight: 1.2,
  },
  propertyListLbl2: {
    fontSize: 13,
    lineHeight: 1.2,
    marginBottom: 8,
  },
  propertyListTxt: {
    fontSize: 13,
    fontWeight: 700,
    lineHeight: 1.2,
    textTransform: 'none',
  },
  tag: {
    wordBreak: 'keep-all',
    display: 'flex',
    [commonTheme.breakpoints.up('md')]: {
      marginRight: 8,
    },
  },
}));

function ProductItem(props) {
  const {
    setOpen,
    setValue,
    setOpen1,
    setItems,
    item,
    items,
    idx,
    product,
    setProduct,
  } = props;

  const classes = useStyles();
  const baseClasses = commonStyles();

  // SP・PCでの分岐用
  const windowWidth = window.innerWidth;
  const breakPoint = 768;
  const isSP = (windowWidth < breakPoint);
  let contents;

  // メモ
  const [openMemo, setOpenMemo] = useState(false);
  const [text, setText] = useState('');
  const handleChange = (e) => {
    setText(e.target.value);
  };
  const onBlur = () => {
    setProduct({
      ...product,
      key: item.id,
      memo: text,
    });
    setItems(items.map((i) => {
      if (i.id !== item.id) return i;
      return ({ ...i, key: i.id, memo: text });
    }));
  };

  const getBox = (
    <TextField
      className={classes.textField}
      onChange={handleChange}
      value={text}
    />
  );

  const deleteAlert = (i) => {
    setValue(i);
    setOpen1(true);
  };

  const onClickHandler = () => {
    setOpen(true);
    setProduct(item);
  };

  useEffect(() => {
    if (item) {
      setText(item.memo);
    }
  }, []);

  useEffect(() => {
    if (product.productType !== '' && (items.some((i) => product.id === i.id))) {
      setItems(items.map((itm) => {
        if (itm.id !== product.id) return itm;
        return {
          ...itm,
          productType: product.productType,
          productName: product.productName,
          siteId: product.siteId,
          memo: product.memo,
        };
      }));
    }
  }, [product, setProduct]);

  /**
   * 全物件検索から選択した物件では、物件の種別が「全物件」と表示されるため、siteIdから実際の物件種別を特定する。
   * @returns 実際の物件種別
   */
  const determineRealProductType = () => {
    if (!item.siteId) {
      return '';
    } else if (item.siteId === SITE_ID_MAP.マイソク) {
      return SELECT_PRODUCT_TYPES.MAISOKU;
    } else if (item.siteId === SITE_ID_MAP['本命物件・案内物件無し']) {
      return SELECT_PRODUCT_TYPES.NO_FAVORITE;
    } else if (item.siteId === SITE_ID_MAP.物件データ無し) {
      return SELECT_PRODUCT_TYPES.NO_HOUSE;
    } else if (item.siteId === SITE_ID_MAP['ホーク・ワン']) {
      return SELECT_PRODUCT_TYPES.HAWK_ONE;
    } else {
      return SELECT_PRODUCT_TYPES.OHD;
    }
  };

  if (isSP) {
    contents = (
      <ListItem className={classes.listItem}>
        <Grid className={classes.propertyBox}>
          <Typography className={`${baseClasses.title4} ${classes.propertyBoxLbl}`}>
            物件{idx}
          </Typography>
          <Button
            className={classes.propertyBoxBtn}
            onClick={() => deleteAlert(item)}
          >
            解除
          </Button>
        </Grid>
        <List className={classes.propertyList}>
          <ListItem className={classes.propertyListItem}>
            <Button
              className={classes.propertyListBtn1}
              onClick={() => {
                onClickHandler();
              }}
            >
              <Typography className={`${baseClasses.title6} ${classes.propertyListLbl1}`}>
                物件種別
              </Typography>
              <Typography className={`${baseClasses.title4} ${classes.propertyListTxt}`}>
                {item.productType}
              </Typography>
            </Button>
          </ListItem>
          {(item.productType === SELECT_PRODUCT_TYPES.OHD
            || ((item.productType === SELECT_PRODUCT_TYPES.HAWK_ONE || SELECT_PRODUCT_TYPES.MAISOKU)
              && !(Object.values(SITE_ID_MAP).includes(item.siteId))))
          && (
          <ListItem className={classes.propertyListItem}>
            <Button
              className={classes.propertyListBtn1}
              onClick={() => {
                setOpen(true);
              }}
            >
              <Typography className={`${baseClasses.title6} ${classes.propertyListLbl1}`}>
                物件番号 / 物件名
              </Typography>
              <Typography className={`${baseClasses.title4} ${classes.propertyListTxt}`}>
                {item.productName}
              </Typography>
            </Button>
          </ListItem>
          )}
          <ListItem className={classes.propertyListItem}>
            <Button
              className={classes.propertyListBtn2}
              onClick={() => { setOpenMemo(!openMemo); }}
            >
              <Typography className={`${baseClasses.title6} ${classes.propertyListLbl2}`}>
                メモ
              </Typography>
              <Typography className={`${baseClasses.title4} ${classes.propertyListTxt}`}>
                {text}
              </Typography>
            </Button>
            <CustomEditDialog
              open={openMemo}
              setOpen={setOpenMemo}
              content={getBox}
              set={onBlur}
              lbl="メモ"
            />
          </ListItem>
        </List>
      </ListItem>
    );
  } else {
    contents = (
      <ListItem className={classes.listItem}>
        <Grid className={classes.forms}>
          <Typography className={`${baseClasses.title6} ${classes.productType}`}>
            <span className={classes.tag}>物件種別<span style={{ color: '#D83420' }}>＊</span></span>
            <Button
              className={classes.inputButton}
              onClick={() => onClickHandler()}
            >
              <span>
                {item.productType === SELECT_PRODUCT_TYPES.ALL_PRODUCT
                  ? determineRealProductType()
                  : item.productType}
              </span>
              {item.productName
                && ((item.productType === SELECT_PRODUCT_TYPES.OHD
                  || item.productType === SELECT_PRODUCT_TYPES.ALL_PRODUCT)
                  || ((item.productType === SELECT_PRODUCT_TYPES.HAWK_ONE
                    || item.productType === SELECT_PRODUCT_TYPES.MAISOKU)
                    && !(Object.values(SITE_ID_MAP).includes(item.siteId))))
                ? (
                  <Typography className={`${baseClasses.title6} ${classes.productName}`}>
                    <span className={classes.tag}>物件<span style={{ color: '#D83420' }}>＊</span></span>
                    <span>{item.productName}</span>
                  </Typography>
                )
                : ''}
            </Button>
          </Typography>
        </Grid>
        <Grid className={classes.forms}>
          <Typography style={{ marginRight: 10 }} className={`${baseClasses.title6} ${classes.tag}`}>
            メモ
          </Typography>
          <TextField
            className={`${baseClasses.textField} ${classes.textField}`}
            onChange={handleChange}
            onBlur={onBlur}
            value={text}
          />
        </Grid>
        <Button
          className={classes.deleteButton}
          onClick={() => deleteAlert(item)}
        >
          <DeleteOutlineIcon className={classes.deleteOutLineIcon} />
        </Button>
      </ListItem>
    );
  }

  return (
    <Grid>
      {contents}
    </Grid>
  );
}

function ProductElements(props) {
  const {
    items,
    setItems,
    isDisabled,
  } = props;
  const classes = useStyles();
  const baseClasses = commonStyles();
  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);
  const [value, setValue] = useState('');
  const [product, setProduct] = useState({ productType: '', productName: '', memo: '' });

  const AddProduct = () => {
    // 入力フォームを追加
    setItems(
      [...items, { id: uuid() }],
    );
  };

  // SP・PCでの分岐用
  const windowWidth = window.innerWidth;
  const breakPoint = 768;
  const isSP = (windowWidth < breakPoint);
  let contents;

  const deleteItems = () => {
    // 新規追加時と既存レコードの編集時では、idのプロパティ名が異なる。
    // 新規追加時: id, 既存レコード編集時：suggestProductId
    setItems(items.filter(item => {
      if ('id' in item) {
        // 新規追加時
        return item.id !== value.id;
      } else {
        // 既存レコード編集時
        return item.suggestProductId !== value.suggestProductId;
      }
    }));
    setOpen1(false);
  };

  if (isSP) {
    contents = (
      <Grid>
        <List className={classes.list}>
          {items.map((item, idx) => {
            return (
              <ProductItem
                key={item.id}
                item={item}
                items={items}
                open={open}
                open1={open1}
                value={value}
                setOpen={setOpen}
                setValue={setValue}
                setOpen1={setOpen1}
                setItems={setItems}
                product={product}
                setProduct={setProduct}
                idx={idx + 1}
              />
            );
          })}
        </List>
        {open ? (
          <SelectProduct
            open={open}
            setOpen={setOpen}
            product={product}
            setProduct={setProduct}
            onClose={() => setOpen(false)}
            item={product}
            items={items}
            setItems={setItems}
            multi
          />
        ) : ''}
        <Button
          className={isDisabled ? classes.disabledButton : classes.addButton}
          onClick={AddProduct}
          disabled={isDisabled}
        >
          追加
        </Button>
      </Grid>
    );
  } else {
    contents = (
      <Grid>
        <List className={classes.list}>
          {items.map((item) => {
            return (
              <ProductItem
                key={item.id}
                item={item}
                items={items}
                open={open}
                open1={open1}
                value={value}
                setOpen={setOpen}
                setValue={setValue}
                setOpen1={setOpen1}
                setItems={setItems}
                product={product}
                setProduct={setProduct}
              />
            );
          })}
        </List>
        <Button
          className={isDisabled ? classes.disabledButton : `${baseClasses.buttonsPrimary} ${classes.addButton}`}
          onClick={AddProduct}
          disabled={isDisabled}
        >
          物件追加
        </Button>
        {open ? (
          <SelectProduct
            open={open}
            setOpen={setOpen}
            product={product}
            setProduct={setProduct}
            onClose={() => setOpen(false)}
            item={product}
            items={items}
            setItems={setItems}
            multi
          />
        ) : ''}
      </Grid>
    );
  }

  return (
    <Grid>
      {contents}
      {/* 削除確認用モーダル */}
      {open1 && (
        <Dialog open={open1} onClose={() => setOpen1(false)} className={classes.dialog}>
          <Grid className={classes.root}>
            <Typography className={`${baseClasses.title2} ${classes.title}`}>
              物件の削除確認
            </Typography>
            <Typography id="draggable-dialog-title" className={`${baseClasses.bass} ${classes.body}`}>
              選択した物件を削除してもよろしいですか？
            </Typography>
            <Grid className={classes.btnWrap}>
              <Button
                className={classes.btn1}
                onClick={() => setOpen1(false)}
              >
                CANCEL
              </Button>
              <Button
                className={`${baseClasses.buttonsPrimary} ${classes.btn2}`}
                onClick={deleteItems}
              >
                OK
              </Button>
            </Grid>
          </Grid>
        </Dialog>
      )}
    </Grid>
  );
}

export default ProductElements;
