import React, { useState, useEffect, useCallback, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  Typography,
  Button,
  TextField,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import commonStyles from '../../../styles';
import AreaSentaku from '../../areaSentaku';
import { hopeFloorPlanSelect, priceSelect } from '../../../../constants/customerMain';
import NormalTooltip from '../../../styles/tooltip';
import SelectRailwaysAndStationsContainer from '../../../../containers/search/railwayAndStations/selectRailwayAndStations';
import stationsApi from '../../../../apis/common/stations';
import { LabelWrap } from '../../labelWrap';
import RangeSelect from '../../rangeSelect';

import {
  convertForm1,
  convertFormNumber,
} from '../../../../commonFunction/convertors';
import {
  validateFormString1,
  validateFormInt1,
  validateFormFloat1,
} from '../../../../commonFunction/validations';
import { TextBaseField } from '../../../eleCommon/validation';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 8,
    marginBottom: 8,
  },
  hope: {
    background: '#fff',
    border: '1px solid #C8C8C8',
    padding: 8,
    marginTop: 8,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  // 希望地域
  hopeArea: {
    width: '100%',
  },
  hopeAreaBtn: {
    background: '#fff',
    textAlign: 'left',
    borderRadius: 0,
    padding: 0,
    minWidth: 'auto',
    width: '100%',
    '& > span': {
      display: 'block',
    },
    '&:hover': {
      background: `${theme.palette.primaryColor}29`,
    },
    '& .MuiTouchRipple-root': {
      display: 'none',
    },
  },
  hopeAreaBtnTxt: {
    fontSize: 13,
    lineHeight: 1.6,
    letterSpacing: 0,
  },
  // 沿線
  hopeRoute: {
    width: '100%',
  },
  hopeRouteBox: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '&:not(:last-child)': {
      marginBottom: 8,
    },
  },
  hopeRouteBox1: {
    width: 220,
  },
  hopeRouteBox2: {
    width: 450,
  },
  hopeRouteButton: {
    background: '#fff',
    textAlign: 'left',
    borderRadius: 0,
    padding: 0,
    fontFamily: 'Roboto',
    fontSize: 13,
    lineHeight: 1.6,
    letterSpacing: 0,
    minWidth: 40,
    '& > span': {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
    },
    '&:hover': {
      background: `${theme.palette.primaryColor}29`,
    },
    '& .MuiTouchRipple-root': {
      display: 'none',
    },
  },
  hopeRouteButton1: {
    width: '100%',
  },
  hopeRouteButtonTxt: {
    fontSize: 13,
    lineHeight: 1.6,
    letterSpacing: 0,
    margin: '0 4px',
  },
  hopeRouteButtonWrap: {
    display: 'flex',
    alignItems: 'center',
  },
  // 価格
  hopePrice: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  hopePriceSubTxt: {
    fontSize: 10,
  },
  hopePriceBox: {
    display: 'flex',
    alignItems: 'center',
  },
  // textFieldのみ
  hopeTf: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  hopeTf1: {
    display: 'flex',
    alignItems: 'center',
    width: 676,
    marginRight: 8,
  },
  hopeTf2: {
    display: 'flex',
    alignItems: 'center',
    width: 218,
  },
  hopeTf3: {
    display: 'flex',
    alignItems: 'center',
    width: 434,
    marginRight: 8,
  },
  hopeTf4: {
    display: 'flex',
    alignItems: 'center',
    width: 434,
  },
  hopeTfLbl: {
    whiteSpace: 'nowrap',
  },
  hopeTfInput: {
    margin: '-3px 0 0 8px',
    width: '100%',
  },
  textField: {
    display: 'block',
    '& > div::before': {
      borderBottom: '1px solid transparent',
    },
    '& > div::after': {
      borderBottom: `2px solid ${theme.palette.primaryColor}`,
    },
    '& > div:hover:not(.Mui-disabled):before': {
      borderBottom: `2px solid ${theme.palette.primaryColor}`,
    },
    '& label': {
      fontFamily: 'Roboto',
      color: '#333',
      right: 0,
      margin: '0 auto',
      textAlign: 'left',
      transformOrigin: 'top left',
      fontSize: 13,
      fontWeight: '700',
      lineHeight: 1.2,
      transform: 'translate(0, 2px) scale(1)',
      position: 'relative',
      letterSpacing: 0,
    },
    '& input': {
      fontFamily: 'Roboto',
      fontSize: 16,
      zoom: 0.81,
      lineHeight: 1.6,
      textAlign: 'left',
      padding: 0,
      height: 'auto',
      letterSpacing: 0,
    },
    '& textarea': {
      fontFamily: 'Roboto',
      fontSize: 16,
      zoom: 0.81,
      lineHeight: 1.6,
      textAlign: 'left',
      padding: 0,
      height: 'auto',
      letterSpacing: 0,
    },
    '& div': {
      margin: 0,
      width: '100%',
    },
    '& span span': {
      fontSize: 10,
    },
  },
  // チェックボックス
  hopeExamination: {
    marginTop: 8,
  },
  formGroup: {
    flexWrap: 'nowrap',
    flexDirection: 'row',
  },
  formControlLabel: {
    display: 'flex',
    alignItems: 'center',
    margin: '0 0 0 5px',
    '& > span': {
      display: 'block',
      fontFamily: 'Roboto',
      fontSize: 13,
      lineHeight: 1.6,
      letterSpacing: 0,
      padding: 0,
      marginLeft: 4,
      '&.Mui-checked': {
        color: theme.palette.primaryColor,
      },
    },
  },
  select: {
    '& .MuiSelect-selectMenu': {
      padding: 0,
    },
  },
  rangeSelect: {
    width: 74,
    '& .MuiSelect-select.MuiSelect-select': {
      height: 18,
    },
  },
}));

// 検討
const examinationItems = [
  {
    name: 'isThinkSale',
    label: '売却',
    tips: [
      '対象：資産整理などで不動産の売却に興味がある顧客',
      '関連：開発事業部',
      '戸建用地の買取をサポート',
    ],
  },
  {
    name: 'isThinkApartment',
    label: 'マンション',
    tips: [
      '対象：新築マンションに興味がある顧客',
      '関連：マンション開発事業部',
      '都心部を中心に1LDK~3LDKの新築マンションを販売',
    ],
  },
  {
    name: 'isThinkWholeHouseInvestment',
    label: '一棟物投資',
    tips: [
      '対象：高額帯の収益物件に興味がある顧客',
      '関連：ソリューション事業部、WM事業部',
      '2億円以上の1棟物の収益物件を販売',
      '節税を目的とした4,000万円前後の海外不動産を販売',
    ],
  },
  {
    name: 'isThinkDivisionInvestment',
    label: '区分投資',
    tips: [
      '対象：投資用1Rマンションに興味がある顧客',
      '関連：プレサンスコーポレーション',
      '3,000万円前後の1R投資用マンションを販売',
    ],
  },
  {
    name: 'isThinkRebuilding',
    label: '建替',
    tips: [
      '対象：戸建の建て替えに興味がある顧客',
      '関連：オープンハウス・アーキテクト',
      '戸建の建て替えを提案',
    ],
  },
];

export default function ResponseRegisterHope(props) {
  const classes = useStyles();
  const baseClasses = commonStyles();

  const { user, detail, setDetail, getComponent, errorCallback } = props;

  // 希望地域初期値
  const [initialParamArea, setInitialParamArea] = useState({
    wishArea: detail.wishArea,
    wishAreaIds: detail.wishAreaIds || [],
    wishAreaCode: detail.wishAreaCode,
  });

  // モーダル開閉用のローカルステート
  const [open, setOpen] = useState(false);
  // モーダルを開くsetState
  const handleOpen = () => {
    setOpen(true);
  };
  // モーダルを閉じるsetState
  const handleClose = () => {
    setOpen(false);
  };
  const detailRef = useRef(detail);
  detailRef.current = detail;
  const initial = detail;

  /**
   * ツールチップ用のテキスト
   * @param {string[]} lines チップ用のテキストの配列
   * @returns {ReactNode}
   */
  const createTips = useCallback((lines) => {
    return (
      <Grid>
        {lines.map((line, i) => (
          <Typography key={String(i)} className={baseClasses.small}>
            {line}
          </Typography>
        ))}
      </Grid>
    );
  }, [baseClasses]);

  const onBlur = (e) => {
    const { name, value } = e.target;
    if (value !== detail[name]) {
      initial[name] = value;
      setDetail({ ...detail });
    }
  };

  const onPriceRangeChange = (key, [min, max]) => {
    detailRef.current[`${key}Min`] = min;
    detailRef.current[`${key}Max`] = max;
    setDetail({ ...detailRef.current });
  };

  // チェックボックス
  const [checked, setChecked] = useState({
    isThinkSale: user.isThinkSale === 1,
    isThinkApartment: user.isThinkApartment === 1,
    isThinkWholeHouseInvestment: user.isThinkWholeHouseInvestment === 1,
    isThinkDivisionInvestment: user.isThinkDivisionInvestment === 1,
    isThinkRebuilding: user.isThinkRebuilding === 1,
  });

  const examinationChange = (e) => {
    const isChecked = e.target.checked;
    setChecked({ ...checked, [e.target.name]: isChecked });
    initial[e.target.name] = isChecked ? 1 : 0;
  };

  const [isOpenWishStation1, setIsOpenWishStation1] = useState(false);
  const onOpenWishStation1 = () => setIsOpenWishStation1(true);
  const onCloseWishStation1 = () => setIsOpenWishStation1(false);
  const onSelectWishStation1 = (data) => {
    console.log('update wish station1:', data);
    setDetail({
      ...detail,
      wishRailwayId1: data.railway.railwayId,
      wishStationIdFrom1: data.fromStation.stationId,
      wishStationIdTo1: data.toStation.stationId,
    });
  };

  const [isOpenWishStation2, setIsOpenWishStation2] = useState(false);
  const onOpenWishStation2 = () => setIsOpenWishStation2(true);
  const onCloseWishStation2 = () => setIsOpenWishStation2(false);
  const onSelectWishStation2 = (data) => {
    console.log('update wish station2:', data);
    setDetail({
      ...detail,
      wishRailwayId2: data.railway.railwayId,
      wishStationIdFrom2: data.fromStation.stationId,
      wishStationIdTo2: data.toStation.stationId,
    });
  };

  const [wish1Railway, setWish1Railway] = useState('-');
  const [wish1FromStation, setWish1FromStation] = useState('-');
  const [wish1ToStation, setWish1ToStation] = useState('-');
  useEffect(() => {
    (async () => {
      if (!detail.wishRailwayId1) {
        setWish1Railway('-');
        setWish1FromStation('-');
        setWish1ToStation('-');
        return;
      }
      const { data } = await stationsApi(detail.wishRailwayId1);

      const fromStation = data.stations.find((value) => {
        return value.stationId === Number(detail.wishStationIdFrom1);
      });
      if (!fromStation) return;

      const toStation = data.stations.find((value) => {
        return value.stationId === Number(detail.wishStationIdTo1);
      });
      if (!toStation) return;

      setWish1Railway(fromStation.railwayName);
      setWish1FromStation(fromStation.name);
      setWish1ToStation(toStation.name);
    })();
  }, [
    detail.wishRailwayId1,
    detail.wishStationIdFrom1,
    detail.wishStationIdTo1,
  ]);

  const [wish2Railway, setWish2Railway] = useState('-');
  const [wish2FromStation, setWish2FromStation] = useState('-');
  const [wish2ToStation, setWish2ToStation] = useState('-');
  useEffect(() => {
    (async () => {
      if (!detail.wishRailwayId2) {
        setWish2Railway('-');
        setWish2FromStation('-');
        setWish2ToStation('-');
        return;
      }
      const { data } = await stationsApi(detail.wishRailwayId2);

      const fromStation = data.stations.find((value) => {
        return value.stationId === Number(detail.wishStationIdFrom2);
      });
      if (!fromStation) return;

      const toStation = data.stations.find((value) => {
        return value.stationId === Number(detail.wishStationIdTo2);
      });
      if (!toStation) return;

      setWish2Railway(fromStation.railwayName);
      setWish2FromStation(fromStation.name);
      setWish2ToStation(toStation.name);
    })();
  }, [
    detail.wishRailwayId2,
    detail.wishStationIdFrom2,
    detail.wishStationIdTo2,
  ]);

  const onAreaChange = (data) => {
    setDetail({
      ...detail,
      wishArea: data.wishArea,
      wishAreaIds: data.wishAreaIds,
      wishAreaCode: data.wishAreaCode,
    });
    setInitialParamArea(data);
  };
  const abodeNowFloorPlanBlur = (e) => {
    if (e.target.value !== detail.floorPlanCode) {
      initial.floorPlanCode = e.target.value;
      setDetail({ ...detail });
      console.log(detail.floorPlanCode);
    }
  };
  const floorPlanSelectObj = {
    type: 'selectPlaceholder',
    placeholder: '-',
    obj: hopeFloorPlanSelect,
    state: user.floorPlanCode,
    set: abodeNowFloorPlanBlur,
  };

  return (
    <Grid className={classes.root}>
      <Typography className={baseClasses.title4}>希望・要望</Typography>
      <LabelWrap label="検討" box className={classes.hopeExamination}>
        <FormGroup className={classes.formGroup}>
          {examinationItems.map((item, i) => (
            <NormalTooltip key={String(i)} title={createTips(item.tips)}>
              <FormControlLabel
                className={classes.formControlLabel}
                control={
                  <Checkbox
                    checked={checked[item.name]}
                    onChange={examinationChange}
                    name={item.name}
                  />
                }
                label={item.label}
              />
            </NormalTooltip>
          ))}
        </FormGroup>
      </LabelWrap>
      <Grid className={classes.hope}>
        <Grid className={classes.hopeArea}>
          <Button className={classes.hopeAreaBtn} onClick={handleOpen}>
            <Typography className={baseClasses.title4}>希望地域</Typography>
            <Typography className={classes.hopeAreaBtnTxt}>
              {detail.wishArea || '-'}
            </Typography>
          </Button>
          <AreaSentaku
            open={open}
            onClose={handleClose}
            initialParam={initialParamArea}
            onUpdate={onAreaChange}
          />
        </Grid>
      </Grid>
      <Grid className={classes.hope}>
        <Grid className={classes.hopeRoute}>
          <Grid className={classes.hopeRouteBox}>
            <Grid className={classes.hopeRouteBox1}>
              <Typography className={baseClasses.title4}>
                沿線
                <span className={classes.hopePriceSubTxt}>（第1希望）</span>
              </Typography>
              <Grid className={classes.hopeRouteButtonWrap}>
                <Button
                  className={`${classes.hopeRouteButton} ${classes.hopeRouteButton1}`}
                  onClick={onOpenWishStation1}
                >
                  {wish1Railway}
                </Button>
              </Grid>
            </Grid>
            <Grid className={classes.hopeRouteBox1}>
              <Typography className={baseClasses.title4}>
                駅<span className={classes.hopePriceSubTxt}>（第1希望）</span>
              </Typography>
              <Grid className={classes.hopeRouteButtonWrap}>
                <Button
                  className={classes.hopeRouteButton}
                  onClick={onOpenWishStation1}
                >
                  {wish1FromStation}
                </Button>
                <Typography className={classes.hopeRouteButtonTxt}>
                  ～
                </Typography>
                <Button
                  className={classes.hopeRouteButton}
                  onClick={onOpenWishStation1}
                >
                  {wish1ToStation}
                </Button>
              </Grid>
            </Grid>
            <Grid className={classes.hopeRouteBox1}>
              <Typography className={baseClasses.title4}>
                沿線
                <span className={classes.hopePriceSubTxt}>（第2希望）</span>
              </Typography>
              <Grid className={classes.hopeRouteButtonWrap}>
                <Button
                  className={`${classes.hopeRouteButton} ${classes.hopeRouteButton1}`}
                  onClick={onOpenWishStation2}
                >
                  {wish2Railway}
                </Button>
              </Grid>
            </Grid>
            <Grid className={classes.hopeRouteBox1}>
              <Typography className={baseClasses.title4}>
                駅<span className={classes.hopePriceSubTxt}>（第2希望）</span>
              </Typography>
              <Grid className={classes.hopeRouteButtonWrap}>
                <Button
                  className={classes.hopeRouteButton}
                  onClick={onOpenWishStation2}
                >
                  {wish2FromStation}
                </Button>
                <Typography className={classes.hopeRouteButtonTxt}>
                  ～
                </Typography>
                <Button
                  className={classes.hopeRouteButton}
                  onClick={onOpenWishStation2}
                >
                  {wish2ToStation}
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid className={classes.hopeRouteBox}>
            <Grid className={classes.hopeRouteBox2}>
              <Typography className={baseClasses.title4}>学区<small>（第1希望）</small></Typography>
              <TextBaseField
                placeholder="-"
                defaultValue={user.wishSchoolDistrict1}
                className={classes.textField}
                name="wishSchoolDistrict1"
                onBlur={onBlur}
                convertor={convertForm1}
                validator={validateFormString1({ maxLengthInt: 255 })}
                errorCallback={errorCallback('wishSchoolDistrict1')}
              />
            </Grid>
            <Grid className={classes.hopeRouteBox2}>
              <Typography className={baseClasses.title4}>学区<small>（第2希望）</small></Typography>
              <TextBaseField
                placeholder="-"
                defaultValue={user.wishSchoolDistrict2}
                className={classes.textField}
                name="wishSchoolDistrict2"
                onBlur={onBlur}
                convertor={convertForm1}
                validator={validateFormString1({ maxLengthInt: 255 })}
                errorCallback={errorCallback('wishSchoolDistrict2')}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid className={classes.hope}>
        <LabelWrap
          label={(
            <>戸建て価格 <span className={classes.hopePriceSubTxt}>（万円）</span></>
          )}
          column
        >
          <RangeSelect
            defaultValue={[detail.detachedHouseBudgetMin, detail.detachedHouseBudgetMax]}
            options={priceSelect.menus}
            onChange={(res) => onPriceRangeChange('detachedHouseBudget', res)}
            equalable
            selectClassName={classes.rangeSelect}
            height={20}
          />
        </LabelWrap>
        <LabelWrap
          label={(
            <>土地価格 <span className={classes.hopePriceSubTxt}>（万円）</span></>
          )}
          column
        >
          <RangeSelect
            defaultValue={[detail.landBudgetMin, detail.landBudgetMax]}
            options={priceSelect.menus}
            onChange={(res) => onPriceRangeChange('landBudget', res)}
            equalable
            selectClassName={classes.rangeSelect}
            height={20}
          />
        </LabelWrap>
        <LabelWrap
          label={(
            <>戸建て広さ <span className={classes.hopePriceSubTxt}>（平米）</span></>
          )}
          column
        >
          <TextBaseField
            defaultValue={user.livingSpace}
            className={classes.textField}
            name="livingSpace"
            onBlur={onBlur}
            validator={validateFormFloat1({
              minFloat: 0.0,
              maxFloat: 999999.0,
              precisionInt: 5,
            })}
            convertor={convertFormNumber}
            errorCallback={errorCallback('livingSpace')}
          />
        </LabelWrap>
        <LabelWrap
          label="間取り"
          column
        >
          {getComponent(floorPlanSelectObj)}
        </LabelWrap>
        <LabelWrap
          label={(
            <>土地広さ <span className={classes.hopePriceSubTxt}>（平米）</span></>
          )}
          column
        >
          <TextBaseField
            defaultValue={user.wishLandArea}
            className={classes.textField}
            name="wishLandArea"
            onBlur={onBlur}
            validator={validateFormFloat1({
              minFloat: 0.0,
              maxFloat: 999999.0,
              precisionInt: 5,
            })}
            convertor={convertFormNumber}
            errorCallback={errorCallback('wishLandArea')}
          />
        </LabelWrap>
      </Grid>
      <Grid className={classes.hope}>
        <Grid className={classes.hopeTf1}>
          <Typography className={`${classes.hopeTfLbl} ${baseClasses.title4}`}>
            これまで見た物件の種類
          </Typography>
          <TextBaseField
            className={`${classes.hopeTfInput} ${classes.textField}`}
            defaultValue={user.checkedProductType}
            name="checkedProductType"
            onBlur={onBlur}
            convertor={convertForm1}
            validator={validateFormString1({ maxLengthInt: 50 })}
            errorCallback={errorCallback('checkedProductType')}
          />
        </Grid>
        <Grid className={classes.hopeTf2}>
          <Typography className={`${classes.hopeTfLbl} ${baseClasses.title4}`}>
            これまで見た物件の数
          </Typography>
          <TextBaseField
            className={`${classes.hopeTfInput} ${classes.textField}`}
            defaultValue={user.checkedProductCount}
            name="checkedProductCount"
            onBlur={onBlur}
            validator={validateFormInt1({ minFloat: 0.0, maxFloat: 9999999999.0 })}
            convertor={convertFormNumber}
            errorCallback={errorCallback('checkedProductCount')}
          />
        </Grid>
      </Grid>
      <Grid className={classes.hope}>
        <Grid className={classes.hopeTf3}>
          <Typography className={`${classes.hopeTfLbl} ${baseClasses.title4}`}>
            検討のきっかけ
          </Typography>
          <TextBaseField
            className={`${classes.hopeTfInput} ${classes.textField}`}
            defaultValue={user.reason}
            name="reason"
            onBlur={onBlur}
            convertor={convertForm1}
            validator={validateFormString1({ maxLengthInt: 100 })}
            errorCallback={errorCallback('reason')}
          />
        </Grid>
        <Grid className={classes.hopeTf4}>
          <Typography className={`${classes.hopeTfLbl} ${baseClasses.title4}`}>
            入居希望時期
          </Typography>
          <TextBaseField
            className={`${classes.hopeTfInput} ${classes.textField}`}
            defaultValue={user.wishMoveInDay}
            name="wishMoveInDay"
            onBlur={onBlur}
            convertor={convertForm1}
            validator={validateFormString1({ maxLengthInt: 50 })}
            errorCallback={errorCallback('wishMoveInDay')}
          />
        </Grid>
      </Grid>
      <Grid className={classes.hope}>
        <Grid className={classes.hopeTf}>
          <Typography className={`${classes.hopeTfLbl} ${baseClasses.title4}`}>
            重視するポイント
          </Typography>
          <TextBaseField
            className={`${classes.hopeTfInput} ${classes.textField}`}
            defaultValue={user.importantPoint}
            name="importantPoint"
            onBlur={onBlur}
            convertor={convertForm1}
            validator={validateFormString1({ maxLengthInt: 200 })}
            errorCallback={errorCallback('importantPoint')}
          />
        </Grid>
      </Grid>
      <Grid className={classes.hope}>
        <Grid className={classes.hopeTf}>
          <Typography className={`${classes.hopeTfLbl} ${baseClasses.title4}`}>
            購入目的
          </Typography>
          <TextBaseField
            className={`${classes.hopeTfInput} ${classes.textField}`}
            defaultValue={user.purchasePurpose}
            name="purchasePurpose"
            onBlur={onBlur}
            convertor={convertForm1}
            validator={validateFormString1({ maxLengthInt: 50 })}
            errorCallback={errorCallback('purchasePurpose')}
          />
        </Grid>
      </Grid>
      <Grid className={classes.hope}>
        <Grid className={classes.hopeTf}>
          <Typography className={`${classes.hopeTfLbl} ${baseClasses.title4}`}>
            概要メモ
          </Typography>
          <TextField
            className={`${classes.hopeTfInput} ${classes.textField}`}
            defaultValue={user.summary}
            name="summary"
            onBlur={onBlur}
          />
        </Grid>
      </Grid>
      <Grid className={classes.hope}>
        <Grid className={classes.hopeTf}>
          <Typography className={`${classes.hopeTfLbl} ${baseClasses.title4}`}>
            メモ
          </Typography>
          <TextField
            className={`${classes.hopeTfInput} ${classes.textField}`}
            defaultValue={user.memo}
            multiline
            name="memo"
            onBlur={onBlur}
          />
        </Grid>
      </Grid>
      <SelectRailwaysAndStationsContainer
        initialParam={{
          wishRailwayId: detail.wishRailwayId1,
          wishStationIdFrom: detail.wishStationIdFrom1,
          wishStationIdTo: detail.wishStationIdTo1,
        }}
        open={isOpenWishStation1}
        onClose={onCloseWishStation1}
        onUpdate={onSelectWishStation1}
      />
      <SelectRailwaysAndStationsContainer
        initialParam={{
          wishRailwayId: detail.wishRailwayId2,
          wishStationIdFrom: detail.wishStationIdFrom2,
          wishStationIdTo: detail.wishStationIdTo2,
        }}
        open={isOpenWishStation2}
        onClose={onCloseWishStation2}
        onUpdate={onSelectWishStation2}
      />
    </Grid>
  );
}
