import React, { useState, useEffect, useCallback } from 'react';
import {
  Grid,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { makeStyles } from '@material-ui/core/styles';
import commonStyles from '../styles';
import UnregisteredRecordTable from './parts/unregisteredRecordTable';
import AllPagenation from './parts/allPagenation';
import InfoRegister from '../common/infoRegister';
import { useLocalStorage } from '../../hooks';

const useStyles = makeStyles((theme) => ({
  container: {
    borderBottom: 'none',
    '& .MuiPaper-elevation1': {
      boxShadow: 'none',
    },
  },
  accordion: {
    '& .MuiAccordionDetails-root': {
      display: 'block',
      padding: 0,
    },
  },
  toggleButton: {
    padding: 0,
    textAlign: 'left',
    display: 'block',
    minWidth: 10,
  },
  headerContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginBottom: 5,
    '& .MuiSelect-icon': {
      color: theme.palette.primaryColor,
    },
    '& .MuiIconButton-root': {
      color: theme.palette.primaryColor,
    },
    '& .MuiIconButton-root.Mui-disabled': {
      color: '#C8C8C8',
    },

  },
  accordingDetails: {
    maxHeight: 230,
    overflow: 'auto',
  },
  buttonFlex: {
    display: 'flex',
    flexDirection: 'flex-start',
    '& .MuiAccordionSummary-root': {
      padding: 0,
      minHeight: 0,
      '& .MuiAccordionSummary-content': {
        margin: 0,
      },
    },
    '& .MuiIconButton-root': {
      padding: 0,
      '& .MuiIconButton-label': {
        color: theme.palette.primaryColor,
      },
    },
    '& .MuiIconButton-edgeEnd': {
      marginRight: 0,
    },
  },
}));

export default function UnregisteredRecordComponent(props) {
  const {
    dataList,
    getCustomerFunction,
    guidanceId,
    customer,
  } = props;
  const classes = useStyles();
  const baseClasses = commonStyles();
  // ページネーション
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(0);
  const { getLocalStorage, addLocalStorage } = useLocalStorage();
  // モーダル開閉用のローカルステート
  const [open, setOpen] = useState(false);
  // モーダルを開くsetState
  const handleOpen = useCallback(() => {
    setOpen(true);
  }, []);
  // モーダルを閉じるsetState
  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  useEffect(() => {
    const displayLimit = getLocalStorage();
    if (!displayLimit) {
      setRowsPerPage(20);
      addLocalStorage(20);
    } else if (rowsPerPage !== Number(displayLimit)) {
      setRowsPerPage(Number(displayLimit));
    }
  }, []);

  useEffect(() => {
    if (!open && customer.customerId) {
      handleOpen();
    }
  }, [customer]);

  return (
    <Grid className={classes.container}>
      <Accordion
        className={classes.accordion}
      >
        <Grid className={classes.headerContainer}>
          <Grid className={classes.buttonFlex}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon className={classes.toggleButton} />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            />
            <Grid>
              <Typography className={baseClasses.title2}>
                実績未登録({dataList.data.unregists.length})
              </Typography>
            </Grid>
          </Grid>
          <AllPagenation
            rowsPerPageOptions={[20, 40, 60]}
            rows={dataList.data.unregists.length}
            rowsPerPage={rowsPerPage}
            page={page}
            setPage={setPage}
            setRowsPerPage={setRowsPerPage}
            className={classes.allPageNation}
          />
        </Grid>
        <AccordionDetails className={classes.accordingDetails}>
          <UnregisteredRecordTable
            dataList={dataList}
            rowsPerPage={rowsPerPage}
            page={page}
            getCustomerFunction={getCustomerFunction}
            customer={customer}
          />
          <InfoRegister
            open={open}
            onOpen={handleOpen}
            onClose={handleClose}
            guidanceId={guidanceId}
            customer={customer}
          />
        </AccordionDetails>
      </Accordion>
    </Grid>
  );
}
