import { useSelector } from 'react-redux';
import { makeStyles, Grid } from '@material-ui/core';

import commonTheme from '../../../styles/theme';

import { TextBaseField } from '../../../eleCommon/validation';

const useStyles = makeStyles({
  root: {
    width: '100%',
    '& > .MuiInput-formControl': {
      [commonTheme.breakpoints.down('sm')]: {
        marginTop: 0,
      },
    },
  },
  item: {
    [commonTheme.breakpoints.up('md')]: {
      '&:last-child': {
        maxWidth: '75%',
        flexBasis: '75%',
      },
    },
  },
  zipLabel: {
    /* Small */
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '13px',
    lineHeight: '160%',
    /* or 21px */

    display: 'flex',
    alignItems: 'center',

    /* OH-Black 60% */
    color: 'rgba(51, 51, 51, 0.6)',

    /* Inside auto layout */
    flex: 'none',
    order: '1',
    alignSelf: 'stretch',
    flexGrow: '0',

    width: '100%',
    margin: '0px 4px',
    padding: '0px 2px',
    [commonTheme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  zipInput: {
    [commonTheme.breakpoints.up('md')]: {
      margin: '0px 4px',
    },
    '& .MuiInput-input': {
      [commonTheme.breakpoints.down('sm')]: {
        fontSize: 16,
        zoom: 0.88,
        textAlign: 'right',
      },
    },
    '&::before, &::after': {
      [commonTheme.breakpoints.down('sm')]: { display: 'none' },
    },
  },
});

const convertZen2Han = (val) => {
  if (typeof val !== 'string') return '';
  const regex = /[Ａ-Ｚａ-ｚ０-９！＂＃＄％＆＇（）＊＋，－．／：；＜＝＞？＠［＼］＾＿｀｛｜｝]/g;

  return val
    .replace(regex, (s) => String.fromCharCode(s.charCodeAt(0) - 0xfee0))
    .replace(/[‐－―]/g, '-');
};

export default function ZipTextField(props) {
  const {
    textField,
    getAddressList,
    initialParam,
    setInitialParam,
  } = props;
  const classes = useStyles();

  // SP・PCでの分岐用
  const isSp = useSelector((state) => state.deviceTypeSlice.isSp);

  const onSearchFunc = () => {
    const param = { code: null, address: '' };
    param.code = convertZen2Han(initialParam.code);
    param.address = convertZen2Han(initialParam.address);
    if (!isSp && (param.code || param.address)) {
      getAddressList(param);
    }
  };
  const onKeyDown = (e) => {
    if (e.key !== 'Enter') return;
    onSearchFunc();
  };

  const onChangeFunc = (e) => {
    setInitialParam({ ...initialParam, [e.target.name]: e.target.value });
  };

  return (
    <Grid key={`${textField.key}-grid`} item md={textField.md} className={classes.item}>
      <TextBaseField
        className={classes.root}
        InputLabelProps={{ shrink: true, className: classes.zipLabel }}
        InputProps={{ className: classes.zipInput }}
        onBlur={onSearchFunc}
        onKeyDown={onKeyDown}
        onChange={onChangeFunc}
        label={textField.label}
        value={initialParam[textField.key]}
        name={textField.key}
        placeholder="-"
      />
    </Grid>
  );
}
