import { List, ListItem, makeStyles } from '@material-ui/core';
import commonTheme from '../../../styles/theme';
import commonStyles from '../../../styles';

const useStyles = makeStyles((theme) => ({
  list: {
    padding: '16px 0 40px 16px',
  },
  listItem: {
    padding: '18.5px 16px',
    borderBottom: '1px solid #F3F3F3',
    '& *': {
      fontWeight: 'bold',
    },
  },
  gutters: {
    margin: '0 auto',
    [commonTheme.breakpoints.down('sm')]: {
      padding: '0',
      '&.Mui-selected': {
        background: '#fff',
        color: theme.palette.primaryColor,
      },
    },
    '& > .MuiListItemText-root': {
      [commonTheme.breakpoints.down('sm')]: {
        marginTop: '0px',
        marginBottom: '0px',
        padding: '16px',
        borderBottom: '1px solid #F3F3F3',
      },
    },
  },
}));

export default function SmsDrawerList(props) {
  const { values, setOpenDrawer, setOpenDrawer2, setHandleOpenEditWindow } = props;

  const classes = useStyles();
  const common = commonStyles();

  const menusKeys = Object.keys(values.menus);

  return (
    <List className={classes.list}>
      {menusKeys.map((data) => (
        <ListItem
          button
          key={data}
          className={`${common.title3} ${classes.listItem}`}
          onClick={() => {
            if (values.menus[data] === 'メモの変更') {
              setHandleOpenEditWindow(true);
            } else {
              setOpenDrawer2(true);
            }
            setOpenDrawer(false);
          }}
        >
          {values.menus[data]}
        </ListItem>
      ))}
    </List>
  );
}
