import { useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  TableCell,
  TableRow,
  Typography,
} from '@material-ui/core';
import Icon from '@material-ui/core/Icon';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import { makeStyles } from '@material-ui/core/styles';

import { changeConfirmMessage } from '../../../store/eleCommon/customConfirmMessage';
import ButtonPopover from '../../eleCommon/buttonPopover';

import commonStyles from '../../styles';
import DriveFileRenameIcon from '../../icons/fileRename';

import StoreGroupHistory from './storeGroupHistory';
import EditStoreGroup from '../editStoreGroup';

const useStyles = makeStyles((theme) => ({
  tableBodyRow: {
    position: 'relative',
    transition: 'all .3s ease',
    '& td': {
      padding: '6px 5px',
      '& p': {
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '12px',
        lineHeight: '160%',
        color: '#333333',
        borderRight: 'solid 1px #C8C8C8',
      },
    },
    '&:nth-of-type(even)': {
      backgroundColor: '#F3F3F3',
    },
    '&:hover': {
      background: `${theme.palette.primaryColor}29!important`,
      '& .buttonContainer': {
        opacity: 1,
      },
    },
  },
  driveFileRenameIcon: {
    color: theme.palette.primaryColor,
    fill: theme.palette.primaryColor,
  },
  historyEduIcon: {
    color: theme.palette.primaryColor,
  },
  deleteOutlineIcon: {
    color: theme.palette.primaryColor,
  },
}));

function StoreGroupTableRow(props) {
  const { row, deleteStoreGroup } = props;
  const classes = useStyles();
  const common = commonStyles();
  const [openEdit, setOpenEdit] = useState(false);
  const [openHistory, setOpenHistory] = useState(false);

  const dispatch = useDispatch();

  const handleOpenEdit = () => {
    setOpenEdit(true);
  };
  const handleOpenHistory = () => {
    setOpenHistory(true);
  };
  const confirmOpen = () => {
    dispatch(
      changeConfirmMessage({
        title: '店舗グループの削除確認',
        msgList: ['選択した店舗グループを削除してもよろしいですか？'],
        buttons: [
          {
            label: 'CANCEL',
            set: () => {},
            classes: common.buttonsSecondary,
          },
          {
            label: 'OK',
            set: () => { deleteStoreGroup(row); },
            classes: common.buttonsPrimary,
          },
        ],
      }),
    );
  };
  const handleOpenCancel = () => {
    confirmOpen();
  };

  // ポップオーバー用のボタンパーツ
  const popOver = {
    buttons: [
      {
        label: '編集',
        icon: <DriveFileRenameIcon className={classes.driveFileRenameIcon} />,
        set: handleOpenEdit,
      },
      {
        label: '履歴',
        icon: <Icon className={classes.historyEduIcon}>history_edu</Icon>,
        set: handleOpenHistory,
      },
      {
        label: '削除',
        icon: <DeleteOutlineIcon className={classes.deleteOutlineIcon} />,
        set: handleOpenCancel,
      },
    ],
  };

  return (
    <TableRow className={classes.tableBodyRow}>
      <TableCell style={{ paddingLeft: '25px' }}>
        <Typography>{row.displayOrder}</Typography>
      </TableCell>
      <TableCell>
        <Typography>{row.storeGroupName}</Typography>
      </TableCell>
      <TableCell>
        <Typography style={{ borderRight: 'none' }}>
          {row.divisionSubName}
        </Typography>
      </TableCell>
      <TableCell>
        <ButtonPopover buttons={popOver.buttons} />
      </TableCell>
      <TableCell>
        <EditStoreGroup
          open={openEdit}
          setOpen={setOpenEdit}
          areaCode={row.areaCode}
          storeGroup={row}
        />
      </TableCell>
      <TableCell>
        <StoreGroupHistory
          setting={row}
          openHistory={openHistory}
          setOpenHistory={setOpenHistory}
        />
      </TableCell>
    </TableRow>
  );
}

export default StoreGroupTableRow;
