import { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import UnregisteredRecordComponent from '../../components/unregisteredRecord/unregisteredRecordComponent';
import schedulesGetUnregistGuidancesApi from '../../apis/schedules/schedulesGetUnregistGuidancesApi';
import { schedulesGetUnregistGuidances } from '../../store/schedules/schedulesGetUnregistGuidancesSlice';
import CustomerApi from '../../apis/customer/customerApi';

const schedulesGetUnregistGuidancesOptions = {
  offset: 0,
  limit: 1000,
};

export default function UnregisteredRecordContainer() {
  const dispatch = useDispatch();
  // user情報をhttpResponseHeaderから取得 (https://openhouse.backlog.jp/alias/wiki/1132107)
  const responseHeader = useSelector((state) => state.responseHeader);
  const responseUnregist = useSelector(
    (state) => state.schedulesGetUnregistGuidances.schedulesGetUnregistGuidances,
  );
  const [guidanceId, setGuidanceId] = useState(0);
  const [customer, setCustomer] = useState({});

  // 実績未入力案内一覧取得
  const schedulesGetUnregistGuidancesFunction = useCallback(async (userId, params) => {
    await schedulesGetUnregistGuidancesApi(userId, params)
      .then(async (res) => {
        await dispatch(schedulesGetUnregistGuidances(res));
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  // GET Customer
  const getCustomerFunction = useCallback(async (unregist) => {
    await CustomerApi([unregist.customerId])
      .then((res) => {
        setGuidanceId(unregist.guidanceId);
        setCustomer(res.data.customers[0]);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  // responseHeader取得後の処理
  useEffect(() => {
    if (!responseHeader.userId) {
      return;
    }
    console.log('responseHeader.userId', responseHeader.userId);
    schedulesGetUnregistGuidancesFunction(
      responseHeader.userId, // debug: 4
      schedulesGetUnregistGuidancesOptions,
    );
  }, [responseHeader.userId]);

  return (
    <UnregisteredRecordComponent
      dataList={responseUnregist}
      getCustomerFunction={getCustomerFunction}
      guidanceId={guidanceId}
      customer={customer}
    />
  );
}
