import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  AppBar,
  Tabs,
  Tab,
  Box,
  Grid,
  Button,
  makeStyles,
} from '@material-ui/core';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import SimulatorContent from './simulatorContent';
import SimulatorStore from './simulatorStore';
import CsvUpload from './csvUpload';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Grid>{children}</Grid>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node.isRequired,
  index: PropTypes.node.isRequired,
  value: PropTypes.node.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    background: '#F3F3F3',
    width: '100%',
  },
  bar: {
    boxShadow: 'none',
    background: '#fff',
  },
  tabs: {
    background: '#fff',
    color: '#333',
    fontFamily: 'Roboto',
    fontSize: 13,
    lineHeight: 1.6,
    letterSpacing: 0,
    width: 1200,
    margin: '0 auto',
    '& .MuiTab-root': {
      minWidth: 'auto',
      padding: 8,
      width: 106,
    },
    '& .MuiTab-root.Mui-selected': {
      color: theme.palette.primaryColor,
      fontWeight: '700',
    },
    '& .MuiTabs-indicator': {
      background: theme.palette.primaryColor,
    },
    '& .MuiTab-wrapper': {
      fontSize: 13,
    },
  },
  btn: {
    display: 'block',
    color: theme.palette.primaryColor,
    background: '#fff',
    fontFamily: 'Roboto',
    fontWeight: '700',
    fontSize: 13,
    lineHeight: 1.6,
    letterSpacing: 0,
    padding: '6px 10px 2px',
    marginBottom: 16,
    '&:hover': {
      background: `${theme.palette.primaryColor}29`,
    },
  },
  content: {
    width: 1200,
    margin: '0 auto',
    '& > .MuiBox-root': {
      padding: 16,
    },
  },
}));

function SimulatorTabs(props) {
  const classes = useStyles();
  const {
    areas,
    clickAreaTabFunc,
    cities,
    storesStores,
    setStoresStores,
    storeScores,
    setStoreScores,
    judgementStart,
    downloadFunc,
    uploadFunc,
  } = props;

  // タブ
  const initialAreaCode = areas[0] ? areas[0].areaCode : null;
  const [value, setValue] = useState(0);
  const [areaCode, setAreaCode] = useState(initialAreaCode);
  const handleChange = (event, newValue) => {
    setValue(newValue);
    // 店舗情報を初期化
    setStoresStores([]);
    setStoreScores([]);
  };

  // CsvUploadの制御
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const showCsvUpload = () => {
    return (
      <CsvUpload
        open={open}
        onClose={handleClose}
        downloadFunc={downloadFunc}
        uploadFunc={uploadFunc}
        areaCode={areaCode}
      />
    );
  };
  const getIndexFromAreaCode = (areaCode1) => {
    const tmp = areas
      .map((data, index) => {
        return { ...data, index };
      })
      .filter((data) => data.areaCode === areaCode1);
    return tmp.length !== 0 ? tmp[0].index : 0;
  };

  useEffect(() => {
    setAreaCode(initialAreaCode);
    if (initialAreaCode) clickAreaTabFunc(initialAreaCode);
  }, [initialAreaCode]);

  return (
    <Grid className={classes.root}>
      <AppBar position="static" className={classes.bar}>
        <Tabs value={value} onChange={handleChange} className={classes.tabs}>
          {areas.map((data, index) => (
            <Tab
              key={data.areaCode}
              label={data.areaName}
              {...a11yProps(index)}
              onClick={() => {
                clickAreaTabFunc(data.areaCode);
                setAreaCode(data.areaCode);
              }}
            />
          ))}
        </Tabs>
      </AppBar>
      {cities.map((list) => (
        <TabPanel
          key={list.id}
          value={value}
          index={getIndexFromAreaCode(list.id)}
          className={classes.content}
        >
          <Button className={classes.btn} onClick={handleOpen}>
            反響データ取込（CSV）
          </Button>
          {list.val && (
            <SimulatorContent
              val={list.val}
              judgementStart={judgementStart}
              areaCode={areaCode}
            />
          )}
          <SimulatorStore ttl="反響を回す店舗" scores={storesStores} />
          <SimulatorStore ttl="店舗得点" scores={storeScores} />
          {showCsvUpload()}
        </TabPanel>
      ))}
    </Grid>
  );
}

export default SimulatorTabs;
