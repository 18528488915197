import { DAILY_LOCAL_STORAGE_ARR } from '../constants/localStorage';
import { DAILY_SESSION_STORAGE_ARR } from '../constants/sessionStorage';

/**
 * setLocalStorage
 * @param {string} key
 * @param {*} value
 */
export function setLocalStorage(key, value) {
  try {
    localStorage.setItem(key, JSON.stringify(value));
  } catch (err) {
    console.error(err);
  }
}

/**
 * setSessionStorage
 * @param {string} key
 * @param {*} value
 */
export function setSessionStorage(key, value) {
  try {
    sessionStorage.setItem(key, JSON.stringify(value));
  } catch (err) {
    console.error(err);
  }
}

/**
 * getLocalStorage
 * @param {string} key
 * @param {*} defValue
 * @returns *
 */
export function getLocalStorage(key, defValue = null) {
  try {
    const cache = localStorage.getItem(key);
    return cache ? JSON.parse(cache) : defValue;
  } catch {
    return defValue;
  }
}

/**
 * getSessionStorage
 * @param {string} key
 * @param {*} defValue
 * @returns *
 */
export function getSessionStorage(key, defValue = null) {
  try {
    const cache = sessionStorage.getItem(key);
    return cache ? JSON.parse(cache) : defValue;
  } catch {
    return defValue;
  }
}

/**
 * removeLocalStorage-
 * @param {string | string[]} key
 */
export function removeLocalStorage(key) {
  if (Array.isArray(key)) {
    key.forEach(removeLocalStorage);
  } else {
    localStorage.removeItem(key);
  }
}

/**
 * removeSessionStorage-
 * @param {string | string[]} key
 */
export function removeSessionStorage(key) {
  if (Array.isArray(key)) {
    key.forEach(removeSessionStorage);
  } else {
    sessionStorage.removeItem(key);
  }
}

/**
 * @description - 一日経ったら対象のローカルストレージとセッションストレージを削除
 */
export function removeDailyStorage() {
  for (let i = 0; DAILY_LOCAL_STORAGE_ARR.length > i; i += 1) {
    localStorage.removeItem(DAILY_LOCAL_STORAGE_ARR[i]);
  }
  for (let i = 0; DAILY_SESSION_STORAGE_ARR.length > i; i += 1) {
    sessionStorage.removeItem(DAILY_SESSION_STORAGE_ARR[i]);
  }
}

/**
 * @description - Cognito認証情報削除
 */
export function removeCognito() {
  for (const data of Object.keys(localStorage)) {
    if (data.includes('Cognito')) removeLocalStorage(data);
  }
}

/**
 * addDailyStorage -
 * @param {string | string[]} key
 * @param {string } value
 */
export function setDailyStorage(key, value) {
  sessionStorage.setItem('daily_' + key, JSON.stringify(value));
}
