// Mail系
export const MAIL_SUBJECT = 'daily_createMailSubject';
export const MAIL_BODY = 'daily_createMailBody';
export const MAIL_FROM_CODE = 'daily_creteMailFromCode';
export const MAIL_SIGN = 'daily_createMailSign';
export const MAIL_ATTACHMENTS = 'daily_createMailAttachments';

// Tree
export const TREE_LEVEL = 'daily_level';
export const TREE_USERLEVELMIN = 'daily_userLevelMin'; // 重要情報 - ランク
export const TREE_USERLEVELMAX = 'daily_userLevelMax'; // 重要情報 - ランク
export const TREE_USERLEVELLIST = 'daily_userLevelList'; // 重要情報 - ランク
export const TREE_ISCANCALLNOW = 'daily_isCanCallNow'; // 重要情報 - 今TELが繋がりやすい顧客
export const TREE_ISEFFECTWEEK = 'daily_isEffectWeek'; // 重要情報 - 直近1週間の反響
export const TREE_ISCALLEDANDNOTMAILED = 'daily_isCalledAndNotMailed'; // 重要情報 - TEL済/未Mail
export const TREE_ISWEBHISTORYEXIST = 'daily_isWebHistoryExist'; // 重要情報 - Web履歴あり
export const TREE_ISSENDABLEMAILEXIST = 'daily_isSendableMailExist'; // 重要情報 - Mailあり
export const TREE_ISCALLABLEPHONENUMBEREXIST = 'daily_isCallablePhoneNumberExist'; // 重要情報 - TELあり
export const TREE_ISDIRECTEXIST = 'daily_isDirectExist'; // 重要情報 - 指示あり
export const TREE_CUSTOMERSTATUSCODE = 'daily_customerStatusCode'; // 重要情報 - 追跡状況
export const TREE_ISRECEIVESHARE = 'daily_isReceiveShare'; // 重要情報 - 共有された顧客のみ
export const TREE_ISSENDSHARE = 'daily_isSendShare'; // 重要情報 - 共有してる顧客のみ
export const TREE_ACTIONFROM = 'daily_actionFrom'; // 対応履歴 - 対応日時From
export const TREE_ACTIONTO = 'daily_actionTo'; // 対応履歴 - 対応日時To
export const TREE_ACTIONS = 'daily_actions'; // 対応履歴 - 種類/反応 親グループのID、種類/反応 被選択複数子要素のオブジェクト配列
export const TREE_ACTIONKEYWORD = 'daily_actionKeyWord'; // 対応履歴 - キーワード
export const TREE_ACTIONSEARCHCODE = 'daily_actionSearchCode'; // 対応履歴 - キーワード キーワードの検索の範囲
export const TREE_RESPONSEFROM = 'daily_responseFrom'; // 反響情報 - 反響日From
export const TREE_RESPONSETO = 'daily_responseTo'; // 反響情報 - 反響日To
export const TREE_EFFECTFROM = 'daily_effectFrom'; // 反響情報 - 反響基準日From
export const TREE_EFFECTTO = 'daily_effectTo'; // 反響情報 - 反響基準日To
export const TREE_RESPONSETYPELIST = 'daily_responseTypeList'; // 反響情報 - 媒体 表示用
export const TREE_LARGERESPONSETYPEID = 'daily_largeResponseTypeId'; // 反響情報 - 媒体（大）
export const TREE_MEDIUMRESPONSETYPEID = 'daily_mediumResponseTypeId'; // 反響情報 - 媒体（中）
export const TREE_SMALLRESPONSETYPEID = 'daily_smallResponseTypeId'; // 反響情報 - 媒体（小）
export const TREE_FINERESPONSETYPEID = 'daily_fineResponseTypeId'; // 反響情報 - 媒体（細）
export const TREE_MEMBERSTATUSCODE = 'daily_memberStatusCode'; // 反響情報 - 会員状態
export const TREE_HOUSEBUDGETMIN = 'daily_houseBudgetMin'; // 希望条件 - 戸建価格Min
export const TREE_HOUSEBUDGETMAX = 'daily_houseBudgetMax'; // 希望条件 - 戸建価格Max
export const TREE_LIVINGSPACEMIN = 'daily_livingSpaceMin'; // 希望条件 - 戸建広さMin
export const TREE_LIVINGSPACEMAX = 'daily_livingSpaceMax'; // 希望条件 - 戸建広さMax
export const TREE_LANDBUDGETMIN = 'daily_landBudgetMin'; // 希望条件 - 土地価格Min
export const TREE_LANDBUDGETMAX = 'daily_landBudgetMax'; // 希望条件 - 土地価格Max
export const TREE_WISHLANDAREAMIN = 'daily_wishLandAreaMin'; // 希望条件 - 土地広さMin
export const TREE_WISHLANDAREAMAX = 'daily_wishLandAreaMax'; // 希望条件 - 土地広さMax
export const TREE_BUDGETMIN = 'daily_budgetMin'; // 詳細検索では使わない
export const TREE_BUDGETMAX = 'daily_budgetMax'; // 詳細検索では使わない
export const TREE_FLOORPLAN = 'daily_floorPlan'; // 希望条件 - 間取り
export const TREE_ANNUALINCOMEMIN = 'daily_annualIncomeMin'; // 希望条件 - 年収Min
export const TREE_ANNUALINCOMEMAX = 'daily_annualIncomeMax'; // 希望条件 - 年収Max
export const TREE_ISTHINKSALE = 'daily_isThinkSale'; // 希望条件 - 検討 - 売却
export const TREE_ISTHINKAPARTMENT = 'daily_isThinkApartment'; // 希望条件 - 検討 - マンション
export const TREE_ISTHINKWHOLEHOUSEINVESTMENT = 'daily_isThinkWholeHouseInvestment'; // 希望条件 - 検討 - 一棟物投資
export const TREE_ISTHINKDIVISIONINVESTMENT = 'daily_isThinkDivisionInvestment'; // 希望条件 - 検討 - 区分投資
export const TREE_ISTHINKREBUILDING = 'daily_isThinkRebuilding'; // 希望条件 - 検討 - 建替
export const TREE_WISHAREA = 'daily_wishArea'; // 希望条件 - 地域
export const TREE_WISHAREACODE = 'daily_wishAreaCode'; // 希望条件 - 地域コード
export const TREE_WISHAREAIDS = 'daily_wishAreaIds'; // 希望条件 - 地域ID
export const TREE_ISNONEWISHAREA = 'daily_isNoneWishArea'; // 希望条件 - 希望地域無し
export const TREE_WISHAREASEARCHTYPECODE = 'daily_wishAreaSearchTypeCode'; // 希望条件 - 地域 ～検索に含める(or条件)
export const TREE_WISHRAILWAYID = 'daily_wishRailwayId'; // 希望条件 沿線ID
export const TREE_WISHRAILWAY = 'daily_wishRailway'; // 希望条件 沿線名
export const TREE_WISHRAILWAYIDSEARCHTYPECODE = 'daily_wishRailwayIdSearchTypeCode'; // 希望条件 沿線検索条件コード ～検索に含める(or条件)
export const TREE_WISHSTATIONFROM = 'daily_wishStationFrom'; // 希望条件 駅FromId
export const TREE_WISHSTATIONFROMNAME = 'daily_wishStationFromName'; // 希望条件 駅FromName
export const TREE_WISHSTATIONTO = 'daily_wishStationTo'; // 希望条件 駅ToID
export const TREE_WISHSTATIONTONAME = 'daily_wishStationToName'; // 希望条件 駅ToName
export const TREE_SEARCHSTATIONTYPECODE = 'daily_searchStationTypeCode'; // 希望条件 駅 ～検索に含める(and条件)
export const TREE_MAILOPENHISTORYCODE = 'daily_mailOpenHistoryCode'; // メール開封
export const TREE_ISGUIDANCEHISTORYSEARCH = 'daily_isGuidanceHistorySearch'; // 行動情報 - 案内実績
export const TREE_ISVISITHISTORYSEARCH = 'daily_isVisitHistorySearch'; // 行動情報 - 来社実績
export const TREE_HOLIDAYWEEKDAYS = 'daily_holidayWeekdays'; // 補足情報 - お休みの日
export const TREE_HOLIDAYWEEKDAYOTHERS = 'daily_holidayWeekdayOthers'; // 詳細検索ではいらない
export const TREE_TELSTATUSCODE = 'daily_telStatusCode'; // 補足情報 - 電話許可
export const TREE_PHONESUPPORTSTATUSCODE = 'daily_phoneSupportStatusCode'; // 行動情報 電話
export const TREE_EMAILSTATUSCODES = 'daily_emailStatusCodes'; // 補足情報 - メール許可
export const TREE_MAILSENDSTATUSCODE = 'daily_mailSendStatusCode'; // 詳細検索では使わない
export const TREE_ISADDRESSEXIST = 'daily_isAddressExist'; //  // 補足情報 - 住所ありなし
export const TREE_ABODENOWBUILDINGTYPE = 'daily_abodeNowBuildingType'; // 補足情報 - 現況住居種類
export const TREE_ISALLOWDM = 'daily_isAllowDm'; // 補足情報 - DM許可
export const TREE_CURRENTUSER = 'daily_currentUser'; // 補足情報 - 現担当Name
export const TREE_CURRENTUSERID = 'daily_currentUserId'; // 補足情報 - 現担当ID
export const TREE_FORMERUSER = 'daily_formerUser'; // 補足情報 - 旧担当Name
export const TREE_FORMERUSERID = 'daily_formerUserId'; // 補足情報 - 旧担当ID
export const TREE_PREVIOUSUSER = 'daily_previousUser'; // 補足情報 - 前担当Name
export const TREE_PREVIOUSUSERID = 'daily_previousUserId'; // 補足情報 - 前担当ID
export const TREE_THINKSTARTSEASONFROM = 'daily_thinkStartSeasonFrom'; // 追わない - 検討再開時期From
export const TREE_THINKSTARTSEASONTO = 'daily_thinkStartSeasonTo'; // 追わない - 検討再開時期To
export const TREE_NOCONTACTDETAILREASONCODES = 'daily_noContactDetailReasonCode'; // 追わない - 追わない理由
export const TREE_NONECONTACTKEYWORD = 'daily_noneContactKeyword'; // 追わない - キーワード
export const TREE_NONECONTACTKEYWORDCODES = 'daily_noneContactKeywordCodes'; // 追わない - キーワードの検索の範囲
export const TREE_KEYWORD = 'daily_keyword'; // キーワード - キーワード
export const TREE_KEYWORDSEARCH = 'daily_keywordSearch'; // キーワードの検索の範囲
export const TREE_RECOMMENDSTATUS = 'daily_recommendStatus'; // レコメンド - レコメンド状況
export const TREE_CUSTOMERIDS = 'daily_customerIds'; // 顧客ID - 顧客ID
export const TREE_CONVENIENTTIMES = 'daily_convenientTimes'; // TELが繋がりやすい時間帯
export const TREE_LASTACTIONDATE = 'daily_lastActionDate'; // 詳細検索では使わない
export const TREE_USERLEVEL = 'daily_userLevel'; // モバイルのみつかう
export const TREE_LASTACCESSDATE = 'daily_lastAccessDate'; // 詳細検索では使わない
export const TREE_DISPLAYORDERCODE = 'daily_displayOrderCode';
export const TREE_SEARCHTYPE2 = 'daily_searchType2'; // 詳細検索では使わない
export const TREE_SEARCHTYPE3 = 'daily_searchType3'; // 詳細検索では使わない
export const TREE_SEARCHTYPE4 = 'daily_searchType4'; // 直近1週間の案内顧客
export const TREE_SEARCHTYPE5 = 'daily_searchType5'; // 未接触顧客
export const TREE_GUIDANCECOUNTMIN = 'daily_guidanceCountMin'; // 案内回数 最小
export const TREE_GUIDANCECOUNTMAX = 'daily_guidanceCountMax'; // 案内回数 最大
export const TREE_ISCONTACTORRECEPTIONSEARCH = 'daily_isContactOrReceptionSearch'; // 接触/受信あり顧客検索フラグ
export const TREE_ISWEBSEARCHOPTION = 'daily_isWebSearchOption'; // 反響情報 - WEB動向検索オプション
export const TREE_ACQUISITIONS = 'daily_acquisitions';
export const TREE_ACQUISITIONNAME = 'daily_acquisitionName';

export const DAILY_SESSION_STORAGE_ARR = [
  MAIL_SUBJECT,
  MAIL_BODY,
  MAIL_FROM_CODE,
  MAIL_SIGN,
  MAIL_ATTACHMENTS,
  TREE_LEVEL,
  TREE_USERLEVELMIN,
  TREE_USERLEVELMAX,
  TREE_USERLEVELLIST,
  TREE_ISCANCALLNOW,
  TREE_ISEFFECTWEEK,
  TREE_ISCALLEDANDNOTMAILED,
  TREE_ISWEBHISTORYEXIST,
  TREE_ISSENDABLEMAILEXIST,
  TREE_ISCALLABLEPHONENUMBEREXIST,
  TREE_ISDIRECTEXIST,
  TREE_CUSTOMERSTATUSCODE,
  TREE_ISRECEIVESHARE,
  TREE_ISSENDSHARE,
  TREE_ACTIONFROM,
  TREE_ACTIONTO,
  TREE_ACTIONS,
  TREE_ACTIONKEYWORD,
  TREE_ACTIONSEARCHCODE,
  TREE_RESPONSEFROM,
  TREE_RESPONSETO,
  TREE_EFFECTFROM,
  TREE_EFFECTTO,
  TREE_RESPONSETYPELIST,
  TREE_LARGERESPONSETYPEID,
  TREE_MEDIUMRESPONSETYPEID,
  TREE_SMALLRESPONSETYPEID,
  TREE_FINERESPONSETYPEID,
  TREE_MEMBERSTATUSCODE,
  TREE_HOUSEBUDGETMIN,
  TREE_HOUSEBUDGETMAX,
  TREE_LIVINGSPACEMIN,
  TREE_LIVINGSPACEMAX,
  TREE_LANDBUDGETMIN,
  TREE_LANDBUDGETMAX,
  TREE_WISHLANDAREAMIN,
  TREE_WISHLANDAREAMAX,
  TREE_BUDGETMIN,
  TREE_BUDGETMAX,
  TREE_FLOORPLAN,
  TREE_ANNUALINCOMEMIN,
  TREE_ANNUALINCOMEMAX,
  TREE_ISTHINKSALE,
  TREE_ISTHINKAPARTMENT,
  TREE_ISTHINKWHOLEHOUSEINVESTMENT,
  TREE_ISTHINKDIVISIONINVESTMENT,
  TREE_ISTHINKREBUILDING,
  TREE_WISHAREA,
  TREE_WISHAREACODE,
  TREE_WISHAREAIDS,
  TREE_ISNONEWISHAREA,
  TREE_WISHAREASEARCHTYPECODE,
  TREE_WISHRAILWAYID,
  TREE_WISHRAILWAY,
  TREE_WISHRAILWAYIDSEARCHTYPECODE,
  TREE_WISHSTATIONFROM,
  TREE_WISHSTATIONFROMNAME,
  TREE_WISHSTATIONTO,
  TREE_WISHSTATIONTONAME,
  TREE_SEARCHSTATIONTYPECODE,
  TREE_MAILOPENHISTORYCODE,
  TREE_ISGUIDANCEHISTORYSEARCH,
  TREE_ISVISITHISTORYSEARCH,
  TREE_HOLIDAYWEEKDAYS,
  TREE_HOLIDAYWEEKDAYOTHERS,
  TREE_TELSTATUSCODE,
  TREE_PHONESUPPORTSTATUSCODE,
  TREE_EMAILSTATUSCODES,
  TREE_MAILSENDSTATUSCODE,
  TREE_ISADDRESSEXIST,
  TREE_ABODENOWBUILDINGTYPE,
  TREE_ISALLOWDM,
  TREE_CURRENTUSER,
  TREE_CURRENTUSERID,
  TREE_FORMERUSER,
  TREE_FORMERUSERID,
  TREE_PREVIOUSUSER,
  TREE_PREVIOUSUSERID,
  TREE_THINKSTARTSEASONFROM,
  TREE_THINKSTARTSEASONTO,
  TREE_NOCONTACTDETAILREASONCODES,
  TREE_NONECONTACTKEYWORD,
  TREE_NONECONTACTKEYWORDCODES,
  TREE_KEYWORD,
  TREE_KEYWORDSEARCH,
  TREE_RECOMMENDSTATUS,
  TREE_CUSTOMERIDS,
  TREE_CONVENIENTTIMES,
  TREE_LASTACTIONDATE,
  TREE_USERLEVEL,
  TREE_LASTACCESSDATE,
  TREE_DISPLAYORDERCODE,
  TREE_SEARCHTYPE2,
  TREE_SEARCHTYPE3,
  TREE_SEARCHTYPE4,
  TREE_SEARCHTYPE5,
  TREE_GUIDANCECOUNTMIN,
  TREE_GUIDANCECOUNTMAX,
  TREE_ISCONTACTORRECEPTIONSEARCH,
  TREE_ISWEBSEARCHOPTION,
  TREE_ACQUISITIONS,
  TREE_ACQUISITIONNAME,
];
