import React from 'react';
import { useSelector } from 'react-redux';
import {
  Grid,
  Typography,
  Radio,
  RadioGroup,
  FormControlLabel,
  List,
  ListItem,
  Select,
  MenuItem,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import commonTheme from '../../../styles/theme';
import { GUIDANCE_STATUS_CODE } from '../../../../constants';
import infoRegisterStyles from './infoRegisterStyle';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    background: '#fff',
    border: '1px solid #C8C8C8',
    padding: '8px 8px 7px',
  },
  ttl: {
    width: 85,
    marginRight: 8,
    fontFamily: 'Roboto',
    fontSize: 13,
    lineHeight: 1.6,
    fontWeight: '700',
    letterSpacing: 0,
    flexShrink: 0,
    '& span': {
      color: '#D83420',
    },
  },
  box: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
  },
  txtWrap: {
    marginRight: 5,
  },
  txt: {
    fontFamily: 'Roboto',
    color: 'rgba(51, 51, 51, 0.6)',
    fontSize: 13,
    fontWeight: '700',
    lineHeight: '32px',
    letterSpacing: 0,
    whiteSpace: 'nowrap',
    '&:not(:last-child)': {
      marginBottom: 8,
    },
  },
  radioGroup: {
    [commonTheme.breakpoints.down('sm')]: {
      display: 'block',
    },
    [commonTheme.breakpoints.up('md')]: {
      width: 390,
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'unset',
    },
    '& > .MuiGrid-root:nth-child(1)': {
      [commonTheme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
    '& > .MuiGrid-root:nth-child(5)': {
      [commonTheme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
    '& > .MuiGrid-root:nth-child(2) > .MuiTypography-root': {
      [commonTheme.breakpoints.down('sm')]: {
        paddingTop: 0,
      },
    },
  },
  radioGroupTtl: {
    [commonTheme.breakpoints.down('sm')]: {
      fontSize: 13,
      padding: '16px 0',
    },
  },
  label: {
    display: 'block',
    [commonTheme.breakpoints.down('sm')]: {
      borderBottom: '1px solid #F3F3F3',
      padding: 16,
      margin: 0,
    },
    [commonTheme.breakpoints.up('md')]: {
      paddingRight: 4,
      margin: '0 0 8px 0',
    },
    '& .MuiTypography-root': {
      fontFamily: 'Roboto',
      fontSize: 13,
      fontWeight: '700',
      lineHeight: 1.6,
      letterSpacing: 0,
    },
    '& .MuiButtonBase-root': {
      padding: 4,
      [commonTheme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
    '& .MuiRadio-colorSecondary.Mui-checked': {
      color: theme.palette.primaryColor,
      '& + .MuiFormControlLabel-label': {
        color: theme.palette.primaryColor,
      },
    },
    '&:nth-child(1)': {
      display: 'none',
    },
    '&:nth-child(5)': {
      display: 'none',
    },
    '&:nth-child(8)': {
      marginBottom: 0,
    },
    '&:nth-child(9)': {
      marginBottom: 0,
    },
  },
  // SP
  list: {
    background: '#fff',
    padding: 0,
  },
  listItem: {
    padding: 0,
  },
  listBtn: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '10px 8px 10px 16px',
    borderBottom: '1px solid #F3F3F3',
    width: '100%',
  },
  listTtl: {
    fontSize: 13,
    lineHeight: 1.2,
    '& span': {
      color: '#D83420',
    },
  },
  listTxt: {
    fontSize: 13,
    fontWeight: 700,
    lineHeight: 1.2,
    textTransform: 'none',
  },
}));

function InfoRegisterRadio1(props) {
  const { values } = props;
  const classes = useStyles();
  const infoRegisterClasses = infoRegisterStyles();

  const menus = Object.keys(GUIDANCE_STATUS_CODE);

  // SP・PCでの分岐用
  const isSp = useSelector((state) => state.deviceTypeSlice.isSp);
  let contents;

  const createMenuItems = () => {
    const menuItems = menus.map(item => {
      return (
        <MenuItem
          key={item}
          value={item}
        >
          {GUIDANCE_STATUS_CODE[item]}
        </MenuItem>
      );
    });
    const preserved = <MenuItem key="dataari" value="ari" disabled>予約あり</MenuItem>;
    const notPreserved = <MenuItem key="datanashi" value="nashi" disabled>予約なし</MenuItem>;
    menuItems.splice(0, 1, preserved);
    menuItems.splice(4, 1);
    menuItems.splice(6, 0, notPreserved);
    return menuItems;
  };

  const getBox = (
    <Grid>
      <Select
        // name="vals"
        value={values.state === undefined ? 99 : values.state}
        onChange={(e) => {
          values.setState(e.target.value);
        }}
      >
        <MenuItem value={99} disabled>未選択</MenuItem>
        {createMenuItems()}
      </Select>
    </Grid>
  );

  if (isSp) {
    contents = (
      <Grid>
        <List className={infoRegisterClasses.list}>
          <ListItem className={infoRegisterClasses.listItem}>
            <Typography className={infoRegisterClasses.listTtl}>
              {values.label}
              {values.required && <span>＊</span>}
            </Typography>
            {getBox}
          </ListItem>
        </List>
      </Grid>
    );
  } else {
    contents = (
      <Grid className={classes.root}>
        <Typography className={classes.ttl}>
          {values.label}
          {values.required && <span>＊</span>}
        </Typography>
        <Grid className={classes.box}>
          <Grid className={classes.txtWrap}>
            <Typography className={classes.txt}>
              予約あり
            </Typography>
            <Typography className={classes.txt}>
              予約なし
            </Typography>
          </Grid>
          <RadioGroup
            name="vals"
            defaultValue={values.state}
            className={classes.radioGroup}
          >
            {menus.map((data) => (
              <FormControlLabel
                key={data}
                value={data}
                control={
                  <Radio
                    onClick={(e) => {
                      values.setState(e.target.value);
                    }}
                    checked={Boolean(Number(data) === Number(values.state))}
                  />
                }
                label={GUIDANCE_STATUS_CODE[data]}
                className={classes.label}
              />
            ))}
          </RadioGroup>
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid>
      {contents}
    </Grid>
  );
}

export default InfoRegisterRadio1;
