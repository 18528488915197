import { useState, useCallback, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, List, ListItem, Typography } from '@material-ui/core';
import commonStyles from '../../../styles';
import UnsentMenuDrawer from './unsentMenuDrawer';
import PullRefComponent from '../../../common/pullRefComponent';
import LoadingEllipsis from '../../../common/pullRefComponent/parts/loadingEllipsis';

const useStyles = makeStyles((theme) => ({
  wrap: {
    minHeight: '100vh',
    overflowY: 'auto',
    paddingTop: '110px',
    paddingBottom: '68px',
    background: '#F3F3F3',
  },
  list: {
    background: '#fff',
    padding: 0,
  },
  item: {
    display: 'block',
    padding: '0 0 0 16px',
    '&.active': {
      background: `${theme.palette.primaryColor}29`,
    },
  },
  itemBody: {
    padding: '13.5px 8px',
    borderBottom: '1px solid #F3F3F3',
  },
  ttl: {
    marginTop: '4.5px',
    '& > p': {
      fontWeight: 'bold',
    },
  },
  pointTime: {
    color: '#D83420',
    marginRight: '8px',
  },
  flex: {
    display: 'flex',
  },
  dl: {
    display: 'flex',
    alignItems: 'center',
    flex: 1,
    margin: 0,
  },
  dt: {
    color: '#8C8C8C',
    lineHeight: 1,
  },
  dd: {
    marginLeft: '4px',
    fontWeight: '700',
  },
  fromData: {
    color: '#8C8C8C',
    fontSize: '13px',
    fontWeight: '400',
  },
  mailSubjectTtl: {
    width: '100%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}));

// 共通パーツ
function ListItemComponent(props) {
  const {
    arrIndex,
    data,
    idx,
    active,
    setActive,
    openDrawer,
    setOpenDrawer,
    setClickItem,
  } = props;

  const classes = useStyles();
  const common = commonStyles();

  return (
    <ListItem
      button
      onClick={() => {
        setOpenDrawer(!openDrawer);
        setClickItem(data);

        if (active === idx) {
          setActive(true);
        } else {
          setActive(idx);
        }
      }}
      className={`${classes.item} ${active === idx ? 'active' : ''}`}
    >
      <Grid className={classes.itemBody}>
        <Typography>
          <span className={common.tags}>送信待ち</span>
          <time className={common.title6}>
            <span className={`${common.title3} ${classes.pointTime}`}>{data.deliveryAt?.split(' ')[1]}</span>
            {data.deliveryAt?.split(' ')[0]}
          </time>
        </Typography>
        <Grid className={`${common.title2} ${classes.ttl}`}>
          <Typography className={classes.mailSubjectTtl}>
            {data.mailSubject}
          </Typography>
        </Grid>
        <Grid className={classes.flex}>
          <dl className={classes.dl}>
            <dt className={`${common.title4} ${classes.dt}`}>To</dt>
            {Number(arrIndex) === 0 && (<dd className={`${common.bass} ${classes.dd}`}>{data.lastName + ' ' + data.firstName + '様'}</dd>)}
            {Number(arrIndex) === 1 && (<dd className={`${common.bass} ${classes.dd}`}>{String(data.deliveryCount) + '件'}</dd>)}
          </dl>
          <dl className={classes.dl}>
            <dt className={`${common.title4} ${classes.dt}`}>from</dt>
            <dd className={`${common.title4} ${classes.dd} ${classes.fromData}`}>{data.divisionSubName}<br />{data.fromName}</dd>
          </dl>
        </Grid>
      </Grid>
    </ListItem>
  );
}

export default function UnsentList(props) {
  const {
    arrIndex,
    list,
    functions,
    mailCount,
    mailCountTab,
    limit,
    setLimit,
    options,
    refresh,
  } = props;
  const classes = useStyles();

  const [active, setActive] = useState(false); // 選択状態
  const [openDrawer, setOpenDrawer] = useState(false); // 今すぐ送信の制御
  const [clickItem, setClickItem] = useState(list[0]); // 現在選択している要素
  const [refreshing, setRefreshing] = useState(false);
  const [isLoadingActive, setIsLoadingActive] = useState(false); // ローディング状態

  const element = useRef(null); // スクロール要素

  if (arrIndex) {
    console.log('送信先数');
  }

  // 更新
  const handleRefresh = useCallback(async () => {
    setRefreshing(true);
    try {
      console.log('更新');
      setRefreshing(false);
    } catch (err) {
      console.error(err);
    }
  }, [refreshing]);

  // コンテンツのスクロール量を検知し、下スクロールで更新カウントを取得
  const handleScroll = async () => {
    const elH = element.current.clientHeight;
    const maxScroll = element.current.scrollHeight;
    const scrollTopVal = element.current.scrollTop;
    const loadingElH = 64; // ローディングアイコン分の高さ
    const diff = maxScroll - elH;
    // limit = APIパラメータに設定されるリクエストのLimit
    // list = APIの全てのレスポンスデータ
    // mailCount = DB内の全データ数(individuallyMailCount=個別, broadCastMailCount = 一斉)
    const judgeCount = limit - list.length;

    if (diff - scrollTopVal < loadingElH && judgeCount < 20) {
      if (mailCountTab === 0) {
        mailCount.individuallyMailCount > limit ? await setLimit(limit + 20) : null;
      } else if (mailCountTab === 1) {
        mailCount.broadCastMailCount > limit ? await setLimit(limit + 20) : null;
      }
      await setIsLoadingActive(true);
      setIsLoadingActive(false);
    }
  };

  return (
    <PullRefComponent
      refreshing={refreshing}
      onRefresh={handleRefresh}
    >
      <Grid className={classes.wrap} ref={element} onScroll={handleScroll}>
        <List className={classes.list}>
          {/* 下スクロールでリストを5件ずつ、追加表示 */}
          {list.map((data, idx) => {
            return (
              <ListItemComponent
                arrIndex={arrIndex}
                data={data}
                key={data.deliveryId}
                idx={idx}
                active={active}
                setActive={setActive}
                openDrawer={openDrawer}
                setOpenDrawer={setOpenDrawer}
                clickItem={clickItem}
                setClickItem={setClickItem}
              />
            );
          })}
        </List>
        {/* ローディング用アイコン */}
        { isLoadingActive && <LoadingEllipsis /> }
      </Grid>
      <UnsentMenuDrawer
        refresh={refresh}
        arrIndex={arrIndex}
        openDrawer={openDrawer}
        setOpenDrawer={setOpenDrawer}
        clickItem={clickItem}
        functions={functions}
        mailCountTab={mailCountTab}
        options={options}
      />
    </PullRefComponent>
  );
}
