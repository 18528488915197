export const SMS_IMMEDIATE_SEND = 1; // SMS即時送信
export const SMS_TIMER_SEND = 2; // SMSタイマー送信
export const SMS_RECEIVE = 3; // SMS受信
// SMS配信ステータス
// ※0,1は現状設定されない。
// SMS送信後、DB上ではDLR通知(2 or 3)が返されるまでnullとなっており、0が返された実績はないため。
export const SMS_SEND_STATUS_UNKNOWN = 0; // SMS配信ステータス：状態不明
export const SMS_SEND_STATUS_SENDING = 1; // SMS配信ステータス：配信中
export const SMS_SEND_STATUS_SUCCEED = 2; // SMS配信ステータス：送達
export const SMS_SEND_STATUS_FAILED = 3; // SMS配信ステータス：配信失敗
export const SMS_SEND_STATUS_CANCELED = 4; // SMS配信ステータス：キャンセル
export const SMS_SEND_STATUS = {
  [SMS_SEND_STATUS_UNKNOWN]: '状態不明',
  [SMS_SEND_STATUS_SENDING]: '送信中',
  [SMS_SEND_STATUS_SUCCEED]: '送信',
  [SMS_SEND_STATUS_FAILED]: '配信失敗',
  [SMS_SEND_STATUS_CANCELED]: 'キャンセル',
};
