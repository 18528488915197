import {
  Typography,
  Grid,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import commonStyles from '../../styles';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#fff',
    border: '1px solid #C8C8C8',
    padding: '10px',
    marginBottom: 8,
    '& .makeStyles-checkBoxContainer-32': {
      display: 'flex',
      flexDirection: 'column',
    },
    width: '391px',
  },
  formGroupContainer: {
    display: 'flex',
    flexDirection: 'column',
    '& .MuiRadio-colorPrimary.Mui-checked': {
      color: theme.palette.primaryColor,
    },
    '& label': {
      '& .PrivateSwitchBase-root-33': {
        padding: 5,
      },
      '& .MuiTypography-body1': {
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '13px',
        lineHeight: '160%',
      },
    },
  },
  formControlList: {
    alignItems: 'flex-start',
  },
  guidanceButton: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '13px',
    lineHeight: '160%',
    color: theme.palette.primaryColor,
    '& .MuiButton-label': {
      justifyContent: 'flex-start',
    },
  },
  launchIcon: {
    width: 18,
    height: 18,
    marginLeft: 8,
  },
}));

function AcquisitionType(props) {
  const classes = useStyles();
  const baseClasses = commonStyles();
  /**
   * @param {Number} acquisitionType
   * @param {function} setAcquisitionType
   */
  const { acquisitionType, setAcquisitionType } = props;

  const handleChange = (e) => {
    setAcquisitionType(e.target.value);
  };

  const contents = (
    <Grid className={classes.container}>
      <Typography style={{ width: '15%' }} className={baseClasses.title6}>
        種類<span style={{ color: '#D83420' }}>＊</span>
      </Typography>
      <FormControl className={classes.formControlList} component="fieldset">
        <RadioGroup
          row
          aria-label="position"
          name="position"
          className={classes.formGroupContainer}
          defaultValue={acquisitionType?.toString()}
        >
          <FormControlLabel
            value="1"
            control={
              <Radio
                color="primary"
                onChange={(e) => handleChange(e)}
              />
            }
            label="社員"
            labelPlacement="end"
          />
          <FormControlLabel
            value="2"
            control={
              <Radio
                color="primary"
                onChange={(e) => handleChange(e)}
              />
            }
            label="内定者"
            labelPlacement="end"
          />
          <FormControlLabel
            value="3"
            control={
              <Radio
                color="primary"
                onChange={(e) => handleChange(e)}
              />
            }
            label="研修生"
            labelPlacement="end"
          />
          <FormControlLabel
            value="6"
            control={
              <Radio
                color="primary"
                onChange={(e) => handleChange(e)}
              />
            }
            label="トライアル"
          />
          <FormControlLabel
            value="4"
            control={
              <Radio
                color="primary"
                onChange={(e) => handleChange(e)}
              />
            }
            label="アルバイト"
          />
        </RadioGroup>
      </FormControl>
    </Grid>
  );

  return (
    <Grid>
      {contents}
    </Grid>
  );
}

export default AcquisitionType;
