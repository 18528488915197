import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Grid,
  Typography,
  List,
  ListItem,
  Select,
  MenuItem,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import commonTheme from '../../../styles/theme';
import infoRegisterStyles from './infoRegisterStyle';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  box: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    width: 280,
    border: '1px solid #C8C8C8',
    background: '#fff',
    padding: '3px 8px 2px',
  },
  txtWrap: {
    marginRight: 5,
  },
  ttl: {
    width: 85,
    marginRight: 8,
    fontFamily: 'Roboto',
    fontSize: 13,
    lineHeight: 1.6,
    fontWeight: '700',
    letterSpacing: 0,
    flexShrink: 0,
    '& span': {
      color: '#D83420',
    },
  },
  radioGroup: {
    [commonTheme.breakpoints.down('sm')]: {
      display: 'block',
    },
    [commonTheme.breakpoints.up('md')]: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'unset',
      width: 292,
    },
  },
  label: {
    display: 'block',
    margin: '0',
    [commonTheme.breakpoints.down('sm')]: {
      borderBottom: '1px solid #F3F3F3',
      padding: 16,
    },
    [commonTheme.breakpoints.up('md')]: {
      paddingRight: 4,
    },
    '& .MuiTypography-root': {
      fontFamily: 'Roboto',
      fontSize: 13,
      fontWeight: '700',
      lineHeight: 1.6,
      letterSpacing: 0,
    },
    '& .MuiButtonBase-root': {
      padding: 4,
      [commonTheme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
    '& .MuiRadio-colorSecondary.Mui-checked': {
      color: theme.palette.primaryColor,
      '& + .MuiFormControlLabel-label': {
        color: theme.palette.primaryColor,
      },
    },
  },
  // SP
  list: {
    background: '#fff',
    padding: 0,
  },
  listItem: {
    padding: 0,
  },
  listBtn: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '12px 8px 12px 16px',
    borderBottom: '1px solid #F3F3F3',
    width: '100%',
  },
  listTtl: {
    fontSize: 13,
    lineHeight: 1.2,
    '& span': {
      color: '#D83420',
    },
  },
  listTxt: {
    fontSize: 13,
    fontWeight: 700,
    lineHeight: 1.2,
    textTransform: 'none',
  },
}));

function InfoRegisterRadio2(props) {
  const { values } = props;
  const classes = useStyles();
  const infoRegisterClasses = infoRegisterStyles();

  const PRESENCE_OR_ABSENCE = {
    1: 'あり',
    0: 'なし',
  };

  const menus = Object.keys(PRESENCE_OR_ABSENCE);

  const [value1, setValue1] = useState(values.state1);
  const [value2, setValue2] = useState(values.state2);

  const handleChange1 = (val) => {
    setValue1(val);
  };
  const handleChange2 = (val) => {
    setValue2(val);
  };

  // 編集時に初期値が一度undefinedになってから入るのでuseEffectで監視
  useEffect(() => { setValue1(values.state1); }, [values.state1]);
  useEffect(() => { setValue2(values.state2); }, [values.state2]);

  // SP・PCでの分岐用
  const isSp = useSelector((state) => state.deviceTypeSlice.isSp);
  let contents;
  const getBox1 = (
    <RadioGroup
      name="vals1"
      value={value1}
      onChange={(e) => {
        handleChange1(e.target.value);
        values.setState1(e.target.value);
      }}
      className={classes.radioGroup}
    >
      {menus.map((data, index) => (
        <FormControlLabel
          key={data}
          value={index}
          control={<Radio />}
          label={PRESENCE_OR_ABSENCE[data]}
          className={classes.label}
        />
      ))}
    </RadioGroup>
  );

  const getBox2 = (
    <RadioGroup
      name="vals2"
      value={value2}
      onChange={(e) => {
        handleChange2(e.target.value);
        values.setState2(e.target.value);
      }}
      className={classes.radioGroup}
    >
      {menus.map((data, index) => (
        <FormControlLabel
          key={data}
          value={index}
          control={<Radio />}
          label={PRESENCE_OR_ABSENCE[data]}
          className={classes.label}
        />
      ))}
    </RadioGroup>
  );

  const getBox3 = (
    <Select
      value={!Number.isFinite(value1) ? 99 : value1}
      onChange={(e) => {
        setValue1(e.target.value);
        handleChange1(e.target.value);
        values.setState1(e.target.value);
      }}
    >
      <MenuItem disabled value={99}>未選択</MenuItem>
      {menus.map((data, index) => (
        <MenuItem
          key={data}
          value={index}
          className={classes.label}
        >
          {PRESENCE_OR_ABSENCE[data]}
        </MenuItem>
      ))}
    </Select>
  );

  const getBox4 = (
    <Select
      value={!Number.isFinite(value2) ? 99 : value2}
      onChange={(e) => {
        setValue2(e.target.value);
        handleChange2(e.target.value);
        values.setState2(e.target.value);
      }}
    >
      <MenuItem disabled value={99}>未選択</MenuItem>
      {menus.map((data, index) => (
        <MenuItem
          key={data}
          value={index}
        >
          {PRESENCE_OR_ABSENCE[data]}
        </MenuItem>
      ))}
    </Select>
  );

  if (isSp) {
    contents = (
      <Grid>
        <List className={infoRegisterClasses.list}>
          <ListItem className={infoRegisterClasses.listItem}>
            <Typography className={infoRegisterClasses.listTtl}>
              {values.label1}
              {values.required && <span>＊</span>}
            </Typography>
            {getBox3}
          </ListItem>
          <ListItem className={infoRegisterClasses.listItem}>
            <Typography className={infoRegisterClasses.listTtl}>
              {values.label2}
              {values.required && <span>＊</span>}
            </Typography>
            {getBox4}
          </ListItem>
        </List>
      </Grid>
    );
  } else {
    contents = (
      <Grid className={classes.root}>
        <Grid className={classes.box}>
          <Typography className={classes.ttl}>
            {values.label1}
            {values.required && <span>＊</span>}
          </Typography>
          {getBox1}
        </Grid>
        <Grid className={classes.box}>
          <Typography className={classes.ttl}>
            {values.label2}
            {values.required && <span>＊</span>}
          </Typography>
          {getBox2}
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid>
      {contents}
    </Grid>
  );
}

export default InfoRegisterRadio2;
