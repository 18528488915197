import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, Button } from '@material-ui/core';
import commonStyles from '../../../styles';
import { howToWorkSelect } from '../../../../constants/customerMain';
import SelectRailwaysAndStationsContainer from '../../../../containers/search/railwayAndStations/selectRailwayAndStations';
import stationsApi from '../../../../apis/common/stations';

import {
  convertForm1,
  convertFormNumber,
} from '../../../../commonFunction/convertors';
import {
  validateFormString1,
  validateFormInt1,
} from '../../../../commonFunction/validations';
import { TextBaseField } from '../../../eleCommon/validation';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 8,
    marginBottom: 8,
  },
  workPlace: {
    background: '#fff',
    border: '1px solid #C8C8C8',
    padding: 8,
    marginTop: 8,
  },
  workPlaceBox: {
    display: 'flex',
    justifyContent: 'space-between',
    '&:not(:last-child)': {
      marginBottom: 8,
    },
  },
  workPlace1: {
    width: 240,
  },
  workPlace2: {
    width: 62,
  },
  workPlace3: {
    width: 280,
  },
  workPlace3Box: {
    '&:not(:last-child)': {
      marginBottom: 8,
    },
  },
  workPlace4: {
    width: 272,
  },
  workPlace4Box: {
    textAlign: 'left',
    '&:not(:last-child)': {
      marginBottom: 8,
    },
  },
  workPlace4Button: {
    display: 'flex',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    width: 'auto',
    minWidth: 139,
  },
  textField: {
    display: 'block',
    '& label': {
      fontFamily: 'Roboto',
      color: '#333',
      right: 0,
      margin: '0 auto',
      textAlign: 'left',
      transformOrigin: 'top left',
      fontSize: 13,
      fontWeight: '700',
      lineHeight: 1.2,
      transform: 'translate(0, 2px) scale(1)',
      position: 'relative',
      letterSpacing: 0,
    },
    '& input': {
      fontFamily: 'Roboto',
      fontSize: 13,
      lineHeight: 1.6,
      textAlign: 'left',
      padding: 0,
      height: 'auto',
    },
    '& div': {
      margin: 0,
      width: '100%',
    },
    '& > div::before': {
      borderBottom: '1px solid transparent',
    },
    '& > div::after': {
      borderBottom: `2px solid ${theme.palette.primaryColor}`,
    },
    '& > div:hover:not(.Mui-disabled):before': {
      borderBottom: `2px solid ${theme.palette.primaryColor}`,
    },
  },
  button: {
    background: '#fff',
    textAlign: 'left',
    borderRadius: 0,
    padding: 0,
    fontFamily: 'Roboto',
    fontSize: 13,
    lineHeight: 1.6,
    letterSpacing: 0,
    minWidth: 40,
    minHeight: '20.8px',
    '& > span': {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
    },
    '&:hover': {
      background: `${theme.palette.primaryColor}29`,
    },
    '& .MuiTouchRipple-root': {
      display: 'none',
    },
  },
  buttonTxt: {
    fontSize: 13,
    lineHeight: 1.6,
    letterSpacing: 0,
    margin: '0 4px',
  },
  claim: {
    background: '#C8C8C8',
    fontSize: 13,
    lineHeight: 1.6,
    fontWeight: '700',
    letterSpacing: 0,
    padding: 4,
    textAlign: 'left',
    marginTop: 4,
  },
}));

export default function ResponseRegisterWorkPlace(props) {
  const classes = useStyles();
  const baseClasses = commonStyles();

  const { user, detail, setDetail, getComponent, errorCallback } = props;

  console.log(user.workingStyleCode);

  const initial = detail;

  const onBlur = (e) => {
    if (e.target.value !== detail[e.target.name]) {
      initial[e.target.name] = e.target.value;
      setDetail({ ...detail });
      console.log(detail[e.target.name]);
    }
  };

  const howToWorkBlur1 = (e) => {
    if (e.target.value !== user.workingStyleCode) {
      initial.workingStyleCode = e.target.value;
      setDetail({ ...detail });
      console.log(detail.workingStyleCode);
    }
  };
  const howToWorkBlur2 = (e) => {
    if (e.target.value !== user.subWorkingStyleCode) {
      initial.subWorkingStyleCode = e.target.value;
      setDetail({ ...detail });
      console.log(detail.subWorkingStyleCode);
    }
  };

  const kokyakuSelectList = {
    howToWorkItem1: {
      type: 'select',
      obj: howToWorkSelect,
      state: user.workingStyleCode,
      set: howToWorkBlur1,
    },
    howToWorkItem2: {
      type: 'select',
      obj: howToWorkSelect,
      state: user.subWorkingStyleCode,
      set: howToWorkBlur2,
    },
  };

  const [isOpenStation, setIsOpenStation] = useState(false);
  const onOpenStation = () => setIsOpenStation(true);
  const onCloseStation = () => setIsOpenStation(false);
  const onSelectStation = (data) => {
    console.log('update station:', data);
    setDetail({
      ...detail,
      companyRailwayId: data.fromStation.railwayId,
      companyStationId: data.fromStation.stationId,
    });
  };

  const [isOpenSubStation, setIsOpenSubStation] = useState(false);
  const onOpenSubStation = () => setIsOpenSubStation(true);
  const onCloseSubStation = () => setIsOpenSubStation(false);
  const onSelectSubStation = (data) => {
    console.log('update subStation:', data);
    setDetail({
      ...detail,
      subCompanyRailwayId: data.fromStation.railwayId,
      subCompanyStationId: data.fromStation.stationId,
    });
  };

  const [companyRailway, setCompanyRailway] = useState('-');
  const [companyStation, setCompanyStation] = useState('-');
  useEffect(() => {
    (async () => {
      if (!detail.companyRailwayId) {
        setCompanyRailway('-');
        setCompanyStation('-');
        return;
      }

      const { data } = await stationsApi(detail.companyRailwayId);

      const station = data.stations.find((value) => {
        return value.stationId === Number(detail.companyStationId);
      });
      if (!station) return;

      setCompanyRailway(station.railwayName);
      setCompanyStation(station.name);
    })();
  }, [detail.companyRailwayId, detail.companyStationId]);

  const [subCompanyRailway, setSubCompanyRailway] = useState('-');
  const [subCompanyStation, setSubCompanyStation] = useState('-');
  useEffect(() => {
    (async () => {
      if (!detail.subCompanyRailwayId) {
        setSubCompanyRailway('-');
        setSubCompanyStation('-');
        return;
      }

      const { data } = await stationsApi(detail.subCompanyRailwayId);

      const station = data.stations.find((value) => {
        return value.stationId === Number(detail.subCompanyStationId);
      });
      if (!station) return;

      setSubCompanyRailway(station.railwayName);
      setSubCompanyStation(station.name);
    })();
  }, [detail.subCompanyRailwayId, detail.subCompanyStationId]);

  const getWorkPlace = () => {
    switch (detail.customerStatusCode) {
      case 11:
        return (
          <Grid className={classes.workPlace}>
            <Grid className={classes.workPlaceBox}>
              <Typography className={baseClasses.title4}>勤務先</Typography>
            </Grid>
            <Typography className={classes.claim}>
              クレーム中は閲覧不可
            </Typography>
          </Grid>
        );
      default:
        return (
          <Grid className={classes.workPlace}>
            <Grid className={classes.workPlaceBox}>
              <Grid className={classes.workPlace1}>
                <Typography className={baseClasses.title4}>勤務先1</Typography>
                <TextBaseField
                  defaultValue={user.company}
                  className={classes.textField}
                  name="company"
                  onBlur={onBlur}
                  placeholder="-"
                  convertor={convertForm1}
                  validator={validateFormString1({ maxLengthInt: 80 })}
                  errorCallback={errorCallback('company')}
                />
              </Grid>
              <Grid className={classes.workPlace2}>
                <Typography className={baseClasses.title4}>年数</Typography>
                <TextBaseField
                  defaultValue={user.companyWorkingYear}
                  className={classes.textField}
                  type="number"
                  name="companyWorkingYear"
                  onBlur={onBlur}
                  placeholder="-"
                  validator={validateFormInt1({ minFloat: 0.0, maxFloat: 99.0 })}
                  convertor={convertFormNumber}
                  errorCallback={errorCallback('companyWorkingYear')}
                />
              </Grid>
              <Grid className={classes.workPlace3}>
                <Grid className={classes.workPlace3Box}>
                  <Typography className={baseClasses.title4}>
                    沿線／最寄り駅
                  </Typography>
                  <Grid className={classes.workPlace4Button}>
                    <Button className={classes.button} onClick={onOpenStation}>
                      {companyRailway}
                    </Button>
                    <Typography className={classes.buttonTxt}>/</Typography>
                    <Button className={classes.button} onClick={onOpenStation}>
                      {companyStation}
                    </Button>
                  </Grid>
                </Grid>
                <Grid className={classes.workPlace3Box}>
                  <Typography className={baseClasses.title4}>勤務先住所</Typography>
                  <TextBaseField
                    placeholder="-"
                    defaultValue={user.companyAddress}
                    className={classes.textField}
                    name="companyAddress"
                    onBlur={onBlur}
                    convertor={convertForm1}
                    validator={validateFormString1({ maxLengthInt: 50 })}
                    errorCallback={errorCallback('companyAddress')}
                  />
                </Grid>
              </Grid>
              <Grid className={classes.workPlace4}>
                <Grid className={classes.workPlace4Box}>
                  <Typography className={baseClasses.title4}>働き方</Typography>
                  {getComponent(kokyakuSelectList.howToWorkItem1)}
                </Grid>
                <Grid className={classes.workPlace4Box}>
                  <Typography className={baseClasses.title4}>職業種</Typography>
                  <TextBaseField
                    placeholder="-"
                    defaultValue={user.jobType}
                    className={classes.textField}
                    name="jobType"
                    onBlur={onBlur}
                    convertor={convertForm1}
                    validator={validateFormString1({ maxLengthInt: 50 })}
                    errorCallback={errorCallback('jobType')}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid className={classes.workPlaceBox}>
              <Grid className={classes.workPlace1}>
                <Typography className={baseClasses.title4}>勤務先2</Typography>
                <TextBaseField
                  defaultValue={user.subCompany}
                  className={classes.textField}
                  name="subCompany"
                  onBlur={onBlur}
                  placeholder="-"
                  convertor={convertForm1}
                  validator={validateFormString1({ maxLengthInt: 50 })}
                  errorCallback={errorCallback('subCompany')}
                />
              </Grid>
              <Grid className={classes.workPlace2}>
                <Typography className={baseClasses.title4}>年数</Typography>
                <TextBaseField
                  defaultValue={user.subCompanyWorkingYear}
                  className={classes.textField}
                  type="number"
                  name="subCompanyWorkingYear"
                  onBlur={onBlur}
                  placeholder="-"
                  validator={validateFormInt1({ minFloat: 0.0, maxFloat: 99.0 })}
                  convertor={convertFormNumber}
                  errorCallback={errorCallback('subCompanyWorkingYear')}
                />
              </Grid>
              <Grid className={classes.workPlace3}>
                <Grid className={classes.workPlace3Box}>
                  <Typography className={baseClasses.title4}>
                    沿線／最寄り駅
                  </Typography>
                  <Grid className={classes.workPlace4Button}>
                    <Button
                      className={classes.button}
                      onClick={onOpenSubStation}
                    >
                      {subCompanyRailway}
                    </Button>
                    <Typography className={classes.buttonTxt}>/</Typography>
                    <Button
                      className={classes.button}
                      onClick={onOpenSubStation}
                    >
                      {subCompanyStation}
                    </Button>
                  </Grid>
                </Grid>
                <Grid className={classes.workPlace3Box}>
                  <Typography className={baseClasses.title4}>勤務先住所</Typography>
                  <TextBaseField
                    placeholder="-"
                    defaultValue={user.subCompanyAddress}
                    className={classes.textField}
                    name="subCompanyAddress"
                    onBlur={onBlur}
                    convertor={convertForm1}
                    validator={validateFormString1({ maxLengthInt: 50 })}
                    errorCallback={errorCallback('subCompanyAddress')}
                  />
                </Grid>
              </Grid>
              <Grid className={classes.workPlace4}>
                <Grid className={classes.workPlace4Box}>
                  <Typography className={baseClasses.title4}>働き方</Typography>
                  {getComponent(kokyakuSelectList.howToWorkItem2)}
                </Grid>
                <Grid className={classes.workPlace4Box}>
                  <Typography className={baseClasses.title4}>職業種</Typography>
                  <TextBaseField
                    placeholder="-"
                    defaultValue={user.subJobType}
                    className={classes.textField}
                    name="subJobType"
                    onBlur={onBlur}
                    convertor={convertForm1}
                    validator={validateFormString1({ maxLengthInt: 50 })}
                    errorCallback={errorCallback('subJobType')}
                  />
                </Grid>
              </Grid>
            </Grid>
            <SelectRailwaysAndStationsContainer
              initialParam={{
                wishRailwayId: detail.companyRailwayId,
                wishStationIdFrom: detail.companyStationId,
              }}
              open={isOpenStation}
              onClose={onCloseStation}
              onUpdate={onSelectStation}
              onClickSelectButton={onSelectStation}
              isNearestStation
            />
            <SelectRailwaysAndStationsContainer
              initialParam={{
                wishRailwayId: detail.subCompanyRailwayId,
                wishStationIdFrom: detail.subCompanyStationId,
              }}
              open={isOpenSubStation}
              onClose={onCloseSubStation}
              onUpdate={onSelectSubStation}
              onClickSelectButton={onSelectSubStation}
              isNearestStation
            />
          </Grid>
        );
    }
  };

  return (
    <Grid className={classes.root}>
      <Typography className={baseClasses.title4}>勤務先情報</Typography>
      {getWorkPlace()}
    </Grid>
  );
}
