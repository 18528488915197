import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  Button,
  Typography,
} from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { Add, Folder, Person } from '@material-ui/icons';
import { v4 as uuid } from 'uuid';
import getTargetFunction from './commonTreeFunction';
import commonStyles from '../../../styles';

const useStyles = makeStyles((theme) => ({
  fadeContainer: {
    position: 'absolute',
    width: '100%',
    top: 0,
    left: 0,
    height: '100%',
  },
  root: {
    width: '100%',
    maxHeight: '100%',
    // overflow: 'auto',
    padding: '0 0 16px 0',
  },
  parent: {
    position: 'relative',
    padding: 0,
  },
  icons: {
    fill: theme.palette.primaryColor,
    width: '16px',
    backgroundColor: '#ffffff',
    position: 'relative',
    zIndex: 1,
  },
  li: {
    padding: '0 0 0 16px',
    display: 'flex',
    background: '#fff',
    // '&.selectedChild': {
    //   display: 'none',
    // },
  },
  btn: {
    lineHeight: 1.6,
    fontWeight: '700',
    width: '100%',
    marginTop: '0px',
    marginBottom: '0px',
    padding: '16px',
    flexShrink: 0,
    borderBottom: '1px solid #F3F3F3',
    '& .MuiButton-label': {
      justifyContent: 'flex-start',
    },
  },
  diviPickableButton1: {
    lineHeight: 1.6,
    fontWeight: '700',
    width: '80%',
    marginTop: '0px',
    marginBottom: '0px',
    padding: '16px',
    flexShrink: 0,
    borderBottom: '1px solid #F3F3F3',
    '& .MuiButton-label': {
      justifyContent: 'flex-start',
    },
  },
  diviPickableButton2: {
    lineHeight: 1.6,
    fontWeight: '700',
    width: '20%',
    marginTop: '0px',
    marginBottom: '0px',
    padding: '16px',
    flexShrink: 0,
    borderBottom: '1px solid #F3F3F3',
    '& .MuiButton-label': {
      justifyContent: 'center',
      '& .MuiSvgIcon-root': {
        marginLeft: '0',
        margin: '0',
      },
    },
  },
  icon: {
    width: '15px',
    marginLeft: '4px',
    marginRight: '4px',
  },
  addButtonIcon: {
    marginLeft: 'auto',
    fill: '#C8C8C8',
  },
  checkedUser: {
    color: theme.palette.primaryColor,
  },
  // 戻るボタン用
  button: {
    padding: '18px 16px',
    width: '100%',
    background: '#fff',
    justifyContent: 'flex-start',
    margin: '16px 0',
  },
  backIcon: {
    width: '13px',
    fill: theme.palette.primaryColor,
    marginRight: '8.6px',
  },
  searchUserItem: {
    padding: 0,
  },
  searchUserWrap: {
    width: '100%',
  },
  searchUserTitle: {
    padding: 16,
  },
  searchUserList: {
    padding: 0,
  },
  result: {
    padding: '40px 16px',
    textAlign: 'center',
    color: '#8C8C8C',
    fontSize: 15,
  },
}));

function TreeForSingleSp(props) {
  const common = commonStyles();
  const classes = useStyles();
  const {
    list,
    setUsers,
    checked,
    setChecked,
    displayDivision,
    setDisplayDivision,
    displayDivisionRoot,
    setDisplayDivisionRoot,
    searchedUserList,
    divisionPickable,
  } = props;
  // // 開閉用ローカルステート
  // const [state] = useState({});

  const getFirstList = (allList) => {
    const firstList = [];
    for (let i = 0; i < allList.length; i += 1) {
      firstList.push({
        levelCode: allList[i].levelCode,
        divisionId: allList[i].divisionId,
        groupName: allList[i].groupName,
        subName: allList[i].subName,
        users: allList[i].users,
      });
    }
    return firstList;
  };

  const selectDivision = (divisionId, backVal) => {
    const resultDivision = getTargetFunction(list, divisionId);
    let result = [];
    if (resultDivision.divisions[0].children) {
      result = resultDivision.divisions[0].children.concat(resultDivision.divisions[0].users);
    } else if (resultDivision.divisions[0].users) {
      result = resultDivision.divisions[0].users;
    }
    if (backVal) {
      const resultArray = [];
      for (let i = 0; i < displayDivisionRoot.length; i += 1) {
        if (backVal.includes(displayDivisionRoot[i].divisionId)) {
          resultArray.push(displayDivisionRoot[i]);
        }
      }
      setDisplayDivisionRoot(resultArray);
    } else {
      setDisplayDivisionRoot([...displayDivisionRoot, resultDivision.divisions[0]]);
    }
    setDisplayDivision(result);
  };

  const handleSelectUser = (item) => {
    if (checked.userId === item.userId) {
      setChecked('');
      setUsers('');
    } else {
      setChecked({ userId: item.userId });
      setUsers(item);
    }
  };

  const handleSelectDivision = (item) => {
    if (checked.divisionId === item.divisionId) {
      setChecked('');
      setUsers('');
    } else {
      setChecked({ divisionId: item.divisionId });
      setUsers(item);
    }
  };

  const getBackList = async () => {
    const hoge = [];
    for (let i = 0; displayDivisionRoot.length > i; i += 1) {
      if (hoge.includes(displayDivisionRoot[i])) {
        break;
      }
      hoge.push(displayDivisionRoot[i].divisionId);
    }
    if (hoge.length > 1) {
      const resetDivision = hoge[hoge.length - 2];
      if (hoge.includes(resetDivision)) {
        await hoge.splice(hoge.indexOf(resetDivision) + 1);
        await setDisplayDivisionRoot(hoge);
        selectDivision(hoge[hoge.length - 1], hoge);
      } else {
        selectDivision(resetDivision);
      }
    } else {
      setDisplayDivision([]);
      setDisplayDivisionRoot([]);
    }
  };

  // ユーザー用のボタン
  const userButton = (item) => (
    <Grid
      key={`u${item.userId}`}
      className={`${common.small} ${classes.li} ${classes.person}`}
    >
      <Button
        className={`${common.title3} ${classes.btn} ${
          // checked === `${item.userId}_${item.userDivisionId}` ? classes.checkedUser : null
          checked.userId === item.userId ? classes.checkedUser : null
        }`}
        id={`id-user-${item.userId}`}
        style={{ cursor: 'pointer' }}
        variant="text"
        startIcon={<Person className={classes.icon} />}
        onClick={() => {
          handleSelectUser(item);
        }}
      >
        {item.userName}
      </Button>
    </Grid>
  );
  // 組織選択用のボタン
  const diviButton = (item) => (
    <Grid className={classes.li}>
      <Button
        className={`${common.title3} ${classes.btn}`}
        onClick={() => {
          selectDivision(item.divisionId);
        }}
      >
        <Folder className={classes.icon} />
        <Typography
          className={common.title3}
        >
          {item.subName}
        </Typography>
        <Add className={classes.addButtonIcon} />
      </Button>
    </Grid>
  );

  const diviPickableButton = (item) => (
    <Grid className={classes.li}>
      <Button
        className={`${common.title3} ${classes.diviPickableButton1} ${
          checked.divisionId === item.divisionId ? classes.checkedUser : null
        }`}
        onClick={() => handleSelectDivision(item)}
      >
        <Folder className={classes.icon} />
        <Typography
          className={common.title3}
        >
          {item.subName}
        </Typography>
      </Button>
      <Button
        className={classes.diviPickableButton2}
        onClick={() => {
          selectDivision(item.divisionId);
        }}
      >
        <Add className={classes.addButtonIcon} />
      </Button>
    </Grid>
  );

  const userList = (arr) => {
    const userlist = [];
    if ('userId' in arr) {
      userlist.push(
        userButton(arr),
      );
    } else {
      arr.users.map((u) => {
        return userlist.push(
          userButton(u),
        );
      });
    }
    return userlist;
  };
  const childList = (c) => {
    const childlist = [];
    childlist.push(
      <Grid key={`divi${c.divisionId}`} className={classes.parent}>
        {divisionPickable ? diviPickableButton(c) : diviButton(c)}
      </Grid>,
    );
    return childlist;
  };
  const getListItem = (sortUserList, initFlg = false) => {
    let firstFlg = initFlg;
    let loopItems = null;
    if (displayDivision.length > 0) {
      loopItems = displayDivision;
    } else if (!sortUserList) {
      firstFlg = true;
      loopItems = getFirstList(list);
    } else {
      return <Typography className={classes.result}>0件</Typography>;
    }
    if (firstFlg) {
      return loopItems.map((data) => (
        <div key={data.subName} className={classes.parent}>
          {divisionPickable ? diviPickableButton(data) : diviButton(data)}
          {/* {'users' in data && (
            userList(data).map((item) => (
              <Grid key={uuid()}>
                {item}
              </Grid>
            ))
          )} */}
        </div>
      ));
    // 検索を実行後、表示させるリスト
    } else if (sortUserList) {
      return (
        sortUserList.map((item) => {
          const initialData = item.value; // data
          const findUser = initialData.map((o) => {
            const result = o.data.divisions[0].users.filter(
              (u) => u.userId === o.userId,
            ); // userIdが一致するか
            return result;
          });

          return (
            <Grid className={classes.searchUserItem} key={uuid()}>
              <Grid className={classes.searchUserWrap}>
                <Typography
                  className={`${common.title3} ${classes.searchUserTitle}`}
                >
                  {initialData[0].data.divisions[0].groupName}
                </Typography>
                {findUser && (
                  <Grid className={classes.searchUserList}>
                    {findUser.map((data) => (
                      userButton(data[0])
                    ))}
                  </Grid>
                )}
              </Grid>
            </Grid>
          );
        })
      );
    } else {
      const resultList = loopItems.map((data) => (
        'userId' in data ? userList(data) : childList(data)
      ));
      return resultList.map((item) => (
        <Grid key={uuid()}>
          {item}
        </Grid>
      ));
    }
  };

  return (
    <>
      {displayDivisionRoot.length > 0 && (
        // 戻るボタン
        <Button
          className={classes.button}
          onClick={getBackList}
        >
          <ArrowBackIosIcon className={classes.backIcon} />
          <Typography className={`${classes.text} ${common.title3}`}>{displayDivisionRoot.slice(-1)[0].groupName}</Typography>
        </Button>
      )}
      <Grid className={`${classes.root}`}>
        {searchedUserList ? (
        // 検索後
          getListItem(displayDivision)
        // 検索前
        ) : (
          getListItem()
        )}
      </Grid>
    </>
  );
}

export default TreeForSingleSp;
