import { makeStyles } from '@material-ui/core/styles';
import { Grid, TextField, Typography } from '@material-ui/core';
import { useEffect, useState } from 'react';
import commonStyles from '../../styles';
import commonTheme from '../../styles/theme';
import SelectProduct, {
  SELECT_PRODUCT_TYPES,
} from '../../pages/selectProduct/selectProduct';

import { classNames } from '../../../commonFunction';

const useStyles = makeStyles((theme) => ({
  select: {
    width: '100%',
    '& .MuiSelect-select': {
      fontSize: '13px',
    },
    '& .MuiSelect-icon': {
      width: '17px',
    },
  },
  wrap: {
    flexGrow: 1,
    marginLeft: '24px',
    '& .MuiInputLabel-formControl': {
      fontSize: '13px',
    },
    '& .MuiInput-underline:before': {
      borderBottom: 'none',
    },
  },
  itemWrap: {
    justifyContent: 'space-between',
    alignItems: 'center',
    background: '#fff',
    [commonTheme.breakpoints.up('md')]: {
      border: '1px #c8c8c8 solid',
      padding: '2px 8px',
      marginBottom: '8px',
    },
    [commonTheme.breakpoints.down('sm')]: {
      borderBottom: '1px solid #F3F3F3',
      width: '100%',
      padding: '16px',
    },
  },
  disable: {
    background: '#c8c8c8',
    '& .MuiFormControl-root': {
      pointerEvents: 'none',
    },
  },
  blue: {
    color: theme.palette.primaryColor,
  },
}));

function MailTextfieldButton(props) {
  const { values } = props;
  const classes = useStyles();
  const common = commonStyles();
  const [open, setOpen] = useState(false);
  const handleOpen = values.disable ? () => {} : () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [product, setProduct] = useState(values.state);

  const inputClasses = values.disable
    ? [common.wrap]
    : [common.modalBtn, classes.wrap];

  const showTypeMap = { ...SELECT_PRODUCT_TYPES };
  // ライブラリ作成時に「本命物件・案内物件無し」は不要とのこと
  // https://openhouse.backlog.jp/view/SFA_ASIAQUEST-3876#comment-181907414
  delete showTypeMap.NO_FAVORITE;
  // 上記同様に「全物件」は不要とのこと
  // https://openhouse.backlog.jp/view/SFA_ASIAQUEST-5896
  delete showTypeMap.ALL_PRODUCT;
  const showTypes = Object.values(showTypeMap);

  // 物件紹介が変更された時
  useEffect(() => {
    // console.log('product', product);
    values.setState({
      productName: product.productName,
      siteId: product.siteId,
      productType: product.productType,
      siteNumber: product.siteNumber,
    });
  }, [product, setProduct]);

  return (
    <Grid container className={classes.itemWrap} style={values.style}>
      <Typography className={common.title4}>
        {values.label}
        {values.require ? <span style={{ color: '#D83420' }}>＊</span> : null}
      </Typography>
      <TextField
        value={values.state.productName}
        onClick={handleOpen}
        className={classNames(inputClasses)}
        InputProps={{ disableUnderline: true }}
        disabled={values.disable}
      />
      <SelectProduct
        open={open}
        setOpen={setOpen}
        onClose={handleClose}
        product={product}
        setProduct={setProduct}
        showTypes={showTypes}
      />
    </Grid>
  );
}

export default MailTextfieldButton;
