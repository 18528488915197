import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import TouchAppOutlinedIcon from '@material-ui/icons/TouchAppOutlined';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import Icon from '@material-ui/core/Icon';
import commonStyles from '../../../styles';
import DirectReportStatusCode from './directReportStatusCode';
import ImportantCode from './importantCode';
import DirectTypeCode from './directTypeCode';
import DirectKindCode from './directKindCode';
import InstructionsWindow from '../../taiouRireki/instructionsWindow';
import { DIRECT_TYPE_CODE } from '../../../../constants';
import { changeConfirmMessage } from '../../../../store/eleCommon/customConfirmMessage';

const useStyles = makeStyles((theme) => ({
  informationItem: {
    background: '#fff',
    padding: 8,
    border: '1px solid #C8C8C8',
    marginTop: 8,
    transition: 'all 0.5s ease',
    '&.tab-2': {
      background: '#F3F3F3',
    },
    '&.expired': {
      background: 'rgba(216, 52, 32, 0.08)',
    },
    '&:hover': {
      background: `${theme.palette.primaryColor}29!important`,
      '& .instructionReportBtn': {
        opacity: 1,
      },
    },
  },
  top: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 8,
  },
  topLbl: {
    letterSpacing: 0,
    textAlign: 'center',
    marginRight: 8,
    padding: '5px 0 3px',
    width: 52,
    background: '#333',
    '& span': {
      display: 'block',
      fontSize: 10,
      lineHeight: 1.2,
      color: '#fff',
    },
  },
  topContent: {
    marginRight: 8,
    width: 234,
  },
  block: {
    display: 'flex',
    alignItems: 'center',
  },
  block2: {
    display: 'flex',
    alignItems: 'center',
    marginRight: 18,
  },
  box1: {
    fontSize: 13,
    lineHeight: 1.6,
    letterSpacing: 0,
    textAlign: 'left',
    marginRight: 2,
    whiteSpace: 'nowrap',
  },
  box2: {
    fontSize: 13,
    fontWeight: 700,
    lineHeight: 1.6,
    letterSpacing: 0,
    textAlign: 'left',
    marginRight: 2,
    whiteSpace: 'nowrap',
    '&.expired': {
      color: '#D83420',
    },
  },
  boxIcon: {
    fontSize: 12,
    color: '#8C8C8C',
    marginRight: 2,
  },
  box2Icon: {
    display: 'none',
    fontSize: 12,
    color: '#D83420',
    margin: '0px 0 -1px 2px',
    '&.expired': {
      display: 'inline-block',
    },
  },
  name: {
    display: 'flex',
    alignItems: 'flex-end',
  },
  name1: {
    fontSize: 15,
    fontWeight: '700',
    lineHeight: 1.6,
    letterSpacing: 0,
    textAlign: 'left',
    marginRight: 8,
  },
  name2: {
    fontSize: 13,
    lineHeight: 1.6,
    letterSpacing: 0,
    textAlign: 'left',
    marginBottom: 2,
  },
  middle: {
    border: '1px solid #C8C8C8',
    padding: '17px 8px 8px',
    marginBottom: 8,
    background: '#fff',
  },
  content: {
    width: '100%',
    marginBottom: 8,
    '& > div::before': {
      borderBottom: '1px solid #8C8C8C',
    },
    '& > div::after': {
      borderBottom: `2px solid ${theme.palette.primaryColor}`,
    },
    '& > div:hover:not(.Mui-disabled):before': {
      borderBottom: `2px solid ${theme.palette.primaryColor}`,
    },
    '& input': {
      fontFamily: 'Roboto',
      fontSize: 13,
      fontWeight: '700',
      lineHeight: '1.2',
      letterSpacing: '0',
      color: '#333',
    },
    '& > div': {
      margin: 0,
    },
  },
  instructionReportBtn: {
    color: theme.palette.primaryColor,
    background: '#fff',
    minWidth: 'auto',
    fontWeight: 700,
    fontSize: 13,
    lineHeight: 1.6,
    letterSpacing: 0,
    width: 58,
    borderRadius: '2px 4px',
    padding: '0 4px',
    marginLeft: 4,
    opacity: 0,
    transition: 'all 0.5s ease',
    '& .material-icons': {
      fontSize: 20,
      marginRight: 3,
    },
  },
  btnWrap: {
    display: 'flex',
    justifyContent: 'space-evenly',
  },
  btn1: {
    width: 81,
    padding: '3px 0',
    '& .MuiButton-label': {
      fontSize: 13,
    },
  },
  btn2: {
    width: 111,
    padding: '3px 0',
    '& .MuiButton-label': {
      fontSize: 13,
    },
  },
  item: {
    display: 'flex',
    alignItems: 'center',
  },
  itemDate: {
    fontSize: 10,
    lineHeight: 1.6,
    letterSpacing: 0,
    textAlign: 'left',
    marginRight: 8,
    flexShrink: 0,
  },
  itemName: {
    fontSize: 13,
    lineHeight: 1.6,
    letterSpacing: 0,
    textAlign: 'left',
    marginRight: 6,
    flexShrink: 0,
  },
  itemContent: {
    fontSize: 13,
    lineHeight: 1.6,
    letterSpacing: 0,
    textAlign: 'left',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  itemIcon: {
    fontSize: 14,
    color: '#8C8C8C',
    marginRight: 6,
  },
  bottom: {
    justifyContent: 'space-evenly',
  },
}));

function InstructionReportItem(props) {
  const {
    setOpen,
    information,
    tab,
    getDetailDirect,
    createTimeline,
    getDirects,
    headerReload,
  } = props;

  const classes = useStyles();
  const baseClasses = commonStyles();
  const dispatch = useDispatch();
  const currentUserId = useSelector((state) => state.responseHeader.userId);

  // モーダル開閉用のローカルステート
  const [open1, setOpen1] = useState(false);
  // モーダルを開くsetState
  const handleOpen1 = () => {
    setOpen1(true);
  };
  // モーダルを閉じるsetState
  const handleClose1 = () => {
    setOpen1(false);
  };

  // ボタン制御
  const [disabled, setDisabled] = useState(true);
  const [timelineText, setTimelineText] = useState('');

  // timeline text
  const onChange = (e) => {
    setTimelineText(e.target.value);
    if (e.target.value === '') {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  };

  /**
   * @module clickReportType - TimeLineボタンClick時のTimeLine作成API
   * @param {integer} - reportType
   */
  const clickReportType = async (reportType) => {
    const params = {
      reportCode: reportType,
      content: timelineText,
    };
    setOpen1(false);
    setOpen(false);
    await createTimeline(information.directId, params);
    headerReload && await headerReload();
  };

  const confirmOpen = (reportType) => {
    dispatch(
      changeConfirmMessage({
        title: '顧客メイン・指示',
        msgList: [`${DIRECT_TYPE_CODE[reportType]}を実行します。よろしいですか？`],
        buttons: [
          {
            label: 'CANCEL',
            set: () => {},
            classes: baseClasses.buttonsSecondary,
          },
          {
            label: 'OK',
            set: () => clickReportType(reportType),
            classes: baseClasses.buttonsPrimary,
          },
        ],
      }),
    );
  };

  /**
   * @module getButtonItems - 選択指示ウィンドウにて必要なボタンを返す
   * @param {int} type - DIRECT_TYPE_CODE参照
   * @returns {jsx} button
   */
  const getButtonItems = (reportType) => {
    return (
      <Button
        className={`${baseClasses.buttonsPrimary} ${classes.btn1} ${(disabled && reportType !== 8) ? 'disabled' : ''}`}
        onClick={() => confirmOpen(reportType)}
      >
        {DIRECT_TYPE_CODE[reportType]}
      </Button>
    );
  };

  /**
   * @module directTab
   * @returns {jsx} - 選択指示ウィンドウ - 指示ver
   */
  const directTab = () => {
    if (currentUserId === information.directUserId
      && currentUserId !== information.directedUserId) {
      // 指示タブ - 指示者の場合 かつ 被支持者ではない場合
      return (
        <Grid className={classes.btnWrap}>
          {getButtonItems(7)}
          {getButtonItems(5)}
          {getButtonItems(6)}
        </Grid>
      );
    } else {
      // 指示タブ - 被指示者の場合 または 支持者でも被支持者でもない場合
      return (
        <Grid className={classes.btnWrap}>
          {getButtonItems(2)}
          {getButtonItems(6)}
          {getButtonItems(4)}
          {getButtonItems(3)}
        </Grid>
      );
    }
  };
  /**
   * @module reportTab - 状態毎の画面ボタン切り分け
   * @returns {jsx} - 選択指示ウィンドウ - 報告ver
   */
  const reportTab = () => {
    if (currentUserId === information.directUserId) {
      // 報告タブ - 指示者の場合 または 指示者 かつ 被支持者の場合
      return (
        <Grid className={classes.btnWrap}>
          {getButtonItems(8)}
          {getButtonItems(9)}
          {getButtonItems(5)}
        </Grid>
      );
    } else {
      // 報告タブ - 被指示者の場合 または 支持者でも被支持者でもない場合
      return (
        <Grid className={classes.btnWrap}>
          {getButtonItems(10)}
        </Grid>
      );
    }
  };

  /**
   * @module getMiddle
   * @param {int} tab - 1: 指示, 2: 報告
   * @returns {jsx} tabButton - 各タブのボタン
   */
  const getMiddle = () => {
    switch (tab) {
      case 0:
        return (
          <Grid className={classes.middle}>
            <TextField
              className={classes.content}
              defaultValue={timelineText}
              name="content"
              onChange={(e) => onChange(e)}
            />
            {directTab()}
          </Grid>
        );
      case 1:
        return (
          <Grid className={classes.middle}>
            <TextField
              className={classes.content}
              defaultValue={information.content}
              name="content"
              onChange={onChange}
              // onBlur={onBlur}
            />
            {reportTab()}
          </Grid>
        );
      default:
        return false;
    }
  };

  // 期日
  const now = new Date();
  const year = now.getFullYear();
  const month = ('00' + (now.getMonth() + 1)).slice(-2);
  const day = ('00' + now.getDate()).slice(-2);
  const currentDate = year + '/' + month + '/' + day;
  const date1 = new Date(currentDate);
  const date2 = information.expireAt ? new Date(information.expireAt.substr(0, 10)) : null;

  const [expired, setExpired] = useState(false);

  useEffect(() => {
    if (tab === 0 || tab === 1) {
      if (date1 > date2) {
        setExpired(true);
      } else {
        setExpired(false);
      }
    }
  });

  return (
    <Grid
      className={`${classes.informationItem} tab-${tab} ${
        expired ? 'expired' : ''
      }`}
    >
      <Grid className={classes.top}>
        <Grid className={classes.topLbl}>
          <DirectReportStatusCode code={information.directReportStatusCode} />
        </Grid>
        <Grid className={classes.topContent}>
          <Grid className={classes.block}>
            <Typography className={classes.box1}>期日</Typography>
            <Typography
              className={`${classes.box2} ${expired ? 'expired' : ''}`}
            >
              {information.expireAt ? information.expireAt.substr(0, 16) : null}
              <Icon
                className={`${classes.box2Icon} ${expired ? 'expired' : ''}`}
              >
                local_fire_department
              </Icon>
            </Typography>
          </Grid>
          <Grid className={classes.block}>
            <Grid className={classes.block2}>
              <Typography className={classes.box1}>重要度</Typography>
              <Typography className={classes.box2}>
                <ImportantCode code={information.importantCode} />
              </Typography>
            </Grid>
            <Grid className={classes.block}>
              <Typography className={classes.box1}>
                種類：
              </Typography>
              <Typography className={classes.box2}>
                <DirectKindCode code={information.directCode} />
              </Typography>
            </Grid>
          </Grid>
          <Grid className={classes.block}>
            <TouchAppOutlinedIcon className={classes.boxIcon} />
            <Typography className={classes.box2}>
              {information.directUserLastName}{' '}
              {information.directUserFirstName}
            </Typography>
            <ArrowForwardIcon className={classes.boxIcon} />
            <Typography className={classes.box2}>
              {information.directedLastName} {information.directedFirstName}
            </Typography>
          </Grid>
        </Grid>
        <Button
          className={`${classes.instructionReportBtn} instructionReportBtn`}
          onClick={handleOpen1}
        >
          <Icon>drive_file_rename_outline</Icon>詳細
        </Button>
        <InstructionsWindow
          open={open1}
          setOpen={setOpen1}
          onClose={handleClose1}
          direct={information}
          getDetailDirect={getDetailDirect}
          getDirects={getDirects}
        />
      </Grid>
      {getMiddle()}
      <Grid className={classes.bottom}>
        {information.timelines.map((timeline) => (
          <Grid key={timeline.directTimelineId} className={classes.item}>
            <Typography className={classes.itemDate}>
              {timeline.createdAt ? timeline.createdAt.substr(0, 16) : null}
            </Typography>
            <Typography className={classes.itemName}>
              {timeline.createdUserLastName} {timeline.createdUserFirstName} [
              <DirectTypeCode code={timeline.reportCode} />]
            </Typography>
            <ArrowForwardIcon className={classes.itemIcon} />
            <Typography className={classes.itemContent}>
              {timeline.content}
            </Typography>
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
}

export default InstructionReportItem;
