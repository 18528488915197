import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  Typography,
  Button,
  TextField,
  Radio,
  RadioGroup,
  FormControl,
  FormControlLabel,
  List,
  ListItem,
} from '@material-ui/core';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import commonStyles from '../../../styles';
import commonTheme from '../../../styles/theme';
import CommonDialog from '../../modal';
import Calendar from '../../calendar';
import ListBtn from '../parts/listBtn';
import EditDialog1 from '../../../customerMain/parts/editDialog1';
import EditDialog2 from '../../../customerMain/parts/editDialog2';
import {
  ACTION_CODE,
  ACTION_DETAIL_CODE,
  ACQUISITION_CODE,
  ACTION_CODE_CHANGEABLE,
} from '../../../../constants';
import { changeConfirmMessage } from '../../../../store/eleCommon/customConfirmMessage';

const useStyles = makeStyles((theme) => ({
  content: {
    background: '#F3F3F3',
    [commonTheme.breakpoints.down('sm')]: {
      padding: '16px 0 0 0',
    },
    [commonTheme.breakpoints.up('md')]: {
      padding: 24,
    },
  },
  contentLbl: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    border: '1px solid #C8C8C8',
    padding: 8,
    marginBottom: 8,
  },
  contentLbl2: {
    display: 'flex',
    alignItems: 'center',
    fontWeight: '700',
    fontFamily: 'Roboto',
    fontSize: 13,
    lineHeight: 1.6,
    letterSpacing: 0,
    '& p': {
      fontWeight: '700',
    },
  },
  contentIpt: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    background: '#fff',
    padding: '9px 8px',
    marginBottom: 8,
    border: '1px solid #C8C8C8',
  },
  contentIpt2: {
    padding: '5px 8px 4px',
  },
  contentIpt3: {
    display: 'block',
  },
  contentIptLbl: {
    '& span': {
      color: '#D83420',
    },
  },
  contentIptVal1: {
    width: 208,
  },
  contentIptVal2: {
    width: '100%',
  },
  contentIptVal4: {
    flexGrow: 1,
    marginLeft: '1em',
  },
  contentIptDate: {
    marginTop: '-2px',
    '& div': {
      border: 'none',
      padding: 0,
    },
    '& span': {
      padding: 0,
    },
    '& label': {
      minWidth: 125,
    },
  },
  contentIptTime: {
    '& .MuiFormGroup-root': {
      justifyContent: 'flex-end',
      flexWrap: 'nowrap',
      flexDirection: 'initial',
      width: '100%',
    },
    '& .MuiButtonBase-root': {
      display: 'none',
    },
  },
  contentIptTimeLbl: {
    margin: '0 0 0 16px',
    '& .MuiTypography-root > span': {
      fontSize: 10,
      textAlign: 'left',
      fontWeight: '700',
      lineHeight: 1.2,
      letterSpacing: '0',
      color: '#8C8C8C',
    },
    '& .MuiTypography-root > span span:first-child': {
      fontSize: 18,
    },
    '& .MuiRadio-colorSecondary.Mui-checked + span span': {
      color: theme.palette.primaryColor,
    },
  },
  contentIptTxt: {
    width: '100%',
    '& > div::before': {
      [commonTheme.breakpoints.up('md')]: {
        borderBottom: '2px solid transparent',
      },
    },
    '& > div::after': {
      borderBottom: `2px solid ${theme.palette.primaryColor}`,
    },
    '& > div:hover:not(.Mui-disabled):before': {
      borderBottom: `2px solid ${theme.palette.primaryColor}`,
    },
    '& input': {
      fontFamily: 'Roboto',
      fontSize: 16,
      zoom: 0.81,
      fontWeight: 700,
      lineHeight: 1.2,
      letterSpacing: 0,
      color: '#333',
      textAlign: 'right',
      [commonTheme.breakpoints.down('sm')]: {
        textAlign: 'left',
      },
    },
    '& textarea': {
      fontFamily: 'Roboto',
      fontSize: 16,
      zoom: 0.81,
      fontWeight: 700,
      lineHeight: 1.2,
      letterSpacing: 0,
      color: '#333',
    },
    '& > div': {
      margin: 0,
    },
  },
  deleteBtn: {
    '& > span': {
      color: '#D83420',
    },
  },
  // SP
  list: {
    padding: 0,
    [commonTheme.breakpoints.up('md')]: {
      marginBottom: 16,
    },
  },
  listItem: {
    background: '#fff',
    padding: '0 0 0 16px',
  },
  listItem2: {
    background: '#fff',
    padding: '0 0 0 16px',
    '&:last-child': {
      '& .MuiButton-label': {
        display: 'block',
        '& > span': {
          display: 'block',
          '&:last-child': {
            marginTop: 8,
          },
        },
      },
    },
  },
  listItem3: {
    padding: '0 0 0 16px',
  },
  selectBtn: {
    width: '100%',
    padding: '18px 0',
    textAlign: 'left',
    borderBottom: '1px solid #F3F3F3',
    '&.select': {
      color: theme.palette.primaryColor,
    },
    '& .MuiButton-label': {
      display: 'block',
      paddingLeft: 16,
    },
  },
  lbl: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '16px 8px 16px 32px',
  },
  lblTxt: {
    display: 'flex',
    alignItems: 'center',
    fontWeight: 700,
    '& p': {
      fontWeight: 700,
    },
  },
  listBox: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '16px 8px 16px 16px',
    borderBottom: '1px solid #F3F3F3',
    width: '100%',
  },
  listLbl: {
    textTransform: 'none',
    '& span': {
      color: '#D83420',
    },
  },
  listVal: {
    textTransform: 'none',
    whiteSpace: 'pre-wrap',
    wordWrap: 'break-word',
  },
}));

export default function EditWindow(props) {
  const {
    open,
    onClose,
    row,
    updateHistory,
    deleteHistory,
    customerId,
  } = props;
  const classes = useStyles();
  const baseClasses = commonStyles();
  // isSpを取得したい場合は下記一行をコピペでOK
  const isSp = useSelector((state) => state.deviceTypeSlice.isSp);
  const dispatch = useDispatch();
  const CONTACT_MINUTES = {
    0: '5分程度',
    1: '15分程度',
    2: '30分以上',
  };

  const menus = Object.keys(CONTACT_MINUTES);

  // 留守電（固定）, 不通（固定）, 留守電（携帯）, 不通（携帯）であるか判別
  const isDisabledTimes = () => {
    return [4, 5, 132, 133].includes(row.detailCode);
  };

  // ボタン制御
  const [disabled, setDisabled] = useState(false);
  const [disabled2, setDisabled2] = useState(false);

  // モーダル用
  const [open1, setOpen1] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);
  const [open4, setOpen4] = useState(false);
  const [open5, setOpen5] = useState(false);
  const [open6, setOpen6] = useState(false);
  const [open7, setOpen7] = useState(false);
  const [open8, setOpen8] = useState(false);
  const [open9, setOpen9] = useState(false);
  const [open10, setOpen10] = useState(false);
  const handleOpen1 = () => {
    setOpen1(true);
  };
  const handleOpen2 = () => {
    if (isDisabledTimes()) {
      return;
    }
    setOpen2(true);
  };
  const handleOpen3 = () => {
    setOpen3(true);
  };
  const handleOpen4 = () => {
    setOpen4(true);
  };
  const handleOpen5 = () => {
    setOpen5(true);
  };
  const handleOpen6 = () => {
    setOpen6(true);
  };
  const handleOpen7 = () => {
    setOpen7(true);
  };
  const handleOpen8 = () => {
    setOpen8(true);
  };
  const handleOpen9 = () => {
    setOpen9(true);
  };
  const handleOpen10 = () => {
    setOpen10(true);
  };
  const handleClose1 = () => {
    setOpen1(false);
  };
  const handleClose2 = () => {
    setOpen2(false);
  };
  const handleClose3 = () => {
    setOpen3(false);
  };
  const handleClose4 = () => {
    setOpen4(false);
  };
  const handleClose5 = () => {
    setOpen5(false);
  };
  const handleClose6 = () => {
    setOpen6(false);
  };
  const handleClose7 = () => {
    setOpen7(false);
  };
  const handleClose8 = () => {
    setOpen8(false);
  };
  const handleClose9 = () => {
    setOpen9(false);
  };
  const handleClose10 = () => {
    setOpen10(false);
  };

  // 対応日時
  const initDateStr = row.startAt.replaceAll('/', '-').substr(0, 16);
  const [date, setDate] = useState({ assignAt: initDateStr });
  const [dateSp, setDateSp] = useState(initDateStr);
  const dateSetState = (value) => {
    console.log('value', value, dateSp);
    const dateStr = value.substr(0, 16);
    if (isSp) {
      setDateSp(dateStr);
    } else {
      setDate({ ...date, assignAt: dateStr });
    }
    setDisabled(value === '');
  };

  const datalist = [
    {
      id: 'id1', // String - labelとinputを紐づけます
      label: '', // String - ラベル
      require: false, // Boolean - 必須かどうか
      type: 'time', // String 'date'|'time'|'multitime'|'multiple'
      state: date, // state
      setState: dateSetState,
    },
  ];

  // 通話時間
  let timeInitial;
  let timeInitialVal;

  if (row.contactMinutes === 0) {
    timeInitial = '';
    timeInitialVal = 0;
  } else if (row.contactMinutes === 5) {
    timeInitial = 'time1';
    timeInitialVal = 0;
  } else if (row.contactMinutes === 15) {
    timeInitial = 'time2';
    timeInitialVal = 1;
  } else if (row.contactMinutes === 30) {
    timeInitial = 'time3';
    timeInitialVal = 2;
  }
  const [time, setTime] = useState(timeInitial);
  const [timeVal, setTimeVal] = useState(0);
  const handleChange = (event) => {
    if (event.target.value !== time) {
      if (event.target.value === 'time1') {
        setTimeVal(5);
      } else if (event.target.value === 'time2') {
        setTimeVal(15);
      } else {
        setTimeVal(30);
      }
    }
    setTime(event.target.value);
  };
  const [timeSelect, setTimeSelect] = useState(timeInitialVal);

  // 件名
  const [mailSubject, setMailSubject] = useState(row.mailSubject);

  // 対応内容
  const [content, setContent] = useState(row.content);

  // SP フォーカスが外れた時に値を退避
  const [eValue, setEValue] = useState('');
  const onBlur = (e) => {
    // if (isSP) {
    //   setEValue(e.target.value);
    // }
    setEValue(e.target.value);
  };

  // SP 決定押下時に値をセット
  // 対応日時
  const onDecisionDate = () => {
    setDate({ ...date, assignAt: dateSp });
  };
  // 件名
  const onDecisionMailSubject = () => {
    setMailSubject(eValue);
  };
  // 対応内容
  const onDecisionContent = () => {
    setContent(eValue);
  };

  // SP 項目選択時に更新
  const onSelect1 = (val) => {
    setTimeSelect(val);
  };

  // SP ボタン
  const getParts = (data) => {
    switch (data.type1) {
      case 'button':
        return (
          <ListBtn
            click={data.click}
            lbl={data.lbl}
            required={data.required}
            fontSize={data.fontSize}
            val={data.val}
          />
        );
      default:
        return <span style={{ display: 'none' }}>該当なし</span>;
    }
  };

  // SP モーダル
  const getEditDialog = (data) => {
    switch (data.type2) {
      case 'text':
        return (
          <EditDialog1
            classes={classes}
            open={data.opn}
            onClose={data.onClose}
            content={data.content}
            set={data.decision}
            lbl={data.lbl}
          />
        );
      case 'select':
        return (
          <EditDialog2
            classes={classes}
            open={data.opn}
            onClose={data.onClose}
            content={data.content}
            lbl={data.lbl}
          />
        );
      case 'multiSelect':
        return (
          <Grid>
            <EditDialog2
              classes={classes}
              open={data.opn1}
              onClose={data.onClose1}
              content={data.content1}
              lbl={data.lbl}
            />
            <EditDialog2
              classes={classes}
              open={data.opn2}
              onClose={data.onClose2}
              content={data.content2}
              lbl={data.lbl}
            />
          </Grid>
        );
      default:
        return (
          <span style={{ display: 'none' }}>該当なし</span>
        );
    }
  };

  useEffect(() => {
    if (row.content === '') {
      setDisabled2(true);
    }
  });

  const contentChange = (e) => {
    setContent(e.target.value);
    if (e.target.value === '') {
      switch (row.detailCode) {
        case 1:
          setDisabled2(true);
          break;
        case 3:
          setDisabled2(true);
          break;
        default:
          break;
      }
    } else {
      setDisabled2(false);
    }
  };

  const mailSubjectChange = (e) => {
    setMailSubject(e.target.value);
  };

  const getActionDetailPc = () => {
    return (
      <Grid className={classes.contentLbl}>
        <Typography
          className={baseClasses.title6}
        >
          種類・反応
        </Typography>
        <Grid className={classes.contentLbl2}>
          {ACTION_CODE[row.actionCode]}
          （
          {ACTION_DETAIL_CODE[row.detailCode]}
          ）
        </Grid>
      </Grid>
    );
  };

  const getActionDetailSp = () => {
    return (
      <Grid className={classes.lbl}>
        <Typography className={baseClasses.title6}>
          種類・反応
        </Typography>
        <Grid className={`${baseClasses.title4} ${classes.lblTxt}`}>
          {ACTION_CODE[row.actionCode]}
          （
          {ACTION_DETAIL_CODE[row.detailCode]}
          ）
        </Grid>
      </Grid>
    );
  };

  const getDate = () => {
    return (
      <Grid className={classes.contentIptDate}>
        <Calendar data={datalist[0]} />
      </Grid>
    );
  };

  const getTime = () => {
    return (
      <Grid className={classes.residency}>
        {menus.map((data) => {
          return (
            <Button
              className={`${baseClasses.title3} ${classes.selectBtn} ${(Number(timeSelect) === Number(data)) && 'select'}`}
              key={data}
              onClick={() => {
                onSelect1(data);
                handleClose2();
              }}
            >
              {CONTACT_MINUTES[data]}
            </Button>
          );
        })}
      </Grid>
    );
  };

  const getContent1Pc = () => {
    return (
      <TextField
        className={classes.contentIptTxt}
        // defaultValue={row.content}
        value={content}
        name="content"
        multiline
        rows="8"
        onChange={contentChange}
        onBlur={onBlur}
      />
    );
  };

  const getContent1Sp = () => {
    return (
      <TextField
        className={classes.contentIptTxt}
        // defaultValue={row.content}
        value={content}
        name="content"
        multiline
        onChange={contentChange}
        onBlur={onBlur}
      />
    );
  };

  const getContent2Pc = () => {
    return (
      <TextField
        className={classes.contentIptTxt}
        // defaultValue={row.content}
        value={content}
        name="content"
        multiline
        rows="8"
        onChange={contentChange}
        onBlur={onBlur}
      />
    );
  };

  const getContent2Sp = () => {
    return (
      <TextField
        className={classes.contentIptTxt}
        // defaultValue={row.content}
        value={content}
        name="content"
        multiline
        onChange={contentChange}
        onBlur={onBlur}
      />
    );
  };

  const getMailSubject = () => {
    return (
      <TextField
        className={classes.contentIptTxt}
        // defaultValue={row.mailSubject}
        value={mailSubject}
        name="mailSubject"
        onChange={mailSubjectChange}
        onBlur={onBlur}
      />
    );
  };

  const getDateArea = () => {
    if (ACTION_CODE_CHANGEABLE.includes(row.actionCode)) {
      return (
        <Grid className={classes.contentIpt}>
          <Typography
            className={`${baseClasses.title6} ${classes.contentIptLbl}`}
          >
            対応日時<span>＊</span>
          </Typography>
          <Grid className={classes.contentIptVal1}>
            {getDate()}
          </Grid>
        </Grid>
      );
    } else {
      return (
        <Grid className={classes.contentLbl}>
          <Typography
            className={baseClasses.title6}
          >
            対応日時<span>＊</span>
          </Typography>
          <Grid className={classes.contentLbl2}>
            {row.startAt}
          </Grid>
        </Grid>
      );
    }
  };

  const getlistBox = (item) => {
    return (
      <Grid
        className={classes.listBox}
      >
        <span className={`${baseClasses.title6} ${classes.listLbl}`}>
          {item.lbl}
          <span>{item.required}</span>
        </span>
        <span
          className={`${baseClasses.title4} ${classes.listVal}`}
          style={{
            fontSize: item.fontSize || '13px',
          }}
        >
          {item.val}
        </span>
      </Grid>
    );
  };

  const tel = (
    <Grid className={classes.contentBox}>
      {getActionDetailPc()}
      {getDateArea()}
      <Grid className={isDisabledTimes() ? classes.contentLbl : classes.contentIpt}>
        <Typography
          className={`${baseClasses.title6} ${classes.contentIptLbl}`}
        >
          通話時間
        </Typography>
        <Grid className={classes.contentIptVal3}>
          <FormControl className={classes.contentIptTime} component="fieldset" disabled={isDisabledTimes()}>
            <RadioGroup name="times" value={time} onChange={handleChange}>
              <FormControlLabel
                value="time1"
                control={<Radio />}
                label={<span><span>5</span><span>分程度</span></span>}
                className={classes.contentIptTimeLbl}
              />
              <FormControlLabel
                value="time2"
                control={<Radio />}
                label={<span><span>15</span><span>分程度</span></span>}
                className={classes.contentIptTimeLbl}
              />
              <FormControlLabel
                value="time3"
                control={<Radio />}
                label={<span><span>30</span><span>分以上</span></span>}
                className={classes.contentIptTimeLbl}
              />
            </RadioGroup>
          </FormControl>
        </Grid>
      </Grid>
      <Grid className={`${classes.contentIpt} ${classes.contentIpt3}`}>
        <Typography
          className={`${baseClasses.title6} ${classes.contentIptLbl}`}
        >
          対応内容<span>＊架電/受電の場合は必須</span>
        </Typography>
        <Grid className={classes.contentIptVal2}>
          {getContent1Pc()}
        </Grid>
      </Grid>
    </Grid>
  );

  const mail = (
    <Grid className={classes.contentBox}>
      {getActionDetailPc()}
      {getDateArea()}
      <Grid className={`${classes.contentIpt} ${classes.contentIpt2}`}>
        <Typography
          className={`${baseClasses.title6} ${classes.contentIptLbl}`}
        >
          件名
        </Typography>
        <Grid className={`${classes.contentIptVal4}`}>
          {getMailSubject()}
        </Grid>
      </Grid>
      <Grid className={`${classes.contentIpt} ${classes.contentIpt3}`}>
        <Typography
          className={`${baseClasses.title6} ${classes.contentIptLbl}`}
        >
          対応内容
        </Typography>
        <Grid className={classes.contentIptVal2}>
          {getContent2Pc()}
        </Grid>
      </Grid>
    </Grid>
  );

  const line = (
    <Grid className={classes.contentBox}>
      {getActionDetailPc()}
      {getDateArea()}
      <Grid className={`${classes.contentIpt} ${classes.contentIpt3}`}>
        <Typography
          className={`${baseClasses.title6} ${classes.contentIptLbl}`}
        >
          対応内容
        </Typography>
        <Grid className={classes.contentIptVal2}>
          {getContent2Pc()}
        </Grid>
      </Grid>
    </Grid>
  );

  const newReturnResale = (
    <Grid className={classes.contentBox}>
      {getActionDetailPc()}
      {getDateArea()}
      <Grid className={classes.contentLbl}>
        <Typography
          className={baseClasses.title6}
        >
          獲得者種別
        </Typography>
        <Grid className={classes.contentLbl2}>
          {ACQUISITION_CODE[row.acquisitionCode]}
        </Grid>
      </Grid>
      <Grid className={classes.contentLbl}>
        <Typography
          className={baseClasses.title6}
        >
          源泉獲得者
        </Typography>
        <Typography className={classes.contentLbl2}>
          {row.acquisitionFullName}
        </Typography>
      </Grid>
      <Grid className={`${classes.contentIpt} ${classes.contentIpt3}`}>
        <Typography
          className={`${baseClasses.title6} ${classes.contentIptLbl}`}
        >
          対応内容
        </Typography>
        <Grid className={classes.contentIptVal2}>
          {getContent2Pc()}
        </Grid>
      </Grid>
    </Grid>
  );

  const onKeep = () => {
    // console.log('保存');
    // console.log(row, timeVal, mailSubject, content);
    // const { userId } = responseHeader;
    const params = {
      actionStartAt: date.assignAt.replace(/-/g, '/').replace(/T/g, ' ') + ':00',
      actionCode: row.actionCode,
      actionDetailCode: row.detailCode,
      customerId,
      // userId,
    };
    if (timeVal) {
      params.contactMinutes = timeVal;
    }
    params.mailSubject = mailSubject || null;
    params.content = content || null;
    console.log('params', params);
    updateHistory(customerId, row.actionId, params);
    onClose();
    setDisabled(false);
    setDisabled2(false);
  };

  const onDelete = () => {
    console.log('deleteHistory', row, customerId);
    deleteHistory(customerId, row.actionId);
    onClose();
    setDisabled(false);
    setDisabled2(false);
  };

  const onCloseBg = () => {
    onClose();
    setDisabled(false);
    setDisabled2(false);
    // 初期化
    setDate({ ...date, assignAt: row.startAt.replaceAll('/', '-').substr(0, 16) });
    setTime(timeInitial);
    setContent(row.content);
    setMailSubject(row.mailSubject);
  };

  const listTel = [
    {
      type1: 'button',
      type2: 'text',
      lbl: '対応日時',
      required: '＊',
      val: date.assignAt.replace(/-/g, '/').replace(/T/g, ' '),
      click: handleOpen1,
      opn: open1,
      onClose: handleClose1,
      content: getDate,
      decision: onDecisionDate,
      disabled: false,
    },
    {
      type1: 'button',
      type2: 'select',
      lbl: '通話時間',
      click: handleOpen2,
      val: CONTACT_MINUTES[timeSelect],
      opn: open2,
      onClose: handleClose2,
      content: getTime,
      disabled: isDisabledTimes(),
    },
    {
      type1: 'button',
      type2: 'text',
      lbl: '対応内容',
      required: '＊架電/受電の場合は必須',
      click: handleOpen3,
      val: content,
      opn: open3,
      onClose: handleClose3,
      content: getContent1Sp,
      decision: onDecisionContent,
      disabled: false,
    },
  ];

  const telSp = (
    <Grid>
      {getActionDetailSp()}
      <List className={classes.list}>
        {listTel.map((item) => {
          return (
            <ListItem
              key={item.lbl}
              className={classes.listItem2}
              disabled={item.disabled}
            >
              {getParts(item)}
              {getEditDialog(item)}
            </ListItem>
          );
        })}
      </List>
    </Grid>
  );

  const listMail = [
    {
      type1: 'button',
      type2: 'text',
      lbl: '対応日時',
      required: '＊',
      val: date.assignAt.replace(/-/g, '/').replace(/T/g, ' '),
      click: handleOpen4,
      opn: open4,
      onClose: handleClose4,
      content: getDate,
      decision: onDecisionDate,
    },
    {
      type1: 'button',
      type2: 'text',
      lbl: '件名',
      click: handleOpen5,
      val: mailSubject,
      opn: open5,
      onClose: handleClose5,
      content: getMailSubject,
      decision: onDecisionMailSubject,
    },
    {
      type1: 'button',
      type2: 'text',
      lbl: '対応内容',
      click: handleOpen6,
      val: content,
      opn: open6,
      onClose: handleClose6,
      content: getContent2Sp,
      decision: onDecisionContent,
    },
  ];

  const mailSp = (
    <Grid>
      {getActionDetailSp()}
      <List className={classes.list}>
        {listMail.map((item) => {
          return (
            <ListItem
              key={item.lbl}
              className={classes.listItem2}
            >
              {getParts(item)}
              {getEditDialog(item)}
            </ListItem>
          );
        })}
      </List>
    </Grid>
  );

  const listLine = [
    {
      type1: 'button',
      type2: 'text',
      lbl: '対応日時',
      required: '＊',
      val: date.assignAt.replace(/-/g, '/').replace(/T/g, ' '),
      click: handleOpen7,
      opn: open7,
      onClose: handleClose7,
      content: getDate,
      decision: onDecisionDate,
      disabled: !ACTION_CODE_CHANGEABLE.includes(row.actionCode),
    },
    {
      type1: 'button',
      type2: 'text',
      lbl: '対応内容',
      click: handleOpen10,
      val: content,
      opn: open10,
      onClose: handleClose10,
      content: getContent2Sp,
      decision: onDecisionContent,
    },
  ];

  const lineSp = (
    <Grid>
      {getActionDetailSp()}
      <List className={classes.list}>
        {listLine.map((item) => {
          if (item.disabled) {
            return (
              <ListItem
                key={item.lbl}
                className={classes.listItem3}
              >
                {getlistBox(item)}
              </ListItem>
            );
          } else {
            return (
              <ListItem
                key={item.lbl}
                className={classes.listItem2}
              >
                {getParts(item)}
                {getEditDialog(item)}
              </ListItem>
            );
          }
        })}
      </List>
    </Grid>
  );

  const listNewReturnResale1 = [
    {
      type1: 'button',
      type2: 'text',
      lbl: '対応日時',
      required: '＊',
      val: date.assignAt.replace(/-/g, '/').replace(/T/g, ' '),
      click: handleOpen8,
      opn: open8,
      onClose: handleClose8,
      content: getDate,
      decision: onDecisionDate,
    },
  ];

  const listNewReturnResale2 = [
    {
      type1: 'button',
      type2: 'text',
      lbl: '対応内容',
      click: handleOpen9,
      val: content,
      opn: open9,
      onClose: handleClose9,
      content: getContent2Sp,
      decision: onDecisionContent,
    },
  ];

  const newReturnResaleSp = (
    <Grid>
      {getActionDetailSp()}
      <List className={classes.list}>
        {listNewReturnResale1.map((item) => {
          return (
            <ListItem
              key={item.lbl}
              className={classes.listItem3}
            >
              {getlistBox(item)}
            </ListItem>
          );
        })}
      </List>
      <Grid className={classes.lbl}>
        <Typography className={baseClasses.title6}>
          獲得者種別
        </Typography>
        <Grid className={`${baseClasses.title4} ${classes.lblTxt}`}>
          {ACQUISITION_CODE[row.acquisitionCode]}
        </Grid>
      </Grid>
      <Grid className={classes.lbl}>
        <Typography className={baseClasses.title6}>
          源泉獲得者
        </Typography>
        <Grid className={`${baseClasses.title4} ${classes.lblTxt}`}>
          {row.acquisitionFullName}
        </Grid>
      </Grid>
      <List className={classes.list}>
        {listNewReturnResale2.map((item) => {
          return (
            <ListItem
              key={item.lbl}
              className={classes.listItem2}
            >
              {getParts(item)}
              {getEditDialog(item)}
            </ListItem>
          );
        })}
      </List>
    </Grid>
  );

  const getActionPc = () => {
    switch (row.actionCode) {
      case 6:
        // 種類「電話」[GA025]
        return (
          <Grid>{tel}</Grid>
        );
      case 7:
        switch (row.detailCode) {
          case 6:
          case 7:
          case 115:
            // 種類「メール」反応「送信」[GA033]
            // 種類「メール」反応「受信」[GA033]
            // https://openhouse.backlog.jp/view/SFA_ASIAQUEST-4755
            // 種類「メール」反応「代行配信」
            return (
              <Grid>{mail}</Grid>
            );
          default:
            // 他の種類[GA034]
            return (
              <Grid>{line}</Grid>
            );
        }
      case 1:
        // 種類「新規」[GA096]
        return (
          <Grid>{newReturnResale}</Grid>
        );
      case 2:
        // 種類「再反」[GA096]
        return (
          <Grid>{newReturnResale}</Grid>
        );
      case 9:
        // 種類「戻し」[GA096]
        return (
          <Grid>{newReturnResale}</Grid>
        );
      default:
        // 他の種類[GA034]
        return (
          <Grid>{line}</Grid>
        );
    }
  };

  const getActionSp = () => {
    switch (row.actionCode) {
      case 6:
        // 種類「電話」[GA025]
        return (
          <Grid>
            {telSp}
          </Grid>
        );
      case 7:
        switch (row.detailCode) {
          // 要確認
          case 111:
            return (
              <span>署名の変更画面</span>
            );
          case 6:
          case 7:
          case 115:
            // 種類「メール」反応「送信」[GA033]
            // 種類「メール」反応「受信」[GA033]
            // https://openhouse.backlog.jp/view/SFA_ASIAQUEST-4755
            // 種類「メール」反応「代行配信」
            return (
              <Grid>{mailSp}</Grid>
            );
          default:
            // 他の種類[GA034]
            return (
              <Grid>{lineSp}</Grid>
            );
        }
      case 1:
        // 種類「新規」[GA096]
        return (
          <Grid>{newReturnResaleSp}</Grid>
        );
      case 2:
        // 種類「再反」[GA096]
        return (
          <Grid>{newReturnResaleSp}</Grid>
        );
      case 9:
        // 種類「戻し」[GA096]
        return (
          <Grid>{newReturnResaleSp}</Grid>
        );
      default:
        // 他の種類[GA034]
        return (
          <Grid>{lineSp}</Grid>
        );
    }
  };

  // 確認画面表示
  const confirmDelete = () => {
    dispatch(
      changeConfirmMessage({
        title: '選択項目を削除',
        msgList: ['対象の対応履歴を削除してもよろしいですか？'],
        buttons: [
          {
            label: 'CANCEL',
            set: () => { console.log('削除'); },
            classes: baseClasses.buttonsSecondary,
          },
          {
            label: 'OK',
            set: () => onDelete(),
            classes: baseClasses.buttonsPrimary,
          },
        ],
      }),
    );
  };

  const confirmKeep = () => {
    dispatch(
      changeConfirmMessage({
        title: '選択項目を保存',
        msgList: ['対象の対応履歴を保存してもよろしいですか？'],
        buttons: [
          {
            label: 'CANCEL',
            set: () => { console.log('保存'); },
            classes: baseClasses.buttonsSecondary,
          },
          {
            label: 'OK',
            set: () => { onKeep(); },
            classes: baseClasses.buttonsPrimary,
          },
        ],
      }),
    );
  };

  return (
    <CommonDialog
      open={open}
      onClose={onCloseBg}
      title={isSp ? '対応編集' : '対応履歴編集ウィンドウ'}
      width={445}
      buttons={[
        {
          key: 'deleteMessage',
          label: '削除',
          // onClick: onDelete,
          onClick: confirmDelete,
          type: 'secondary',
          className: classes.deleteBtn,
          icon: <DeleteOutlineOutlinedIcon />,
        },
        {
          key: 'saveMessage',
          label: '保存',
          // onClick: onKeep,
          onClick: confirmKeep,
          disabled: disabled || disabled2,
        },
      ]}
    >
      {isSp ? getActionSp() : getActionPc()}
    </CommonDialog>
  );
}
