import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Simulator from '../simulator';

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    background: '#F3F3F3',
    height: 'calc(100vh - 52px)',
    margin: '0 auto',
    '& > .MuiGrid-container': {
      position: 'fixed',
      zIndex: 9,
      top: 0,
      left: 0,
    },
  },
});

function SimulatorPage() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid className={classes.main}>
        <Simulator />
      </Grid>
    </div>
  );
}

export default SimulatorPage;
