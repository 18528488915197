// see: https://qiita.com/putan/items/8d976afab638ffb96acb
import { useSelector } from 'react-redux';
import { Typography, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import commonStyles from '../../../styles';
import commonTheme from '../../../styles/theme';
import { validateFormInt1 } from '../../../../commonFunction/validations';
import { convertFormNumber } from '../../../../commonFunction/convertors';
import { TextBaseField } from '../../../eleCommon/validation';

const useStyles = makeStyles({
  boxContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 4,
  },
  largeBox: {
    height: 81,
    backgroundColor: '#fff',
    position: 'relative',
    [commonTheme.breakpoints.down('sm')]: {
      width: '49.5%',
    },
    [commonTheme.breakpoints.up('md')]: {
      border: 'solid 1px #C8C8C8',
      width: 292,
    },
  },
  inner: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translateY(-50%) translateX(-50%)',
  },
  boxHeader: {
    textAlign: 'center',
    marginBottom: 5,
  },
  smallBox: {
    height: 81,
    backgroundColor: '#fff',
    position: 'relative',
    [commonTheme.breakpoints.down('sm')]: {
      width: '24.3%',
    },
    [commonTheme.breakpoints.up('md')]: {
      border: 'solid 1px #C8C8C8',
      width: 142.5,
    },
  },
  numericalValues: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'flex-end',
  },
  achievment: {
    '& input': {
      width: 32,
      padding: 0,
      textAlign: 'center',
      [commonTheme.breakpoints.up('md')]: {
        width: 32,
        fontSize: 24,
      },
      [commonTheme.breakpoints.down('sm')]: {
        fontSize: 24,
        fontWeight: 700,
      },
    },
    '& input[type=number]::-webkit-inner-spin-button': {
      WebkitAppearance: 'none',
      margin: '0',
    },
    '& input[type=number]::-webkit-outer-spin-button': {
      WebkitAppearance: 'none',
      margin: '0',
    },
  },
  target: {
    '& input': {
      padding: 0,
      textAlign: 'center',
      [commonTheme.breakpoints.down('sm')]: {
        width: 32,
        fontSize: 15,
      },
      [commonTheme.breakpoints.up('md')]: {
        width: 32,
        fontSize: 18,
      },
    },
    '& input[type=number]::-webkit-inner-spin-button': {
      WebkitAppearance: 'none',
      margin: '0',
    },
    '& input[type=number]::-webkit-outer-spin-button': {
      WebkitAppearance: 'none',
      margin: '0',
    },
  },
  target2: {
    '& input': {
      padding: 0,
      textAlign: 'center',
      [commonTheme.breakpoints.down('sm')]: {
        width: 60,
        fontSize: 24,
        fontWeight: 700,
      },
    },
    '& input[type=number]::-webkit-inner-spin-button': {
      WebkitAppearance: 'none',
      margin: '0',
    },
    '& input[type=number]::-webkit-outer-spin-button': {
      WebkitAppearance: 'none',
      margin: '0',
    },
  },
});

function Achievment(props) {
  const {
    editableFlag,
    actRegistFlag,
    targetObj,
    setTargetObj,
    archieveObj,
    setArchieveObj,
    values,
    setUpdateData,
    isFinished,
    isSource,
    isEdit,
    errorCallback,
  } = props;

  const classes = useStyles();
  const baseClasses = commonStyles();

  // SP・PCでの分岐用
  const isSp = useSelector((state) => state.deviceTypeSlice.isSp);
  let contents;

  // 目標をセット
  const handleChangeTargetObj = (e, key) => {
    setTargetObj({ ...targetObj, [key]: e.target.value });
  };
  // 実績をセット
  const handleChangeArchieveObj = (e, key) => {
    setArchieveObj({ ...archieveObj, [key]: e.target.value });
  };

  const handleUpdate = (k, v) => {
    setUpdateData({ ...values, [k]: Number(v) });
  };

  if (isSp) {
    contents = (
      <Grid>
        <Grid className={classes.boxContainer}>
          <Grid className={classes.largeBox}>
            <Grid className={classes.inner}>
              <Typography className={`${baseClasses.title6} ${classes.boxHeader}`}>カード総数</Typography>
              {editableFlag && isSource && !isEdit ? (
                <Grid className={classes.numericalValues}>
                  <TextBaseField
                    type="number"
                    className={classes.achievment}
                    onChange={(e) => handleChangeArchieveObj(e, 'archieveTotalCard')}
                    onBlur={() => { handleUpdate('resultEnqueteTotal', archieveObj.archieveTotalCard); }}
                    defaultValue={editableFlag ? values.resultEnqueteTotal : ''}
                    disabled={!editableFlag || isFinished}
                    key="archieveTotalCard"
                    validator={validateFormInt1({
                      minFloat: 0.0,
                      maxFloat: 999.0,
                    })}
                    convertor={convertFormNumber}
                    errorCallback={errorCallback('resultEnqueteTotal')}
                  />
                  <span>/</span>
                  <TextBaseField
                    type="number"
                    className={classes.target}
                    onChange={(e) => handleChangeTargetObj(e, 'targetTotalCard')}
                    onBlur={() => { handleUpdate('targetEnqueteTotal', targetObj.targetTotalCard); }}
                    defaultValue={actRegistFlag ? '' : values.targetEnqueteTotal}
                    disabled={!actRegistFlag}
                    key="targetTotalCardSource"
                    validator={validateFormInt1({
                      minFloat: 0.0,
                      maxFloat: 999.0,
                    })}
                    convertor={convertFormNumber}
                    errorCallback={errorCallback('targetEnqueteTotal')}
                  />
                </Grid>
              ) : (
                <Grid className={classes.numericalValues}>
                  <TextBaseField
                    type="number"
                    className={classes.target2}
                    onChange={(e) => handleChangeTargetObj(e, 'targetTotalCard')}
                    onBlur={() => { handleUpdate('targetEnqueteTotal', targetObj.targetTotalCard); }}
                    defaultValue={actRegistFlag ? '' : values.targetEnqueteTotal}
                    disabled={(!actRegistFlag && !isEdit) || isSource}
                    key="targetTotalCard"
                    validator={validateFormInt1({
                      minFloat: 0.0,
                      maxFloat: 999.0,
                    })}
                    convertor={convertFormNumber}
                    errorCallback={errorCallback('targetEnqueteTotal')}
                  />
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid className={classes.largeBox}>
            <Grid className={classes.inner}>
              <Typography className={`${baseClasses.title6} ${classes.boxHeader}`}>内、探しカード</Typography>
              {editableFlag && isSource && !isEdit ? (
                <Grid className={classes.numericalValues}>
                  <TextBaseField
                    type="number"
                    className={classes.achievment}
                    onChange={(e) => handleChangeArchieveObj(e, 'archieveSearchCard')}
                    onBlur={() => { handleUpdate('resultSearchHouseEnquete', archieveObj.archieveSearchCard); }}
                    defaultValue={editableFlag ? values.resultSearchHouseEnquete : ''}
                    disabled={!editableFlag || isFinished}
                    key="archieveSearchCard"
                    validator={validateFormInt1({
                      minFloat: 0.0,
                      maxFloat: 999.0,
                    })}
                    convertor={convertFormNumber}
                    errorCallback={errorCallback('resultSearchHouseEnquete')}
                  />
                  <span>/</span>
                  <TextBaseField
                    type="number"
                    className={classes.target}
                    onChange={(e) => handleChangeTargetObj(e, 'targetSearchCard')}
                    onBlur={() => { handleUpdate('targetSearchHouseEnquete', targetObj.targetSearchCard); }}
                    defaultValue={actRegistFlag ? '' : values.targetSearchHouseEnquete}
                    disabled={editableFlag}
                    key="targetSearchCardSource"
                    validator={validateFormInt1({
                      minFloat: 0.0,
                      maxFloat: 999.0,
                    })}
                    convertor={convertFormNumber}
                    errorCallback={errorCallback('targetSearchHouseEnquete')}
                  />
                </Grid>
              ) : (
                <Grid className={classes.numericalValues}>
                  <TextBaseField
                    type="number"
                    className={classes.target2}
                    onChange={(e) => handleChangeTargetObj(e, 'targetSearchCard')}
                    onBlur={() => { handleUpdate('targetSearchHouseEnquete', targetObj.targetSearchCard); }}
                    defaultValue={actRegistFlag ? '' : values.targetSearchHouseEnquete}
                    disabled={(!actRegistFlag && !isEdit) || isSource}
                    key="targetSearchCard"
                    validator={validateFormInt1({
                      minFloat: 0.0,
                      maxFloat: 999.0,
                    })}
                    convertor={convertFormNumber}
                    errorCallback={errorCallback('targetSearchHouseEnquete')}
                  />
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid className={classes.boxContainer}>
          <Grid className={classes.smallBox}>
            <Grid className={classes.inner}>
              <Typography className={`${baseClasses.title6} ${classes.boxHeader}`}>TELアポ</Typography>
              {editableFlag && isSource && !isEdit ? (
                <Grid className={classes.numericalValues}>
                  <TextBaseField
                    type="number"
                    className={classes.achievment}
                    onChange={(e) => handleChangeArchieveObj(e, 'archieveTelAppoint')}
                    onBlur={() => { handleUpdate('resultTelephoneAppointment', archieveObj.archieveTelAppoint); }}
                    defaultValue={editableFlag ? values.resultTelephoneAppointment : ''}
                    disabled={!editableFlag || isFinished}
                    key="archieveTelAppoint"
                    validator={validateFormInt1({
                      minFloat: 0.0,
                      maxFloat: 999.0,
                    })}
                    convertor={convertFormNumber}
                    errorCallback={errorCallback('resultTelephoneAppointment')}
                  />
                  <span>/</span>
                  <TextBaseField
                    type="number"
                    className={classes.target}
                    onChange={(e) => handleChangeTargetObj(e, 'targetTelAppoint')}
                    onBlur={() => { handleUpdate('targetTelephoneAppointment', targetObj.targetTelAppoint); }}
                    defaultValue={actRegistFlag ? '' : values.targetTelephoneAppointment}
                    disabled={!actRegistFlag}
                    key="targetTelAppointSource"
                    validator={validateFormInt1({
                      minFloat: 0.0,
                      maxFloat: 999.0,
                    })}
                    convertor={convertFormNumber}
                    errorCallback={errorCallback('targetTelephoneAppointment')}
                  />
                </Grid>
              ) : (
                <Grid className={classes.numericalValues}>
                  <TextBaseField
                    type="number"
                    className={classes.target2}
                    onChange={(e) => handleChangeTargetObj(e, 'targetTelAppoint')}
                    onBlur={() => { handleUpdate('targetTelephoneAppointment', targetObj.targetTelAppoint); }}
                    defaultValue={actRegistFlag ? '' : values.targetTelephoneAppointment}
                    disabled={(!actRegistFlag && !isEdit) || isSource}
                    key="targetTelAppoint"
                    validator={validateFormInt1({
                      minFloat: 0.0,
                      maxFloat: 999.0,
                    })}
                    convertor={convertFormNumber}
                    errorCallback={errorCallback('targetTelephoneAppointment')}
                  />
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid className={classes.smallBox}>
            <Grid className={classes.inner}>
              <Typography className={`${baseClasses.title6} ${classes.boxHeader}`}>案内</Typography>
              {editableFlag && isSource && !isEdit ? (
                <Grid className={classes.numericalValues}>
                  <TextBaseField
                    type="number"
                    className={classes.achievment}
                    onChange={(e) => handleChangeArchieveObj(e, 'archieveGuidance')}
                    onBlur={() => { handleUpdate('resultGuidanceCount', archieveObj.archieveGuidance); }}
                    defaultValue={editableFlag ? values.resultGuidanceCount : ''}
                    disabled={!editableFlag || isFinished}
                    key="archieveGuidance"
                    validator={validateFormInt1({
                      minFloat: 0.0,
                      maxFloat: 999.0,
                    })}
                    convertor={convertFormNumber}
                    errorCallback={errorCallback('resultGuidanceCount')}
                  />
                  <span>/</span>
                  <TextBaseField
                    type="number"
                    className={classes.target}
                    onChange={(e) => handleChangeTargetObj(e, 'targetGuidance')}
                    onBlur={() => { handleUpdate('targetGuidanceCount', targetObj.targetGuidance); }}
                    defaultValue={actRegistFlag ? '' : values.targetGuidanceCount}
                    disabled={!actRegistFlag}
                    key="targetGuidanceSource"
                    validator={validateFormInt1({
                      minFloat: 0.0,
                      maxFloat: 999.0,
                    })}
                    convertor={convertFormNumber}
                    errorCallback={errorCallback('targetGuidanceCount')}
                  />
                </Grid>
              ) : (
                <Grid className={classes.numericalValues}>
                  <TextBaseField
                    type="number"
                    className={classes.target2}
                    onChange={(e) => handleChangeTargetObj(e, 'targetGuidance')}
                    onBlur={() => { handleUpdate('targetGuidanceCount', targetObj.targetGuidance); }}
                    defaultValue={actRegistFlag ? '' : values.targetGuidanceCount}
                    disabled={(!actRegistFlag && !isEdit) || isSource}
                    key="targetGuidance"
                    validator={validateFormInt1({
                      minFloat: 0.0,
                      maxFloat: 999.0,
                    })}
                    convertor={convertFormNumber}
                    errorCallback={errorCallback('targetGuidanceCount')}
                  />
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid className={classes.smallBox}>
            <Grid className={classes.inner}>
              <Typography className={`${baseClasses.title6} ${classes.boxHeader}`}>即案</Typography>
              {editableFlag && isSource && !isEdit ? (
                <Grid className={classes.numericalValues}>
                  <TextBaseField
                    type="number"
                    className={classes.achievment}
                    onChange={(e) => handleChangeArchieveObj(e, 'archieveImmediateGuidance')}
                    onBlur={() => { handleUpdate('resultPromptPlanCount', archieveObj.archieveImmediateGuidance); }}
                    defaultValue={editableFlag ? values.resultPromptPlanCount : ''}
                    disabled={!editableFlag || isFinished}
                    key="archieveImmediateGuidance"
                    validator={validateFormInt1({
                      minFloat: 0.0,
                      maxFloat: 999.0,
                    })}
                    convertor={convertFormNumber}
                    errorCallback={errorCallback('resultPromptPlanCount')}
                  />
                  <span>/</span>
                  <TextBaseField
                    type="number"
                    className={classes.target}
                    onChange={(e) => handleChangeTargetObj(e, 'targetImmediateGuidance')}
                    onBlur={() => { handleUpdate('targetPromptPlanCount', targetObj.targetImmediateGuidance); }}
                    defaultValue={actRegistFlag ? '' : values.targetPromptPlanCount}
                    disabled={!actRegistFlag}
                    key="targetImmediateGuidanceSource"
                    validator={validateFormInt1({
                      minFloat: 0.0,
                      maxFloat: 999.0,
                    })}
                    convertor={convertFormNumber}
                    errorCallback={errorCallback('targetPromptPlanCount')}
                  />
                </Grid>
              ) : (
                <Grid className={classes.numericalValues}>
                  <TextBaseField
                    type="number"
                    className={classes.target2}
                    onChange={(e) => handleChangeTargetObj(e, 'targetImmediateGuidance')}
                    onBlur={() => { handleUpdate('targetPromptPlanCount', targetObj.targetImmediateGuidance); }}
                    defaultValue={actRegistFlag ? '' : values.targetPromptPlanCount}
                    disabled={(!actRegistFlag && !isEdit) || isSource}
                    key="targetImmediateGuidance"
                    validator={validateFormInt1({
                      minFloat: 0.0,
                      maxFloat: 999.0,
                    })}
                    convertor={convertFormNumber}
                    errorCallback={errorCallback('targetPromptPlanCount')}
                  />
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid className={classes.smallBox}>
            <Grid className={classes.inner}>
              <Typography className={`${baseClasses.title6} ${classes.boxHeader}`}>来社</Typography>
              {editableFlag && isSource && !isEdit ? (
                <Grid className={classes.numericalValues}>
                  <TextBaseField
                    type="number"
                    className={classes.achievment}
                    onChange={(e) => handleChangeArchieveObj(e, 'archieveVisit')}
                    onBlur={() => { handleUpdate('resultVisitCount', archieveObj.archieveVisit); }}
                    defaultValue={editableFlag ? values.resultVisitCount : ''}
                    disabled={!editableFlag || isFinished}
                    key="archieveVisit"
                    validator={validateFormInt1({
                      minFloat: 0.0,
                      maxFloat: 999.0,
                    })}
                    convertor={convertFormNumber}
                    errorCallback={errorCallback('resultVisitCount')}
                  />
                  <span>/</span>
                  <TextBaseField
                    type="number"
                    className={classes.target}
                    onChange={(e) => handleChangeTargetObj(e, 'targetVisit')}
                    onBlur={() => { handleUpdate('targetVisitCount', targetObj.targetVisit); }}
                    defaultValue={actRegistFlag ? '' : values.targetVisitCount}
                    disabled={!actRegistFlag}
                    key="targetVisitSource"
                    validator={validateFormInt1({
                      minFloat: 0.0,
                      maxFloat: 999.0,
                    })}
                    convertor={convertFormNumber}
                    errorCallback={errorCallback('targetVisitCount')}
                  />
                </Grid>
              ) : (
                <Grid className={classes.numericalValues}>
                  <TextBaseField
                    type="number"
                    className={classes.target2}
                    onChange={(e) => handleChangeTargetObj(e, 'targetVisit')}
                    onBlur={() => { handleUpdate('targetVisitCount', targetObj.targetVisit); }}
                    defaultValue={actRegistFlag ? '' : values.targetVisitCount}
                    disabled={(!actRegistFlag && !isEdit) || isSource}
                    key="targetVisit"
                    validator={validateFormInt1({
                      minFloat: 0.0,
                      maxFloat: 999.0,
                    })}
                    convertor={convertFormNumber}
                    errorCallback={errorCallback('targetVisitCount')}
                  />
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  } else {
    contents = (
      <Grid>
        <Grid className={classes.boxContainer}>
          <Grid className={classes.largeBox}>
            <Grid className={classes.inner}>
              <Typography className={`${baseClasses.title6} ${classes.boxHeader}`}>カード総数</Typography>
              <Grid className={classes.numericalValues}>
                {!actRegistFlag && (
                  <>
                    <TextBaseField
                      type="number"
                      className={classes.achievment}
                      onChange={(e) => handleChangeArchieveObj(e, 'archieveTotalCard')}
                      onBlur={() => { handleUpdate('resultEnqueteTotal', archieveObj.archieveTotalCard); }}
                      defaultValue={editableFlag ? values.resultEnqueteTotal : ''}
                      disabled={isEdit || isFinished}
                      validator={validateFormInt1({
                        minFloat: 0.0,
                        maxFloat: 999.0,
                      })}
                      convertor={convertFormNumber}
                      errorCallback={errorCallback('resultEnqueteTotal')}
                    />
                    <span>/</span>
                  </>
                )}
                <TextBaseField
                  type="number"
                  className={classes.target}
                  onChange={(e) => handleChangeTargetObj(e, 'targetTotalCard')}
                  onBlur={() => { handleUpdate('targetEnqueteTotal', targetObj.targetTotalCard); }}
                  defaultValue={actRegistFlag ? '' : values.targetEnqueteTotal}
                  disabled={!actRegistFlag}
                  validator={validateFormInt1({
                    minFloat: 0.0,
                    maxFloat: 999.0,
                  })}
                  convertor={convertFormNumber}
                  errorCallback={errorCallback('targetEnqueteTotal')}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid className={classes.largeBox}>
            <Grid className={classes.inner}>
              <Typography className={`${baseClasses.title6} ${classes.boxHeader}`}>内、探しカード</Typography>
              <Grid className={classes.numericalValues}>
                {!actRegistFlag && (
                  <>
                    <TextBaseField
                      type="number"
                      className={classes.achievment}
                      onChange={(e) => handleChangeArchieveObj(e, 'archieveSearchCard')}
                      onBlur={() => { handleUpdate('resultSearchHouseEnquete', archieveObj.archieveSearchCard); }}
                      defaultValue={editableFlag ? values.resultSearchHouseEnquete : ''}
                      disabled={isEdit || isFinished}
                      validator={validateFormInt1({
                        minFloat: 0.0,
                        maxFloat: 999.0,
                      })}
                      convertor={convertFormNumber}
                      errorCallback={errorCallback('resultSearchHouseEnquete')}
                    />
                    <span>/</span>
                  </>
                )}
                <TextBaseField
                  type="number"
                  className={classes.target}
                  onChange={(e) => handleChangeTargetObj(e, 'targetSearchCard')}
                  onBlur={() => { handleUpdate('targetSearchHouseEnquete', targetObj.targetSearchCard); }}
                  defaultValue={actRegistFlag ? '' : values.targetSearchHouseEnquete}
                  disabled={editableFlag}
                  validator={validateFormInt1({
                    minFloat: 0.0,
                    maxFloat: 999.0,
                  })}
                  convertor={convertFormNumber}
                  errorCallback={errorCallback('targetSearchHouseEnquete')}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid className={classes.boxContainer}>
          <Grid className={classes.smallBox}>
            <Grid className={classes.inner}>
              <Typography className={`${baseClasses.title6} ${classes.boxHeader}`}>TELアポ</Typography>
              <Grid className={classes.numericalValues}>
                {!actRegistFlag && (
                  <>
                    <TextBaseField
                      type="number"
                      className={classes.achievment}
                      onChange={(e) => handleChangeArchieveObj(e, 'archieveTelAppoint')}
                      onBlur={() => { handleUpdate('resultTelephoneAppointment', archieveObj.archieveTelAppoint); }}
                      defaultValue={editableFlag ? values.resultTelephoneAppointment : ''}
                      disabled={isEdit || isFinished}
                      validator={validateFormInt1({
                        minFloat: 0.0,
                        maxFloat: 999.0,
                      })}
                      convertor={convertFormNumber}
                      errorCallback={errorCallback('resultTelephoneAppointment')}
                    />
                    <span>/</span>
                  </>
                )}
                <TextBaseField
                  type="number"
                  className={classes.target}
                  onChange={(e) => handleChangeTargetObj(e, 'targetTelAppoint')}
                  onBlur={() => { handleUpdate('targetTelephoneAppointment', targetObj.targetTelAppoint); }}
                  defaultValue={actRegistFlag ? '' : values.targetTelephoneAppointment}
                  disabled={!actRegistFlag}
                  validator={validateFormInt1({
                    minFloat: 0.0,
                    maxFloat: 999.0,
                  })}
                  convertor={convertFormNumber}
                  errorCallback={errorCallback('targetTelephoneAppointment')}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid className={classes.smallBox}>
            <Grid className={classes.inner}>
              <Typography className={`${baseClasses.title6} ${classes.boxHeader}`}>案内</Typography>
              <Grid className={classes.numericalValues}>
                {!actRegistFlag && (
                  <>
                    <TextBaseField
                      type="number"
                      className={classes.achievment}
                      onChange={(e) => handleChangeArchieveObj(e, 'archieveGuidance')}
                      onBlur={() => { handleUpdate('resultGuidanceCount', archieveObj.archieveGuidance); }}
                      defaultValue={editableFlag ? values.resultGuidanceCount : ''}
                      disabled={isEdit || isFinished}
                      validator={validateFormInt1({
                        minFloat: 0.0,
                        maxFloat: 999.0,
                      })}
                      convertor={convertFormNumber}
                      errorCallback={errorCallback('resultGuidanceCount')}
                    />
                    <span>/</span>
                  </>
                )}
                <TextBaseField
                  type="number"
                  className={classes.target}
                  onChange={(e) => handleChangeTargetObj(e, 'targetGuidance')}
                  onBlur={() => { handleUpdate('targetGuidanceCount', targetObj.targetGuidance); }}
                  defaultValue={actRegistFlag ? '' : values.targetGuidanceCount}
                  disabled={!actRegistFlag}
                  validator={validateFormInt1({
                    minFloat: 0.0,
                    maxFloat: 999.0,
                  })}
                  convertor={convertFormNumber}
                  errorCallback={errorCallback('targetGuidanceCount')}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid className={classes.smallBox}>
            <Grid className={classes.inner}>
              <Typography className={`${baseClasses.title6} ${classes.boxHeader}`}>即案</Typography>
              <Grid className={classes.numericalValues}>
                {!actRegistFlag && (
                  <>
                    <TextBaseField
                      type="number"
                      className={classes.achievment}
                      onChange={(e) => handleChangeArchieveObj(e, 'archieveImmediateGuidance')}
                      onBlur={() => { handleUpdate('resultPromptPlanCount', archieveObj.archieveImmediateGuidance); }}
                      defaultValue={editableFlag ? values.resultPromptPlanCount : ''}
                      disabled={isEdit || isFinished}
                      validator={validateFormInt1({
                        minFloat: 0.0,
                        maxFloat: 999.0,
                      })}
                      convertor={convertFormNumber}
                      errorCallback={errorCallback('resultPromptPlanCount')}
                    />
                    <span>/</span>
                  </>
                )}
                <TextBaseField
                  type="number"
                  className={classes.target}
                  onChange={(e) => handleChangeTargetObj(e, 'targetImmediateGuidance')}
                  onBlur={() => { handleUpdate('targetPromptPlanCount', targetObj.targetImmediateGuidance); }}
                  defaultValue={actRegistFlag ? '' : values.targetPromptPlanCount}
                  disabled={!actRegistFlag}
                  validator={validateFormInt1({
                    minFloat: 0.0,
                    maxFloat: 999.0,
                  })}
                  convertor={convertFormNumber}
                  errorCallback={errorCallback('targetPromptPlanCount')}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid className={classes.smallBox}>
            <Grid className={classes.inner}>
              <Typography className={`${baseClasses.title6} ${classes.boxHeader}`}>来社</Typography>
              <Grid className={classes.numericalValues}>
                {!actRegistFlag && (
                  <>
                    <TextBaseField
                      type="number"
                      className={classes.achievment}
                      onChange={(e) => handleChangeArchieveObj(e, 'archieveVisit')}
                      onBlur={() => { handleUpdate('resultVisitCount', archieveObj.archieveVisit); }}
                      defaultValue={editableFlag ? values.resultVisitCount : ''}
                      disabled={isEdit || isFinished}
                      validator={validateFormInt1({
                        minFloat: 0.0,
                        maxFloat: 999.0,
                      })}
                      convertor={convertFormNumber}
                      errorCallback={errorCallback('resultVisitCount')}
                    />
                    <span>/</span>
                  </>
                )}
                <TextBaseField
                  type="number"
                  className={classes.target}
                  onChange={(e) => handleChangeTargetObj(e, 'targetVisit')}
                  onBlur={() => { handleUpdate('targetVisitCount', targetObj.targetVisit); }}
                  defaultValue={actRegistFlag ? '' : values.targetVisitCount}
                  disabled={!actRegistFlag}
                  validator={validateFormInt1({
                    minFloat: 0.0,
                    maxFloat: 999.0,
                  })}
                  convertor={convertFormNumber}
                  errorCallback={errorCallback('targetVisitCount')}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid>
      {contents}
    </Grid>
  );
}

export default Achievment;
