/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { v4 as uuid } from 'uuid';
import ProductElements from './productElements';
import CommonDialog from '../../common/modal';

/**
 * @module selectProductMulti
 * @param {obj} props
 * @param {bool} props.open - 開閉ステート：必須
 * @param {function} props.onClose - 複数物件選択モーダルを閉じるfunction：必須
 * @param {obj} props.products - 複数物件が入ったstate；必須
 * @param {func} props.setProducts - 複数物件のsetState：必須
 * @returns
 */
function SelectProductMulti(props) {
  const {
    open,
    onClose,
    products,
    setProducts,
  } = props;
  const [items, setItems] = useState([{ id: uuid(),
    productType: '',
    productName: '',
    memo: '' }]);
  const isDisabled = items.some(i => i.productType === '' || !i.productType);
  const isDisabled2 = items.some(i => i.productType === '' || !i.productType) || items.length < 1;
  // isSpを取得したい場合は下記一行をコピペでOK
  const isSp = useSelector((state) => state.deviceTypeSlice.isSp);

  const onCloseBg = () => {
    onClose();
    setItems([{ id: uuid() }]);
  };

  // 選択処理
  const handleSelect = () => {
    setProducts(items);
    onClose();
  };

  useEffect(() => {
    // 初期ロード時にproductsに値があれば、itemsに値を入れる（物件を表示）
    if (products && products.length > 0) {
      setItems(products.map((p) => {
        return {
          ...p,
          productType: p.productType, // https://openhouse.backlog.jp/view/OH_KDSFA-375 にてproductTypeを送るよう変更
          productName: p.productName,
          siteId: p.siteId,
          memo: p.memo,
        };
      }));
    }
  }, []);

  return (
    <CommonDialog
      open={open}
      onClose={onCloseBg}
      title={isSp ? '物件選択' : '複数物件選択ウインドウ'}
      maxWidth={1024}
      buttons={{
        label: '選択',
        onClick: handleSelect,
        disabled: isDisabled2,
      }}
    >
      <ProductElements
        items={items}
        setItems={setItems}
        isDisabled={isDisabled}
      />
    </CommonDialog>
  );
}

export default SelectProductMulti;
