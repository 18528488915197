import { useSelector } from 'react-redux';
import { Grid, makeStyles } from '@material-ui/core';
import SelectRailway from './selectRailway';
import SelectStations from './selectStations';
import CommonDialog from '../../common/modal';
import commonTheme from '../../styles/theme';
import { useLoading } from '../../../hooks';

const useStyles = makeStyles({
  item: {
    [commonTheme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  content: {
    [commonTheme.breakpoints.down('sm')]: {
      paddingBottom: 0,
    },
  },
});

export default function SelectRailwaysAndStations(props) {
  const {
    open,
    onClose,
    openedStations,
    openStations,
    setOpenedStations,
    setOpenStations,
    openFromStation,
    setOpenFromStation,
    openToStation,
    setOpenToStation,
    selectedItems,
    railways,
    stations,
    showOnlyFromStation,
    clickAreaTabFunc,
    clickRailwayFunc,
    clickStationsFunc,
    clickSelectFunc,
    clickDeselectionFunc,
    isNearestStation,
  } = props;
  const { hasLoading } = useLoading();
  const classes = useStyles();
  const isSp = useSelector((state) => state.deviceTypeSlice.isSp);

  return (
    <CommonDialog
      open={open}
      onClose={onClose}
      title={isSp ? '沿線選択' : '沿線/区間ウィンドウ'}
      maxWidth={1024}
      bodyClassName={classes.content}
      buttons={[
        {
          label: isSp ? '全クリア' : '選択解除',
          onClick: clickDeselectionFunc,
          type: 'secondary',
          disabled: hasLoading('RailwayArea') || hasLoading('RailwayStation'),
        },
        {
          label: isSp ? 'OK' : '選択',
          onClick: clickSelectFunc,
          disabled: hasLoading('RailwayArea') || hasLoading('RailwayStation'),
        },
      ]}
    >
      <Grid container>
        <Grid className={classes.item} item md={4}>
          <SelectRailway
            onClose={onClose}
            openedStations={openedStations}
            setOpenedStations={setOpenedStations}
            setOpenStations={setOpenStations}
            selectedItems={selectedItems}
            railways={railways}
            clickAreaTabFunc={clickAreaTabFunc}
            clickRailwayFunc={clickRailwayFunc}
          />
        </Grid>
        {!isSp ? (
          <Grid item md={8}>
            <SelectStations
              selectedItems={selectedItems}
              stations={stations}
              showOnlyFromStation={showOnlyFromStation}
              clickStationsFunc={clickStationsFunc}
              setOpenStations={setOpenStations}
              onClose={onClose}
              openFromStation={openFromStation}
              setOpenFromStation={setOpenFromStation}
              openToStation={openToStation}
              setOpenToStation={setOpenToStation}
              isNearestStation={isNearestStation}
            />
          </Grid>
        ) : (
          openStations && (
            <CommonDialog
              open={openStations}
              onClose={() => { setOpenStations(false); }}
              title={isNearestStation ? '最寄り駅選択' : '区間選択'}
              bodyClassName={classes.content}
              width={660}
              buttons={[
                {
                  label: '全クリア',
                  onClick: clickDeselectionFunc,
                  type: 'secondary',
                  disabled: hasLoading('RailwayArea') || hasLoading('RailwayStation'),
                },
                {
                  label: 'OK',
                  onClick: clickSelectFunc,
                  disabled: hasLoading('RailwayArea') || hasLoading('RailwayStation'),
                },
              ]}
            >
              <SelectStations
                selectedItems={selectedItems}
                stations={stations}
                showOnlyFromStation={showOnlyFromStation}
                clickStationsFunc={clickStationsFunc}
                setOpenStations={setOpenStations}
                onClose={onClose}
                openFromStation={openFromStation}
                setOpenFromStation={setOpenFromStation}
                openToStation={openToStation}
                setOpenToStation={setOpenToStation}
              />
            </CommonDialog>
          )
        )}
      </Grid>
    </CommonDialog>
  );
}
