/* eslint-disable max-len */
import React, { useState } from 'react';
import Calendar from '../common/calendar';

// 下記一旦テスト用
function CalendarTest() {
  const [time, setTime] = useState({ assignAt: '2021-01-01T00:00:00' });
  const timeOnchange = (value) => {
    setTime({ ...time, assignAt: value });
  };
  const datalist = [
    {
      id: 'id3', // ※IDが同じでも独立して動きますが、コンソールエラーが出るのでユニーク推奨
      label: '日時',
      require: false,
      type: 'time',
      state: time,
      setState: timeOnchange,
    },
  ];

  return (
    <div>
      <span>Test</span>
      {datalist.map(array => {
        return (
          <div key={array.id}>
            <Calendar data={array} />
          </div>
        );
      })}
      <p>親コンポーネント側state</p>
      <p>time:{time.assignAt}</p>
    </div>
  );
}

export default CalendarTest;
