import React, { useState } from 'react';
import {
  Grid,
  Typography,
  Link,
  Tooltip,
  TableCell,
} from '@material-ui/core';
import Rating from '@material-ui/lab/Rating';
import Icon from '@material-ui/core/Icon';
import { makeStyles } from '@material-ui/core/styles';
import commonStyles from '../../styles';
import MemberStatusCode from '../../eleCommon/memberStatusCode';
import { classNames } from '../../../commonFunction';
// eslint-disable-next-line import/no-cycle
import { URL_MAPPER } from '../../../constants/urls';
import { GUIDANCE_STATUS_CODE } from '../../../constants';

const useStyles = makeStyles((theme) => ({
  bodyCustomerContainer: {
    display: 'flex',
    alignItems: 'center',
    '& .material-icons': {
      color: theme.palette.primaryColor,
      fontSize: 16,
      marginRight: 5,
    },
  },
  bodyCustomerNameTxt: {
    color: theme.palette.primaryColor,
    fontSize: 13,
    display: 'flex',
    gap: 3,
  },
  ratingIcon: {
    margin: '0 10px',
    '& span': {
      fontSize: 18,
      marginRight: 0,
    },
  },
  memberStatus: {
    display: 'flex',
    alignItems: 'center',
    '& > div': { marginRight: 30 },
    '& > a': {
      display: 'inline-flex',
      alignItems: 'center',
      gap: 1,
      color: theme.palette.primaryColor,
      marginRight: 30,
    },
    '& svg': {
      width: 18.33,
      height: 18.33,
    },
  },
}));

function Customer(props) {
  const {
    id,
    fullName = '',
    rating,
    // Web会員状態コード/会員ステータスコード
    status,
    children,
  } = props;

  const classes = useStyles();
  const baseClasses = commonStyles();

  const [open, setOpen] = useState(false);

  const onOpen = () => {
    const targets = document.getElementsByClassName('dashbordScrollArea');
    for (let i = 0; i < targets.length; i += 1) {
      const el = targets[i];
      el.addEventListener('scroll', () => { setOpen(false); }, { once: true });
    }
  };

  return (
    <>
      <TableCell style={{ width: '70px' }}>
        <Link href={`${URL_MAPPER.customer}?customerId=${id}`} target="_blank" className={classes.bodyCustomerContainer}>
          <Icon className="material-icons-outlined">summarize</Icon>
          <Tooltip title={fullName} onOpen={onOpen} open={open}>
            <Typography
              className={`${classes.bodyCustomerNameTxt} ${baseClasses.smallStrong} nowrap`}
              onMouseOver={() => { return setOpen(true); }}
              onMouseLeave={() => { return setOpen(false); }}
            >
              {fullName}
            </Typography>
          </Tooltip>
        </Link>
      </TableCell>
      <TableCell style={{ width: '90px' }}>
        <Rating
          value={rating}
          readOnly
          size="large"
          className={classes.ratingIcon}
        />
      </TableCell>
      <TableCell>
        <Grid className={classNames(classes.memberStatus, 'nowrap')}>
          { status === null ? (
            <MemberStatusCode code={status} />
          ) : (
            <Link href={`${URL_MAPPER.web}?customerId=${id}`} target="_blank">
              <MemberStatusCode code={status} />
            </Link>
          )}
          { children }
        </Grid>
      </TableCell>
    </>
  );
}

export default Customer;

export const createGuidanceStatusText = (data) => {
  const arr = [];
  // 案内ステータスコード
  if (data.guidanceStatusCode) {
    arr.push(GUIDANCE_STATUS_CODE[data.guidanceStatusCode]);
  }
  if (data.isPairCheck) arr.push('揃い');
  if (data.isFp) arr.push('FP');
  return arr.join('/');
};
