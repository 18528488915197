import CustomerShareUpdateComponent from '../../../../components/common/customer/shareupdate/customerShareupdate';

function CustomerShareUpdateContainer(props) {
  const { customerId, customerList, functions, shareAddDisabledFlg } = props;

  return (
    <CustomerShareUpdateComponent
      customerId={customerId}
      customerList={customerList}
      functions={functions}
      shareAddDisabledFlg={shareAddDisabledFlg}
    />
  );
}

export default CustomerShareUpdateContainer;
