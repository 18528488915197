/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import {
  Typography,
  Grid,
  List,
  ListItem,
  Select,
  MenuItem,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import commonStyles from '../../../styles';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#fff',
    border: '1px solid #C8C8C8',
    padding: '10px',
    marginBottom: 8,
    '& .makeStyles-checkBoxContainer-32': {
      display: 'flex',
      flexDirection: 'column',
    },
  },
  formGroupContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    '& .MuiRadio-colorPrimary.Mui-checked': {
      color: theme.palette.primaryColor,
    },
    '& label': {
      '& .PrivateSwitchBase-root-33': {
        padding: 5,
      },
      '& .MuiTypography-body1': {
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '13px',
        lineHeight: '160%',
      },
    },
  },
  formControlList: {
    alignItems: 'flex-start',
    flexGrow: 1,
  },
  guidanceButton: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '13px',
    lineHeight: '160%',
    color: theme.palette.primaryColor,
    '& .MuiButton-label': {
      justifyContent: 'flex-start',
    },
  },
  launchIcon: {
    width: 18,
    height: 18,
    marginLeft: 8,
  },
  // SP
  list: {
    background: '#fff',
    padding: '0 0 0 16px',
    marginBottom: 4,
  },
  listItem: {
    padding: 0,
  },
  listBtn: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '16px 8px 16px 16px',
    borderBottom: '1px solid #F3F3F3',
    width: '100%',
  },
  listTtl: {
    fontSize: 13,
    fontWeight: 700,
    lineHeight: 1.2,
    '& span': {
      color: '#D83420',
    },
  },
  listTxt: {
    fontSize: 13,
    fontWeight: 700,
    lineHeight: 1.2,
    textTransform: 'none',
  },
  radioGroup: {
    display: 'block',
  },
  radioGroupTtl: {
    fontSize: 13,
    padding: '16px 0',
  },
  label: {
    display: 'block',
    borderBottom: '1px solid #F3F3F3',
    padding: 16,
    margin: 0,
    '& .MuiTypography-root': {
      fontFamily: 'Roboto',
      fontSize: 13,
      fontWeight: '700',
      lineHeight: 1.6,
      letterSpacing: 0,
    },
    '& .MuiButtonBase-root': {
      display: 'none',
    },
    '& .MuiRadio-colorSecondary.Mui-checked': {
      color: theme.palette.primaryColor,
      '& + .MuiFormControlLabel-label': {
        color: theme.palette.primaryColor,
      },
    },
  },
  typeLabel: { marginRight: 20 },
  hr: {
    borderTop: '1px #00000029 solid',
    borderBottom: 'none',
    margin: '2px 0',
    display: 'block',
  },
}));

function SelectAct(props) {
  const { setISchecked, actFilled, setActFilled, actRegistFlag, editableFlag, code,
    setActionType, menus,
    disabled, values, setUpdateData,
  } = props;
  const classes = useStyles();
  const baseClasses = commonStyles();

  // モーダル用
  const [value, setValue] = useState('');
  const handleChange = (e) => {
    setValue(e.target.value);
    setActFilled(e.target.value);
    setActionType(e.target.value);
    setUpdateData({ ...values, actionScheduleCode: e.target.value });
  };

  // SP・PCでの分岐用
  const windowWidth = window.innerWidth;
  const breakPoint = 768;
  const isSP = (windowWidth < breakPoint);
  let contents;

  const openSearchSpace = (ev) => {
    setISchecked(true);
    if (ev.target.checked) {
      setActFilled(ev.target.value);
    }
  };
  const closeSearchSpace = (ev) => {
    setISchecked(false);
    if (ev.target.checked) {
      setActFilled(ev.target.value);
    }
  };

  const onRadioChangeHandler = (ev) => {
    setActionType(ev.target.value);
    // 登録データの更新
    setUpdateData({ ...values, actionScheduleCode: ev.target.value });

    if (ev.target.value >= 3) {
      closeSearchSpace(ev);
    }

    if (ev.target.value < 3) {
      openSearchSpace(ev);
    }
  };

  useEffect(() => {
    setValue(values.actionScheduleCode);
  }, [values.actionScheduleCode]);

  if (isSP) {
    contents = (
      <Grid>
        <List className={classes.list}>
          <ListItem className={`${classes.listItem} ${classes.listBtn}`}>
            <Typography className={classes.listTtl}>
              種類
              <span>＊</span>
            </Typography>
            <Select
              value={value}
              onChange={handleChange}
            >
              {menus.map((data) => (
                <MenuItem
                  value={data}
                >
                  {code[data]}
                </MenuItem>
              ))}
            </Select>
          </ListItem>
        </List>
      </Grid>
    );
  } else {
    contents = (
      <Grid className={classes.container}>
        <Typography style={{ width: '15%' }} className={baseClasses.title6}>
          種類<span style={{ color: '#D83420' }}>＊</span>
        </Typography>
        <FormControl className={classes.formControlList} component="fieldset" disabled={disabled}>
          <RadioGroup
            row
            defaultValue={(actRegistFlag && !editableFlag) ? actFilled : values.actionScheduleCode.toString()}
            aria-label="position"
            name="position"
            className={classes.formGroupContainer}
            onChange={(ev) => onRadioChangeHandler(ev)}
          >
            <Grid container alignItems="center">
              <span className={classes.typeLabel}>源泉</span>
              <FormControlLabel
                value="6" // "源泉(駅前)"
                control={
                  <Radio
                    color="primary"
                    onChange={closeSearchSpace}
                  />
                }
                label="駅前"
              />
              <FormControlLabel
                value="2" // "源泉(物件前)"
                control={
                  <Radio
                    color="primary"
                  />
                }
                label="物件前"
                labelPlacement="end"
              />
              <FormControlLabel
                value="1" // "源泉(ポーター)"
                control={
                  <Radio
                    color="primary"
                  />
                }
                label="ポーター"
                labelPlacement="end"
              />
              <FormControlLabel
                value="7" // "源泉(その他)"
                control={
                  <Radio
                    color="primary"
                    onChange={closeSearchSpace}
                  />
                }
                label="その他"
              />
            </Grid>
            <hr className={classes.hr} />
            <FormControlLabel
              value="3" // "追客"
              control={
                <Radio
                  color="primary"
                />
              }
              label="追客"
              labelPlacement="end"
            />
            <hr className={classes.hr} />
            <FormControlLabel
              value="4" // "業務"
              control={
                <Radio
                  color="primary"
                  onChange={closeSearchSpace}
                />
              }
              label="業務"
            />
            <hr className={classes.hr} />
            <FormControlLabel
              value="5" // "その他"
              control={
                <Radio
                  color="primary"
                  onChange={closeSearchSpace}
                />
              }
              label="その他"
            />
          </RadioGroup>
        </FormControl>
      </Grid>
    );
  }

  return (
    <Grid>
      {contents}
    </Grid>
  );
}

export default SelectAct;
