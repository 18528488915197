import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Calendar from '../../common/calendar';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    height: '100%',
  },
  test1: {
    height: '100%',
    '&>div': {
      height: '100%',
      padding: 0,
      '&>*': {
        width: '100%',
        border: 'none',
      },
      '&>label': {
        padding: '6px 0 7px',
      },
    },
  },
}));

// 下記一旦テスト用
function CalendarTest(props) {
  const classes = useStyles();
  const { dateSingle } = props;
  const readySetState = (data) => {
    if (data) {
      dateSingle.setDate(data.replaceAll('-', '/'));
    }
  };
  const datalist = [
    {
      id: 'id1', // String - labelとinputを紐づけます
      label: dateSingle.title, // String - ラベル
      require: false, // Boolean - 必須かどうか
      type: 'dateNew', // String 'date'|'time'|'multitime'|'multiple'
      state: dateSingle.state, // state
      setState: readySetState,
    },
  ];

  return (
    <div className={classes.root}>
      {datalist.map((array) => {
        return (
          <div className={classes.test1} key={array.id}>
            <Calendar data={array} />
          </div>
        );
      })}
    </div>
  );
}

export default React.memo(CalendarTest);
