import { useState, useEffect } from 'react';
import { Grid, Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import commonStyles from '../styles';
import UserBasicComponent from './userBasic';
import UserChangeHistory from './userChangeHistory';
import AddUserButton from './parts/addUserButton';
import SaveUserButton from './parts/saveUserButton';
import { USERS_TREE } from '../../constants/customerTree';
import UserTree from '../common/userTreeNew';

const useStyles = makeStyles(() => ({
  root: {
    overflow: 'hidden',
  },
  customMenuBox: {
    /* Auto layout */
    display: 'flex',
    'flex-direction': 'column',
    'align-items': 'flex-start',
    padding: '0px',
    position: 'static',
    width: '100%',
    height: '52px',
    left: '0px',
    top: '0px',
    flex: 'none',
    order: 0,
    'align-self': 'stretch',
    'flex-grow': 0,
    margin: '0px 0px',
  },
  customMenuTypography: {
    position: 'static',
    left: '31.11%',
    right: '11.11%',
    top: '13.79%',
    bottom: '13.79%',
  },
  customTitle: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingLeft: '1rem',
    position: 'static',
    height: '53px',
    left: '0px',
    top: '1px',
    boxSizing: 'border-box',
    width: '100%',
  },
  addUserBtn: {
    margin: '0 0.5rem',
  },
  customTitle3: {
    position: 'static',
    width: '811px',
    height: '20px',
    left: '8px',
    top: '8px',
    display: 'flex',
    'align-items': 'center',
    /* OH-Black */
    color: '#333333',
    flex: 'none',
    order: 0,
    'flex-grow': 0,
    margin: '8px 4px 0px',
  },
  userCustomButton: {
    padding: '4px 16px',
    height: '29px',
    flex: 'none',
    order: 3,
    flexGrow: 0,
    margin: '0px 8px',
  },
  userCustomButtonSave: {
    padding: '8px 22px',
    width: '74px',
    height: '40px',
    flex: 'none',
    order: 2,
    'flex-grow': 0,
    margin: '12px 16px',
  },
  customButtonSpan: {
    color: '#ffffff',
    position: 'static',
    left: '32%',
    right: '16%',
    top: '13.79%',
    bottom: '13.79%',
  },
  backGrey: {
    position: 'static',
    width: '2px',
    height: '974px',
    left: '240px',
    top: '0px',
    background: '#8C8C8C',
    /* Inside auto layout */
    flex: 'none',
    order: 1,
    'align-self': 'stretch',
    'flex-grow': 0,
    margin: '0px 0px',
  },
  sidemenu: {
    flexWrap: 'nowrap',
  },
  treeGrid: {
    display: 'flex',
    'flex-direction': 'column',
    'align-items': 'flex-start',
    padding: '0px',
    maxHeight: 'calc(100vh - (52px + 52px + 1px))',
    overflow: 'hidden',
    position: 'static',
    height: 'calc(100vh - (52px + 52px + 1px))',
    left: '0px',
    top: '0px',

    /* OH-White */
    background: '#FFFFFF',
    /* Inside auto layout */
    flex: 'none',
    order: 0,
    'align-self': 'stretch',
    'flex-grow': 0,
    margin: '0px 0px',
  },
  borderDefault: {
    border: '1px solid #8C8C8C',
    borderLeft: 'none',
  },
  shainJohoGrid: {
    height: 'calc(100vh - (52px + 52px + 1px))',
    maxHeight: 'calc(100vh - (52px + 52px + 1px))',
    overflow: 'auto',
    background: '#f3f3f3',
    display: 'flex',
    flexDirection: 'column',
  },
  shainJohoBox: {
    /* Auto layout */
    display: 'flex',
    'flex-direction': 'column',
    'align-items': 'flex-start',
    padding: '16px 16px 8px',
    boxSizing: 'border-box',
    position: 'sticky',
    left: '0px',
    top: '0px',
    /* OH-White */
    background: '#FFFFFF',
    /* Inside auto layout */
    flex: 'none',
    order: 0,
    'align-self': 'stretch',
    'flex-grow': 0,
    margin: '0px 0px',
    zIndex: 1,
  },
  mainGrid: {
    /* Auto layout */
    display: 'flex',
    flexWrap: 'nowrap',
    'flex-direction': 'row',
    'align-items': 'flex-start',
    padding: 4,
    position: 'static',
    height: 'calc(100% - 117px)',
    left: '0px',
    top: '49px',
    /* OH-liteGray */
    background: '#F3F3F3',
    /* Inside auto layout */
    flex: 'none',
    order: 1,
    'align-self': 'stretch',
    'flex-grow': 1,
    margin: '0px 0px',
    borderBottom: '1px solid #808080',
    flexGrow: 1,
    overflow: 'auto',
  },
  bottomWrapper: {
    background: '#fff',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    order: 2,
  },
}));

function Users(props) {
  const {
    setUser,
    setDivision,
    getUserFunction,
    createUserFunction,
    updateUserFunction,
    resetUserFunction,
    updateDivisionFunction,
    userListFunction,
    userDivListFunction,
    deleteUserDivision,
  } = props;
  const [validate, setValidate] = useState(false);
  const [createMode, setCreateMode] = useState(true);
  const [selectUser, setSelectUser] = useState({ userId: 0, userName: '' });
  const [stayUserId, setStayUserId] = useState(0);
  const classes = useStyles();
  const baseClasses = commonStyles();

  const [errorSet, setErrorSet] = useState(new Set());

  const errorCallback = (key) => (hasError) => {
    setErrorSet((prev) => {
      if (hasError) return new Set(prev.add(key));
      prev.delete(key);
      return new Set(prev);
    });
  };

  useEffect(() => {
    if (selectUser.userId) {
      if (selectUser.userId !== stayUserId) {
        getUserFunction(selectUser.userId);
        setStayUserId(selectUser.userId);
      }
      setValidate(false);
    }
  }, [selectUser]);

  return (
    <Grid className={classes.root}>
      {/* メニューComponent呼び出し */}
      <Grid
        container
        className={`${classes.customTitle} ${classes.borderDefault}`}
      >
        <Grid item>
          <Typography className={baseClasses.title2}>
            組織・社員マスタ
          </Typography>
        </Grid>
        <Grid item className={classes.addUserBtn}>
          <AddUserButton
            resetUserFunction={resetUserFunction}
            createMode={createMode}
            setCreateMode={setCreateMode}
          />
        </Grid>
      </Grid>
      <Grid container className={classes.sidemenu}>
        <Grid item className={`${classes.treeGrid} ${classes.borderDefault}`}>
          <UserTree
            setSelectUser={setSelectUser}
            treeHeaderSelect={[USERS_TREE]}
            isUserMaster
            doubleSearch
          />
        </Grid>
        <Grid item className={classes.shainJohoGrid}>
          <Box className={`${classes.shainJohoBox} ${classes.borderDefault}`}>
            <Typography className={baseClasses.title2}>
              社員情報
            </Typography>
          </Box>
          <Grid container className={classes.mainGrid}>
            <Grid item style={{ width: '49%', minWidth: 560, flexShrink: 0 }}>
              <Typography
                className={`${baseClasses.title3} ${classes.customTitle3}`}
              >
                基本情報
              </Typography>
              <UserBasicComponent
                setUser={setUser}
                validate={validate}
                userListFunction={userListFunction}
                userDivListFunction={userDivListFunction}
                createMode={createMode}
                updateUserFunction={updateUserFunction}
                deleteUserDivision={deleteUserDivision}
                errorCallback={errorCallback}
                updateDivisionFunction={updateDivisionFunction}
              />
            </Grid>
            <Grid item style={{ width: '50%', minWidth: 760, flexShrink: 0 }}>
              <Typography
                className={`${baseClasses.title3} ${classes.customTitle3}`}
              >
                組織の変更履歴
              </Typography>
              <UserChangeHistory
                setDivision={setDivision}
                selectUser={selectUser}
                updateDivisionFunction={updateDivisionFunction}
              />
            </Grid>
          </Grid>
          <Grid className={classes.bottomWrapper}>
            <SaveUserButton
              createUserFunction={createUserFunction}
              updateUserFunction={updateUserFunction}
              setValidate={setValidate}
              errorSet={errorSet}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default Users;
