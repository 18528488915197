import { TableCell, TableRow, Grid } from '@material-ui/core';
import Select from '../select';

function DivisionMasterRow(props) {
  const {
    userDivision,
    onChangeUserDivisionId,
    toDivisions,
    className,
    authority,
  } = props;

  return (
    <TableRow>
      <TableCell className={className.td}>{userDivision.userId}</TableCell>
      <TableCell className={`${className.td} nowrap`}>{userDivision.userFullName}</TableCell>
      <TableCell className={`${className.td} nowrap`}>{userDivision.positionName}</TableCell>
      <TableCell className={`${className.td} nowrap`}>{userDivision.gradeName}</TableCell>
      <TableCell className={`${className.td} nowrap`}>{userDivision.userRoleGroupName}</TableCell>
      <TableCell className={`${className.td} nowrap`}>{userDivision.fromDivisionName}</TableCell>
      <TableCell className={`${className.td} nowrap`}><Grid item xs={2}>→</Grid></TableCell>
      <TableCell className={`${className.td} nowrap`}>
        <Select
          options={toDivisions}
          labelKey="divisionName"
          valueKey="divisionId"
          value={userDivision.toDivisionId}
          onChange={(val) => onChangeUserDivisionId(val, userDivision)}
          className={`${className.select} ${!authority && className.disable}`}
        />
      </TableCell>
      <TableCell className={className.td}>{userDivision.subDivisions.map((subDivision) => subDivision.divisionName).join(',')}</TableCell>
    </TableRow>
  );
}

export default DivisionMasterRow;
