import client from '../httpClient';

// 【sms010】SMS送信キャンセル
const cancelSmsApi = async (smsMessageId) => {
  /**
   * @param {string} smsMessageId
   */
  const result = await client.post(`/sms/cancel/${smsMessageId}`);
  return result;
};

export default cancelSmsApi;
