import { createSlice } from '@reduxjs/toolkit';
import { getNowDate } from '../../commonFunction';

const END_DATETIME = '2099/12/31 23:59';

export const initialState = {
  divisions: [],
  activeDirectoryAuthenticationId: '', // str
  endAt: END_DATETIME,
  firstName: '',
  firstNameKana: '',
  genderCode: null,
  isHide: 0,
  isQualification: 0,
  lastName: '',
  lastNameKana: '',
  mail: '',
  memo: '',
  mobilePhoneNumber: '',
  recruitedSeason: null,
  isLastaAutoInputName: 0,
  startAt: getNowDate('YYYY/MM/DD HH:mm'),
  systems: [
    {
      systemCode: 1,
      roleGroupId: 11,
    },
    {
      systemCode: 2,
      roleGroupId: 103,
    },
    {
      systemCode: 3,
      roleGroupId: 206,
    },
  ],
  userBusinesses: [{
    businessCode: 1,
    assignAt: getNowDate('YYYY/MM/DD'),
    isSalesTarget: 1,
  }],
  userCode: '',
  userId: '', // 新規追加の時はnull -- 画面上では編集できない
  userStatusCode: 1,
  userTypeCode: 1,
};

export const initialDivisionState = [
  {
    divisionHistorieId: '', // int
    divisionId: '', // int
    divisionName: '', // str
    gradeCode: '', // int
    positionCode: '',
    isMainDivision: '', // int
    gradeName: '',
    startAt: '',
    endAt: '',
  },
];

export const userSlice = createSlice({
  name: 'userData',
  initialState: {
    userDetail: initialState,
    userDivisionDetail: initialDivisionState,
  },
  reducers: {
    setUserApi: (state, data) => {
      state.userDetail = data.payload;
      state.userDivisionDetail = data.payload.divisionAll;
    },
    setUserIdApi: (state, data) => {
      state.userDetail.userId = data.payload;
    },
    setDivisionApi: (state, data) => {
      state.userDivisionDetail[data.payload.index] = data.payload.modifyObj;
    },
    setDivisionResponseApi: (state, data) => {
      state.userDivisionDetail = data.payload;
    },
  },
});

export const {
  setUserApi,
  setUserIdApi,
  setDivisionApi,
  setDivisionResponseApi,
} = userSlice.actions;

export default userSlice.reducer;
