import { useState } from 'react';
import { useDispatch } from 'react-redux';

import { Button, Grid, makeStyles, Typography } from '@material-ui/core';
import { MailOutline, OpenInNew, PhoneIphone, Share } from '@material-ui/icons';

import QRCode from 'qrcode.react';

import commonStyles from '../styles';

import copyToClipboard from '../../commonFunction/clipboard';

import DraggableDialog from '../common/modal';
import EasyRegisterSend from './easyRegisterSend';
import { isOpen } from '../../store/common/apiMessageSlice';
import { LASTA_STAFF_URL, MAIL_FIRST_TEMPLATE, MAIL_LAST_TEMPLATE } from '../../constants/easyRegister';

const useStyles = makeStyles({
  qrWrap: {
    background: '#F3F3F3',
    width: 300,
    height: 300,
    margin: 'auto',
    border: '16px #fff solid',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  share: {
    alignItems: 'center',
    justifyContent: 'center',
    margin: '24px 0 10px',
    color: '#8C8C8C',
  },
  btn: {
    borderRadius: '50%',
    border: '1px #c8c8c8 solid',
    background: '#fff',
    color: '#8C8C8C',
    padding: 0,
    height: 60,
    width: 60,
    '& .MuiButton-label': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
  btnTxt: {
    fontSize: 10,
    fontWeight: 700,
  },
});
export default function EasyRegisterComponent(props) {
  const { userId } = props;
  const common = commonStyles();
  const classes = useStyles();
  const dispatch = useDispatch();

  const lastaStaffUrl = `${LASTA_STAFF_URL}?staff_id=${userId}&link_id=stafftopbtn`;
  const onClickCopy = () => {
    const res = copyToClipboard(lastaStaffUrl);
    if (res) {
      dispatch(isOpen({
        status: 'success',
        message: '',
        messageList: ['URLをコピーしました'],
      }));
    }
  };

  /**
 * メールで送信する内容を生成し、クリップボードにコピーする
*/
  const mailBody = `${MAIL_FIRST_TEMPLATE}${LASTA_STAFF_URL}?staff_id=${userId}&link_id=stafftopbtn
${MAIL_LAST_TEMPLATE}`;
  const copyMailInfoToClipBoard = () => {
    // クリップボードへの本文をコピー
    const result = copyToClipboard(mailBody);
    // メッセージを表示
    if (result) {
      dispatch(isOpen({
        status: 'success',
        message: '',
        messageList: ['メール送信する情報をクリップボードにコピーしました。'],
      }));
    }
  };

  const [open, setOpen] = useState(false);
  const onOpen = () => { setOpen(true); };
  const onClose = () => { setOpen(false); };
  return (
    <>
      <Grid className={classes.qrWrap}>
        <QRCode value={lastaStaffUrl} size={160} />
      </Grid>
      <Grid container className={classes.share}>
        <Share />
        <Typography className={common.strong}>シェアする</Typography>
      </Grid>
      <Grid container justifyContent="space-evenly">
        <Button
          className={classes.btn}
          onClick={() => {
            return onClickCopy();
          }}
        >
          <OpenInNew />
          <Typography className={classes.btnTxt}>コピー</Typography>
        </Button>
        <Button className={classes.btn} onClick={onOpen}>
          <PhoneIphone />
          <Typography className={classes.btnTxt}>SMS</Typography>
        </Button>
        <Button className={classes.btn} onClick={copyMailInfoToClipBoard}>
          <MailOutline />
          <Typography className={classes.btnTxt}>メール</Typography>
        </Button>
        <DraggableDialog
          open={open}
          onClose={onClose}
          title="電話番号を入力"
          white
        >
          <EasyRegisterSend
            onClose={onClose}
            userId={userId}
          />
        </DraggableDialog>
      </Grid>
    </>
  );
}
