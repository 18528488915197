import React from 'react';
import { Grid } from '@material-ui/core';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { makeStyles } from '@material-ui/core/styles';
import commonStyles from '../../styles';
import {
  GENDER_CODE,
  IS_QUALIFICATION,
  USER_STATUS,
} from '../../../constants/index';
import modifyObject from '../../../commonFunction/modifyObject';
import { validateFormFloat1 } from '../../../commonFunction/validations';
import { convertFormNumber } from '../../../commonFunction/convertors';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    padding: 8,
    position: 'static',
    width: '24%',
    left: '0px',
    top: '0px',
    background: '#FFFFFF',
    border: '1px solid #C8C8C8',
    boxSizing: 'border-box',
    flex: 'none',
    order: 0,
    flexGrow: 1,
    margin: '0.5%',
  },
}));

function InputFormOption(props) {
  const { getComponent, state, setState, errorCallback } = props;
  const baseClasses = commonStyles();
  const classes = useStyles();

  const handleChange = (e, data) => {
    const val = data.key === 'recruitedSeason' ? e : e.target.value;
    switch (data.key) {
      case 'genderCode':
        setState({ ...state, genderCode: Number(val) });
        break;
      case 'isQualification':
        setState({ ...state, isQualification: val });
        break;
      case 'recruitedSeason':
        setState({ ...state, recruitedSeason: Number(val) });
        break;
      case 'userStatusCode':
        setState({ ...state, userStatusCode: val });
        break;
      default:
        break;
    }
  };

  const basicOption = [
    {
      title: '性別',
      required: false,
      key: 'genderCode',
      type: 'flg',
      obj: modifyObject(GENDER_CODE, '性別'),
      state: state.genderCode,
      set: handleChange,
    },
    {
      title: '宅建有無',
      required: false,
      key: 'isQualification',
      type: 'flg',
      obj: modifyObject(IS_QUALIFICATION, '宅建有無'),
      state: state.isQualification,
      set: handleChange,
    },
    {
      title: '入社期',
      required: false,
      key: 'recruitedSeason',
      type: 'number',
      state: state.recruitedSeason,
      set: handleChange,
      halfNum: true,
      validator: validateFormFloat1({
        minFloat: 0.0,
        maxFloat: 999.0,
        precisionInt: 1,
      }),
      convertor: convertFormNumber,
      errorCallback: errorCallback('recruitedSeason'),
    },
    {
      title: 'ユーザーステータス',
      required: false,
      key: 'userStatusCode',
      type: 'flg',
      obj: modifyObject(USER_STATUS, 'ユーザーステータス'),
      state: state.userStatusCode,
      set: handleChange,
    },
  ];

  return basicOption.map((data) => (
    <Grid
      key={data.key}
      className={`${classes.root} ${baseClasses.inputWrap}`}
    >
      {getComponent(data)}
    </Grid>
  ));
}

export default React.memo(InputFormOption);
