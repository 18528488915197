import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  Button,
  Drawer,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import TaiouRirekiType from './parts/taiouRirekiType';
import TaiouRirekiItems from './parts/taiouRirekiItems';
import TaiouRirekiItemsSp from './parts/taiouRirekiItemsSp';
import commonTheme from '../../styles/theme';
import { useLoading } from '../../../hooks';
import commonStyles from '../../styles';
import PullRefComponent from '../pullRefComponent';
import InfoRegister from '../infoRegister';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    [commonTheme.breakpoints.down('sm')]: {
      paddingTop: 102,
      height: 'calc(100vh - 58px)',
      background: '#fff',
      overflow: 'hidden',
    },
  },
  button: {
    [commonTheme.breakpoints.down('sm')]: {
      zIndex: 1101,
      minWidth: 'auto',
      padding: '0',
      position: 'fixed',
      bottom: '84px',
      right: '24px',
      width: '56px',
      height: '56px',
      backgroundColor: theme.palette.primaryColor,
      borderRadius: '50%',
      boxShadow:
        '0px 6px 6px rgba(51, 51, 51, 0.26), 0px 10px 20px rgba(51, 51, 51, 0.19)',
    },
  },
  addButton: {
    fontFamily: 'Roboto',
    color: theme.palette.primaryColor,
  },
  addIcon: {
    width: '15px',
    height: '15px',
    color: theme.palette.primaryColor,
    marginRight: '3px',
    [commonTheme.breakpoints.down('sm')]: {
      width: '24px',
      height: '24px',
      color: '#fff',
    },
  },
  drawer: {
    '& .MuiDrawer-paper': {
      borderRadius: '12px 12px 0 0',
    },
  },
}));

function TaiouRireki(props) {
  const {
    customerId,
    setHistory,
    setOpenAlert,
    getHistoryList,
    getActionHistoryList,
    downloadAttachment,
    createHistory,
    updateHistory,
    deleteHistory,
    search,
    customer,
    setSearch,
    actionHistory,
    refreshing,
    onRefresh,
    rerenderKeySearch,
    rerenderSearch,
  } = props;
  const { hasLoading } = useLoading();
  const isSp = useSelector((state) => state.deviceTypeSlice.isSp);

  const classes = useStyles();
  const common = commonStyles();

  // モーダル開閉用のローカルステート
  const [open, setOpen] = useState(false);
  // モーダルを開くsetState
  const handleOpen = () => {
    setOpen(true);
  };
  // // モーダルを閉じるsetState
  const handleClose = () => {
    setOpen(false);
  };

  // 案内登録画面管理用
  const [infoOpen, setInfoOpen] = useState(false);

  useEffect(() => {
    // TaiouRirekiItemsに同じ処理があるため、SPのみ実行
    isSp && getActionHistoryList(customerId, search);
  }, [isSp]);

  let contents;

  if (isSp) {
    contents = (
      <>
        <PullRefComponent
          refreshing={refreshing}
          onRefresh={onRefresh}
        >
          <Grid className={`${classes.root} ${hasLoading('taiouRireki') ? common.loading : ''}`}>
            <TaiouRirekiItemsSp
              state={actionHistory}
              setState={setHistory}
              getActionHistoryList={getActionHistoryList}
              search={search}
              updateHistory={updateHistory}
              deleteHistory={deleteHistory}
              downloadAttachment={downloadAttachment}
              customerId={customerId}
              customer={customer}
            />
          </Grid>
        </PullRefComponent>
        {/* 追加ボタン */}
        <Button className={classes.button} onClick={handleOpen}>
          <AddIcon className={classes.addIcon} />
        </Button>
        {/* 新規登録 */}
        <Drawer
          anchor="bottom"
          open={open}
          onClose={handleClose}
          className={classes.drawer}
        >
          <TaiouRirekiType
            onClose={handleClose}
            setOpenAlert={setOpenAlert}
            createHistory={createHistory}
            customer={customer}
            customerId={customerId}
            newRegistrationFlg // 新規登録flg (案内情報などで既存IDが入ってきても、新規登録として扱うことを明示)
            getActionHistoryList={getActionHistoryList}
            actionHistorySearch={search}
          />
        </Drawer>
      </>
    );
  } else {
    contents = (
      <Grid className={classes.root}>
        <TaiouRirekiType
          onClose={handleClose}
          setOpenAlert={setOpenAlert}
          createHistory={createHistory}
          customerId={customerId}
          customer={customer}
          newRegistrationFlg // 新規登録flg (案内情報などで既存IDが入ってきても、新規登録として扱うことを明示)
          getActionHistoryList={getActionHistoryList}
          actionHistorySearch={search}
          rerenderSearch={rerenderSearch}
        />
        <TaiouRirekiItems
          key={rerenderKeySearch}
          state={actionHistory}
          setState={setHistory}
          updateHistory={updateHistory}
          deleteHistory={deleteHistory}
          getHistoryList={getHistoryList}
          getActionHistoryList={getActionHistoryList}
          downloadAttachment={downloadAttachment}
          search={search}
          setSearch={setSearch}
          customerId={customerId}
          customer={customer}
          rerenderSearch={rerenderSearch}
          setInfoOpen={setInfoOpen}
        />
        {/* 対応履歴から続けて案内登録をする際に使用(rerender回避) */}
        <InfoRegister
          open={infoOpen}
          onClose={() => setInfoOpen(false)}
          guidanceId={null} // 新規登録
          customer={customer}
        />
      </Grid>
    );
  }

  return (
    <Grid>
      {actionHistory && contents}
    </Grid>
  );
}

export default React.memo(TaiouRireki);
