import { isArray, getLocalStorage, setLocalStorage, removeLocalStorage } from '../../../commonFunction';

const API_INFO = {
  // patternId: 0,
  areaCode: 0,
  patternName: '',
  targetCityJisCode: '',
  specialRule: 0,
  displayOrder: 0,
  isDelete: 0,
  // datails: [],
};

const PATTERN_PRICE_KEYS = {
  // 1: '',
  2: 'pricePatternTypeCode2',
  3: 'pricePatternTypeCode3',
  // 4: '',
};

const createIdList = checkedList => {
  const idList = [];
  checkedList.forEach(item => {
    idList.push(item.id);
  });
  return idList;
};

const createDeletedIdList = (defaultCheckedIdList, nowSelectedIdList) => {
  const deletedIdList = [];
  defaultCheckedIdList.forEach(defaultCheckedId => {
    if (!nowSelectedIdList.includes(defaultCheckedId)) {
      deletedIdList.push(defaultCheckedId);
    }
  });
  return deletedIdList;
};

const createAddedIdList = (defaultCheckedIdList, nowSelectedIdList) => {
  const addedIdList = [];
  nowSelectedIdList.forEach(nowSelectedId => {
    if (!defaultCheckedIdList.includes(nowSelectedId)) {
      addedIdList.push(nowSelectedId);
    }
  });
  return addedIdList;
};

const createNoChangeIdList = (defaultCheckedIdList, nowSelectedIdList) => {
  const noChangeIdList = [];
  defaultCheckedIdList.forEach(defaultCheckedId => {
    if (nowSelectedIdList.includes(defaultCheckedId)) {
      noChangeIdList.push(defaultCheckedId);
    }
  });
  return noChangeIdList;
};

const creatDeletedList = (deletedIdList, defaultCheckedList) => {
  const result = [];
  defaultCheckedList.forEach(item => {
    if (deletedIdList.includes(item.id)) {
      result.push(item);
    }
  });
  return result;
};

const createAddedList = (addedIdList, nowSelectedList) => {
  const result = [];
  nowSelectedList.forEach(item => {
    if (addedIdList.includes(item.id)) {
      result.push(item);
    }
  });
  return result;
};

const creatNoChangeList = (noChangeIdList, defaultCheckedList) => {
  const result = [];
  defaultCheckedList.forEach(item => {
    if (noChangeIdList.includes(item.id)) {
      result.push(item);
    }
  });
  return result;
};

const PATTERN_TYPE_ID_STORAGE_KEY = 'PATTERN_TYPE_ID_';

export function formatSelectedStore(data, form) {
  let arr = [];
  let deletedResultList = [];
  let addedResultList = [];
  let nochageResultList = [];
  Object.keys(data).forEach((patternTypeId) => {
    // デフォルトで選択されていたリスト
    const defaultCheckedList = getLocalStorage(PATTERN_TYPE_ID_STORAGE_KEY + patternTypeId);
    // デフォルトで選択されていたIDリスト
    const defaultCheckedIdList = createIdList(defaultCheckedList);
    // 現在選択されているリスト
    const nowSelectedList = data[patternTypeId];
    // 現在選択されているIDリスト
    const nowSelectedIdList = createIdList(nowSelectedList);
    // 選択を外されたIDリスト
    const deletedIdList = createDeletedIdList(defaultCheckedIdList, nowSelectedIdList);
    // 選択を外されたリスト
    const deletedList = creatDeletedList(deletedIdList, defaultCheckedList);
    // 新たに追加されたIDリスト
    const addedIdList = createAddedIdList(defaultCheckedIdList, nowSelectedIdList);
    // 新たに追加されたリスト
    const addedList = createAddedList(addedIdList, nowSelectedList);
    // 変更のないもののIDリスト(更新扱い)
    const noChangeIdList = createNoChangeIdList(defaultCheckedIdList, nowSelectedIdList);
    // 変更のないもののリスト(更新扱い)
    const noChangeList = creatNoChangeList(noChangeIdList, defaultCheckedList);
    // localStorageからデフォルト選択リストを削除
    removeLocalStorage(PATTERN_TYPE_ID_STORAGE_KEY + patternTypeId);
    // 削除分
    deletedResultList = deletedList.map((item) => {
      return {
        storeGroupId: item.storeGroupId,
        // patternId: form.patternId,
        patternTypeCode: patternTypeId ? Number(patternTypeId) : null,
        // areaCode: item.areaCode,
        isDelete: 1,
        // eslint-disable-next-line max-len
        price: form[PATTERN_PRICE_KEYS[patternTypeId]] ? Number(form[PATTERN_PRICE_KEYS[patternTypeId]]) : null,
      };
    });
    // 追加分
    addedResultList = addedList.map((item) => {
      return {
        storeGroupId: item.storeGroupId,
        patternTypeCode: patternTypeId ? Number(patternTypeId) : null,
        isDelete: 0,
        // eslint-disable-next-line max-len
        price: form[PATTERN_PRICE_KEYS[patternTypeId]] ? Number(form[PATTERN_PRICE_KEYS[patternTypeId]]) : null,
      };
    });
    // 変更なし分
    nochageResultList = noChangeList.map((item) => {
      return {
        storeGroupId: item.storeGroupId,
        patternTypeCode: patternTypeId ? Number(patternTypeId) : null,
        isDelete: 0,
        // eslint-disable-next-line max-len
        price: form[PATTERN_PRICE_KEYS[patternTypeId]] ? Number(form[PATTERN_PRICE_KEYS[patternTypeId]]) : null,
      };
    });
    const concatenatedResult = deletedResultList.concat(addedResultList, nochageResultList);
    arr = arr.concat(concatenatedResult);
  });
  return arr;
}

export function formatFormData(form, selectedStores) {
  const data = {
    patternId: form.patternId,
  };
  Object.keys(API_INFO).forEach(key => {
    data[key] = form[key] ?? API_INFO[key];
  });
  // targetCityJisCode
  data.targetCityJisCode = form.targetCityCode;
  // datails
  data.details = formatSelectedStore(selectedStores, form);
  return {
    information: [data],
  };
}

export function formatInitialParamStore(num, data) {
  const codeKey = ['storeGroupIdsPatternTypeCode' + num];
  const nameKey = ['storeGroupNamesPatternTypeCode' + num];
  let arr = [];
  if (isArray(data[codeKey])) {
    arr = data[codeKey].map((id, i) => {
      return {
        id,
        type: 'store',
        storeGroupId: id,
        storeGroupName: data[nameKey][i],
        // uniqueId: id,
      };
    });
  }
  setLocalStorage(PATTERN_TYPE_ID_STORAGE_KEY + num, arr);
  return arr;
}
