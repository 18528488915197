import { useState, useEffect } from 'react';
import store from '../../../../store';
import UserTree from '../../userTreeNew';
import { IS_SHAIN, USE_AQCUISITION_TREE_GROUP } from '../../../../constants';

function SelectAcquisitionWindow(props) {
  const {
    isOpen,
    setIsOpen = () => {},
    onSelect,
    detail,
  } = props;

  const { responseHeader } = store.getState();

  const [selectUser, setSelectUser] = useState({});
  const [selectDivision, setSelectDivision] = useState({});

  const [displayName, setDisplayName] = useState(detail.acquisitionMemo || responseHeader.userName);
  const [initialAcquirer, setInitialAcquirer] = useState(String(IS_SHAIN));

  const initial = {
    acquisitionId: null,
    name: '',
    acquisitionCode: null,
  };

  // ツリーを表示するかどうか
  const isTree = USE_AQCUISITION_TREE_GROUP.includes(Number(initialAcquirer));

  const handleSelectFunction = () => {
    // 社員のとき、userIdが空にならないように
    if (isTree && !selectUser?.userId) return;
    // 獲得者
    onSelect({
      acquisitionId: isTree
        ? selectUser.userId
        : null,
      name: isTree
        ? selectUser.userName
        : displayName,
      acquisitionCode: initialAcquirer,
    });
  };

  const handleClearFunction = () => {
    setDisplayName(responseHeader.userName);
    onSelect(initial);
  };

  useEffect(() => {
    if (!isOpen) return;
    // 初期条件の追加
    setSelectUser({
      userId: detail.acquisitionId || responseHeader.userId,
      userName: detail.acquisitionName || responseHeader.userName,
    });
    setInitialAcquirer(detail.acquisitionCode ? String(detail.acquisitionCode) : String(IS_SHAIN));
    setDisplayName(detail.acquisitionMemo || responseHeader.userName);
  }, [isOpen]);

  return (
    <UserTree
      selectUser={selectUser}
      setSelectUser={setSelectUser}
      selectDivision={selectDivision}
      setSelectDivision={setSelectDivision}
      isOpenTree={isOpen}
      setIsOpenTree={setIsOpen}
      handleSelectFunction={handleSelectFunction}
      handleClearFunction={handleClearFunction}
      isDialog
      isSelectUserWindow
      isAcquirer
      initialAcquirer={initialAcquirer}
      setInitialAcquirer={setInitialAcquirer}
      setDisplayName={setDisplayName}
      defaultDisplayName={displayName}
    />
  );
}

export default SelectAcquisitionWindow;
