import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  date: '',
  divisions: [],
};

export const schedulesGetActionDateSlice = createSlice({
  name: 'schedulesGetActionDate',
  initialState: { schedulesGetActionDate: initialState },
  reducers: {
    getSchedulesActionDateApi: (state, data) => {
      state.schedulesGetActionDate = data.payload;
    },
  },
});

export const { getSchedulesActionDateApi } = schedulesGetActionDateSlice.actions;

export default schedulesGetActionDateSlice.reducer;
