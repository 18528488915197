// Create->watanabe
import React from 'react';
import localStyles from './localStyle';
import commonStyles from '../../../styles';
import toISOStringWithTimezone from '../../../../commonFunction/toISOStringWithTimezone';

// date = YYYY/MM/dd (ex: 2022/11/23)
export const CalendarInputOnlyDate = React.forwardRef((props, ref) => {
  const {
    date,
    id,
    require,
    type,
    setDate,
    readonly,
    min,
    max,
    convertDateToIso,
    placeholder,
    initialDate,
  } = props;
  const style = localStyles();
  const common = commonStyles();

  // SP・PCでの分岐用
  const windowWidth = window.innerWidth;
  const breakPoint = 768;
  const isSP = (windowWidth < breakPoint);

  const valChange = (e) => {
    // return YYYY/MM/dd (ex: 2022/11/23)
    setDate(e.target.value.replaceAll('-', '/'));
    // SP:日時を選択後、隣接している時刻用inputにfocusをあてる
    if (isSP && ref !== null) {
      ref.current.focus();
    }
  };

  const isInvalidDate = (val) => Number.isNaN(new Date(val).getTime());

  const defaultVal = (dateVal) => {
    if (!dateVal) return toISOStringWithTimezone(new Date()).split('T')[0];

    const defaultDate = isInvalidDate(dateVal) ? new Date() : new Date(dateVal);
    return toISOStringWithTimezone(defaultDate).split('T')[0];
  };

  const onClick = (e, dateVal) => {
    let d;
    if (initialDate && !dateVal) {
      d = new Date(initialDate);
      setDate(initialDate.replaceAll('-', '/'));
    } else {
      d = new Date(dateVal);
    }
    e.target.value = convertDateToIso(d);
  };

  return (
    <label className={style.label} htmlFor={id}>
      <span className={`${common.strong} ${style.txt}`}>{date}</span>
      {!date && <span className={style.placeholderTxt}>{placeholder || '-'}</span>}
      <input
        className={style.input}
        require={require.toString()}
        id={id}
        type={type}
        defaultValue={defaultVal(date)}
        onChange={(ev) => valChange(ev)}
        onClick={(e) => onClick(e, date)}
        // autoFocus={autoFocus}
        readOnly={readonly ? 'readOnly' : ''}
        min={min}
        max={max}
      />
    </label>
  );
});

export default CalendarInputOnlyDate;
