import {
  Grid,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { useSelector } from 'react-redux';
import commonStyles from '../../../styles';
import NormalTooltip from '../../../styles/tooltip';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    overflowX: 'auto',
    '&::-webkit-scrollbar-thumb': { background: `linear-gradient(180deg, ${theme.palette.primaryColor}00 0%, ${theme.palette.primaryColor}00 80%, ${theme.palette.primaryColor}FF 81%, ${theme.palette.primaryColor}FF 100%)` },
    // '&::-webkit-scrollbar-thumb': { background: theme.palette.primaryColor },
  },
  wrap: {
    display: 'flex',
    alignItems: 'center',
    whiteSpace: 'nowrap',
  },
  itemWrap: {
    margin: '0 8px',
  },
  red: {
    color: '#D83420',
  },
  label: {
    whiteSpace: 'nowrap',
    marginRight: '24px',
  },
  label2: {
    whiteSpace: 'nowrap',
  },
  line: {
    display: 'block',
    background: '#c8c8c8',
    height: 'calc(100% - 16px)',
    margin: '8px 16px',
    width: '1px',
  },
}));

function MainmenuStatus(props) {
  const { env } = props;
  const common = commonStyles();
  const classes = useStyles();
  const countState = useSelector((state) => state.commonGetHeaderNumber.commonGetHeaderNumber);
  const guidanceMessage = () => {
    return (
      <Grid>
        <Typography className={common.small}>
          ・カウント対象案件
        </Typography>
        <Typography className={common.small}>
          　→「案内あり」で実績登録されている案件
        </Typography>
        <Typography className={common.small}>
          ・カウント対象者
        </Typography>
        <Typography className={common.small}>
          　→「獲得者」
        </Typography>
      </Grid>
    );
  };
  const visitMessage = () => {
    return (
      <Grid>
        <Typography className={common.small}>
          ・カウント対象案件
        </Typography>
        <Typography className={common.small}>
          　→「来社あり」で実績登録されている案件
        </Typography>
        <Typography className={common.small}>
          ・カウント対象者
        </Typography>
        <Typography className={common.small}>
          　→ 来案/来店接客のみ/源泉接触/ポーター接触の場合、「獲得者」
        </Typography>
        <Typography className={common.small}>
          　→ 訪案/待案/資料渡しの場合、「案内者」
        </Typography>
        <Typography className={common.small}>
          　(ただし「案内者」の登録がない場合、「案内予定者」)
        </Typography>
      </Grid>
    );
  };

  return (
    <Grid className={classes.root}>
      <Grid className={classes.wrap}>
        {env !== 'PRODUCTION' && (
          <Typography className={`${common.title4} ${classes.label} ${classes.red}`}>{`${env}環境`}</Typography>
        )}
        <Typography className={`${common.title4} ${classes.label}`}>基礎数字</Typography>
        <NormalTooltip title={guidanceMessage()}>
          <Grid container direction="column" alignItems="center" className={classes.itemWrap}>
            <Typography className={`${common.title4} ${classes.label2}`}>案内</Typography>
            <Typography className={common.title3}>
              <span className={!countState.guidanceNumerator ? classes.red : ''}>
                {countState.guidanceNumerator} / {countState.guidanceDenominator}
              </span>
            </Typography>
          </Grid>
        </NormalTooltip>
        <NormalTooltip title={visitMessage()}>
          <Grid container direction="column" alignItems="center" className={classes.itemWrap}>
            <Typography className={`${common.title4} ${classes.label2}`}>来社</Typography>
            <Typography className={common.title3}>
              <span className={!countState.visitCompanyNumerator ? classes.red : ''}>
                {countState.visitCompanyNumerator} / {countState.visitCompanyDenominator}
              </span>
            </Typography>
          </Grid>
        </NormalTooltip>
      </Grid>
      <div className={classes.line}> </div>
      <Grid className={classes.wrap}>
        <Typography className={`${common.title4} ${classes.label}`}>本日の実績</Typography>
        <Grid container direction="column" alignItems="center" className={classes.itemWrap}>
          <Typography className={`${common.title4} ${classes.label2}`}>TEL</Typography>
          <Typography className={common.title3}>
            <span className={!countState.telCountNumerator ? classes.red : ''}>
              {countState.telCountNumerator} / {countState.telCountDenominator}
            </span>
          </Typography>
        </Grid>
        <Grid container direction="column" alignItems="center" className={classes.itemWrap}>
          <Typography className={`${common.title4} ${classes.label2}`}>個別Mail</Typography>
          <Typography className={common.title3}>
            <span className={!countState.mailSingle ? classes.red : ''}>
              {countState.mailSingle}
            </span>
          </Typography>
        </Grid>
        <Grid container direction="column" alignItems="center" className={classes.itemWrap}>
          <Typography className={`${common.title4} ${classes.label2}`}>一斉Mail</Typography>
          <Typography className={common.title3}>
            <span className={!countState.mailMulti ? classes.red : ''}>
              {countState.mailMulti}
            </span>
          </Typography>
        </Grid>
        <Grid container direction="column" alignItems="center" className={classes.itemWrap}>
          <Typography className={`${common.title4} ${classes.label2}`}>SMS送信</Typography>
          <Typography className={common.title3}>
            <span className={!countState.smsSend ? classes.red : ''}>
              {countState.smsSend}
            </span>
          </Typography>
        </Grid>
        <Grid container direction="column" alignItems="center" className={classes.itemWrap}>
          <Typography className={`${common.title4} ${classes.label2}`}>LINE送信</Typography>
          <Typography className={common.title3}>
            <span className={!countState.lineSend ? classes.red : ''}>
              {countState.lineSend}
            </span>
          </Typography>
        </Grid>
      </Grid>
      <div className={classes.line}> </div>
      <Grid className={classes.wrap}>
        <Grid container direction="column" alignItems="center" className={classes.itemWrap}>
          <Typography className={`${common.title4} ${classes.label2}`}>指示あり</Typography>
          <Typography className={common.title3}>
            <span className={countState.direct ? classes.red : ''}>
              {countState.direct}
            </span>
          </Typography>
        </Grid>
        <Grid container direction="column" alignItems="center" className={classes.itemWrap}>
          <Typography className={`${common.title4} ${classes.label2}`}>承認待ち</Typography>
          <Typography className={common.title3}>
            <span className={countState.approve ? classes.red : ''}>
              {countState.approve}
            </span>
          </Typography>
        </Grid>
      </Grid>
      <div className={classes.line}> </div>
    </Grid>
  );
}

export default MainmenuStatus;
