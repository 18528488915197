import { Grid } from '@material-ui/core';
// リスト
import FlyerSalesTreeListResponse from './parts/treeList';

function FlyerSalesTreeResponseWrapper(props) {
  const {
    list,
    setSelectUser,
    setSelectDivision,
    setSelectFlyer,
  } = props;

  return (
    <Grid container justifyContent="flex-end" alignItems="center">
      <FlyerSalesTreeListResponse
        list={list}
        setSelectUser={setSelectUser}
        setSelectDivision={setSelectDivision}
        setSelectFlyer={setSelectFlyer}
      />
    </Grid>
  );
}

export default FlyerSalesTreeResponseWrapper;
