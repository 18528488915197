import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, List, ListItem, Button, Typography } from '@material-ui/core';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import MemberStatusCode from '../../../eleCommon/memberStatusCode';
import commonStyles from '../../../styles';
import MemberInfoDetail from '../memberInfoDetail';
import MemberChange from '../memberChange';
import ChanceTable from '../chanceTable';
import LoanTable from '../loanTable';
import BrowsingTable from '../browsingTable';
import SearchTable from '../searchTable';
import ContentsTable from '../contentsTable';
import OneclickTable from '../oneclickTable';
import FavoritesTable from '../favoritesTable';
import ProcessTableSp from './processTableSp';
import RelatedMemberListSp from './relatedMemberListSp';
import CustomScrollDialog from '../../../eleCommon/sp/customScrollDialog';
import CustomSimpleDrawer from '../../../eleCommon/sp/customSimpleDrawer';

const useStyles = makeStyles({
  list: {
    padding: 0,
    background: '#fff',
    '&:not(&:first-child)': {
      marginTop: 16,
    },
  },
  item: {
    padding: '0 0 0 16px',
    '& > div': {
      width: '100%',
    },
  },
  btn: {
    width: '100%',
    padding: 16,
    borderBottom: '1px solid #F3F3F3',
    '& .MuiButton-label': {
      justifyContent: 'flex-start',
    },
  },
  icon: {
    width: 15,
    marginRight: 4.5,
  },
  status: {
    marginLeft: 'auto',
    display: 'flex',
  },
  related: {
    marginLeft: 'auto',
    display: 'flex',
    '& > p + p': {
      marginLeft: 8,
    },
  },
});

export default function BrowsingHistoryList(props) {
  const {
    state,
    setState,
    updateStatusChange,
    adjustPriceText,
  } = props;

  const classes = useStyles();
  const common = commonStyles();

  const [open1, setOpen1] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);
  const [open4, setOpen4] = useState(false);
  const [open5, setOpen5] = useState(false);
  const [open6, setOpen6] = useState(false);
  // const [open7, setOpen7] = useState(false);
  const [open8, setOpen8] = useState(false);
  const [open9, setOpen9] = useState(false);
  const [open10, setOpen10] = useState(false);
  const [open11, setOpen11] = useState(false);
  const [open12, setOpen12] = useState(false);

  const fullName = `${state.member.lastName ?? ''} ${state.member.firstName ?? ''}`;

  // ステータスのモーダルをClose
  const handleClose3 = () => {
    setOpen3(!open3);
  };

  // クリックで表示される要素がモーダルかドラワー
  const switchType = (data) => {
    switch (data.type) {
      case 'dialog': {
        return (
          <CustomScrollDialog
            color={data.color}
            open={data.open}
            setOpen={data.setOpen}
            lbl={data.lbl}
            content={data.content}
          />);
      }
      case 'drawer':
        return (
          <CustomSimpleDrawer
            open={data.open}
            setOpen={data.setOpen}
            onClose={data.onClose}
            title={data.lbl}
            list={data.content}
          />
        );
      default:
        return null;
    }
  };

  const getContent = (index) => {
    switch (index) {
      case 1:
        return (<RelatedMemberListSp
          list={state.webs}
          setOpen={setOpen1}
          state={state}
          setState={setState}
        />);
      case 2:
        return <MemberInfoDetail detail={state.member} />;
      case 3:
        return (<MemberChange
          statusCode={state.member.memberStatusCode}
          onClose={handleClose3}
          onChange={updateStatusChange}
        />);
      case 4:
        return <ProcessTableSp list={state.histories.histories} />;
      case 5:
        return <ChanceTable isSP data={state.notifications} />;
      case 6:
        return <LoanTable isSP data={state.loan} />;
      case 8:
        return (<BrowsingTable
          isSP
          data={state.productsHistories}
          adjustPriceText={adjustPriceText}
        />);
      case 9:
        return <SearchTable isSP data={state.searchConditions} />;
      case 10:
        return <ContentsTable isSP data={state.contentsHistories} />;
      case 11:
        return <OneclickTable isSP data={state.oneclickSearchConditions} />;
      case 12:
        return (<FavoritesTable
          isSP
          data={state.favorites}
          adjustPriceText={adjustPriceText}
        />);
      default:
        return null;
    }
  };

  const dataList = [
    {
      key: 0,
      data: [
        {
          type: 'drawer',
          type2: 'relatedMemberId',
          lbl: `関連Web会員ID(${state.webs.length})`,
          name: fullName,
          state: state.member.memberId,
          setState: () => {}, // TODO:APIが見当たらないので、一旦仮で置き
          open: open1,
          setOpen: setOpen1,
          content: getContent(1),
        },
        {
          type: 'dialog',
          color: 'gray',
          lbl: '会員情報詳細',
          open: open2,
          setOpen: setOpen2,
          content: getContent(2),
        },
        {
          type: 'drawer',
          type2: 'status',
          lbl: '会員ステータス',
          state: state.member.memberStatusCode,
          open: open3,
          setOpen: setOpen3,
          onClose: handleClose3,
          content: getContent(3),
          disabled: state.member.memberStatusCode === null
           || state.member.memberStatusCode === undefined,
        },
        {
          type: 'dialog',
          lbl: '会員ステータスの変更履歴',
          open: open4,
          setOpen: setOpen4,
          content: getContent(4),
        },
      ],
    },
    {
      key: 1,
      data: [
        {
          type: 'dialog',
          lbl: 'チャンス通知',
          open: open5,
          setOpen: setOpen5,
          content: getContent(5),
        },
        {
          type: 'dialog',
          lbl: 'ローンシミュレーション',
          open: open6,
          setOpen: setOpen6,
          content: getContent(6),
        },
        {
          type: 'dialog',
          lbl: '閲覧履歴',
          open: open8,
          setOpen: setOpen8,
          content: getContent(8),
        },
        {
          type: 'dialog',
          lbl: '検索条件履歴',
          open: open9,
          setOpen: setOpen9,
          content: getContent(9),
        },
        {
          type: 'dialog',
          lbl: 'コンテンツ閲覧履歴',
          open: open10,
          setOpen: setOpen10,
          content: getContent(10),
        },
        {
          type: 'dialog',
          lbl: 'ワンクリック検索条件履歴',
          open: open11,
          setOpen: setOpen11,
          content: getContent(11),
        },
        {
          type: 'dialog',
          lbl: 'お気に入りリスト',
          open: open12,
          setOpen: setOpen12,
          content: getContent(12),
        },
      ],
    },
  ];

  return (
    <Grid>
      {dataList.map((item) => (
        <List key={item.key} className={classes.list}>
          {item.data.map((o) => (
            <ListItem key={o.lbl} className={classes.item}>
              <Grid>
                <Button
                  onClick={() => { o.setOpen(!o.open); }}
                  className={classes.btn}
                  disabled={o.disabled}
                >
                  {['dialog', 'fullBodyDialog'].includes(o.type) && (
                    <MenuBookIcon className={classes.icon} />
                  )}
                  {o.lbl}
                  {/* 関連Web会員IDの場合 */}
                  {o.type2 === 'relatedMemberId' && (
                    <Grid className={classes.related}>
                      <Typography className={`${common.title4}`}>
                        {o.state}
                      </Typography>
                      <Typography className={`${common.title4}`}>
                        {o.name}
                      </Typography>
                    </Grid>
                  )}
                  {/* ステータスの場合 */}
                  {o.type2 === 'status' && (
                    <Grid className={classes.status}>
                      <MemberStatusCode
                        code={state.member.memberStatusCode}
                        hideNonMemberIcon
                      />
                    </Grid>
                  )}
                </Button>
              </Grid>
              {switchType(o)}
            </ListItem>
          ))}
        </List>
      ))}
    </Grid>
  );
}
