import { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  AppBar,
  Button,
  Grid,
  Tab,
  Tabs,
  Typography,
} from '@material-ui/core';
import { Refresh } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import commonStyles from '../styles';
import UnsentPagenation from './parts/unsentPagenation';
import UnsentListTable from './parts/unsentListTable';
import UnsentSmsListTable from './parts/unsentSmsListTable';
import { useLocalStorage } from '../../hooks';
import { TAB_INDEX_UNSEND_MAIL_SINGLE, TAB_INDEX_UNSEND_MAIL_MULTI, TAB_INDEX_UNSEND_SMS } from '../../constants';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '16px',
    background: '#fff',
    height: 'calc(100vh - 52px)',
  },
  appBar: {
    color: '#333',
    background: 'none',
    width: 800,
    boxShadow: 'none',
    position: 'relative',
    '& .MuiTabs-indicator': {
      background: theme.palette.primaryColor,
    },
  },
  tabs: {
    minHeight: 'auto',
  },
  tab: {
    width: '50%',
    minWidth: 'auto',
    minHeight: 'auto',
    padding: '8px 0',
    '&.Mui-selected': {
      color: theme.palette.primaryColor,
    },
    '& .MuiTab-wrapper': {
      fontFamily: 'Roboto',
      fontSize: '13px',
      lineHeight: '1.6',
      letterSpacing: '0',
    },
  },
  tabPanel: {
    '& .MuiBox-root': {
      padding: 0,
    },
  },
  header: {
    marginBottom: '32px',
    alignItems: 'center',
  },
  refresh: {
    color: theme.palette.primaryColor,
    margin: '0 40px',
    '& .MuiSvgIcon-root': {
      width: '16px',
    },
  },
  sticky: {
    position: 'sticky',
    top: 0,
    background: '#fff',
    zIndex: 1,
    marginTop: -24,
    marginBottom: 24,
  },
}));
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Grid
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Grid p={3}>
          <Grid>{children}</Grid>
        </Grid>
      )}
    </Grid>
  );
}
TabPanel.propTypes = {
  children: PropTypes.node.isRequired,
  index: PropTypes.node.isRequired,
  value: PropTypes.node.isRequired,
};

export default function UnsentMailComponent(props) {
  const {
    list,
    smsList,
    refresh,
    functions,
    smsFunctions,
    mailCount,
    smsCount,
    initialParam,
    isSp,
    isSmsTab,
    setIsSmsTab,
  } = props;
  const classes = useStyles();
  const common = commonStyles();
  // タブの値
  const [tabValue, setTabValue] = useState(0);
  // ページネーション offset and limit
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(0);
  const [mailCode, setMailCode] = useState(0);
  const { getLocalStorage, addLocalStorage } = useLocalStorage();
  const [pageCount, setPageCount] = useState(0);

  const options = useRef({
    offset,
    limit,
    mailCode,
  });

  useEffect(() => {
    const displayLimit = getLocalStorage();
    if (!displayLimit) {
      setLimit(20);
      addLocalStorage(20);
    } else if (limit !== Number(displayLimit)) {
      setLimit(Number(displayLimit));
    }
  }, []);

  useEffect(() => {
    if (!isSp && limit > 0) {
      smsFunctions.getUnSendSmsList({ offset, limit });
      // tabindexをmailCodeに流用している作りのため、smsタブ選択時はgetUnSendListを叩かないようにする
      if (!isSmsTab) {
        functions.getUnSendList({ offset, limit, mailCode });
      }
      options.current = {
        offset,
        limit,
        mailCode,
      };
    }
    if (!isSp) {
      initialParam.current.offset = offset;
      initialParam.current.limit = limit;
    }
  }, [offset, limit, tabValue]);

  // タブの切り替えEvent
  const changeTab = (event, newValue) => {
    setTabValue(newValue);
    setOffset(0);
    setMailCode(newValue);
    setPageCount(0);
    options.current = {
      offset: 0,
      limit: 20,
      mailCode: newValue,
    };
    initialParam.current.mailCode = newValue;
    setIsSmsTab(newValue === TAB_INDEX_UNSEND_SMS);
  };

  return (
    <Grid className={classes.root}>
      <div className={classes.sticky}>
        <Grid container className={classes.header}>
          <Typography className={common.title2}>未送信メール/SMS</Typography>
          <Button
            className={classes.refresh}
            onClick={refresh}
          >
            <Refresh />
            <Typography className={common.smallStrong}>更新</Typography>
          </Button>
          <UnsentPagenation
            rowsPerPageOptions={[20, 40, 60]}
            rows={tabValue}
            limit={limit}
            offset={offset}
            setOffset={setOffset}
            setLimit={setLimit}
            setMailCount={setMailCode}
            mailCount={mailCount}
            smsCount={smsCount}
            pageCount={pageCount}
            setPageCount={setPageCount}
            isSmsTab={isSmsTab}
          />
        </Grid>
      </div>
      <AppBar className={classes.appBar}>
        <Tabs
          onChange={changeTab}
          className={classes.tabs}
          value={tabValue}
        >
          <Tab label={`個別メール (${mailCount.individuallyMailCount})`} {...a11yProps(TAB_INDEX_UNSEND_MAIL_SINGLE)} className={classes.tab} />
          <Tab label={`一斉メール (${mailCount.broadCastMailCount})`} {...a11yProps(TAB_INDEX_UNSEND_MAIL_MULTI)} className={classes.tab} />
          <Tab label={`個別SMS (${smsCount.individuallySmsCount})`} {...a11yProps(TAB_INDEX_UNSEND_SMS)} className={classes.tab} />
        </Tabs>
      </AppBar>
      <TabPanel value={tabValue} index={TAB_INDEX_UNSEND_MAIL_SINGLE} className={classes.tabPanel}>
        <UnsentListTable
          list={list.individual}
          single
          label="個別メール"
          page={offset}
          rowsPerPage={limit}
          functions={functions}
          tabValue={tabValue}
          options={options}
        />
      </TabPanel>
      <TabPanel value={tabValue} index={TAB_INDEX_UNSEND_MAIL_MULTI} className={classes.tabPanel}>
        <UnsentListTable
          list={list.all}
          label="一斉メール"
          page={offset}
          rowsPerPage={limit}
          functions={functions}
          tabValue={tabValue}
          options={options}
        />
      </TabPanel>
      <TabPanel value={tabValue} index={TAB_INDEX_UNSEND_SMS} className={classes.tabPanel}>
        <UnsentSmsListTable
          smsList={smsList.sms}
          label="個別SMS"
          page={offset}
          rowsPerPage={limit}
          smsFunctions={smsFunctions}
          tabValue={tabValue}
          options={options}
        />
      </TabPanel>
    </Grid>
  );
}
