/* eslint-disable max-len */
import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import {
  Typography,
  Grid,
  Button,
  Paper,
  Drawer,
} from '@material-ui/core';
import Draggable from 'react-draggable';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import { makeStyles } from '@material-ui/core/styles';
import commonStyles from '../../styles';
import commonTheme from '../../styles/theme';
import DateField from './parts/dateField';
import SelectAct from './parts/selectAct';
import Store from './parts/store';
import Product from './parts/product';
import Memo from './parts/memo';
import Achievment from './parts/achievment';
import UserTree from '../../common/userTreeNew';
import { dateFormatter } from '../../../commonFunction';
import store from '../../../store';
import Modal from '../../common/modal';
import { ACTION_TYPE_FLYER_SALES, ACTION_TYPE_FLYER_SALES_WITHOUT_PORTER } from '../../../constants';

const useStyles = makeStyles((theme) => ({
  dialog: {
    [commonTheme.breakpoints.down('sm')]: {
      '& .MuiPaper-root': {
        width: '100%',
        background: '#F3F3F3',
      },
      '& .MuiDialog-paper': {
        maxWidth: 'none',
        margin: 0,
        width: '100%',
        height: '100vh',
        maxHeight: 'initial',
      },
      '& .MuiDialog-paperWidthSm': {
        maxWidth: 'none',
        borderRadius: 0,
      },
    },
    '& .MuiDialog-paperWidthSm': {
      maxWidth: 'none',
    },
  },
  dialogHeader: {
    width: 640,
    background: '#fff',
    padding: '24px 24px 9px 24px',
    boxSizing: 'border-box',
  },
  dialogContent: {
    width: 640,
    backgroundColor: '#F3F3F3',
    borderTop: '1px solid #C8C8C8',
    borderBottom: '1px solid #C8C8C8',
    padding: '24px',
    boxSizing: 'border-box',
  },
  dialogFooter: {
    display: 'flex',
    [commonTheme.breakpoints.down('sm')]: {
      justifyContent: 'center',
      padding: '16px 0',
      position: 'fixed',
      left: 0,
      bottom: 0,
      width: '100%',
      background: '#fff',
    },
    [commonTheme.breakpoints.up('md')]: {
      justifyContent: 'space-around',
      padding: '16px 200px',
    },
  },
  releaseButton: {
    color: '#D83420',
    '& .MuiSvgIcon-root': {
      fontSize: 18,
    },
  },
  selectButton: {
    width: '74px',
    height: '40px',
    color: '#fff',
  },
  drawer1: {
    zIndex: '1201!important',
    '& .MuiDrawer-paper': {
      borderRadius: '12px 12px 0 0',
      padding: '0 0 40px 16px',
    },
  },
  drawer1Btn: {
    display: 'block',
    borderBottom: '1px solid #F3F3F3',
    padding: '18px 16px',
    '& .MuiButton-label': {
      fontFamily: 'Roboto',
      fontWeight: 700,
      fontSize: 16,
      lineHeight: 1.2,
      letterSpacing: 0,
      textAlign: 'left',
    },
  },
  drawer2: {
    zIndex: '1202!important',
    '& .MuiDrawer-paper': {
      height: '100%',
      background: '#F3F3F3',
    },
  },
  drawerHeader: {
    textAlign: 'center',
    fontSize: '24px',
    padding: '16px 16px 8px 16px',
    width: '100%',
    boxSizing: 'border-box',
    background: '#fff',
  },
  drawerHeader2: {
    marginBottom: 16,
  },
  drawerContent: {
    background: '#F3F3F3',
    [commonTheme.breakpoints.down('sm')]: {
      paddingBottom: 72,
    },
  },
  drawerLbl: {
    padding: '16px 8px 16px 16px',
  },
  guidanceButton: {
    fontFamily: 'Roboto',
    fontWeight: 700,
    fontSize: 13,
    lineHeight: 1.6,
    color: theme.palette.primaryColor,
    display: 'flex',
    margin: '0 0 4px auto',
    '& .MuiSvgIcon-root': {
      fontSize: 14,
      marginLeft: 2,
    },
  },
  deleteButton: {
    color: '#0679BF',
    margin: '40px auto 0',
    display: 'flex',
    fontSize: 15,
    '& svg': {
      fontSize: 18,
    },
  },
}));

const force2Digits = (value) => {
  return value.toString().padStart(2, '0');
};

// date：行動登録の日時(from)初期値設定
const defaultStartDateTime = () => {
  const now = new Date();
  const startDateTime = now;
  const currentMinutes = now.getMinutes();
  if ((currentMinutes + 30) > 30 && (currentMinutes + 30) < 60) {
    startDateTime.setHours(startDateTime.getHours() + 1);
    startDateTime.setMinutes(0);
  }
  if ((currentMinutes + 30) > 60) {
    startDateTime.setHours(startDateTime.getHours() + 1);
    startDateTime.setMinutes(30);
  }
  return {
    time: startDateTime.toLocaleString(),
    strings: `${startDateTime.getFullYear()}/${force2Digits(startDateTime.getMonth() + 1)}/${force2Digits(startDateTime.getDate())} ${force2Digits(startDateTime.getHours())}:${force2Digits(startDateTime.getMinutes())}`,
  };
};

// date：行動登録の日時(to)初期値設定
const defaultEndDateTime = () => {
  const defaultStart = new Date(defaultStartDateTime().time);
  const endDateTime = new Date(defaultStart.setHours(defaultStart.getHours() + 2));
  return {
    time: endDateTime.toLocaleString(),
    strings: `${endDateTime.getFullYear()}/${force2Digits(endDateTime.getMonth() + 1)}/${force2Digits(endDateTime.getDate())} ${force2Digits(endDateTime.getHours())}:${force2Digits(endDateTime.getMinutes())}`,
  };
};

function PaperComponent(props) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  );
}

function ActRegistWindow(props) {
  const {
    open,
    onClose,
    onCloseParent,
    setReacquisitionFlg,
    targetObj,
    setTargetObj,
    values,
    setUpdateData,
    actRegistFlag,
    editableFlag,
    selectDivision,
    setSelectDivision,
    archieveObj,
    setArchieveObj,
    setOpenCustomertree,
    isFinished,
    isSource,
    isEdit,
    createUpdateFunc,
    schedulesDeleteApiFunction,
    date = '',
  } = props;

  // user情報をhttpResponseHeaderから取得 (https://openhouse.backlog.jp/alias/wiki/1132107)
  const { responseHeader } = store.getState();

  const [errorSet, setErrorSet] = useState(new Set());

  const errorCallback = (key) => (hasError) => {
    setErrorSet((prev) => {
      if (hasError) return new Set(prev.add(key));
      prev.delete(key);
      return new Set(prev);
    });
  };

  const [date1, setDate1] = useState({
    assignAt: defaultStartDateTime().strings,
  });

  const [date2, setDate2] = useState({
    assignAt: defaultEndDateTime().strings,
  });

  const actionScheduleEndDateTime = (d) => {
    const defaultStart = new Date(d);
    const endDateTime = new Date(defaultStart.setHours(defaultStart.getHours() + 2));
    return {
      strings: `${endDateTime.getFullYear()}/${force2Digits(endDateTime.getMonth() + 1)}/${force2Digits(endDateTime.getDate())} ${force2Digits(endDateTime.getHours())}:${force2Digits(endDateTime.getMinutes())}`,
    };
  };

  // /**
  //  * 登録時に送るパラメータ
  //  * @param {string} startAt 開始時間（必須）
  //  * @param {string} endAt 終了時間（必須）
  //  * @param {number} actionScheduleCode 行動予定区分コード（必須）
  //  * @param {number} siteId 現場ID（任意）
  //  * @param {number} divisionId 所属ID（任意）
  //  * @param {number} userId ユーザーID（任意）
  //  * @param {number} targetEnqueteTotal 目標カード枚数（任意）
  //  * @param {number} targetSearchHouseEnquete 目標内、探しカード（任意）
  //  * @param {number} targetTelephoneAppointment 目標TELアポ（任意）
  //  * @param {number} targetGuidanceCount 目標案内（任意）
  //  * @param {number} targetPromptPlanCount 目標即案（任意）
  //  * @param {number} targetVisitCount 目標来社（任意）
  //  * @param {number} resultEnqueteTotal 結果カード枚数（任意）
  //  * @param {number} resultSearchHouseEnquete 結果内、探しカード（任意）
  //  * @param {number} resultTelephoneAppointment 結果TELアポ（任意）
  //  * @param {number} resultGuidanceCount 結果案内（任意）
  //  * @param {number} resultPromptPlanCount 結果即案（任意）
  //  * @param {number} resultVisitCount 結果来社（任意）
  //  * @param {number} flyerSalesCode 源泉コード（任意）
  //  * @param {string} memo メモ（任意）
  //  * @param {number} latitude 緯度（任意）
  //  * @param {number} longitude 経度（任意）
  //  */

  // ラベルの更新制御
  const updateRndRef = useRef({
    flg: false,
    type: '', // regist or delete
  });
  const valuesRef = useRef({});
  const [isChecked, setISchecked] = useState(true); // 初期値が源泉（ポーター）であるため
  const [actFilled, setActFilled] = useState('6'); // 登録ボタンの活性・非活性判定用
  // 店舗
  const [isOpenOrgTree, setIsOpenOrgTree] = useState(false);
  // 種類
  const [actionType, setActionType] = useState(6);
  // 登録ボタンの活性・非活性
  const [isRegistButtonDisable, setIsRegistButtonDisable] = useState(false);
  // 源泉（駅前、物件前、その他）が選択されているかどうか
  const [isGensen, setIsGensen] = useState(false);
  // メモに値が入っているかどうか
  const [isMemo, setIsMemo] = useState(false);

  const addFlag = 1;
  const classes = useStyles();
  const baseClasses = commonStyles();

  // 入力値の初期化
  const handleClear = () => {
    // 時刻
    setDate1({ assignAt: defaultStartDateTime().strings });
    setDate2({ assignAt: defaultEndDateTime().strings });
    // 種類(初期化は編集時には実行せず。※編集直後に遷移する詳細画面にて、強制的に種別がポーターとなってしまうため。)
    if (!editableFlag) {
      setActionType(6);
    }
    setActFilled('6');
  };

  // レスポンスで送る更新データをコピー
  const moldingParameter = (resultDivision) => {
    const copyObj = Object.assign({}, resultDivision);
    return copyObj;
  };

  // SP・PCでの分岐用
  const isSp = useSelector((state) => state.deviceTypeSlice.isSp);
  let contents;

  // モーダル用
  const [open1, setOpen1] = useState(false);
  const handleOpen1 = () => {
    setOpen1(true);
  };
  const handleClose1 = () => {
    console.log('editableFlag');
    setOpen1(false);
    onClose();
  };

  // 登録ボタン押下
  const handleActRegist = () => {
    // ラベルの再取得
    setReacquisitionFlg(true);
    updateRndRef.current = {
      flg: true,
      type: 'regist',
    };
  };

  const handleDelete = () => {
    // ラベルの再取得
    setReacquisitionFlg(true);
    updateRndRef.current = {
      flg: true,
      type: 'delete',
    };
  };

  // https://openhouse.backlog.jp/view/SFA_ASIAQUEST-4892
  const ACTION_TYPE = {
    6: '源泉(駅前)',
    2: '源泉(物件前)',
    1: '源泉(ポーター)',
    7: '源泉(その他)',
    3: '追客',
    4: '業務',
    5: 'その他',
  };

  const menus = [6, 2, 1, 7, 3, 4, 5];

  const onSelect = async (val) => {
    await setActionType(val);
    setUpdateData({
      ...values,
      actionScheduleCode: Number(val),
    });
  };

  useEffect(() => {
    if (ACTION_TYPE_FLYER_SALES.includes(Number(actionType))) {
      setISchecked(true);
    } else {
      setISchecked(false);
    }
    if (ACTION_TYPE_FLYER_SALES_WITHOUT_PORTER.includes(Number(actionType))) {
      setIsGensen(true);
    } else {
      setIsGensen(false);
    }
  }, [actionType]);

  // 登録の活性・非活性A
  useEffect(() => {
    // ◇登録ボタンの活性/非活性 日時と種類と店舗の入力有無で判断
    const isNotDate = !date1.assignAt || !date2.assignAt;
    const isNotDivision = ['', '-'].includes(selectDivision.divisionName);
    // メモが必須の場合はチェックする
    const isNotMemo = isGensen ? !isMemo : false;
    setIsRegistButtonDisable(isNotDate || isNotDivision || isNotMemo);
  }, [date1, date2, actionType, selectDivision.divisionName, isGensen, isMemo]);

  // 登録 & 削除処理
  // useEffectでやらないとreacquisitionFlgがtrueに切り替わるタイミングが1テンポ送れるため
  // getSchedulesDateが実行されないので注意
  // src/containers/actionSchedule/actionScheduleContext.js
  useEffect(() => {
    if (!updateRndRef.current.flg) {
      return;
    }
    if (updateRndRef.current.type === 'regist') {
      if (!values.flyerSalesCode) values.flyerSalesCode = 0;
      const updateSchedule = moldingParameter(values);
      // siteNameはAPIで送信しない
      delete updateSchedule.siteName;
      createUpdateFunc(updateSchedule);
    } else if (updateRndRef.current.type === 'delete') {
      // delete処理
      schedulesDeleteApiFunction(values.scheduleId);
    }
    handleClear();
    onClose();
    if (onCloseParent) {
      // 編集から来た場合、親を閉じる
      onCloseParent();
    }
    if (open1) {
      handleClose1();
    }
  }, [updateRndRef.current]);

  // 組織変更時に更新データに格納
  useEffect(() => {
    setUpdateData({
      ...values,
      divisionId: selectDivision.divId,
    });
  }, [selectDivision]);

  // 未選択の状態からUserTreeqを開いた場合は、自分の組織を選択して表示
  // https://openhouse.backlog.jp/view/SFA_ASIAQUEST-4379
  useEffect(() => {
    if (!isOpenOrgTree) {
      return;
    }
    if (!selectDivision?.divId) {
      // 自分の所属している組織を選択する
      if (responseHeader?.mainDivision?.id) {
        setSelectDivision({
          ...selectDivision,
          divId: responseHeader.mainDivision.id,
          divisionName: responseHeader.mainDivision.name,
        });
      }
    }
  }, [isOpenOrgTree]);

  // 初期化時に自身の所属する組織を選択
  useEffect(() => {
    if (!open) {
      return;
    }
    if (isSource) {
      setOpen1(true);
    }
    // 前回と同じDivisionの場合useEffectがコールされないので一度初期化
    setSelectDivision({
      divId: 0,
      divisionName: '-',
    });
    if (editableFlag) {
      // 編集画面ですでに初期値が設定されている場合
      const start = new Date(values.startAt);
      const end = new Date(values.endAt);
      // 時刻
      setDate1({ assignAt: dateFormatter(start, 'YYYY/MM/DD hh:mm') });
      setDate2({ assignAt: dateFormatter(end, 'YYYY/MM/DD hh:mm') });
      // 種類
      setActionType(values.actionScheduleCode);
      // エリア
      setSelectDivision({
        divId: values.divisionId,
        divisionName: values.divisionName,
      });
      // メモ
      setIsMemo(!!values.memo);
    } else {
      // 新規入力の場合
      let start = new Date(date1.assignAt);
      let end = new Date(date2.assignAt);

      if (date !== '') {
        // 行動予定表からの登録の場合
        const startDate = dateFormatter(date, 'YYYY/MM/DD') + date1.assignAt.substr(10, 6);
        const endDate = actionScheduleEndDateTime(startDate).strings;
        setDate1({ assignAt: startDate });
        setDate2({ assignAt: endDate });
        start = new Date(startDate);
        end = new Date(endDate);
      }

      setUpdateData({
        actionScheduleCode: actionType,
        startAt: dateFormatter(start),
        endAt: dateFormatter(end),
      });
      // 自分の所属している組織を選択する
      if (responseHeader?.mainDivision?.id) {
        setSelectDivision({
          ...selectDivision,
          divId: responseHeader.mainDivision.id,
          divisionName: responseHeader.mainDivision.name,
        });
      }
    }
  }, [open, responseHeader]);

  useEffect(() => {
    if (!editableFlag) {
      return;
    }
    // 編集の時
    if (open) {
      console.log('open', values);
      // オープン時にvaluesを退避
      valuesRef.current = Object.assign({}, values);
    } else {
      if (!updateRndRef.current.flg) {
        // 閉じた時にアップデートフラグがfalseの場合は元の値に戻す
        setUpdateData(valuesRef.current);
      }
      // 初期値に戻す
      updateRndRef.current = {
        flg: false,
        type: '',
      };
      valuesRef.current = {};
    }
  }, [open]);

  if (isSp) {
    contents = (
      <>
        <Drawer
          anchor="bottom"
          open={open && actRegistFlag}
          onClose={onClose}
          className={classes.drawer1}
        >
          {menus.map((data) => {
            return (
              <Button
                key={data}
                className={classes.drawer1Btn}
                onClick={() => {
                  onSelect(data);
                  handleOpen1();
                }}
              >
                {ACTION_TYPE[data]}
              </Button>
            );
          })}
        </Drawer>
        <Modal
          anchor="top"
          open={open1 || (open && editableFlag)}
          onClose={handleClose1}
          className={classes.drawer2}
          title={isChecked ? (
            <span>源泉</span>
          ) : (
            <span>行動予定</span>
          )}
          buttons={[{
            className: `${baseClasses.buttonsPrimary} ${classes.selectButton}`,
            onClick: handleActRegist,
            disabled: isRegistButtonDisable || errorSet.size !== 0,
            label: '登録',
          }]}
        >
          {isChecked && (
            <Typography className={`${baseClasses.title4} ${classes.drawerLbl}`}>予定情報</Typography>
          )}
          {/* 日時 */}
          <DateField
            date1={date1}
            setDate1={setDate1}
            date2={date2}
            setDate2={setDate2}
            addFlag={addFlag}
            values={values}
            setUpdateData={setUpdateData}
          />
          {/* 種類 */}
          <SelectAct
            onCloseParent={onCloseParent || onClose}
            setISchecked={setISchecked}
            actRegistFlag={actRegistFlag}
            editableFlag={editableFlag}
            actFilled={actFilled}
            setActFilled={setActFilled}
            code={ACTION_TYPE}
            actionType={actionType}
            setActionType={setActionType}
            menus={menus}
            disabled={isSource}
            values={values}
            setUpdateData={setUpdateData}
          />
          {/* 店舗 */}
          <Store
            setIsOpenOrgTree={setIsOpenOrgTree}
            isOpenOrgTree={isOpenOrgTree}
            selectDivision={selectDivision}
          />
          {isOpenOrgTree && (
            <UserTree
              isOpenTree={isOpenOrgTree}
              setIsOpenTree={setIsOpenOrgTree}
              selectDivision={selectDivision}
              setSelectDivision={setSelectDivision}
              showOnlyDivisions
              isDialog
            />
          )}
          {/* 現場 */}
          {isChecked && (
            <Product editableFlag={editableFlag} values={values} setUpdateData={setUpdateData} />
          )}
          {/* メモ */}
          <Memo
            editableFlag={editableFlag}
            values={values}
            setUpdateData={setUpdateData}
            errorCallback={errorCallback}
            isGensen={isGensen}
            setIsMemo={setIsMemo}
          />
          {isChecked && (
            <Typography className={`${baseClasses.title4} ${classes.drawerLbl}`}>
              目標
            </Typography>
          )}
          {isChecked && (
            <Achievment
              actRegistFlag={actRegistFlag}
              editableFlag={editableFlag}
              targetObj={targetObj}
              setTargetObj={setTargetObj}
              archieveObj={archieveObj}
              setArchieveObj={setArchieveObj}
              values={values}
              setUpdateData={setUpdateData}
              isSource={isSource}
              isFinished={isFinished}
              isEdit={isEdit}
              errorCallback={errorCallback}
            />
          )}
          <Button
            className={classes.deleteButton}
            onClick={handleDelete}
            disabled={actRegistFlag}
          >
            <DeleteOutlineIcon />
            削除
          </Button>
        </Modal>
      </>
    );
  } else {
    contents = (
      <Modal
        title="行動登録ウィンドウ"
        width={640}
        open={open}
        onClose={onClose}
        PaperComponent={PaperComponent}
        className={classes.dialog}
        buttons={[{
          onClick: handleDelete,
          className: `${baseClasses.strong} ${classes.releaseButton}`,
          disabled: actRegistFlag,
          label: '削除',
        }, {
          className: `${baseClasses.buttonsPrimary} ${classes.selectButton}`,
          onClick: handleActRegist,
          disabled: isRegistButtonDisable || errorSet.size !== 0,
          label: '登録',
        }]}
      >
        {isChecked && (
          <Typography style={{ marginBottom: 10 }} className={baseClasses.title4}>予定</Typography>
        )}
        {/* 日時 */}
        <DateField
          date1={date1}
          setDate1={setDate1}
          date2={date2}
          setDate2={setDate2}
          addFlag={addFlag}
          values={values}
          setUpdateData={setUpdateData}
        />
        {/* 種類 */}
        <SelectAct
          onCloseParent={onCloseParent || onClose}
          setISchecked={setISchecked}
          actRegistFlag={actRegistFlag}
          editableFlag={editableFlag}
          actFilled={actFilled}
          setActFilled={setActFilled}
          code={ACTION_TYPE}
          actionType={actionType}
          setActionType={setActionType}
          menus={menus}
          setOpenCustomertree={setOpenCustomertree}
          values={values}
          setUpdateData={setUpdateData}
          disabled={isSource}
        />
        {/* 店舗 */}
        <Store setIsOpenOrgTree={setIsOpenOrgTree} isOpenOrgTree={isOpenOrgTree} selectDivision={selectDivision} />
        {isOpenOrgTree && (
          <UserTree
            isOpenTree={isOpenOrgTree}
            setIsOpenTree={setIsOpenOrgTree}
            selectDivision={selectDivision}
            setSelectDivision={setSelectDivision}
            showOnlyDivisions
            isDialog
          />
        )}
        {/* 現場 */}
        {isChecked && (
          <Product editableFlag={editableFlag} values={values} setUpdateData={setUpdateData} />
        )}
        {/* メモ */}
        <Memo
          editableFlag={editableFlag}
          values={values}
          setUpdateData={setUpdateData}
          errorCallback={errorCallback}
          isGensen={isGensen}
          setIsMemo={setIsMemo}
        />
        {isChecked && (
          <Typography style={{ marginBottom: 10 }} className={baseClasses.title4}>{actRegistFlag ? '目標' : '実績/目標'}</Typography>
        )}
        {isChecked && (
          <Achievment
            actRegistFlag={actRegistFlag}
            editableFlag={editableFlag}
            targetObj={targetObj}
            setTargetObj={setTargetObj}
            values={values}
            setUpdateData={setUpdateData}
            archieveObj={archieveObj}
            setArchieveObj={setArchieveObj}
            isFinished={isFinished}
            isSource={isSource}
            isEdit={isEdit}
            errorCallback={errorCallback}
          />
        )}
      </Modal>
    );
  }

  return (
    <Grid>
      {contents}
    </Grid>
  );
}

export default ActRegistWindow;
