import React, { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  Typography,
  List,
  ListItem,
  Button,
  Select,
  MenuItem,
} from '@material-ui/core';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import commonStyles from '../../../../styles';
import commonTheme from '../../../../styles/theme';
import RegisterWindowDate from './registerWindowDate';
import RegisterWindowContent from './registerWindowContent';
import RegisterWindowSubject from './registerWindowSubject';
import RegisterWindowGuidance from './registerWindowGuidance';

const useStyles = makeStyles((theme) => ({
  dialog: {
    '& .MuiDialog-paperWidthSm': {
      maxWidth: 'none',
    },
  },
  paper: {
    width: 384,
    minWidth: 384,
    background: '#F3F3F3',
    padding: 8,
  },
  box: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    background: '#fff',
    border: '1px solid #C8C8C8',
    padding: 8,
    marginBottom: 8,
  },
  appBar: {
    width: 310,
    boxShadow: 'none',
  },
  tabs: {
    minHeight: 'auto',
    [commonTheme.breakpoints.down('sm')]: {
      background: '#F3F3F3',
    },
    [commonTheme.breakpoints.up('md')]: {
      background: '#fff',
    },
    '& .MuiTabs-flexContainer': {
      justifyContent: 'space-between',
    },
    '& .MuiTab-root': {
      minWidth: 'auto',
      padding: 0,
      opacity: '1',
      minHeight: 'auto',
    },
    '& .MuiTab-root.Mui-selected .MuiTypography-root': {
      color: theme.palette.primaryColor,
    },
    '& .MuiTab-root.Mui-selected .MuiSvgIcon-root': {
      color: theme.palette.primaryColor,
    },
    '& .MuiTab-root.Mui-selected .MuiIcon-root': {
      color: theme.palette.primaryColor,
    },
    '& .MuiTabs-indicator': {
      display: 'none',
    },
  },
  buttonClose: {
    background: '#fff',
    borderRadius: 4,
    display: 'flex',
    margin: '0 auto 8px',
    padding: '4px 4px 3px 8px',
    '& .MuiButton-label': {
      color: theme.palette.primaryColor,
      fontFamily: 'Roboto',
      fontSize: 13,
      fontWeight: 700,
      lineHeight: 1.6,
      letterSpacing: 0,
    },
    '& .MuiButton-startIcon': {
      marginBottom: 1,
    },
  },
  button: {
    color: '#8C8C8C',
    background: 'none',
    fontFamily: 'Roboto',
    fontSize: 10,
    fontWeight: 700,
    lineHeight: 1.2,
    letterSpacing: 0,
    padding: '0',
    margin: '0',
    boxShadow: 'none',
    minWidth: 'auto',
    width: 54,
    '& .MuiButton-label': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexWrap: 'wrap',
    },
    '& .MuiButton-startIcon': {
      display: 'block',
      margin: 0,
      order: 1,
    },
    '& .MuiButton-endIcon': {
      display: 'block',
      margin: 0,
      order: 2,
      textAlign: 'left',
    },
    '& .MuiButton-iconSizeMedium > *:first-child': {
      fontSize: 14,
    },
  },
  buttonIcon: {
    '& .MuiSvgIcon-root': {
      fontSize: 14,
    },
    '& .MuiIcon-root': {
      fontSize: 14,
    },
  },
  buttonTxt: {
    textAlign: 'center',
  },
  contentResponseLbl: {
    marginTop: 8,
  },
  contentResponseBox1: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 4,
  },
  contentResponseBox2: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 8,
  },
  btn1: {
    width: 85,
    padding: '3px 0',
    '& .MuiButton-label': {
      fontSize: 13,
    },
    '& .MuiSvgIcon-root': {
      fontSize: 13,
    },
    '& .MuiIcon-root': {
      fontSize: 13,
    },
    '& .MuiButton-startIcon': {
      margin: '0 4px 0 0',
    },
  },
  btn2: {
    width: 117,
    padding: '3px 0',
    '& .MuiButton-label': {
      fontSize: 13,
    },
    '& .MuiSvgIcon-root': {
      fontSize: 13,
    },
    '& .MuiIcon-root': {
      fontSize: 13,
    },
    '& .MuiButton-startIcon': {
      margin: '0 4px 0 0',
    },
  },
  content: {
    background: '#F3F3F3',
    padding: 24,
  },
  contentIpt: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    background: '#fff',
    border: '1px solid #C8C8C8',
    padding: '9px 8px',
    marginBottom: 8,
  },
  contentIpt3: {
    display: 'block',
  },
  contentIptLbl: {
    '& span': {
      color: '#D83420',
    },
  },
  contentIptVal2: {
    width: '100%',
    marginTop: 8,
  },
  // モーダル
  paper2: {
    width: 515,
    backgroundColor: '#fff',
    boxShadow:
      '0px 15px 12px rgba(51, 51, 51, 0.22), 0px 19px 38px rgba(51, 51, 51, 0.3)',
    borderRadius: 4,
    padding: '24px 24px 18px',
  },
  paperTtl: {
    textAlign: 'center',
    margin: '0 0 24px',
  },
  paperBox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  paperBtn: {
    color: theme.palette.primaryColor,
    fontFamily: 'Roboto',
    fontSize: 15,
    lineHeight: 1.6,
    letterSpacing: 0,
    margin: '0 60px',
  },
  // SP
  lbl: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '16px 8px 16px 32px',
  },
  lblTxt: {
    display: 'flex',
    alignItems: 'center',
    fontWeight: 700,
    '& p': {
      fontWeight: 700,
    },
  },
  list: {
    background: '#fff',
    padding: '0 0 0 16px',
    marginBottom: 16,
  },
  listItem: {
    padding: 0,
  },
  selectTtl: {
    paddingTop: 16,
  },
  selectBtn: {
    width: '100%',
    padding: '18px 0',
    textAlign: 'left',
    borderBottom: '1px solid #F3F3F3',
    '&.select': {
      color: theme.palette.primaryColor,
    },
    '& .MuiButton-label': {
      display: 'block',
      paddingLeft: 16,
    },
  },
  footer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    left: 0,
    bottom: 0,
    width: '100%',
    padding: '24px 0',
    background: '#fff',
    borderTop: '1px solid rgba(51, 51, 51, 0.04)',
  },
  footerBtn: {
    fontFamily: 'Roboto',
    fontSize: 15,
    fontWeight: '700',
    lineHeight: 1.6,
    letterSpacing: 0,
    padding: '8px 19px',
  },
}));

export default function RegisterWindowMail(props) {
  const {
    onClose,
    confirmOpen,
    getParts,
    getEditDialog,
    typeClose,
    updateHistoryParams,
    callCreateHistory,
  } = props;

  const classes = useStyles();
  const baseClasses = commonStyles();

  const REACTION_TEXT1 = {
    6: '送信',
    7: '受信',
  };

  const REACTION_TEXT2 = {
    8: '郵送',
  };

  const menus1 = Object.keys(REACTION_TEXT1);
  const menus2 = Object.keys(REACTION_TEXT2);

  // SP・PCでの分岐用
  const isSp = useSelector((state) => state.deviceTypeSlice.isSp);
  let contents;

  // 対応日時
  const now = new Date();
  const year = now.getFullYear();
  const month = ('00' + (now.getMonth() + 1)).slice(-2);
  const day = ('00' + now.getDate()).slice(-2);
  const hour = ('00' + now.getHours()).slice(-2);
  const min = ('00' + now.getMinutes()).slice(-2);
  const dateInitial = year + '/' + month + '/' + day + ' ' + hour + ':' + min;
  const [date, setDate] = useState(dateInitial);

  // 案内登録
  const [checked, setChecked] = useState(false);
  const checkChange = (e) => {
    setChecked(e.target.checked);
  };

  // 件名
  const [subject, setSubject] = useState('');

  // 対応内容
  const [content, setContent] = useState('');

  // 反応
  const [reactionCode, setReactionCode] = useState(0);
  const [reactionText, setReactionText] = useState('');

  // ボタン制御
  const [disabled, setDisabled] = useState(false);
  const [disabled2, setDisabled2] = useState(true);

  const onControl = (code) => {
    if (code === 0) {
      setDisabled2(true);
    } else {
      setDisabled2(false);
    }
  };

  // SP 項目選択時に更新
  const onSelect1 = (val) => {
    setReactionCode(val);
    setReactionText(REACTION_TEXT1[val]);
    onControl(val);
  };
  const onSelect2 = (val) => {
    setReactionCode(val);
    setReactionText(REACTION_TEXT2[val]);
    onControl(val);
  };

  // 初期化
  const handleInitial = () => {
    setSubject('');
    setContent('');
    setChecked(false);
    setDisabled(false);
    setDisabled2(true);
  };

  const isButtonClicked = useRef(false);

  // 登録処理
  const onRegister = (code) => {
    if (!isButtonClicked.current) {
      isButtonClicked.current = true;
      const params = {
        actionStartAt: date + ':00',
        // 郵送の時は8, メールの時は7
        actionCode: Number(code) === 8 ? 21 : 7,
        actionDetailCode: Number(code),
      };
      if (subject) {
        params.mailSubject = subject;
      }
      if (content) {
        params.content = content;
      }
      updateHistoryParams(params);
      if (checked) {
        confirmOpen();
      } else {
        callCreateHistory();
        onClose();
        typeClose();
        handleInitial();
      }
    }
  };

  // ボタン
  const mailBtns = [
    {
      txt: '送信',
      code: 6,
      icon: <MailOutlineIcon />,
    },
    {
      txt: '受信',
      code: 7,
      icon: <MailOutlineIcon />,
    },
    {
      txt: '郵送',
      code: 8,
      icon: '〒',
    },
  ];

  useEffect(() => {
    console.log('date', date);
  }, [date]);

  const getList = () => {
    return (
      <Grid>
        <RegisterWindowDate
          date={date}
          setDate={setDate}
          setDisabled={setDisabled}
          getParts={getParts}
          getEditDialog={getEditDialog}
        />
        <RegisterWindowGuidance
          checked={checked}
          checkChange={checkChange}
          getParts={getParts}
        />
        <RegisterWindowSubject
          subject={subject}
          setSubject={setSubject}
          getParts={getParts}
          getEditDialog={getEditDialog}
        />
        <RegisterWindowContent
          content={content}
          setContent={setContent}
          getParts={getParts}
          getEditDialog={getEditDialog}
        />
      </Grid>
    );
  };

  const getReaction = () => {
    return (
      <Select defaultValue={0}>
        <MenuItem value={0} disabled>未選択</MenuItem>
        <MenuItem disabled>
          メール
        </MenuItem>
        {menus1.map((data) => {
          return (
            <MenuItem
              key={data}
              value={data}
              onClick={() => {
                onSelect1(data);
              }}
            >
              {REACTION_TEXT1[data]}
            </MenuItem>
          );
        })}
        <MenuItem disabled>
          郵便
        </MenuItem>
        {menus2.map((data) => {
          return (
            <MenuItem
              key={data}
              value={data}
              onClick={() => {
                onSelect2(data);
              }}
            >
              {REACTION_TEXT2[data]}
            </MenuItem>
          );
        })}
      </Select>
    );
  };

  const list = [
    {
      type1: 'noModal',
      type2: 'select',
      lbl: '反応',
      required: '＊',
      val: reactionText,
      content: getReaction,
    },
  ];

  if (isSp) {
    contents = (
      <Grid className={classes.contentBox}>
        <Grid className={classes.lbl}>
          <Typography className={baseClasses.title6}>
            登録種類
          </Typography>
          <Grid className={`${baseClasses.title4} ${classes.lblTxt}`}>
            メール/郵送
          </Grid>
        </Grid>
        {getList()}
        <List className={classes.list}>
          {list.map((item) => {
            return (
              <ListItem
                key={item.lbl}
                className={classes.listItem}
              >
                {getParts(item)}
                {getEditDialog(item)}
              </ListItem>
            );
          })}
        </List>
        <Grid className={classes.footer}>
          <Button
            className={
              `${baseClasses.buttonsPrimary} ${classes.footerBtn} ${disabled ? 'disabled' : ''} ${disabled2 ? 'disabled' : ''}`
            }
            onClick={() => {
              onRegister(reactionCode);
            }}
          >
            登録
          </Button>
        </Grid>
      </Grid>
    );
  } else {
    contents = (
      <Grid className={classes.contentBox}>
        {getList()}
        <Grid className={`${classes.contentIpt} ${classes.contentIpt3}`}>
          <Typography
            className={`${baseClasses.title6} ${classes.contentIptLbl}`}
          >
            反応<span>＊</span>
          </Typography>
          <Grid className={classes.contentIptVal2}>
            <Grid className={classes.contentResponse}>
              <Grid className={classes.contentResponseBox2}>
                {mailBtns.map((mailBtn) => (
                  <Button
                    key={mailBtn.txt}
                    className={
                      `${baseClasses.buttonsPrimary} ${classes.btn2} ${disabled ? 'disabled' : ''}`
                    }
                    startIcon={mailBtn.icon}
                    onClick={() => {
                      onRegister(mailBtn.code);
                    }}
                  >
                    {mailBtn.txt}
                  </Button>
                ))}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid>
      {contents}
    </Grid>
  );
}
