import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { makeStyles } from '@material-ui/core/styles';
import commonStyles from '../../styles';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    'flex-direction': 'column',
    'align-items': 'flex-start',
    padding: 8,
    position: 'static',
    left: '0px',
    top: '0px',
    background: '#FFFFFF',
    border: '1px solid #C8C8C8',
    'box-sizing': 'border-box',
    flex: 'none',
    order: 0,
    'flex-grow': 1,
    margin: '0.5%',
  },
  customTitle3: {
    position: 'static',
    width: '811px',
    height: '20px',
    left: '8px',
    top: '8px',
    display: 'flex',
    'align-items': 'center',
    /* OH-Black */
    color: '#333333',
    flex: 'none',
    order: 0,
    'flex-grow': 0,
    margin: '20px 4px 0',
  },
}));

function InputFormMemo(props) {
  const { getComponent, state, setState } = props;
  const classes = useStyles();
  const baseClasses = commonStyles();
  const handleChange = (e) => {
    setState({ ...state, memo: e });
  };

  const memoObj = {
    title: '',
    required: false,
    key: 'memo',
    type: 'text',
    state: state.memo,
    set: handleChange,
  };

  return (
    <>
      <Grid item xs={12} md={12} lg={12} xl={12}>
        <Typography
          className={`${baseClasses.title3} ${classes.customTitle3}`}
        >
          メモ
        </Typography>
      </Grid>
      <Grid
        item
        xs={12}
        md={12}
        lg={12}
        xl={12}
        key={memoObj.key}
        className={`${classes.root} ${baseClasses.inputWrap}`}
      >
        {getComponent(memoObj)}
      </Grid>
    </>
  );
}

export default React.memo(InputFormMemo);
