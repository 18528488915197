import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  open: false,
  title: '',
  msgList: [],
  buttons: [],
  onBackdropClick: null,
};

export const customConfirmMessageSlice = createSlice({
  name: 'confirmMessage',
  initialState: { confirmStatus: initialState },
  reducers: {
    changeConfirmMessage: (state, data) => {
      const { msgList, title, buttons, onBackdropClick = () => {} } = data.payload;
      state.confirmStatus.open = true;
      state.confirmStatus.title = title === undefined ? '' : title;
      state.confirmStatus.msgList = msgList;
      state.confirmStatus.buttons = buttons;
      state.confirmStatus.onBackdropClick = onBackdropClick;
    },
    closeConfirmMessage: (state) => {
      state.confirmStatus.open = false;
      state.confirmStatus.title = '';
      state.confirmStatus.msgList = [];
      state.confirmStatus.buttons = [];
      state.confirmStatus.onBackdropClick = () => {};
    },
    backdropClickConfirmMessage: (state) => {
      state.confirmStatus.onBackdropClick();
    },
  },
});

export const {
  changeConfirmMessage,
  closeConfirmMessage,
  backdropClickConfirmMessage,
} = customConfirmMessageSlice.actions;

export default customConfirmMessageSlice.reducer;
