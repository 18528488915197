/**
 * 選択された組織/ユーザー配下の全ての顧客IDを取得
 * @param {*} targetObj 反響ツリーで選択された情報
 * @returns {{customerId: number; customerName: string;}[]}
 */
const getDataFromAllCustomer = (targetObj) => {
  const customerList = [];
  // customerListへPush -- 共通関数
  const pushCustomerId = (target, lastName, firstName) => {
    const customer = { ...target };
    // 重複チェック
    if (customerList.some(item => item.customerId === customer?.customerId)) return;
    customer.customerName = lastName + ' ' + firstName;
    customerList.push(customer);
  };
  const getCustomerIds = (target) => {
    if (Array.isArray(target)) {
      target.forEach(item => getCustomerIds(item));
    } else if (typeof target === 'object' && target !== null) {
      if (target.customerId) {
        pushCustomerId(target, target.lastName, target.firstName);
      } else {
        Object.keys(target).forEach(key => getCustomerIds(target[key]));
      }
    }
  };
  getCustomerIds(targetObj);
  return customerList;
};

export default getDataFromAllCustomer;

/**
 * 選択された組織/ユーザー情報を取得
 * @typedef {{
 *   userId: number;
 * }} CustomerTreeItem
 * @param {CustomerTreeItem} list CustomerTreeItem[]
 * @param {number} userId number
 * @param {number} divisionId number
 * @returns {null | CustomerTreeItem}
 */
export const getUserFromCustomerTree = (list, userId, divisionId) => {
  if (!list?.length || !userId) return null;
  let item;
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < list.length; i++) {
    item = list[i];
    if (item.userId === userId && item.divisionId === divisionId) {
      return item;
    } else {
      const res = getUserFromCustomerTree(item.users, userId, divisionId)
        || getUserFromCustomerTree(item.children, userId, divisionId);
      if (res) return res;
    }
  }
  return null;
};

/**
 * 選択された組織の下のユーザー情報を取得(Divisionのみ版)
 * @typedef {{
*   userId: number;
* }} CustomerTreeItem
* @param {CustomerTreeItem} list CustomerTreeItem[]
* @param {number} divisionId number
* @returns {null | CustomerTreeItem}
*/
export const getUserFromCustomerTreeDivision = (list, divisionId) => {
  if (!list?.length) return null;
  let item;
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < list.length; i++) {
    item = list[i];
    if (item.divisionId === divisionId) {
      return item;
    } else {
      const res = getUserFromCustomerTreeDivision(item.users, divisionId)
        || getUserFromCustomerTreeDivision(item.children, divisionId);
      if (res) return res;
    }
  }
  return null;
};
