import { useEffect, useState } from 'react';
import commonStyles from '../../../styles';
import CustomerShareUpdate from '../shareupdate';
import CustomAccordion from '../parts/customAccordion';
import ChangeManageWindow from '../changeManagerWindow';
import customerPutResponsibilitiesApi from '../../../../apis/customer/customerPutResponsibilitiesApi';
import CommonDialog from '../../modal';

function ChangeManageComponent(props) {
  const {
    initialParam,
    // onClickSelectButton,
    shares,
    open,
    setOpen,
    functions,
    updateTree = () => {},
  } = props;
  const common = commonStyles();

  // 組織ツリー ユーザー選択用
  const [selectUser, setSelectUser] = useState({
    userId: initialParam.userId,
    userName: initialParam.userName,
  });
  // 組織ツリー選択
  const [selectDivision, setSelectDivision] = useState({ divId: 0, divisionName: '' });
  // 保存用カウント
  // const [count, setCount] = useState(0);

  const handleClose = () => {
    setOpen(false);
  };

  // 担当者を変更した時の処理
  const onClickChangeUser = async () => {
    const changeParams = {
      customerId: initialParam.customerId, // デフォルトの顧客ID
    };
    await customerPutResponsibilitiesApi({
      userId: selectUser.userId === 0 ? null : selectUser.userId, // 変更した担当者のユーザーID
      divisionId: selectDivision.divId, // 変更した担当者の組織ID
      responsibilities: [changeParams],
    });
    // 画面を閉じる
    handleClose();
    // 反響ツリーをリロード
    updateTree();
    // setCount(count + 1);
  };

  // 初期表示で[/customer/sharelist]APIを呼び出す
  useEffect(async () => {
    functions.getCustomerShare(initialParam.customerId);
  }, []);

  const shareAddDisabledFlg = selectUser.userId === null;

  return (
    <CommonDialog
      open={open}
      onClose={handleClose}
      title="担当変更・共有ウィンドウ"
      width={445}
    >
      <ChangeManageWindow
        selectUser={selectUser}
        setSelectUser={setSelectUser}
        selectDivision={selectDivision}
        setSelectDivision={setSelectDivision}
        handleSelectFunction={onClickChangeUser}
        clickableUserAndDivision
      />
      <p className={common.small}>
        ＊担当者を他者に変更すると、あなたは編集権限を失ってしまいます。<br />(変更後の編集権限は新しい担当者とその上長のみ）
      </p>
      <CustomAccordion
        label01="共有"
        label02="期間中は閲覧、編集が可能です。"
      >
        <CustomerShareUpdate
          customerId={initialParam.customerId}
          data={shares}
          functions={functions}
          shareAddDisabledFlg={shareAddDisabledFlg}
        />
      </CustomAccordion>
    </CommonDialog>
  );
}

export default ChangeManageComponent;
