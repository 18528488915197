import React from 'react';
import {
  TableRow,
  TableCell,
  Radio,
  Typography,
  FormControlLabel,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  tableRow: {
    width: '100%',
    display: 'inline-table',
    '&:nth-of-type(even)': {
      backgroundColor: '#F3F3F3',
    },
    '& td': {
      padding: 3,
      '& p': {
        borderRight: 'solid 1px #C8C8C8',
        textAlign: 'center',
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '13px',
        lineHeight: '160%',
        color: '#333333',
        '& span': {
          padding: 0,
        },
        '& .MuiRadio-colorSecondary.Mui-checked': {
          color: theme.palette.primaryColor,
        },

      },
      '&:last-of-type': {
        '& p': {
          borderRight: 'none',
        },
      },
    },
  },
  FormControlLabel: {
    margin: 0,
  },
}));

function ResTableRow(props) {
  const { row, selectedValue } = props;
  const classes = useStyles();

  const {
    responsibleUserHistoriesId,
    responseId,
    customerId,
    divisionId,
    responsibleUserId,
    effectAt,
  } = row;

  const { responsibleUserHistoriesId: selectedId } = selectedValue;

  const isSameRow = selectedId === responsibleUserHistoriesId;

  return (
    <TableRow className={classes.tableRow}>
      <TableCell style={{ width: '4%' }}>
        <Typography>
          <FormControlLabel
            value={JSON.stringify({
              responsibleUserHistoriesId,
              responseId,
              customerId,
              divisionId,
              responsibleUserId,
              effectAt,
            })}
            control={<Radio checked={isSameRow} />}
            className={classes.FormControlLabel}
          />
        </Typography>
      </TableCell>
      <TableCell style={{ width: '10%' }}>
        <Typography>{row.effectAt}</Typography>
      </TableCell>
      <TableCell style={{ width: '7%' }}>
        <Typography>{row.responseImportResultCodeName}</Typography>
      </TableCell>
      <TableCell style={{ width: '12%' }}>
        <Typography>{row.responseTypeName1}</Typography>
      </TableCell>
      <TableCell style={{ width: '12%' }}>
        <Typography>{row.responseTypeName2}</Typography>
      </TableCell>
      <TableCell style={{ width: '12%' }}>
        <Typography>{row.responseTypeName3}</Typography>
      </TableCell>
      <TableCell style={{ width: '12%' }}>
        <Typography>{row.responseTypeName4}</Typography>
      </TableCell>
      <TableCell style={{ width: '12%' }}>
        <Typography>{row.divisionName}</Typography>
      </TableCell>
      <TableCell style={{ width: '12%' }}>
        <Typography>
          {row.responsibleUserLastName} {row.responsibleUserFirstName}
        </Typography>
      </TableCell>
      <TableCell style={{ width: '7%' }}>
        <Typography>{row.responseId}</Typography>
      </TableCell>
    </TableRow>
  );
}

export default ResTableRow;
