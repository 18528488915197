import { useState } from 'react';
import { makeStyles, TableCell, TableRow, Typography } from '@material-ui/core';
import commonStyles from '../../styles';
import NormalTooltip from '../../styles/tooltip';
import { SMS_SEND_STATUS } from '../../../constants/sms';
import UnsentSmsMenuPopper from './unsentSmsMenuPopper';
import UnsentSmsDialogConfirmForPC from './unsentSmsDialogConfirmForPC';

const useStyles = makeStyles((theme) => ({
  row: {
    '&:nth-of-type(even)': { background: '#F3F3F3' },
    '&:hover': { background: `${theme.palette.primaryColor}29` },
  },
  cell: {
    padding: '8px',
    maxWidth: '600px',
  },
  tooltip: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  tooltipStyle: {
    whiteSpace: 'normal',
    maxWidth: '600px',
  },
}));

export default function UnsentSmsListTableRow(props) {
  const {
    row, cells,
    smsFunctions, options, tabValue,
  } = props;
  const common = commonStyles();
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = useState(null);
  const onMouse = (e) => setAnchorEl(e.currentTarget);
  const onMouseLeave = () => setAnchorEl(null);

  const [openDeleteMail, setOpenDeleteMail] = useState(false);

  return (
    <TableRow
      className={classes.row}
      onMouseEnter={onMouse}
      onMouseLeave={onMouseLeave}
    >
      {cells.map((c) => {
        let result = row[c.id];
        switch (c.id) {
          case 'customerLastName':
            result = row[c.id] + row.customerFirstName;
            break;
          case 'userLastName':
            result = row[c.id] + row.userFirstName;
            break;
          case 'sendStatusCode':
            result = row.sendStatusCode ? SMS_SEND_STATUS[row.sendStatusCode] : '予約';
            break;
          default:
            break;
        }
        return (
          <TableCell className={classes.cell} key={c.id}>
            {result?.length > 48
              ? (
                <NormalTooltip
                  title={result}
                  className={classes.tooltip}
                  classes={{ tooltip: classes.tooltipStyle }}
                >
                  <Typography className={common.small}>
                    {result}
                  </Typography>
                </NormalTooltip>)
              : <Typography className={common.small}>{result}</Typography>}
          </TableCell>
        );
      })}
      <UnsentSmsMenuPopper
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        row={row}
        setOpenDialog2={setOpenDeleteMail}
      />
      <UnsentSmsDialogConfirmForPC
        openDialog={openDeleteMail}
        setOpenDialog={setOpenDeleteMail}
        smsFunctions={smsFunctions}
        targetRow={row}
        deleteFlg
        tabValue={tabValue}
        options={options}
        text={
          <Typography className={`${common.bass} ${classes.contentText}`}>
            送信予定のメッセージを削除します。
            <br />
            よろしいですか？
          </Typography>
        }
      />
    </TableRow>
  );
}
