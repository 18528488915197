import React, { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  Typography,
  List,
  ListItem,
  Button,
  Select,
  MenuItem,
} from '@material-ui/core';
import PhoneIcon from '@material-ui/icons/Phone';
import PhoneIphoneIcon from '@material-ui/icons/PhoneIphone';
import commonStyles from '../../../../styles';
import commonTheme from '../../../../styles/theme';
import RegisterWindowDate from './registerWindowDate';
import RegisterWindowGuidance from './registerWindowGuidance';
import RegisterWindowCall from './registerWindowCall';
import RegisterWindowContentShort from './registerWindowContentShort';
import AfterCallWindow from '../../../../mail/afterCallWindow';
import AfterCallWindowSp from '../../../../mail/sp/afterCallWindow';
import CustomerApi from '../../../../../apis/customer/customerApi';
import { getLocalStorage } from '../../../../../commonFunction';
import { AFTER_CALL_CODE } from '../../../../../constants/localStorage';

const useStyles = makeStyles((theme) => ({
  dialog: {
    '& .MuiDialog-paperWidthSm': {
      maxWidth: 'none',
    },
  },
  paper: {
    width: 384,
    minWidth: 384,
    background: '#F3F3F3',
    padding: 8,
  },
  box: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    background: '#fff',
    border: '1px solid #C8C8C8',
    padding: 8,
    marginBottom: 8,
  },
  appBar: {
    width: 310,
    boxShadow: 'none',
  },
  tabs: {
    minHeight: 'auto',
    [commonTheme.breakpoints.down('sm')]: {
      background: '#F3F3F3',
    },
    [commonTheme.breakpoints.up('md')]: {
      background: '#fff',
    },
    '& .MuiTabs-flexContainer': {
      justifyContent: 'space-between',
    },
    '& .MuiTab-root': {
      minWidth: 'auto',
      padding: 0,
      opacity: '1',
      minHeight: 'auto',
    },
    '& .MuiTab-root.Mui-selected .MuiTypography-root': {
      color: theme.palette.primaryColor,
    },
    '& .MuiTab-root.Mui-selected .MuiSvgIcon-root': {
      color: theme.palette.primaryColor,
    },
    '& .MuiTab-root.Mui-selected .MuiIcon-root': {
      color: theme.palette.primaryColor,
    },
    '& .MuiTabs-indicator': {
      display: 'none',
    },
  },
  buttonClose: {
    background: '#fff',
    borderRadius: 4,
    display: 'flex',
    margin: '0 auto 8px',
    padding: '4px 4px 3px 8px',
    '& .MuiButton-label': {
      color: theme.palette.primaryColor,
      fontFamily: 'Roboto',
      fontSize: 13,
      fontWeight: 700,
      lineHeight: 1.6,
      letterSpacing: 0,
    },
    '& .MuiButton-startIcon': {
      marginBottom: 1,
    },
  },
  button: {
    color: '#8C8C8C',
    background: 'none',
    fontFamily: 'Roboto',
    fontSize: 10,
    fontWeight: 700,
    lineHeight: 1.2,
    letterSpacing: 0,
    padding: '0',
    margin: '0',
    boxShadow: 'none',
    minWidth: 'auto',
    width: 54,
    '& .MuiButton-label': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexWrap: 'wrap',
    },
    '& .MuiButton-startIcon': {
      display: 'block',
      margin: 0,
      order: 1,
    },
    '& .MuiButton-endIcon': {
      display: 'block',
      margin: 0,
      order: 2,
      textAlign: 'left',
    },
    '& .MuiButton-iconSizeMedium > *:first-child': {
      fontSize: 14,
    },
  },
  buttonIcon: {
    '& .MuiSvgIcon-root': {
      fontSize: 14,
    },
    '& .MuiIcon-root': {
      fontSize: 14,
    },
  },
  buttonTxt: {
    textAlign: 'center',
  },
  contentResponseLbl: {
    marginTop: 8,
  },
  contentResponseBox1: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 4,
  },
  contentResponseBox2: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 8,
  },
  btn1: {
    width: 85,
    padding: '3px 0',
    '& .MuiButton-label': {
      fontSize: 13,
    },
    '& .MuiSvgIcon-root': {
      fontSize: 13,
    },
    '& .MuiIcon-root': {
      fontSize: 13,
    },
    '& .MuiButton-startIcon': {
      margin: '0 4px 0 0',
    },
  },
  btn2: {
    width: 117,
    padding: '3px 0',
    '& .MuiButton-label': {
      fontSize: 13,
    },
    '& .MuiSvgIcon-root': {
      fontSize: 13,
    },
    '& .MuiIcon-root': {
      fontSize: 13,
    },
    '& .MuiButton-startIcon': {
      margin: '0 4px 0 0',
    },
  },
  content: {
    background: '#F3F3F3',
    padding: 24,
  },
  contentIpt: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    background: '#fff',
    border: '1px solid #C8C8C8',
    padding: '9px 8px',
    marginBottom: 8,
  },
  contentIpt3: {
    display: 'block',
  },
  contentIptLbl: {
    '& span': {
      color: '#D83420',
    },
  },
  contentIptVal2: {
    width: '100%',
    marginTop: 8,
  },
  // モーダル
  paper2: {
    width: 515,
    backgroundColor: '#fff',
    boxShadow:
      '0px 15px 12px rgba(51, 51, 51, 0.22), 0px 19px 38px rgba(51, 51, 51, 0.3)',
    borderRadius: 4,
    padding: '24px 24px 18px',
  },
  paperTtl: {
    textAlign: 'center',
    margin: '0 0 24px',
  },
  paperBox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  paperBtn: {
    color: theme.palette.primaryColor,
    fontFamily: 'Roboto',
    fontSize: 15,
    lineHeight: 1.6,
    letterSpacing: 0,
    margin: '0 60px',
  },
  // SP
  lbl: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '16px 8px 16px 32px',
  },
  lblTxt: {
    display: 'flex',
    alignItems: 'center',
    fontWeight: 700,
    '& p': {
      fontWeight: 700,
    },
  },
  list: {
    background: '#fff',
    padding: '0 0 0 16px',
    marginBottom: 16,
  },
  listItem: {
    padding: 0,
  },
  selectTtl: {
    paddingTop: 16,
  },
  selectBtn: {
    width: '100%',
    padding: '18px 0',
    textAlign: 'left',
    borderBottom: '1px solid #F3F3F3',
    '&.select': {
      color: theme.palette.primaryColor,
    },
    '& .MuiButton-label': {
      display: 'block',
      paddingLeft: 16,
    },
  },
  footer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    left: 0,
    bottom: 0,
    width: '100%',
    padding: '24px 0',
    background: '#fff',
    borderTop: '1px solid rgba(51, 51, 51, 0.04)',
  },
  footerBtn: {
    fontFamily: 'Roboto',
    fontSize: 15,
    fontWeight: '700',
    lineHeight: 1.6,
    letterSpacing: 0,
    padding: '8px 19px',
  },
}));

export default function RegisterWindowTel(props) {
  const {
    onClose,
    confirmOpen,
    getParts,
    getEditDialog,
    typeClose,
    updateHistoryParams,
    callCreateHistory,
    customer,
    successSendCallBack,
    setModalHidden,
  } = props;

  const classes = useStyles();
  const baseClasses = commonStyles();

  const isSp = useSelector((state) => state.deviceTypeSlice.isSp);
  // src/containers/common/customer/customerInfo/customerInfoContainer.jsから分離
  // 理由はcustomerInfoContainerのgetCustomerFunctionSingleをコールすると
  // customerMailに値が入り、
  // /src/components/customerMain/parts/fixedFootNav.js内のモーダルが開くため
  const [customerMail, setCustomerMail] = useState(null);
  const getCustomerFunctionSingle = async (customerId) => {
    const [customersRes] = await Promise.all([
      // 顧客取得
      CustomerApi(customerId, isSp ? 1 : 0),
    ]);
    return customersRes;
  };

  const REACTION_TEXT1 = {
    1: '架電',
    3: '受電',
  };

  const REACTION_TEXT2 = {
    4: '留守電',
    5: '不通',
  };

  const REACTION_TEXT3 = {
    2: '架電',
    131: '受電',
  };

  const REACTION_TEXT4 = {
    132: '留守電',
    133: '不通',
  };

  const menus1 = Object.keys(REACTION_TEXT1);
  const menus2 = Object.keys(REACTION_TEXT2);
  const menus3 = Object.keys(REACTION_TEXT3);
  const menus4 = Object.keys(REACTION_TEXT4);

  // モーダル用
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  // SP・PCでの分岐用
  let contents;

  // 対応日時
  const now = new Date();
  const year = now.getFullYear();
  const month = ('00' + (now.getMonth() + 1)).slice(-2);
  const day = ('00' + now.getDate()).slice(-2);
  const hour = ('00' + now.getHours()).slice(-2);
  const min = ('00' + now.getMinutes()).slice(-2);
  const dateInitial = year + '/' + month + '/' + day + ' ' + hour + ':' + min;
  const [date, setDate] = useState(dateInitial);

  // 案内登録
  const [checked, setChecked] = useState(false);
  const checkChange = (e) => {
    setChecked(e.target.checked);
  };

  // 通話時間
  const [time, setTime] = useState('');
  const [timeVal, setTimeVal] = useState('');
  const timeChange = (event) => {
    console.log(event.target.value, time);
    if (event.target.value !== time) {
      if (event.target.value === 'time1') {
        setTimeVal(5);
      } else if (event.target.value === 'time2') {
        setTimeVal(15);
      } else {
        setTimeVal(30);
      }
    }
    setTime(event.target.value);
  };
  const timeArraySP = [5, 15, 30];
  const timeChangeSP = (index) => {
    const value = timeArraySP[index];
    setTimeVal(value);
  };

  // 対応内容
  const [content, setContent] = useState('');

  // 反応
  const [reactionCode, setReactionCode] = useState(0);
  const [reactionText, setReactionText] = useState('');

  // ボタン制御
  const [disabled, setDisabled] = useState(false);
  const [disabled2, setDisabled2] = useState(true);
  const [disabled3, setDisabled3] = useState(true);

  // PC版: 不通時機能がONかどうか
  const isAfterCallOption = Number(getLocalStorage(AFTER_CALL_CODE, 1)) === 1;

  // 不通が選択され登録押下時
  const isAfterCall = useRef(false);
  // 不通時メッセージ送信ウィンドウの開閉
  const [openAfterCall, setOpenAfterCall] = useState(false);

  // SP 項目選択時に更新
  const onSelect1 = (val) => {
    setReactionCode(val);
    setReactionText('会社の固定電話 / ' + REACTION_TEXT1[val]);
    setDisabled3(true);
  };
  const onSelect2 = (val) => {
    setReactionCode(val);
    setReactionText('会社の固定電話 / ' + REACTION_TEXT2[val]);
    setDisabled3(false);
  };
  const onSelect3 = (val) => {
    setReactionCode(val);
    setReactionText('社用の携帯電話 / ' + REACTION_TEXT3[val]);
    setDisabled3(true);
  };
  const onSelect4 = (val) => {
    setReactionCode(val);
    setReactionText('社用の携帯電話 / ' + REACTION_TEXT4[val]);
    setDisabled3(false);
  };

  // 初期化
  const handleInitial = () => {
    setTime('');
    setTimeVal('');
    setContent('');
    setChecked(false);
    setDisabled(false);
    setDisabled2(true);
    setDisabled3(true);
  };

  const isButtonClicked = useRef(false);

  // 登録処理
  const onRegister = async (code) => {
    if (!isButtonClicked.current) {
      isButtonClicked.current = true;
      const params = {
        actionStartAt: date + ':00',
        actionCode: 6,
        actionDetailCode: Number(code),
      };
      if (content) {
        params.content = content;
      }
      if (timeVal) {
        params.contactMinutes = timeVal;
      }
      // 対応履歴をアップデート
      updateHistoryParams(params);
      if (checked) {
        confirmOpen();
      } else {
        console.log('対応日時：' + date);
        console.log('通話時間：' + timeVal);
        console.log('対応内容：' + content);
        console.log('反応：' + code);
        console.log('登録処理');
        callCreateHistory();
        handleInitial();
        // SP版またはPC版不通時機能がON & 会社の固定電話[不通]または、社用の携帯電話[不通]の時
        if ((isSp || isAfterCallOption) && (Number(code) === 5 || Number(code) === 133)) {
          // メールアドレス変更時に変更後の値を取得するために必要(customerは変更前)
          // 情報取得後、useEffect[customerMail]が動く
          const customersRes = await getCustomerFunctionSingle([customer.customerId]);
          isAfterCall.current = true;
          setCustomerMail(customersRes.data.customers[0]);
          setModalHidden(true);
        } else {
          typeClose();
          onClose();
        }
      }
    }
  };

  // 不通時メッセージ送信ウィンドウを閉じた時
  const handleCloseAfterCall = () => {
    isAfterCall.current = false;
    typeClose();
    onClose();
    setModalHidden(false);
  };

  useEffect(() => {
    if (isAfterCall.current && customerMail) {
      // 個人の顧客情報を取得後、モーダルを開く
      // console.log({ isAfterCall, customerMail });
      setOpenAfterCall(true);
    }
  }, [customerMail]);

  const getList = () => {
    return (
      <Grid>
        <RegisterWindowDate
          date={date}
          setDate={setDate}
          setDisabled={setDisabled}
          getParts={getParts}
          getEditDialog={getEditDialog}
        />
        <RegisterWindowGuidance
          checked={checked}
          checkChange={checkChange}
          getParts={getParts}
        />
        <RegisterWindowCall
          time={time}
          setTime={setTime}
          timeChange={timeChange}
          timeChangeSP={timeChangeSP}
          getParts={getParts}
          getEditDialog={getEditDialog}
        />
        <RegisterWindowContentShort
          content={content}
          setContent={setContent}
          setDisabled2={setDisabled2}
          getParts={getParts}
          getEditDialog={getEditDialog}
        />
      </Grid>
    );
  };

  const getReaction = () => {
    return (
      <Select
        defaultValue={0}
      >
        <MenuItem value={0} disabled>
          未選択
        </MenuItem>
        <MenuItem disabled>
          会社の固定電話
        </MenuItem>
        {menus1.map((data) => {
          return (
            <MenuItem
              key={data}
              value={data}
              onClick={() => {
                onSelect1(data);
                handleClose();
              }}
            >
              {REACTION_TEXT1[data]}
            </MenuItem>
          );
        })}
        {menus2.map((data) => {
          return (
            <MenuItem
              key={data}
              value={data}
              onClick={() => {
                onSelect2(data);
                handleClose();
              }}
            >
              {REACTION_TEXT2[data]}
            </MenuItem>
          );
        })}
        <MenuItem disabled>
          社用の携帯電話
        </MenuItem>
        {menus3.map((data) => {
          return (
            <MenuItem
              key={data}
              value={data}
              onClick={() => {
                onSelect3(data);
                handleClose();
              }}
            >
              {REACTION_TEXT3[data]}
            </MenuItem>
          );
        })}
        {menus4.map((data) => {
          return (
            <MenuItem
              key={data}
              value={data}
              onClick={() => {
                onSelect4(data);
                handleClose();
              }}
            >
              {REACTION_TEXT4[data]}
            </MenuItem>
          );
        })}
      </Select>
    );
  };

  const list = [
    {
      type1: 'noModal',
      type2: 'select',
      lbl: '反応',
      required: '＊',
      click: handleOpen,
      val: reactionText,
      opn: open,
      onClose: handleClose,
      content: getReaction,
    },
  ];

  if (isSp) {
    contents = (
      <Grid className={classes.contentBox}>
        <Grid className={classes.lbl}>
          <Typography className={baseClasses.title6}>
            登録種類
          </Typography>
          <Grid className={`${baseClasses.title4} ${classes.lblTxt}`}>
            電話
          </Grid>
        </Grid>
        {getList()}
        <List className={classes.list}>
          {list.map((item) => {
            return (
              <ListItem
                key={item.lbl}
                className={classes.listItem}
              >
                {getParts(item)}
                {getEditDialog(item)}
              </ListItem>
            );
          })}
        </List>
        <Grid className={classes.footer}>
          <Button
            className={
              `${baseClasses.buttonsPrimary} ${classes.footerBtn} ${disabled ? 'disabled' : ''} ${(disabled2 || reactionCode === 0) && disabled3 ? 'disabled' : ''}`
            }
            onClick={() => {
              onRegister(reactionCode);
            }}
          >
            登録
          </Button>
        </Grid>
        {customerMail && openAfterCall && (
          <AfterCallWindowSp
            user={customerMail}
            open={openAfterCall}
            setOpen={setOpenAfterCall}
            onClose={handleCloseAfterCall}
          />
        )}
      </Grid>
    );
  } else {
    contents = (
      <Grid className={classes.contentBox}>
        {getList()}
        <Grid className={`${classes.contentIpt} ${classes.contentIpt3}`}>
          <Typography
            className={`${baseClasses.title6} ${classes.contentIptLbl}`}
          >
            反応<span>＊</span>
          </Typography>
          <Grid className={classes.contentIptVal2}>
            <Grid className={classes.contentResponse}>
              <Typography
                className={`${baseClasses.title5} ${classes.contentResponseLbl}`}
              >
                会社の固定電話
              </Typography>
              <Grid className={classes.contentResponseBox1}>
                {menus1.map((data) => (
                  <Button
                    key={data}
                    className={
                      `${baseClasses.buttonsPrimary} ${classes.btn1} ${disabled ? 'disabled' : ''} ${disabled2 ? 'disabled' : ''}`
                    }
                    startIcon={<PhoneIcon />}
                    onClick={() => {
                      onRegister(data);
                    }}
                  >
                    {REACTION_TEXT1[data]}
                  </Button>
                ))}
                {menus2.map((data) => (
                  <Button
                    key={data}
                    className={
                      `${baseClasses.buttonsPrimary} ${classes.btn1} ${disabled ? 'disabled' : ''}`
                    }
                    startIcon={<PhoneIcon />}
                    onClick={() => {
                      onRegister(data);
                    }}
                  >
                    {REACTION_TEXT2[data]}
                  </Button>
                ))}
              </Grid>
              <Typography
                className={`${baseClasses.title5} ${classes.contentResponseLbl}`}
              >
                社用の携帯電話
              </Typography>
              <Grid className={classes.contentResponseBox1}>
                {menus3.map((data) => (
                  <Button
                    key={data}
                    className={
                      `${baseClasses.buttonsPrimary} ${classes.btn1} ${disabled ? 'disabled' : ''} ${disabled2 ? 'disabled' : ''}`
                    }
                    startIcon={<PhoneIphoneIcon />}
                    name={data}
                    onClick={() => {
                      onRegister(data);
                    }}
                  >
                    {REACTION_TEXT3[data]}
                  </Button>
                ))}
                {menus4.map((data) => (
                  <Button
                    key={data}
                    className={
                      `${baseClasses.buttonsPrimary} ${classes.btn1} ${disabled ? 'disabled' : ''}`
                    }
                    startIcon={<PhoneIphoneIcon />}
                    name={data}
                    onClick={() => {
                      onRegister(data);
                    }}
                  >
                    {REACTION_TEXT4[data]}
                  </Button>
                ))}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {customerMail && openAfterCall && (
          <AfterCallWindow
            user={customerMail}
            open={openAfterCall}
            setOpen={setOpenAfterCall}
            onClose={handleCloseAfterCall}
            successSendCallBack={successSendCallBack}
          />
        )}
      </Grid>
    );
  }

  return (
    <Grid>
      {contents}
    </Grid>
  );
}
