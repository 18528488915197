import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

import commonTheme from '../../../../styles/theme';

const useStyles = makeStyles((theme) => ({
  list: {
    padding: '16px 0 40px 16px',
  },
  listItem: {
    width: '100%',
    '& .MuiButton-label': {
      justifyContent: 'flex-start',
    },
  },
  listItemText: {
    '& .MuiTypography-root': {
      fontWeight: '700',
    },
  },
  gutters: {
    margin: '0 auto',
    [commonTheme.breakpoints.down('sm')]: {
      padding: '0',
      '&.Mui-selected': {
        background: '#fff',
        color: theme.palette.primaryColor,
      },
    },
    '& > .MuiListItemText-root': {
      [commonTheme.breakpoints.down('sm')]: {
        marginTop: '0px',
        marginBottom: '0px',
        padding: '16px',
        borderBottom: '1px solid #F3F3F3',
      },
    },
  },
}));

export default function InstructionsWindowSelectList(props) {
  const { values, setOpenDrawer, handleOpenCalendarWindow } = props;
  const classes = useStyles();

  return (
    <List className={classes.list}>
      {values.list.map((item) => (
        <ListItem
          button
          key={item.val}
          className={classes.listItem}
          classes={{ gutters: classes.gutters }}
          selected={values.state === item.val}
          onClick={() => {
            values.setState(item.val);
            values.setLabelState(item.label);

            if (item.label === '指定日時') {
              handleOpenCalendarWindow(true);
            }
            setOpenDrawer(false);
          }}
        >
          <ListItemText
            primary={item.label}
            className={classes.listItemText}
          />
        </ListItem>
      ))}
    </List>
  );
}
