import { AREA_CODES } from './apiParameterCode';

export * from './actionDetail';

// 以下全体共通のConstant
export const TEST_CONST = 'TEST_CONST';
export const FORMAT_DATE = 'YYYY/MM/DD';

export const MAN_YEN = 1e+4;

// personalTable.js ※API定義書に無かったので今村追加
export const GROUP_PERSONAL_TH = [
  '年',
  '月',
  '棟',
  '売上（万円）',
  '所属組織',
  '役割',
  '更新日',
];

// userChangeHistory.js ※API定義書に無かったので今村追加
export const USER_DIVISION_SELECT = {
  0: 'DX div.',
  1: 'SystemDevelop1',
  2: 'InfraGroup',
  22: 'SystemDevelop2',
};

// userChangeHistory.js ※API定義書に無かったので今村追加
export const USER_GRADE_SELECT = {
  0: 'プレイヤー',
  1: '主任',
  2: '係長',
};

// userChangeHistory.js ※API定義書に無かったので今村追加
export const GROUP_HISTORY_TH = {
  0: '所属(開始)',
  1: '所属(終了)',
  2: '所属組織',
  3: '役職',
  4: '役割',
};

// userChangeHistory.js ※API定義書に無かったので今村追加
export const USER_ROLE_SELECT = {
  0: '戸建営業支援_強',
  1: '戸建営業支援_中',
  2: '戸建営業支援_弱(物件広告アルバイト)',
};

// userBasicAuthority.js ※API定義書に無かったので今村追加
export const SYSTEM_ROLE_SELECT = {
  0: '利用禁止',
  1: '権限1',
  2: '権限2',
  3: '権限3',
  4: '権限4',
};

export const RESPONSE_LEVEL_MAP = {
  1: '媒体(大)',
  2: '媒体(中)',
  3: '媒体(小)',
  4: '媒体(細)',
};

export const RESPONSE_LEVEL_LIST = [
  { value: '', text: '　' },
  { value: '1', text: '媒体(大)' },
  { value: '2', text: '媒体(中)' },
  { value: '3', text: '媒体(小)' },
  { value: '4', text: '媒体(細)' },
];

export const IS_MOBAILE = {
  0: 'モバイルでない',
  1: 'モバイル',
};

// userBasicMainOption.js
export const GENDER_CODE = {
  1: '男性',
  2: '女性',
};

export const PERMANENT_RESIDENCY = {
  0: 'なし',
  1: 'あり',
  2: '申請中',
};

export const IS_MAILSEND1 = {
  0: '送信不可',
  1: '送信可',
};

export const IS_MAILSEND2 = {
  0: '送信不可',
  1: '送信可',
};

export const IS_MAILSEND3 = {
  0: '送信不可',
  1: '送信可',
};

export const IS_MAILSEND4 = {
  0: '送信不可',
  1: '送信可',
};

export const CUSTOMER_STATUS_PURSUING = 1;
export const CUSTOMER_STATUS_NOT_PURSUING = 2;
export const CUSTOMER_STATUS_CONTRACT = 10;
export const CUSTOMER_STATUS_CLAIM = 11;
export const CUSTOMER_STATUS_CODE = {
  [CUSTOMER_STATUS_PURSUING]: '追客中',
  [CUSTOMER_STATUS_NOT_PURSUING]: '追わない',
  [CUSTOMER_STATUS_CONTRACT]: '契約',
  [CUSTOMER_STATUS_CLAIM]: 'クレーム',
};

export const TEL_STATUS_CODE = {
  0: '電話NG',
  1: '電話OK',
  2: '誤登録',
  3: '制限あり',
};

export const MAIL_TYPE_INDIVIDUAL_SEND = 1;
export const MAIL_TYPE_INDIVIDUAL_TIMER = 2;
export const MAIL_TYPE_SIMULTANEOUS_SEND = 3;
export const MAIL_TYPE_SIMULTANEOUS_TIMER = 4;
export const MAIL_TYPE_CODE = {
  [MAIL_TYPE_INDIVIDUAL_SEND]: '個別　即時メール',
  [MAIL_TYPE_INDIVIDUAL_TIMER]: '個別　タイマーメール',
  [MAIL_TYPE_SIMULTANEOUS_SEND]: '一斉　即時メール',
  [MAIL_TYPE_SIMULTANEOUS_TIMER]: '一斉　タイマーメール',
};

export const EMAIL_STATUS_DISABLED = 0;
export const EMAIL_STATUS_PERSONAL = 1;
export const EMAIL_STATUS_GROUP = 3;
export const EMAIL_STATUS_AUTO = 5;

export const EMAIL_STATUS_CODE = {
  [EMAIL_STATUS_DISABLED]: 'ダメ全部NG',
  [EMAIL_STATUS_PERSONAL]: '個別のみOK',
  [EMAIL_STATUS_GROUP]: '一斉・個別OK',
  [EMAIL_STATUS_AUTO]: '代行も全てOK',
};

export const IS_MAIN = {
  0: 'メインではない',
  1: 'メインである',
};

export const MEMBER_STATUS_NORMAL_MEMBER = 0;
export const MEMBER_STATUS_REQUEST_PREMIUM = 1;
export const MEMBER_STATUS_PREMIUM_MEMBER = 2;
export const MEMBER_STATUS_DENY_PREMIUM = 3;
export const MEMBER_STATUS_NON_MEMBER = 4;
export const MEMBER_STATUS_CODE = {
  [MEMBER_STATUS_NORMAL_MEMBER]: '通常会員',
  [MEMBER_STATUS_REQUEST_PREMIUM]: 'プレミアム申請中',
  [MEMBER_STATUS_PREMIUM_MEMBER]: 'プレミアム会員',
  [MEMBER_STATUS_DENY_PREMIUM]: 'プレミアム否認',
  [MEMBER_STATUS_NON_MEMBER]: '非会員',
};

export const USER_LEVEL = {
  0: '★なし',
  1: '★',
  2: '★★',
  3: '★★★',
  4: '★★★★',
  5: '★★★★★',
};

export const USER_LEVEL_NO = {
  0: '★なし',
  1: '★1',
  2: '★2',
  3: '★3',
  4: '★4',
  5: '★5',
};

export const CUSTOMER_INTRODUCTION_STATUS1 = {
  0: '非対象',
  1: '対象',
};

/** 獲得者種類コード「社員」 */
export const IS_SHAIN = 1;
/** 獲得者種類コード「内定者」 */
export const IS_PROSPECTIVE = 2;
/** 獲得者種類コード「研修生」 */
export const IS_TRAINEE = 3;
/** 獲得者種類コード「トライアル」 */
export const IS_TRIAL = 6;
/** 獲得者種類コード「アルバイト」 */
export const IS_PARTTIMER = 4;
/** 獲得者ツリーを表示するグループ */
export const USE_AQCUISITION_TREE_GROUP = [IS_SHAIN, IS_PROSPECTIVE, IS_TRAINEE, IS_TRIAL];

// 獲得者種類コード
// acquisitionCode
export const ACQUISITION_CODE = {
  1: '社員',
  2: '内定者',
  3: '研修生',
  // 4: '源泉アルバイト',
  // 5: 'その他',
  6: 'トライアル',
  4: 'アルバイト',
};

/** 獲得者ツリーを表示するグループ（名前） */
export const USE_AQCUISITION_TREE_GROUP_NAME = [
  ACQUISITION_CODE[IS_SHAIN],
  ACQUISITION_CODE[IS_PROSPECTIVE],
  ACQUISITION_CODE[IS_TRAINEE],
  ACQUISITION_CODE[IS_TRIAL],
];

// 獲得者種類コードソート用 社員, 内定者, 研修生, トライアル, アルバイトの順に表示
export const ACQUISITION_CODE_SORT_IDS = [1, 2, 3, 6, 4];

export const IS_WISH_MANSION = {
  0: '検討しない',
  1: '検討する',
};

export const FLOOR_PLAN = {
  4: '2LDK以下',
  8: '2SLDK・3LDK',
  16: '3SLDK・4LDK',
  32: '5LDK以上',
};

export const IS_INVESTMENT1 = {
  0: '検討しない',
  1: '検討する',
};

export const IS_PURCHASE1 = {
  0: '検討しない',
  1: '検討する',
};

export const ABODE_NOW_FLOOR_PLAN = {
  1: '1K',
  2: '1LDK',
  4: '2LDK以下',
  8: '2SLDK・3LDK',
  16: '3SLDK・4LDK',
  32: '5LDK以上',
};

export const IS_CARPARK = {
  0: '希望しない',
  1: '希望する',
};

export const NO_CONTACT_DETAIL_REASON_CODE = {
  1: '投資目的',
  2: '他決',
  3: 'マンションのみ',
  4: '購入できない',
  5: '売却前提',
  6: 'しばらく買う気がない',
  7: 'OHDにはまらない',
  8: '連絡不可・カマシ',
};

export const IS_THINK_WHOLE_HOUSE_INVESTMENT = {
  0: '検討しない',
  1: '検討する',
};

export const IS_THINK_SALE = {
  0: '検討しない',
  1: '検討する',
};

export const IS_THINK_APARTMENT = {
  0: '検討しない',
  1: '検討する',
};

export const IS_THINK_DIVISION_INVESTMENT = {
  0: '検討しない',
  1: '検討する',
};

export const IS_THINK_REBUILDING = {
  0: '検討しない',
  1: '検討する',
};

export const DIRECT_STATUS_CODE = {
  1: '未確認',
  2: '対応予定',
  3: '承認待ち',
  4: '完了',
  6: '取り消し',
  7: '対応なし',
};

export const GUIDANCE_STATUS_CODE = {
  0: '不明',
  1: '来案',
  2: '訪案',
  3: '待案',
  4: '現地即案',
  5: '来店接客のみ',
  6: '資料渡し',
  7: '源泉接触',
  8: 'ポーター接触',
};

export const ISNOT_RESPONSE = {
  0: '顧客である',
  1: '顧客でない',
};

export const AREA_CODE = {
  [AREA_CODES.HOKAIDO]: '北海道',
  [AREA_CODES.TOHOKU]: '東北',
  [AREA_CODES.KANTO]: '関東',
  [AREA_CODES.GUNMA]: '北関東',
  [AREA_CODES.KOSHINETSU]: '甲信越',
  [AREA_CODES.HOKURIKU]: '北陸',
  [AREA_CODES.TOKAI]: '名古屋',
  [AREA_CODES.KANSAI]: '関西',
  [AREA_CODES.CHUKOKU]: '中国',
  [AREA_CODES.SHIKOKU]: '四国',
  [AREA_CODES.KYUSHU]: '福岡',
  [AREA_CODES.OKINAWA]: '沖縄',
};

export const AREA_CODE_HISTORY = {
  [AREA_CODES.KANTO]: '関東',
  [AREA_CODES.GUNMA]: '北関東',
  [AREA_CODES.TOKAI]: '名古屋',
  [AREA_CODES.KANSAI]: '関西',
  [AREA_CODES.KYUSHU]: '福岡',
};

export const PREFECTURES_CODE = {
  東京都: '東京都',
  神奈川県: '神奈川県',
  埼玉県: '埼玉県',
  千葉県: '千葉県',
  茨城県: '茨城県',
  栃木県: '栃木県',
  群馬県: '群馬県',
  北海道: '北海道',
  青森県: '青森県',
  岩手県: '岩手県',
  宮城県: '宮城県',
  秋田県: '秋田県',
  山形県: '山形県',
  福島県: '福島県',
  新潟県: '新潟県',
  山梨県: '山梨県',
  長野県: '長野県',
  富山県: '富山県',
  石川県: '石川県',
  福井県: '福井県',
  岐阜県: '岐阜県',
  静岡県: '静岡県',
  愛知県: '愛知県',
  三重県: '三重県',
  滋賀県: '滋賀県',
  京都府: '京都府',
  大阪府: '大阪府',
  兵庫県: '兵庫県',
  奈良県: '奈良県',
  和歌山県: '和歌山県',
  鳥取県: '鳥取県',
  島根県: '島根県',
  岡山県: '岡山県',
  広島県: '広島県',
  山口県: '山口県',
  徳島県: '徳島県',
  香川県: '香川県',
  愛媛県: '愛媛県',
  高知県: '高知県',
  福岡県: '福岡県',
  佐賀県: '佐賀県',
  長崎県: '長崎県',
  熊本県: '熊本県',
  大分県: '大分県',
  宮崎県: '宮崎県',
  鹿児島県: '鹿児島県',
  沖縄県: '沖縄県',
  その他: 'その他',
};

export const IS_ALLOCATION = {
  0: '配点設定無',
  1: '配点設定有',
};

export const IS_WISH_AREA = {
  0: '希望エリアに表示するものとして設定無',
  1: '希望エリアに表示するものとして設定有',
};

export const SORT_COLUMN = {
  0: 'ソート指定なし',
  1: '配点設定の表示順（DISPLAY_ORDER）を指定する',
  2: '希望エリア表示順（KIBO_AREA_ORDER）を指定する',
};

export const ASC = 0;
export const DESC = 1;
export const SORT_ORDER = {
  0: '昇順（asc）',
  1: '降順（desc）',
};

export const WISH_ROUGHT_AREA_CODE = {
  1: '都心エリア',
  2: '城東エリア',
  3: '城南エリア',
  4: '城西エリア',
  5: '城北エリア',
  6: '東京都下',
  10: '川崎市',
  11: '横浜市A',
  12: '横浜市B',
  13: '神奈川県内',
  20: 'さいたま市',
  21: '埼玉県内',
  30: '千葉市',
  31: '千葉県内',
  101: '名古屋16区',
  102: '尾張地方（尾張）',
  103: '尾張地方（海辺）',
  104: '尾張地方（知多）',
  105: '三河地方（西三河）',
  106: '三河地方（東三河）',
};

export const IS_WISH_ALONG_RAILWAY = {
  0: '希望しない',
  1: '希望する',
};

export const JR_COMPANY_CODE = {
  1: 'JR会社コード 北海道',
  2: 'JR会社コード 東日本',
  4: 'JR会社コード 東海',
  6: 'JR会社コード 西日本',
  8: 'JR会社コード 四国',
  9: 'JR会社コード 九州',
};

export const IS_WISH_STATION = {
  0: '希望しない',
  1: '希望する',
};

export const MULTI_ZIP_CODE1 = {
  0: '該当しない',
  1: '該当する',
};

export const MULTI_ZIP_CODE2 = {
  0: '該当しない',
  1: '該当する',
};

export const MULTI_ZIP_CODE3 = {
  0: '該当しない',
  1: '該当する',
};

export const MULTI_ZIP_CODE4 = {
  0: '該当しない',
  1: '該当する',
};

export const MULTI_ZIP_CODE5 = {
  0: '変更なし',
  1: '変更あり',
  2: '廃止（廃止データのみ使用）',
};

export const MULTI_ZIP_CODE6 = {
  0: '変更なし',
  1: '市政・区政・町政・分区・政令指定都市施行',
  2: '住居表示の実施',
  3: '区画整理',
  4: '郵便区調整等',
  5: '訂正',
  6: '廃止（廃止データのみ使用）',
};

export const IS_INCLUDE_FINISHED = {
  0: '含めない',
  1: '含める',
};

// userBasicMainOption.js userBasicOrg.js
export const USER_STATUS_DIVISION = {
  1: '社員',
  2: '内定者',
  3: '研修生',
  4: '契約社員',
  5: '派遣社員',
  6: '業務委託',
  7: 'アルバイト',
};

// userBasicMainOption.js userBasicOrg.js
export const USER_STATUS = {
  1: '利用可',
  2: '利用停止',
  3: '退職',
};

// userBasicmain.js
export const IS_HIDE = {
  0: '無効',
  1: '有効',
};

export const IS_TREE = {
  0: '無効',
  1: '有効',
};

export const IS_HIDE_OPPOSE = {
  0: '無効',
  1: '有効（組織削除）',
};

// userBasicMainOption.js
export const IS_QUALIFICATION = {
  0: '資格なし',
  1: '資格あり',
};

export const USER_DIVISION_CODE = {
  1: '社員',
  2: '内定者',
};

export const IS_MAIN_DIVISION = {
  0: 'サブ',
  1: 'メイン',
};

export const POSITION_CODE = {
  1: '営業部長',
  2: 'センター長',
  3: 'マネージャ',
  4: 'プレーヤー',
  5: 'その他',
  6: 'サブセンター長',
};

export const GRADE_CODE = {
  0: 'なし',
  1: '副主任',
  2: '主任',
  3: '係長',
  4: '専任課長',
  5: '課長',
  6: '専任次長',
  7: '次長',
  8: '部長代理',
  9: '部長',
  10: '副本部長',
  11: '本部長',
  12: '専務',
  13: '監査役',
  14: '調査役',
  15: '室長',
  16: '参与',
  17: '顧問',
  18: '削除',
  19: '常務',
};

export const SYSTEM_CODE = {
  1: 'SFA',
  2: 'キマリス',
  3: 'ダイジン',
};

export const BUSINESS_CODE = {
  1: '戸建',
};

// userBasicAuthority.js
export const IS_SALES = {
  0: '営業成績対象外',
  1: '営業成績対象',
};

export const DIVISION_UPDATE_TYPE = {
  1: '追加',
  2: '統合(異動/削除)',
};

export const IS_MOVE = {
  0: '持っていく',
  1: '置いていく',
};

// TODO: 一緒なので一度、統一
export const LEAVE_STATUS = CUSTOMER_STATUS_CODE;

export const IS_RECIEVE_SHARE = {
  0: '共有解除しない',
  1: '共有解除する',
};

export const IS_SEND_SHARE = {
  0: '共有解除しない',
  1: '共有解除する',
};

export const IS_DEPARTMENT_SALES = {
  0: '対象',
  1: '対象外',
};

export const IS_IGNORE_EXPIRE = {
  0: '無視しない',
  1: '無視する',
};

export const DIVISION_CODE = {
  0: '反響日',
  1: 'ランク',
  2: '電話の最新日',
  3: 'メール最終送信日',
};

export const UNIT_CODE = {
  0: '未選択',
  1: '地域',
  2: '部',
  3: 'センター',
  4: '課',
  5: '課員',
};

export const IS_WEB_HISTORY = {
  0: 'なし',
  1: 'あり',
};

export const IS_SEND_MAIL = {
  0: 'なし',
  1: 'あり',
};

export const IS_CALL_PHONE = {
  0: 'なし',
  1: 'あり',
};

export const IS_DIRECT = {
  0: 'なし',
  1: 'あり',
};

export const ACTION_CODE = {
  1: '新規',
  2: '再反',
  3: '来社',
  4: '案内予約',
  5: '案内',
  6: '電話',
  7: 'メール',
  8: 'DM',
  9: '戻し',
  10: '契約',
  11: 'ダウンロード',
  12: 'クレーム処理',
  13: 'クリーニング',
  14: 'メールエラー',
  15: 'suumoネット',
  16: '退会のお知らせ',
  17: '情報の変更',
  18: '顧客の結合',
  19: 'LINE',
  20: 'SMS',
  21: '郵送',
  22: 'システム', // SMSエラー用 ※メール送信エラーと表示を合わせる
  91: 'システム',
};

// 対応履歴種別コードの中で日時の変更可のコード
export const ACTION_CODE_CHANGEABLE = [6, 7, 19, 20, 21];

export const ACTION_SEARCH_CODE = {
  1: '件名',
  2: '対応内容',
  4: '対応者',
};

export const IS_INVESTMENT2 = {
  0: '投資用でない',
  1: '投資用',
};

export const IS_PURCHASE2 = {
  0: '検討なし',
  1: '検討あり',
};

export const MAIL_OPEN_HISTORY = {
  0: '開封なし',
  1: '今日',
  2: '～7日',
  3: '～14日',
  4: '～30日',
  5: '～過去全て',
};

export const GOUDANCE_HISTORY = {
  0: 'なし',
  1: 'あり',
};

export const GUIDANCE_HISTORY = {
  0: 'なし',
  1: 'あり',
};

export const IS_PHONE = {
  0: '連絡不可',
  1: '連絡可',
};

export const PHONE_SUPPORT_STATUS = {
  1: '今日',
  2: '～7日',
  3: '～14日',
  4: '～30日',
  5: '～過去全て',
};

export const IS_MAIL = {
  1: '今日',
  2: '～7日',
  3: '～14日',
  4: '～30日',
  5: '～過去全て',
};

export const MAIL_SENDSTATUS = {
  0: 'ダメ全部NG',
  1: '個別のみOK',
  3: '一斉・個別OK',
  5: '代行もすべてOK',
};

export const IS_ADRESS = {
  0: 'なし',
  1: 'あり',
};

export const CUSTOMER_INTRODUCTION_STATUS2 = {
  0: '対象外',
  1: '対象',
};

export const IS_ALLOW_DM = {
  0: '許可しない',
  1: '許可',
};

export const SERCH_TYPE4 = {
  0: '案内無し',
  1: '案内有り',
};

export const SERCH_TYPE5 = {
  0: '接触無し',
  1: '接触有り',
};

export const SERCH_TYPE6 = {
  0: 'なし',
  1: 'あり',
};

export const CONTACT_CODE = {
  1: '案内',
  2: '架電',
};

export const IS_ATTACHMENT = {
  0: '無',
  1: '有',
};

export const IS_GUIDED = {
  0: '無',
  1: '有',
};

export const IS_VISITED_COMPANY = {
  0: '無',
  1: '有',
};

export const IS_OPENED_MAIL = {
  0: '無',
  1: '有',
};

export const IS_RESERVE = {
  0: '予約無',
  1: '予約有',
};

export const IS_RESULT_RESISTER = {
  0: '案内実績登録無',
  1: '案内実績登録有',
};

export const IS_PAIR_CHECK = {
  0: '無',
  1: '有',
};

export const IS_FINANCIAL_PLANNER = {
  0: '無',
  1: '有',
};

export const ACTION_TYPE = {
  1: '源泉(ポ)',
  2: '源泉(売)',
  3: '追客',
  4: '業務',
  5: 'その他',
  6: '源泉(駅)',
  7: '源泉(他)',
  999: '案内',
};

export const ACTION_TYPE_FLYER_SALES = [1, 2, 6, 7];

export const ACTION_TYPE_FLYER_SALES_WITHOUT_PORTER = [2, 6, 7];

export const CONTACT_USER_CODE = {
  0: '架電した社員',
  1: '接客社員1',
  2: '接客社員2',
  3: '同席社員（接客に同席した社員）',
  4: '案内社員',
  5: '同行社員（案内に同行した社員）',
};

export const DELETE_REASON_CODE = {
  0: '削除理由未設定',
  1: '案内キャンセル',
  2: '間違い（案内削除）',
  3: '対応履歴削除',
};

export const CUSTOMER_CODE = {
  0: '未入力',
  1: '本人',
  2: '配偶者',
  3: '親',
  4: '子ども',
  5: 'その他',
};

export const PORTER_RESULT_CODE = {
  0: 'ポーター実績無し',
  1: '来案',
  2: '来店接客のみ',
  3: '店舗にて資料渡しのみ',
};

export const IS_VISIT_COMPANY_RESULT = {
  0: '無し',
  1: '有',
};

export const IS_GUIDANCE_RESULT = {
  0: '無し',
  1: '有',
};

export const IS_GUIDED_BUILDING = {
  0: '案内済みでない',
  1: '案内済み',
};

export const IS_SCHEDULE_GUIDANCE = {
  0: '案内予定でない',
  1: '案内予定',
};

export const FLYERSALESMAN_CODE = {
  0: '未開始',
  1: '開始',
  2: '終了',
};

export const MAIL_FROM_SELF = 1;
export const MAIL_FROM_TEAM_MEMBER = 2;
export const MAIL_FROM_INFO = 3;
export const MAIL_FROM_CODE = {
  [MAIL_FROM_SELF]: '自分',
  [MAIL_FROM_TEAM_MEMBER]: '担当課員',
  [MAIL_FROM_INFO]: 'INFO',
};

export const MAIL_CODE_PRIVATE = 1;
export const MAIL_CODE_PUBLIC = 2;
export const MAIL_CODE_TRASH = 3;
export const MAIL_CODE_PRIVATE_SIGNATURE = 4;
export const MAIL_CODE_PRIVATE_UN_NOTIFICATION_OF_MAIL = 5;
export const MAIL_CODE_PRIVATE_UN_NOTIFICATION_OF_SMS = 6;
export const MAIL_CODE_PARENT_OF_USERS = 7;

export const MAIL_CODE = {
  [MAIL_CODE_PRIVATE]: '個人フォルダ',
  [MAIL_CODE_PUBLIC]: 'その他',
  [MAIL_CODE_TRASH]: 'ゴミ箱',
  [MAIL_CODE_PRIVATE_SIGNATURE]: '個人フォルダ（署名）',
  [MAIL_CODE_PRIVATE_UN_NOTIFICATION_OF_MAIL]: '個人フォルダ（不通時メール）',
  [MAIL_CODE_PRIVATE_UN_NOTIFICATION_OF_SMS]: '個人フォルダ（不通時SMS）',
  [MAIL_CODE_PARENT_OF_USERS]: 'ユーザ別フォルダ（親）',
};

export const INFO_AT_INFO = 1;
export const INFO_OH_OFFICE = 2;
export const INFO_CODE = {
  [INFO_AT_INFO]: '＠INFO',
  [INFO_OH_OFFICE]: 'オープンハウス事務局',
};

export const RESPONSE_TYPE_LEVEL_CODE = {
  1: '媒体(大)',
  2: '媒体(中)',
  3: '媒体(小)',
  4: '媒体(細)',
};

export const STORE_ORDER = {
  0: '昇順（asc）',
  1: '降順（desc）',
};

export const DIRECT_REPORT_STATUS_CODE = {
  1: '未確認',
  2: ' 対応予定',
  3: ' 承認待ち',
};

export const IMPORTANT_CODE = {
  1: '低',
  2: '中',
  3: '高',
};

export const IS_MANAGER = {
  0: 'マネージャでない',
  1: 'マネージャ',
};

export const IS_CENTER_MANAGER = {
  0: 'センター長でない',
  1: 'センター長',
};

export const DIRECT_TYPE_CODE = {
  0: '指示（指示を出した時）',
  1: '更新',
  2: '対応予定',
  3: '報告',
  4: '対応なし',
  5: '取り消し',
  6: 'コメント',
  7: '催促',
  8: '承認',
  9: 'やり直し',
  10: '引き戻し',
};

export const DIRECT_KIND_CODE_TEL = 1;
export const DIRECT_KIND_CODE_MAIL = 2;
export const DIRECT_KIND_CODE_LINE = 3;
export const DIRECT_KIND_CODE_GUIDANCE = 4;
export const DIRECT_KIND_CODE_REMINDER = 5;
export const DIRECT_KIND_CODE_OTHER = 6;
export const DIRECT_KIND_CODE = {
  [DIRECT_KIND_CODE_TEL]: '電話',
  [DIRECT_KIND_CODE_MAIL]: 'メール',
  [DIRECT_KIND_CODE_LINE]: 'LINE',
  [DIRECT_KIND_CODE_GUIDANCE]: '案内',
  [DIRECT_KIND_CODE_REMINDER]: '催促',
  [DIRECT_KIND_CODE_OTHER]: 'その他',
};

export const IS_NEED_DIRECTOR_CHECK = {
  0: '承認不要（即時完了にする）',
  1: '承認待ちにする',
};

export const IS_REMIND = {
  0: 'リマインドしない',
  1: 'リマインドする',
};

export const IS_DIRECT_MAIL = {
  0: '送信しない',
  1: '送信する',
};

export const IS_REPORT_MAIL = {
  0: '受信しない',
  1: '受信する',
};

export const IS_QUIT = {
  0: '退会していない',
  1: '退会している',
};

export const OWN_FUND_CODE = {
  1: '100万未満',
  2: '100万円～200万円',
  3: '200万円～300万円',
  4: '300万円～400万円',
  5: '400万円～500万円',
  6: '500万円～600万円',
  7: '600万円～700万円',
  8: '700万円～800万円',
  9: '800万円～900万円',
  10: '900万円～1000万円',
  11: '1000万円～2000万円',
  12: '2000万円～3000万円',
  13: '3000万円～4000万円',
  14: '4000万円～5000万円',
  15: '5000万円以上',
};

export const IS_REPLACEMENT = {
  0: '未回答',
  1: '買替有',
  2: '買替無',
};

export const LOAN_CODE = {
  1: '年収から計算',
  2: '毎月の返済額から計算',
  3: '家賃から計算する',
  4: '物件価格から計算',
};

export const TYPE = {
  0: '登録/更新',
  1: '買替有',
};

export const IS_SHARE = {
  0: '共有していない',
  1: '共有している',
};

export const IS_REMOVED = {
  0: '削除しない',
  1: '削除する',
};

export const IS_SEND_TYPE = {
  0: 'SMS',
  1: 'メール',
};

export const IS_WITHIN_DAYS = {
  0: '絞らない',
  1: '絞る',
};

export const DIRECTORY_CODE_SYSTEM_FIXED = 1;
export const DIRECTORY_CODE_SYSTEM = 2;
export const DIRECTORY_CODE_USER = 3;
export const DIRECTORY_CODE = {
  [DIRECTORY_CODE_SYSTEM_FIXED]: 'システムディレクトリ(下に作れない)',
  [DIRECTORY_CODE_SYSTEM]: 'システムディレクトリ(下に作れる)',
  [DIRECTORY_CODE_USER]: 'ユーザディレクトリ',
};

export const IS_NOTIFICATION = {
  0: '通知しない',
  1: '通知する',
};

export const IS_ORGANIC = {
  0: '獲得していない',
  1: '獲得している',
};

export const IS_SEND_MAIL_LATER = {
  0: '延期しない',
  1: '延期する',
};

export const IS_ABODE_NOW_REPLACEMENT = {
  0: '買替無し',
  1: '買替有り',
};

export const TYPE_FLAG = {
  0: 'WEB戻し',
  1: 'WEB再反',
};

export const IS_PRODUCTION = {
  0: 'テスト実行',
  1: '本番実行',
};

export const RESISTED_ARRS_TYPE1_VALUE = {
  0: '未処理',
  1: '組合せパターン',
  2: '配点',
  3: '設定区上限',
  4: '店舗指名リクエスト',
};

export const RESISTED_ARRS_TYPE2_VALUE = {
  0: '未処理',
  1: '組合せパターン単一',
  2: '希望地域の配点単一',
  3: 'WEB閲覧最多',
  4: '最寄店舗',
  5: '市外局番',
  6: '当月反響最少',
  7: 'ランダム',
  8: '検索地域の配点最多',
  9: 'REGISTA駅沿線最多',
  10: '店舗指名リクエスト',
};

export const DIVISION_TYPE = {
  1: '会社',
  2: '事業部',
  3: '部',
  4: 'センター',
  5: '課',
};

export const NUMBER_OF_GUIDES = {
  1: '0',
  2: '1',
  3: '2',
  4: '3',
  5: '4',
  6: '5',
  7: '6回以上',
};

export const CURRENT_SITUATION_TYPE_NO_BLANK = {
  1: '実家',
  2: '持家',
  3: '賃貸',
  4: '社宅',
};

export const CURRENT_SITUATION_TYPE = {
  0: '　',
  ...CURRENT_SITUATION_TYPE_NO_BLANK,
};

export const WORKING_STYLE_TYPE = {
  0: '　',
  1: '在宅',
  2: '出社',
  3: '在宅/出社',
};

export const ALLOCATION = {
  1: '担当有り',
  2: '担当未定',
  3: '退職者',
  4: '非営業職',
};

export const FAMILY_STRUCTURE = {
  1: '主人',
  2: '奥様',
  3: '夫婦',
  4: '主人 + 子',
  5: '奥様 + 子',
  6: '夫婦 + 子',
  7: '親',
  8: '単身男性',
  9: '単身女性',
  10: 'その他',
};

export const MAIL_STATUS_CODE = {
  0: '予約',
  1: '配信待機中',
  2: '配信中',
  3: '配信済',
  4: '配信キャンセル',
  5: '予約エラー',
};

export const BUDGET_SELECT = {
  0: 0,
  1000: 1000,
  1500: 1500,
  2000: 2000,
  2500: 2500,
  3000: 3000,
  3500: 3500,
  4000: 4000,
  4500: 4500,
  5000: 5000,
  5500: 5500,
  6000: 6000,
  6500: 6500,
  7000: 7000,
  7500: 7500,
  8000: 8000,
  8500: 8500,
  9000: 9000,
  9500: 9500,
  10000: 10000,
  11000: 11000,
  12000: 12000,
  13000: 13000,
  14000: 14000,
  15000: 15000,
  16000: 16000,
  17000: 17000,
  18000: 18000,
  19000: 19000,
  20000: 20000,
  999999: 999999,
};

export const COLOR_THEME = {
  default: 'OHブルー',
  green: 'グリーン',
  pink: 'ピンク',
  orange: 'オレンジ',
  lightgreen: 'ライトグリーン',
  purple: 'パープル',
};

export const COLOR_CODE = {
  default: '#0679BF',
  green: '#048308',
  pink: '#bf0653',
  orange: '#d56719',
  lightgreen: '#67bf06',
  purple: '#5d13d9',
};

export const GROUP_MAIL_AREA_TYPE = {
  1: '城南',
  2: '城西',
  3: '城東',
  4: '城北',
  5: '神奈川',
  6: '名古屋',
  7: '福岡',
  8: '関西',
  9: '埼玉',
  10: '千葉',
  11: '川崎',
  12: '横浜',
  13: '名古屋西',
  14: '名古屋東',
  15: '大阪',
  16: '兵庫',
  17: '群馬',
};

export const SEND_MAIL_AREA = {
  1: GROUP_MAIL_AREA_TYPE[1],
  2: GROUP_MAIL_AREA_TYPE[2],
  3: GROUP_MAIL_AREA_TYPE[3],
  4: GROUP_MAIL_AREA_TYPE[4],
  7: GROUP_MAIL_AREA_TYPE[7],
  9: GROUP_MAIL_AREA_TYPE[9],
  10: GROUP_MAIL_AREA_TYPE[10],
  11: GROUP_MAIL_AREA_TYPE[11],
  12: GROUP_MAIL_AREA_TYPE[12],
  13: GROUP_MAIL_AREA_TYPE[13],
  14: GROUP_MAIL_AREA_TYPE[14],
  15: GROUP_MAIL_AREA_TYPE[15],
  16: GROUP_MAIL_AREA_TYPE[16],
  17: GROUP_MAIL_AREA_TYPE[17],
};

export const PREVENT_TARGET_KEY_CODE = [
  13, // Enter
  32, // Space
];

// Web閲覧履歴
export const WEB_BROWSING_HISTORY = {
  PROCESS: 0, // 処理履歴
  CHANCE: 1, // チャンス
  LOAN: 2, // ローンシミュレーション
  KEYWORDS: 3, // 検索・流入キーワード
  BROWSING: 4, // 閲覧履歴
  SEARCH: 5, // 検索条件履歴
  CONTENTS: 6, // コンテンツ閲覧履歴
  ONECLICK: 7, // ワンクリック検索条件履歴
  FAVORITES: 8, // お気に入りリスト
};

// 個別SMSタブindex
export const TAB_INDEX_UNSEND_MAIL_SINGLE = 0;
export const TAB_INDEX_UNSEND_MAIL_MULTI = 1;
export const TAB_INDEX_UNSEND_SMS = 2;
