import { useEffect, useState } from 'react';
import {
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  makeStyles,
} from '@material-ui/core';
import commonStyles from '../../styles';
import { useCustomerTree } from '../../../containers/common/customerTree/context';
import { getLocalStorage, setLocalStorage } from '../../../commonFunction';
import {
  TREE_DIVISION_ID,
  ALL_DIVISION,
} from '../../../constants/localStorage';
import getOwnDivisionApi from '../../../apis/divisionMaster/getOwnDivisionApi';

const useStyles = makeStyles({
  selectWrap: {
    borderBottom: '1px solid #C8C8C8',
    paddingTop: 0,
    marginTop: 8,
  },
  select: {
    width: '33%',
    marginBottom: 4,
    '& .MuiSelect-selectMenu': {
      fontSize: '13px',
      textAlign: 'left',
    },
  },
});

function CustomerTreeSelectDivision() {
  const { searchHeader, setSearchHeader } = useCustomerTree();
  const baseClasses = commonStyles();
  const classes = useStyles();

  const [selectList, setSelectList] = useState({
    menus: {},
    title: '部選択',
  });

  const setValues = (e) => {
    setLocalStorage(TREE_DIVISION_ID, Number(e.target.value));
    setSearchHeader({
      ...searchHeader,
      divisionId: Number(e.target.value),
    });
  };

  const getOwnDivision = async (params) => {
    let result;
    await getOwnDivisionApi(params)
      .then((res) => {
        result = res?.divisions;
      })
      .catch(() => {
        result = [];
      });
    return result;
  };

  const allDivision = async () => {
    let resultObj = {
      menus: {},
      title: '部選択',
    };
    const divisionStorage = await getLocalStorage(ALL_DIVISION, resultObj);
    if (Object.keys(divisionStorage?.menus).length > 0) {
      resultObj = divisionStorage;
    } else {
      const ownDivisions = await getOwnDivision({ divisionSearchCode: 0 });
      if (ownDivisions?.length > 0) {
        for (let i = 0; ownDivisions?.length > i; i += 1) {
          resultObj.menus[ownDivisions[i].divisionId] = ownDivisions[i].divisionName;
        }
      }
      if (Object.keys(resultObj.menus).length > 0) {
        setLocalStorage(ALL_DIVISION, resultObj);
      }
    }
    return resultObj;
  };

  useEffect(async () => {
    const result = await allDivision();
    setSelectList(result);
  }, []);

  return (
    <FormControl className={`${baseClasses.inputWrap} ${classes.select}`}>
      <InputLabel>営業部</InputLabel>
      <Select
        value={searchHeader?.divisionId}
        onChange={(e) => setValues(e)}
      >
        {Object.keys(selectList.menus).map((key) => (
          <MenuItem key={selectList?.menus[key]} value={key}>
            {selectList?.menus[Number(key)]}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export default CustomerTreeSelectDivision;
