import { useState } from 'react';
import InstructionReportWindowSp from '../../../../components/common/instructionReport/sp/instructionReportWindowSp/instructionReportWindowSp';
import directGetDetailApi from '../../../../apis/direct/directGetDetailApi';
import directTupdateApi from '../../../../apis/direct/directTupdateApi';

import { dateFormatter } from '../../../../commonFunction';
import { DIRECT_ACTION } from '../../../../constants/loading';
import { useLoading } from '../../../../hooks';

const dateInitial = dateFormatter(new Date());

const initialDetail = {
  isManager: null,
  isCenterManger: null,
  directCode: null,
  approveCode: null,
  directId: null,
  customerId: null,
  customerLastName: null,
  customerFirstName: null,
  memberStatusCode: null,
  directedUserId: null,
  directedLastName: null,
  directedFirstName: null,
  directedDivisionId: null,
  expireAt: dateInitial,
  directReportStatusCode: null,
  directUserId: null,
  directUserLastName: null,
  directUserFirstName: null,
  importantCode: null,
  userLevel: null,
  others: [],
  timelines: [],
};

function InstructionWindowSpContainers(props) {
  const {
    open,
    setOpen,
    onClose,
    tab,
    row,
    getDirects,
    resetRender,
    setResetRender,
    displayCustomerDetail,
    openCustomer,
    setOpenCustomer,
    visibleFooter,
  } = props;

  const { addLoading, removeLoading } = useLoading();

  const [detail, setDetail] = useState(initialDetail);

  // 指示情報取得 【direct002】
  /**
   * @param {integer} directId
   */
  const getDetailDirect = async (directId) => {
    await directGetDetailApi(directId)
      .then((res) => setDetail(res.data))
      .catch((err) => console.log(err));
  };

  // タイムライン登録 【direct005】
  const createTimeline = async (directId, params) => {
    addLoading(DIRECT_ACTION);
    await directTupdateApi(directId, params)
      .then((res) => {
        console.log(res);
        setResetRender(resetRender + 1);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => removeLoading(DIRECT_ACTION));
  };

  return (
    <InstructionReportWindowSp
      open={open}
      setOpen={setOpen}
      onClose={onClose}
      tab={tab}
      row={row}
      detail={detail}
      getDetailDirect={getDetailDirect}
      createTimeline={createTimeline}
      getDirects={getDirects}
      displayCustomerDetail={displayCustomerDetail}
      openCustomer={openCustomer}
      setOpenCustomer={setOpenCustomer}
      visibleFooter={visibleFooter}
    />
  );
}

export default InstructionWindowSpContainers;
