import { Button, makeStyles, Grid, Dialog } from '@material-ui/core';
import { useState, useRef } from 'react';
import Calendar from '../../../common/calendar';
import commonStyles from '../../../styles';
import toISOStringWithTimezone from '../../../../commonFunction/toISOStringWithTimezone';

const useStyles = makeStyles({
  flex: {
    padding: '24px',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    '& .MuiGrid-root': {
      border: 'none',
    },
  },
  item: {
    '& label': {
      width: '100%',
      '& > span': {
        fontSize: '20px',
      },
    },
  },
  header: {
    padding: '16px 16px 8px 16px',
    width: '100%',
    borderBottom: '1px solid rgba(51, 51, 51, 0.04)',
    boxSizing: 'border-box',
    position: 'relative',
    textAlign: 'center',
  },
});

export default function UnsentDialogChangeSendTime(props) {
  const {
    open,
    setOpen,
    values,
    functions,
    clickItem,
    options,
  } = props;
  const common = commonStyles();
  const classes = useStyles();

  const focusRef = useRef();

  const reData = new Date(values.deliveryAt);
  const defaultDate = reData.getFullYear() + '/' + (reData.getMonth() + 1) + '/' + reData.getDate();
  const hourStr = reData.getHours().toString().padStart(2, '0');
  const minuteStr = reData.getMinutes().toString().padStart(2, '0');
  const defaultTime = hourStr + ':' + minuteStr;
  const [date, setDate] = useState({ limitAt: defaultDate });
  const [time, setTime] = useState({ assignAt: defaultTime });

  const timeOnchange = (value) => {
    setTime({ ...time, assignAt: value });
  };

  const onClose = () => {
    setOpen(false);
  };

  const onclickSave = async () => {
    const fullTime = date.limitAt + ' ' + time.assignAt;
    await functions
      .updateUnSendMail({
        mailQueueId: clickItem.mailQueueId,
        deliveryAt: fullTime,
      }, options?.current)
      .then((res) => {
        console.log(res);
        setOpen(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const formatDate = (dt) => {
    const tostring = toISOStringWithTimezone(dt);
    const result = tostring.slice(0, 16);
    return result;
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      className={`${common.dialog} ${common.maxWidthDialog}`}
    >
      <Grid container>
        <Grid className={`${common.title1} ${classes.header}`}>
          送信開始時間
          <Button
            className={common.closeButton}
            onClick={onClose}
          />
        </Grid>
        <Grid className={classes.flex}>
          {/* 日時選択 */}
          <Grid className={classes.item}>
            <Calendar
              data={{
                type: 'date',
                label: '',
                state: date.limitAt,
                ref: focusRef,
                setState: (e) => { return setDate({ limitAt: e }); },
                min: formatDate(new Date()),
              }}
            />
          </Grid>
          {/* 時刻選択 */}
          <Grid className={classes.item}>
            <Calendar
              data={{
                id: 'seedtime',
                label: '',
                type: 'timeonly',
                state: time,
                ref: focusRef,
                setState: timeOnchange,
                notConvert: true,
              }}
            />
          </Grid>
        </Grid>
        <Grid container justifyContent="center" className={common.fixedFooter}>
          <Button
            className={
              `${common.buttonsSecondary} ${classes.fixedFooterBtn}`
            }
            onClick={onClose}
          >
            キャンセル
          </Button>
          <Button
            className={
              `${common.buttonsPrimary} ${classes.fixedFooterBtn}`
            }
            onClick={onclickSave}
          >
            保存
          </Button>
        </Grid>
      </Grid>
    </Dialog>
  );
}
