import { createSlice } from '@reduxjs/toolkit';
import { format } from 'date-fns';
// 初期値
import initialRegisterState from './initialRegisterState';

import registerStateToUpdateState from './registerStateToUpdateState';
import { UPDATE_TYPE_CODE } from '../../constants/apiParameterCode';
import { JOIN_DATA_TYPES, JOIN_DATA_FROM_TYPES } from '../../constants/crm';

const clone = (value) => JSON.parse(JSON.stringify(value));

const initialInquiry = {
  data: {
    total: 0,
    products: [],
  },
};

const initialResponse = {
  data: {
    responses: [],
  },
};

/**
 * データ結合画面を表示するかの情報
 * @type {{
 *   isShow: boolean;
 *   type: JOIN_DATA_TYPES;
 *   childResponseId: number | null;
 *   webResponseData: null | Record<string, any>;
 *   fromType: JOIN_DATA_FROM_TYPES;
 * }}
 */
const initialJoinData = {
  // データ結合画面を表示するか
  isShow: false,
  type: JOIN_DATA_TYPES.INITIAL,
  childResponseId: null,
  // 反響未処理（WEB）/源泉未処理で選択された行の情報
  webResponseData: null,
  // 反響未処理（WEB）/源泉未処理区分
  fromType: JOIN_DATA_FROM_TYPES.INITIAL,
};

const initialState = {
  /**
   * ページを表示する準備ができているか
   */
  isReady: false,
  /**
   * GA058の操作によって変化する
   * register: 新規登録 customerIdが"-"の時のみ
   * update: 顧客情報の変更 updateType=1
   * return: 戻し updateType=2
   * rebellion: 再反 updateType=3
   * @type {UPDATE_TYPE_CODE}
   */
  registerType: UPDATE_TYPE_CODE.INITIAL,
  customerId: '-',
  editCustomerId: '-',
  /**
   * 検索一覧で選択された行の情報
   */
  selectedCustomerRow: null,
  user: clone(initialRegisterState),
  inquiry: clone(initialInquiry),
  response: clone(initialResponse),
  // /**
  //  * GA058の操作によって変化する
  //  */
  // isClaim: false,
  /**
   * GA069画面に検索ワードを渡す
   */
  searchKeyword: '',
  forceSearch: 0,
  /**
   * GA058顧客操作画面（モーダル）
   * 「データ結合、WEB再反登録、WEB戻し登録」ボタンを押したらTrueにする
   * データ結合後にFalseにする、GA066「新規反響登録」を表示するため
   * @type {initialJoinData}
   */
  joinData: clone(initialJoinData),
  /**
   * 結合完了時に強制再読み込みする
   * https://openhouse.backlog.jp/view/SFA_ASIAQUEST-4569
   * 呼び出し元
   * src/components/pages/joinData/joinData.js
   */
  forceReload: {
    responseList: false,
    flyerSalesList: false,
  },
};

const getTodayDateTime = () => format(new Date(), 'yyyy/MM/dd HH:mm:ss');

export const crmPageSlice = createSlice({
  name: 'crmPage',
  initialState,
  reducers: {
    /**
     * /crm画面の時に実行
     */
    enterPage: (state) => {
      const effectAt = getTodayDateTime();
      state.user.effectAt = effectAt;
      state.isReady = true;
    },
    /**
     * 検索一覧で選択された行の情報をセットする
     * @param {initialState} state
     * @param {{ playload?: *}} param1
     */
    setSelectedCustomerRow: (state, { payload }) => {
      state.selectedCustomerRow = payload;
      state.customerId = payload?.customerId;
    },
    /**
     * お問い合わせ物件を追加した
     * @param {import("./type").SelectedInquiryProduct} action
     */
    selectedInquiryProduct: (state, action) => {
      const { products } = state.inquiry.data;
      const { siteNumber, productName, siteId, inquiredAt } = action.payload;
      // 既に存在したら、追加しない
      if (
        products.some((item) => item.siteNumber === siteNumber
        && item.productName === productName && item.siteId === siteId)
      ) return;
      const randomNumber = -Number(new Date());
      products.push({
        // DBに登録されているデータと分けるため、負数を入れる
        inquiryProductId: randomNumber,
        // YYYY/MM/DDの場合、APIバリデーションエラーが出る
        inquiredAt,
        siteNumber,
        productName,
        siteId,
      });
    },
    /**
     * お問い合わせ物件を削除した
     * @param {import("./type").DeletedInquiryProducts} action
     */
    deletedInquiryProducts: (state, action) => {
      state.inquiry.data.products = state.inquiry.data.products.filter(
        (value) => value.inquiryProductId !== action.payload.inquiryProductId,
      );
    },
    /**
     * 1.「編集内容を破棄する」を実行した
     * 2.新規登録が完了した後「いいえ」をクリック、またはモーダルの外をクリックした
     * 3.「保存」「戻し」「再反」をクリックした
     */
    resetCustomerData: (state) => {
      const effectAt = getTodayDateTime();
      state.registerType = UPDATE_TYPE_CODE.INITIAL;
      state.customerId = '-';
      state.editCustomerId = '-';
      state.user = clone({ ...initialRegisterState, effectAt });
      state.inquiry = clone(initialInquiry);
      state.response = clone(initialResponse);
    },
    /**
     * 新規登録が完了した
     * @param {import("./type").CompletedCreateResponseRegister} action
     */
    completedCreateResponseRegister: (state, action) => {
      state.customerId = action.payload.customerId;
    },
    /**
     * 新規登録が完了した後「はい」をクリックした
     * @param {import("./type").KeepEditAtCompletedResponseRegister} action
     */
    keepEditAtCompletedResponseRegister: (state, action) => {
      state.registerType = UPDATE_TYPE_CODE.CUSTOMER_UPDATE;

      const newState = registerStateToUpdateState(action.payload.pageUserState);
      newState.updateType = UPDATE_TYPE_CODE.CUSTOMER_UPDATE;
      newState.effectAt = getTodayDateTime();
      newState.getDataAt = getTodayDateTime();

      state.user = newState;
      state.editCustomerId = state.customerId;
    },
    exitPage: (state) => {
      // eslint-disable-next-line no-unused-vars
      state = initialState;
    },

    /**
     * 顧客操作画面のモーダルの「顧客情報変更」をクリックした
     */
    clickedCustomerUpdateButton: (state, action) => {
      state.registerType = UPDATE_TYPE_CODE.CUSTOMER_UPDATE;
      const user = action.payload.customer || {};
      user.updateType = UPDATE_TYPE_CODE.CUSTOMER_UPDATE;
      // データ取得時刻
      if (!user.getDataAt) user.getDataAt = getTodayDateTime();
      state.user = user;

      state.inquiry = action.payload.inquiry || clone(initialInquiry);
      state.response = action.payload.response || clone(initialResponse);
      state.editCustomerId = state.customerId;
    },
    /**
     * 「再反登録」をクリックした
     */
    clickedRebellionButton: (state, { payload }) => {
      state.registerType = UPDATE_TYPE_CODE.REBELLION;
      const user = payload.customer;
      user.updateType = UPDATE_TYPE_CODE.REBELLION;
      if (!user.getDataAt) user.getDataAt = getTodayDateTime();
      state.user = user;

      state.inquiry = payload.inquiry || clone(initialInquiry);
      state.response = payload.response || clone(initialResponse);
      state.editCustomerId = state.customerId;
    },
    /**
     * 「戻し登録」をクリックした
     */
    clickedReturnButton: (state, { payload }) => {
      state.registerType = UPDATE_TYPE_CODE.RETURN;
      const user = payload.customer;
      user.updateType = UPDATE_TYPE_CODE.RETURN;
      if (!user.getDataAt) user.getDataAt = getTodayDateTime();
      state.user = user;

      state.inquiry = payload.inquiry || clone(initialInquiry);
      state.response = payload.response || clone(initialResponse);
      state.editCustomerId = state.customerId;
    },
    /**
     * GA066で検索できる項目をクリックした
     * @param {initialState} state
     * @param {{ payload: string; type: string }} param1
     */
    setSearchKeyword: (state, { payload }) => {
      const { isForceSearch = false, searchWord } = payload;
      state.searchKeyword = searchWord;
      if (isForceSearch) state.forceSearch += 1;
    },
    researchCustomer: (state) => {
      state.forceSearch += 1;
    },
    /**
     * GA058顧客操作画面（モーダル）で
     * 「データ結合、WEB再反登録、WEB戻し登録」ボタンを押したらisShowをTrueにする
     * データ結合後にFalseにする、GA066「新規反響登録」を表示するため
     * @param {initialState} state
     * @param {{payload: void 0 | initialJoinData; type: sting}} param1
     */
    setJoinData: (state, { payload }) => {
      if (payload) {
        state.joinData = {
          ...state.joinData,
          ...payload,
        };
      } else {
        // 検索一覧で選択された情報をリセットする
        state.joinData = { ...initialJoinData };
      }
    },
    /**
     * 結合完了時に強制再読み込みする
     * https://openhouse.backlog.jp/view/SFA_ASIAQUEST-4569
     * 呼び出し元
     * src/components/pages/joinData/joinData.js
     */
    forceReload: (state, { payload }) => {
      if (payload.responseList) {
        state.forceReload.responseList = !state.forceReload.responseList;
      }
      if (payload.flyerSalesList) {
        state.forceReload.flyerSalesList = !state.forceReload.flyerSalesList;
      }
    },
  },
});

/**
 * @param {import("../type").Store} state
 */
export const crmPageSelector = (state) => state.crmPage;

export const crmPageActions = crmPageSlice.actions;

export default crmPageSlice.reducer;
