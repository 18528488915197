import { useState, useEffect } from 'react';
import areaListApi from '../../../apis/common/areaList';
import AreaSentakuWrapper from '../../../components/common/areaSentaku/areaSentaku';
import { AREA_CODE } from '../../../constants/index';
import { useLoading } from '../../../hooks';
import { useStoreGroupsSettingsAreas } from '../../../components/combinationSetting/common/useStoreGroupsSettingsAreas';

function AreaSentakuContainers(props) {
  const {
    initialParam = {},
    open,
    onClose,
    onUpdate,
    isFromSearch = false,
    isNoneWishAreaInit = false, // 希望地域無しの初期値を指定（簡易/詳細検索のstateと同期するため)
  } = props;
  const { addLoading, removeLoading } = useLoading();
  const { areaList } = useStoreGroupsSettingsAreas();
  const initialAreas = areaList.map((area) => ({
    id: area.areaCode,
    val: [],
    loaded: false,
    wish: [],
    ids: [],
  }));
  // エリアタブのインデックスを保持
  const [areaTabIndex, setAreaTabIndex] = useState(0);
  // エリアタブ変更用
  const [countTabIndex, setCountTabIndex] = useState(0);
  // API読み込み完了検知用
  const [countApi, setCountApi] = useState(0);
  // エリア情報を保持
  const [areas, setAreas] = useState(initialAreas);
  // 希望地域無しチェックボックスの状態を保持
  const [isNoneWishArea, setIsNoneWishArea] = useState(isNoneWishAreaInit);

  // keyでグループ化した配列を返却
  const groupBy = (xs, key) => {
    // チェック後に順番を変更するためのidを追加
    xs = xs.map((rv, i) => {
      return Object.assign(rv, { id: i });
    });
    // グループ化
    const obj = xs.reduce((rv, x) => {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
    // グループを配列に変換し、子要素用にデータを揃える
    const entries = Object.entries(obj).map((value, index) => {
      return {
        id: index + 1,
        state: {
          data: {
            areas: value[1],
          },
        },
      };
    });
    return entries;
  };

  // GET areaList
  const areaListFunction = async (areaCode) => {
    // sortColumnとsortOrderを追加
    // https://openhouse.backlog.jp/view/SFA_ASIAQUEST-4434
    const params = {
      isWishArea: 1,
      sortColumn: 2,
      sortOrder: 0,
    };
    addLoading('Area');
    await areaListApi(areaCode, params)
      .then((res) => {
        removeLoading('Area');
        // 何度も読み込ませる必要がないためエリア情報をスタック
        const newAreas = [...areas];
        newAreas[areaTabIndex] = { ...newAreas[areaTabIndex], val: groupBy(res.data.areas, 'wishRoughAreaName'), loaded: true };
        setAreas(newAreas);
        setCountApi(countApi + 1);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // エリアタブチェンジ時
  const onChangeArea = (index) => {
    setAreaTabIndex(index);
    setCountTabIndex(countTabIndex + 1);
  };

  // チェックボックス変更時
  const onChangeChecked = (param) => {
    const newAreas = [...areas];
    const currentNewArea = newAreas[areaTabIndex];
    let wishAreas = currentNewArea.wish;
    const wishIndex = wishAreas.findIndex(({ id }) => param.id === id);
    if (param.checked && wishIndex === -1) {
      // 追加
      delete param.checked;
      wishAreas.push(param);
    } else if (!param.checked && wishIndex !== -1) {
      // 削除
      wishAreas.splice(wishIndex, 1);
    }
    // idでソート
    wishAreas = wishAreas.sort((a, b) => {
      return a.id < b.id ? -1 : 1;
    });
    setAreas(newAreas);
  };

  // 選択が押された時の処理
  const onSelect = () => {
    const currentArea = areas[areaTabIndex];
    const wishAreaIds = currentArea.wish.map(o => o.cityJisCode);
    const params = {
      id: initialParam.id,
      wishAreaCode: wishAreaIds.length ? currentArea.id : null,
      wishArea: wishAreaIds.length || isNoneWishArea
        ? `${!isFromSearch ? `/${AREA_CODE[currentArea.id]}` : ''}/${currentArea.wish.map(o => o.label).join('/')}${currentArea.wish.length ? '/' : ''}${isNoneWishArea ? '希望地域無し/' : ''}`
        : null,
      wishAreaIds,
      isNoneWishArea,
    };
    onUpdate(params);
  };

  // 初期値の受け渡し
  useEffect(() => {
    if (open) {
      // wishAreaCode: 希望地域コードid
      // wishAreaIds: 希望地域id配列
      // 初期値の設定（任意の値）
      const areaIds = areas.map((area) => area.id);
      initialParam.id = [undefined, null].includes(initialParam.id) ? 1 : initialParam.id;
      initialParam.wishAreaCode = !areaIds.includes(initialParam.wishAreaCode)
        ? areas[0].id : initialParam.wishAreaCode;
      initialParam.wishAreaIds = [undefined, null].includes(initialParam.wishAreaIds)
        ? [] : initialParam.wishAreaIds;
      // 希望地域無しチェックボックスの初期化
      setIsNoneWishArea(isNoneWishAreaInit);
      // wishの初期化
      const newAreas = [...areas];
      for (let i = 0; i < newAreas.length; i += 1) {
        newAreas[i] = { ...newAreas[i], wish: [] };
      }
      setAreas(newAreas);
      // 初期タブの設定
      const index = areas.findIndex(({ id }) => id === initialParam.wishAreaCode);
      const currentTabIndex = index === -1 ? 0 : index;
      onChangeArea(currentTabIndex);
    }
  }, [open]);

  // countTabIndex変更時
  useEffect(() => {
    if (open) {
      if (areas[areaTabIndex].loaded) {
        setCountApi(countApi + 1);
      } else {
        // 読み込まれていない場合のみAPI通信する
        const areaCode = areas[areaTabIndex].id;
        areaListFunction(areaCode);
      }
    }
  }, [countTabIndex]);

  // countApi変更時
  useEffect(() => {
    if (open) {
      // 希望地域を初期設定
      if (initialParam.init) {
        return;
      }
      if (areas[areaTabIndex].loaded) {
        initialParam.init = true;
        const currentArea = areas[areaTabIndex];
        currentArea.val.forEach((val, i) => {
          val.state.data.areas.forEach((valAreas) => {
            if (initialParam.wishAreaIds.includes(valAreas.cityJisCode)) {
              onChangeChecked({
                id: valAreas.id,
                index: i,
                label: valAreas.city,
                cityJisCode: valAreas.cityJisCode,
                checked: true,
              });
            }
          });
        });
      }
    }
  }, [countApi]);

  // areaList更新時
  useEffect(() => {
    setAreas(initialAreas);
  }, [areaList]);

  return (
    <AreaSentakuWrapper
      areaTabIndex={areaTabIndex}
      areas={areas}
      open={open}
      onClose={onClose}
      onSelect={onSelect}
      onChangeArea={onChangeArea}
      onChangeChecked={onChangeChecked}
      isNoneWishArea={isNoneWishArea}
      setIsNoneWishArea={setIsNoneWishArea}
      isFromSearch={isFromSearch}
    />
  );
}

export default AreaSentakuContainers;
