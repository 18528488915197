import {
  makeStyles,
  Grid,
} from '@material-ui/core';
import Modal from '../../common/modal';
import commonStyles from '../../styles';

const useStyles = makeStyles({
  dialog: {
    '& .MuiPaper-root': {
      padding: '24px',
      width: '100%',
      marginRight: '16px',
      marginLeft: '16px',
    },
  },
  content: {
    padding: 0,
  },
  ttl: {
    textAlign: 'center',
    padding: 0,
    marginTop: '8px',
    '& .MuiTypography-root': {
      fontWeight: '700',
    },
  },
  button: {
    '&::before, &::after': {
      background: '#fff',
    },
  },
  footer: {
    /* Auto layout */
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '40px',
  },
});

export default function UnsentSmsDialogConfirmForPC(props) {
  const {
    openDialog,
    setOpenDialog,
    text,
    smsFunctions,
    targetRow, // 対象smsの全情報
    options,
  } = props;

  const classes = useStyles();
  const baseClasses = commonStyles();

  /**
   * @module deleteSms - 【sendmail005】メール送信停止
   * @param {Object} targetRow - 選択された対象メールの全内容
   */
  const deleteSms = async () => {
    setOpenDialog(false);
    await smsFunctions.cancelSms(targetRow.smsMessageId, options?.current)
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <Modal
      open={openDialog}
      width={360}
      onClose={() => {
        setOpenDialog(false);
      }}
      title="確認"
      buttons={[{
        onClick: () => { setOpenDialog(false); },
        label: 'CANCEL',
        className: `${baseClasses.buttonsSecondary} ${classes.button}`,
      }, {
        className: `${baseClasses.buttonsPrimary} ${classes.button}`,
        onClick: () => { deleteSms(); },
        label: 'OK',
      }]}
    >
      <Grid className={classes.content}>{text}</Grid>
    </Modal>
  );
}
