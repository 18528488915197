import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  Typography,
  TextField,
  List,
  ListItem,
  Button,
} from '@material-ui/core';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import commonStyles from '../../../../styles';
import commonTheme from '../../../../styles/theme';
import CustomBackSelect from '../../../../eleCommon/customBackSelect';
import {
  shorteningList,
} from '../../../../../constants/customerMain';

const useStyles = makeStyles((theme) => ({
  contentIpt: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    background: '#fff',
    border: '1px solid #C8C8C8',
    padding: '9px 8px',
    marginBottom: 8,
  },
  contentIpt3: {
    display: 'block',
  },
  contentIptLbl: {
    '& span': {
      color: '#D83420',
    },
  },
  contentIptVal4: {
    width: '100%',
    marginTop: 32,
  },
  contentIptTxt: {
    width: '100%',
    '& > div::before': {
      [commonTheme.breakpoints.up('md')]: {
        borderBottom: '2px solid transparent',
      },
    },
    '& > div::after': {
      borderBottom: `2px solid ${theme.palette.primaryColor}`,
    },
    '& > div:hover:not(.Mui-disabled):before': {
      borderBottom: `2px solid ${theme.palette.primaryColor}`,
    },
    '& input': {
      fontFamily: 'Roboto',
      fontSize: 16,
      zoom: 0.81,
      fontWeight: 700,
      lineHeight: 1.2,
      letterSpacing: 0,
      color: '#333',
      [commonTheme.breakpoints.up('md')]: {
        textAlign: 'right',
      },
    },
    '& textarea': {
      fontFamily: 'Roboto',
      fontSize: 16,
      zoom: 0.81,
      fontWeight: 700,
      lineHeight: 1.2,
      letterSpacing: 0,
      color: '#333',
    },
    '& > div': {
      margin: 0,
    },
  },
  contentShortening: {
    position: 'absolute',
    top: 8,
    right: 8,
    width: 104,
    height: 40,
  },
  contentShorteningLbl: {
    background: '#FBFAF8',
    borderRadius: 4,
    color: theme.palette.primaryColor,
    fontFamily: 'Roboto',
    fontSize: 15,
    fontWeight: '700',
    lineHeight: 1.6,
    letterSpacing: 0,
    width: 104,
    textAlign: 'center',
    padding: '8px 0',
    cursor: 'pointer',
    position: 'absolute',
    zIndex: 2,
    top: 0,
    left: 0,
    pointerEvents: 'none',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  contentShorteningLblIcon: {
    marginLeft: 2,
  },
  contentShorteningSelect: {
    width: 104,
    position: 'absolute',
    zIndex: 1,
    top: 0,
    left: 0,
    '& .MuiSelect-select.MuiSelect-select': {
      padding: '9px 0',
    },
    '& .MuiInputBase-root::before': {
      borderBottom: '1px solid transparent',
    },
    '& .MuiInputBase-root::after': {
      borderBottom: '2px solid transparent',
    },
    '& .MuiInputBase-root:not(.Mui-disabled):before': {
      borderBottom: '2px solid transparent',
    },
  },
  // SP
  list: {
    background: '#fff',
    padding: '0 0 0 16px',
    marginBottom: 16,
  },
  listItem: {
    padding: 0,
    minHeight: 16,
    '& .MuiButton-label': {
      display: 'block',
      '& > span': {
        display: 'block',
        '&:last-child': {
          marginTop: 8,
        },
      },
    },
    '&:last-child': {
      background: '#F3F3F3',
      margin: '0 0 32px -16px',
      width: 'calc(100% + 16px)',
      '& .MuiButton-root': {
        background: '#fff',
        padding: '2px 12px 1px 22px',
        width: 'auto',
        margin: '8px 0 0 16px',
      },
      '& .MuiButton-label': {
        '& > span': {
          '&:first-child': {
            fontSize: 15,
            lineHeight: 1.6,
            color: theme.palette.primaryColor,
            fontWeight: 700,
          },
          '&:last-child': {
            display: 'none',
          },
          '& svg': {
            color: theme.palette.primaryColor,
            margin: '0 0 -4px 2px',
            fontSize: 18,
          },
        },
      },
    },
  },
  selectBtn: {
    width: '100%',
    padding: '18px 0',
    textAlign: 'left',
    borderBottom: '1px solid #F3F3F3',
    '&.select': {
      color: theme.palette.primaryColor,
    },
    '& .MuiButton-label': {
      display: 'block',
      paddingLeft: 16,
    },
  },
}));

export default function RegisterWindowDate(props) {
  const {
    content,
    setContent,
    setDisabled2,
    getParts,
    getEditDialog,
  } = props;

  const classes = useStyles();
  const baseClasses = commonStyles();

  const SHORT_TEXT = {
    1: '忙しい',
    2: '仕事中',
    3: 'かけ直し',
    4: 'クレーム',
  };

  const menus = Object.keys(SHORT_TEXT);

  // モーダル用
  const [open2, setOpen2] = useState(false);
  const handleOpen2 = () => {
    setOpen2(true);
  };
  const handleClose2 = () => {
    setOpen2(false);
  };

  // SP・PCでの分岐用
  const windowWidth = window.innerWidth;
  const breakPoint = 768;
  const isSP = (windowWidth < breakPoint);
  let contents;

  // 短縮入力
  let shortInitial = 1;
  if (isSP) {
    shortInitial = 0;
  }
  const [short, setShort] = useState(shortInitial);
  const getComponent = (data) => {
    switch (data.type) {
      case 'selectBack':
        return <CustomBackSelect initial={data} />;
      default:
        return <div>対応しない要素です</div>;
    }
  };
  const shorteningChange = (e) => {
    setShort(Number(e.target.value));
    setContent(SHORT_TEXT[e.target.value]);
    if (e.target.value === '') {
      setDisabled2(true);
    } else {
      setDisabled2(false);
    }
  };

  const onBlur = (e) => {
    if (e.target.value === '') {
      setDisabled2(true);
    } else {
      setDisabled2(false);
    }
  };

  // SP 項目選択時に更新
  const onSelect = (val) => {
    setShort(val);
    setContent(SHORT_TEXT[val]);
    if (val === '') {
      setDisabled2(true);
    } else {
      setDisabled2(false);
    }
  };

  const registerSelectList = {
    prefectureItem: {
      type: 'selectBack',
      obj: shorteningList,
      state: short,
      change: shorteningChange,
      set: onBlur,
    },
  };

  const onChange = (e) => {
    setContent(e.target.value);
  };

  const getContentSp = () => {
    return (
      <TextField
        className={classes.contentIptTxt}
        defaultValue={content}
        name="content"
        onBlur={onBlur}
        placeholder="-"
        value={content}
        onChange={onChange}
      />
    );
  };

  const getShort = () => {
    return (
      <Grid>
        {menus.map((data) => {
          return (
            <Button
              className={`${baseClasses.title3} ${classes.selectBtn} ${(Number(short) === Number(data)) && 'select'}`}
              key={data}
              onClick={() => {
                onSelect(data);
                handleClose2();
              }}
            >
              {SHORT_TEXT[data]}
            </Button>
          );
        })}
      </Grid>
    );
  };

  const list = [
    {
      type1: 'noModal',
      type2: 'text',
      lbl: '対応内容',
      required: '＊架電/受電の場合は必須',
      val: content,
      content: getContentSp,
    },
    {
      type1: 'button',
      type2: 'select',
      lbl: '短縮入力',
      required: <MoreHorizIcon />,
      click: handleOpen2,
      val: SHORT_TEXT[short],
      opn: open2,
      onClose: handleClose2,
      content: getShort,
    },
  ];

  if (isSP) {
    contents = (
      <Grid>
        <List className={classes.list}>
          {list.map((item) => {
            return (
              <ListItem
                key={item.lbl}
                className={classes.listItem}
              >
                {getParts(item)}
                {getEditDialog(item)}
              </ListItem>
            );
          })}
        </List>
      </Grid>
    );
  } else {
    contents = (
      <Grid className={`${classes.contentIpt} ${classes.contentIpt3}`}>
        <Typography
          className={`${baseClasses.title6} ${classes.contentIptLbl}`}
        >
          対応内容<span>＊架電/受電の場合は必須</span>
        </Typography>
        <Grid className={classes.contentIptVal4}>
          <TextField
            className={classes.contentIptTxt}
            value={content}
            name="content"
            onBlur={onBlur}
            multiline
            rows="8"
            onChange={onChange}
          />
          <Grid className={classes.contentShortening}>
            <Grid
              className={classes.contentShorteningLbl}
            >
              短縮入力
              <MoreHorizIcon className={classes.contentShorteningLblIcon} />
            </Grid>
            <Grid className={classes.contentShorteningSelect}>
              {getComponent(registerSelectList.prefectureItem)}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid>
      {contents}
    </Grid>
  );
}
