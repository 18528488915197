import React, { useMemo } from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import commonTheme from '../../styles/theme';
import CalendarLabel from './parts/CalendarLabel';
import CalendarInputMultiTime from './parts/CalendarInputMultiTime';
import { CalendarInputOnlyDate } from './parts/CalendarInputOnlyDate';
import CalendarInputDateTime from './parts/CalendarInputDateTime';
import CalendarInputMultiDate from './parts/CalendarInputMultiDate';
import CalendarInputMultiDateUser from './parts/CalendarInputMultiDateUser';
import { CalendarInputOnlyTime } from './parts/sp/CalendarInputOnlyTime';

const useStyles = makeStyles({
  wrap: {
    alignItems: 'center',
    padding: '8px',
    [commonTheme.breakpoints.down('sm')]: {
      justifyContent: 'center',
    },
  },
  wrapMultiParallel: {
    alignItems: 'center',
    padding: '0px',
  },
  border: {
    border: '1px solid #C8C8C8',
  },
  noUnderline: {
    '& label': {
      borderColor: 'transparent',
    },
  },
  alignLeft: {
    '& label': {
      textAlign: 'left',
    },
    '& label > span': {
      padding: '8px 0',
    },
  },
  wideInput: {
    '& label': {
      minWidth: '280px',
    },
  },
});

const convertDateTimeToIso = (d) => {
  return d.getFullYear() + '-'
       + (('0' + (d.getMonth() + 1)).slice(-2)) + '-'
       + ('0' + d.getDate()).slice(-2) + 'T'
       + ('0' + d.getHours()).slice(-2) + ':'
       + ('0' + d.getMinutes()).slice(-2) + ':'
       + ('0' + d.getSeconds()).slice(-2);
};

const convertDateToIso = (d) => {
  return d.getFullYear() + '-'
       + (('0' + (d.getMonth() + 1)).slice(-2)) + '-'
       + ('0' + d.getDate()).slice(-2);
};

const Calendar = React.forwardRef((props, ref) => {
  const {
    noBorder,
    noUnderline,
    alignLeft,
    wideInput,
    data,
  } = props;
  const classes = useStyles();

  // wrapのclassName
  const wrapClassName = useMemo(() => {
    const arr = [];
    if (!noBorder) {
      arr.push(classes.wrap, classes.border);
    }
    if (!noUnderline) arr.push(classes.noUnderline);
    if (alignLeft) arr.push(classes.alignLeft);
    if (wideInput) arr.push(classes.wideInput);
    arr.push(data.className);
    return arr.join(' ');
  }, [
    noBorder,
    noUnderline,
    alignLeft,
    wideInput,
    data.className,
  ]);

  const getComponent = (v) => {
    switch (v.type) {
      case 'date':
        return (
          <Grid container className={wrapClassName}>
            {v.label !== '' && <CalendarLabel txt={v.label} require={!!v.require} className={v.labelClassName || null} />}
            <CalendarInputOnlyDate
              id={v.id} // id1
              type="date"
              require={!!v.require} // true
              date={v.state} // ''
              setDate={v.setState}
              ref={v.ref || ref}
              // autoFocus={v.autoFocus}
              readonly={v.readonly}
              min={v.min ? v.min : null}
              max={v.max ? v.max : null}
              convertDateToIso={convertDateToIso}
              placeholder={v.placeholder}
              initialDate={v.initialDate ? v.initialDate : null}
            />
          </Grid>
        );
      case 'dateNew':
        return (
          <Grid container className={wrapClassName}>
            <CalendarLabel txt={v.label} require={!!v.require} />
            <CalendarInputOnlyDate
              id="calendar"
              type="date"
              require={!!v.require}
              date={v.state}
              setDate={v.setState}
              readonly={v.readonly}
              convertDateToIso={convertDateToIso}
              placeholder={v.placeholder}
            />
          </Grid>
        );
      case 'time':
        return (
          <Grid container className={`${classes.wrap} ${classes.border}`}>
            <CalendarLabel txt={v.label} require={!!v.require} />
            <CalendarInputDateTime
              id={v.id}
              type="datetime-local"
              require={!!v.require}
              date={v.state}
              setDate={v.setState}
              readonly={v.readonly}
              min={v.min ? v.min : null}
              max={v.max ? v.max : null}
              convertDateTimeToIso={convertDateTimeToIso}
              reset={v.reset ? v.reset : null}
              setReset={v.setReset ? v.setReset : null}
              placeholder={v.placeholder}
              fixedMinute={v.fixedMinute ? v.fixedMinute : false}
              fixedQuarterMinute={v.fixedQuarterMinute ? v.fixedQuarterMinute : false}
              alert={v.alert ? v.alert : () => {}}
              onBlur={v.onBlur ? v.onBlur : () => {}}
              isShowPicker={!!v.fromMail}
            />
          </Grid>
        );
      case 'timeonly':
        return (
          <Grid container className={`${classes.wrap} ${classes.border}`}>
            <CalendarLabel txt={v.label} require={!!v.require} />
            <CalendarInputOnlyTime
              id={v.id}
              type="time"
              require={!!v.require}
              date={v.state}
              setDate={v.setState}
              ref={v.ref}
              readonly={v.readonly}
              placeholder={v.placeholder}
              notConvert={v.notConvert}
              convertDateTimeToIso={convertDateTimeToIso}
              min={v.min ? v.min : null}
            />
          </Grid>
        );
      case 'multitime':
        return (
          <Grid container className={`${classes.wrap} ${classes.border}`}>
            <CalendarLabel txt={v.label} require={!!v.require} />
            <CalendarInputMultiTime
              id={v.id}
              require={!!v.require}
              start={v.startState}
              end={v.endState}
              setDate={v.setState}
              readonly={v.readonly}
              placeholder={v.placeholder}
              convertDateToIso={convertDateToIso}
            />
          </Grid>
        );
      case 'multiple':
        return (
          <CalendarInputMultiDate
            id={v.id}
            type={v.inputType ? v.inputType : 'datetime-local'}
            label={v.label}
            require={!!v.require}
            start={v.startState}
            end={v.endState}
            setDate={v.setState}
            readonly={v.readonly}
            convertDateTimeToIso={convertDateTimeToIso}
            convertDateToIso={convertDateToIso}
            placeholder={v.placeholder}
          />
        );
      case 'multipleParallel':
        return (
          <CalendarInputMultiDateUser
            id={v.id}
            type="datetime-local"
            label={v.label}
            require={!!v.require}
            state={v.state}
            setDate={v.setState}
            valid={v.valid}
            readonly={v.readonly}
            convertDateTimeToIso={convertDateTimeToIso}
            placeholder={v.placeholder}
          />
        );
      default:
        return false;
    }
  };

  return getComponent(props.data);
});

export default Calendar;
