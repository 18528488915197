import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  date: [],
  divisions: [],
};

export const schedulesGetActionWeekSlice = createSlice({
  name: 'schedulesGetActionWeek',
  initialState: { schedulesGetActionWeek: initialState },
  reducers: {
    getSchedulesActionWeekApi: (state, data) => {
      state.schedulesGetActionWeek = data.payload;
    },
  },
});

export const { getSchedulesActionWeekApi } = schedulesGetActionWeekSlice.actions;

export default schedulesGetActionWeekSlice.reducer;
