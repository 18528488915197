import { useState } from 'react';
import { Button, Grid, makeStyles, Typography } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import commonStyles from '../styles';

import ResponseTypeMasterTable from './responseTypeMasterTable';
import ResponseTypeList from '../common/responseType/responseTypeList';
import ResponseTypeEditModal from './parts/responseTypeEditModal';

const useStyles = makeStyles({
  header: {
    paddingTop: '16px',
    paddingBottom: '8px',
    paddingLeft: '16px',
  },
  main: {
    borderTop: '1px solid #C8C8C8',
    flex: '1 1 auto',
    height: 'calc(100vh - 102px)',
  },
  left: {
    paddingLeft: '16px',
    paddingRight: '18px',
    borderRight: '1px solid #8C8C8C',
    overflowY: 'auto',
    height: '100%',
  },
  right: {
    paddingLeft: '16px',
    borderLeft: '1px solid #8C8C8C',
    overflowY: 'auto',
    height: '100%',
  },
  rightTitle: {
    paddingTop: '16px',
    paddingBottom: '8px',
  },
  showResponseType: {
    paddingTop: '32px',
  },
  btn: {
    marginLeft: 16,
  },
});

const getRelativeResponseTypeList = (initial, responseTypeList = []) => {
  const parentTypes = [initial];

  const getTypeList = (nowResponseType) => {
    if (nowResponseType.responseTypeLevelCode === 1 || !nowResponseType.responseTypeLevelCode) {
      return;
    }

    const parentResponseTypeId = nowResponseType.responseTypeParentId;
    let parentResponseType = {};

    if (responseTypeList) {
      responseTypeList.some((responseType) => {
        if (responseType.responseTypeId === parentResponseTypeId) {
          parentResponseType = responseType;
          return true;
        }
        return false;
      });
    }

    parentTypes.push(parentResponseType);
    getTypeList(parentResponseType);
  };

  if (responseTypeList.length > 0) {
    getTypeList(initial);
  }

  return parentTypes;
};

function ResponseTypeMaster(props) {
  const {
    responseTypeObj,
    selectedResponseType,
    setSelectedResponseType,
    resisterResponseType,
    updateResponseType,
    deleteResponseType,
  } = props;

  const commonClasses = commonStyles();
  const classes = useStyles();

  const [isNewEntry, setIsNewEntry] = useState(false);

  const [isOpenModal, setIsOpenModal] = useState(false);

  const initialRelativeResponseTypeList = getRelativeResponseTypeList(
    selectedResponseType,
    responseTypeObj.responseTypes,
  );

  // 編集モーダル制御
  const onOpenModal = () => {
    setIsOpenModal(true);
  };
  const onCloseModal = () => {
    setIsOpenModal(false);
    setIsNewEntry(false);
  };

  return (
    <>
      <Grid className={classes.header}>
        <Typography className={commonClasses.title2}>反響媒体マスタ</Typography>
      </Grid>
      <Grid container className={classes.main}>
        <Grid item md={6} className={classes.left}>
          <ResponseTypeMasterTable
            responseTypeList={responseTypeObj.responseTypes}
            selectedResponseType={selectedResponseType}
            setSelectedResponseType={setSelectedResponseType}
            onOpenModal={onOpenModal}
            setIsNewEntry={setIsNewEntry}
          />
        </Grid>
        <Grid item md={6} className={classes.right}>
          <Grid container className={classes.rightTitle}>
            <Typography className={commonClasses.title2}>表示確認</Typography>
            {selectedResponseType.responseTypeId === undefined ? '' : (
              <Button className={`${commonClasses.buttonsPrimary} ${classes.btn}`} onClick={onOpenModal}>
                <EditIcon className={classes.icon} size="small" />
                <Typography className={commonClasses.title4}>編集</Typography>
              </Button>
            )}
          </Grid>
          <Grid container className={classes.showResponseType}>
            <ResponseTypeList
              data={responseTypeObj}
              state={initialRelativeResponseTypeList}
              setState={() => {}}
              noChange
            />
          </Grid>
        </Grid>
      </Grid>
      {isOpenModal ? (
        <ResponseTypeEditModal
          isNewEntry={isNewEntry}
          open={isOpenModal}
          onClose={onCloseModal}
          initial={selectedResponseType}
          resisterResponseType={resisterResponseType}
          updateResponseType={updateResponseType}
          deleteResponseType={deleteResponseType}
        />
      ) : null}
    </>
  );
}

export default ResponseTypeMaster;
