import client from '../httpClient';

// user: users/{id} ユーザー取得
const userDetailApi = (userId) => {
  //   const GET_USER_LIST_URL = `/api/v1/users/${String(userId)}`;
  const GET_USER_LIST_URL = `/users/${String(userId)}`;
  return client.get(GET_USER_LIST_URL, String(userId));
};

export default userDetailApi;
