import React, { useState } from 'react';
import { Grid, Button, List, ListItem, makeStyles } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import CustomSimpleDrawer from '../../../../eleCommon/sp/customSimpleDrawer';
import commonStyles from '../../../../styles';
import { unit } from '../../../../../constants/customerTree';
import { useCustomerTree } from '../../../../../containers/common/customerTree/context';
import CustomerTreeSelectDivisionSp from '../../sp/customerTreeSelectDivisionSp';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'flex-end',
    flexWrap: 'wrap',
    marginTop: 8,
    '& > *': {
      marginBottom: 8,
      '&:not(:last-of-type)': {
        marginRight: 8,
      },
    },
  },
  selectBtn: {
    background: '#fff',
    border: '1px solid #C8C8C8',
    padding: '4px 4px 4px 12px',
    fontSize: 13,
    borderRadius: 60,
  },
  arrowIcon: {
    color: theme.palette.primaryColor,
    fontSize: 18,
  },
  // ここからDrawer用のスタイル
  list: {
    padding: 0,
  },
  item: {
    padding: 0,
    '&.Mui-selected': {
      background: 'transparent',
      '& .MuiButton-label': {
        color: theme.palette.primaryColor,
      },
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
  },
  btn: {
    lineHeight: 1.6,
    fontWeight: '700',
    width: '100%',
    marginTop: '0px',
    marginBottom: '0px',
    padding: '16px',
    borderBottom: '1px solid #F3F3F3',
    '& .MuiButton-label': {
      justifyContent: 'flex-start',
    },
  },
}));

function DrawerList(props) {
  const { initial, onClose } = props;
  const classes = useStyles();
  const common = commonStyles();
  const list = initial.menu;

  return (
    <List className={classes.list}>
      {Object.keys(list).map((o) => (
        <ListItem
          key={o}
          className={classes.item}
          selected={initial.state !== undefined && initial.state === Number(o)}
        >
          <Button
            className={`${common.title3} ${classes.btn}`}
            onClick={() => {
              initial.setState(Number(o));
              onClose();
            }}
          >
            {initial.menu[o]}
          </Button>
        </ListItem>
      ))}
    </List>
  );
}

function GetTreeSelectHeader(props) {
  const {
    searchHeader,
    setSearchHeader,
    setSelectUser,
    setSelectDivision,
    setSelectCustomer,
    setDisplayDivision,
    setDisplayDivisionRoot,
  } = props;
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  // ステートを更新
  const set = (key, val) => {
    setSearchHeader({ ...searchHeader, [key]: val });
    setSelectUser({ userId: 0, userName: '' });
    setSelectDivision({ divId: '', divisionName: '' });
    setSelectCustomer([]);
    setDisplayDivision([]);
    setDisplayDivisionRoot([]);
  };

  // SPにはTypeCodeは不要
  const data = [
    {
      key: 'unitCode',
      obj: unit,
      state: searchHeader.unitCode,
      setState: (e) => { return set('unitCode', e); },
      menu: unit.menus,
    },
  ];

  return (
    <Grid className={classes.root}>
      {data.map((d, index) => (
        <Grid
          item
          key={d.key}
        >
          <Button onClick={() => { setOpen((prev) => ({ ...prev, [`open${index}`]: true })); }} className={classes.selectBtn}>
            {d.state === undefined ? d.menu.title : d.menu[d.state]}
            <ArrowDropDownIcon className={classes.arrowIcon} />
          </Button>
          <CustomSimpleDrawer
            index={index}
            open={open[`open${index}`]}
            onClose={() => { setOpen((prev) => ({ ...prev, [`open${index}`]: false })); }}
            title={d.obj.title}
            list={<DrawerList initial={d} onClose={() => { setOpen((prev) => ({ ...prev, [`open${index}`]: false })); }} />}
          />
        </Grid>
      ))}
      <CustomerTreeSelectDivisionSp
        setDisplayDivision={setDisplayDivision}
        setDisplayDivisionRoot={setDisplayDivisionRoot}
      />
    </Grid>
  );
}

export default function CustomerTreeResponseSelectUnitSp(props) {
  const {
    searchHeader,
    setSearchHeader,
    setSelectUser,
    setSelectDivision,
    setSelectCustomer,
  } = useCustomerTree();

  const {
    setDisplayDivision,
    setDisplayDivisionRoot,
  } = props;

  return (
    <GetTreeSelectHeader
      searchHeader={searchHeader}
      setSearchHeader={setSearchHeader}
      setSelectUser={setSelectUser}
      setSelectDivision={setSelectDivision}
      setSelectCustomer={setSelectCustomer}
      setDisplayDivision={setDisplayDivision}
      setDisplayDivisionRoot={setDisplayDivisionRoot}
    />
  );
}
