import { useEffect, useRef } from 'react';
import { InputAdornment, makeStyles, TextField } from '@material-ui/core';
import { Search } from '@material-ui/icons';
import { classNames } from '../../commonFunction';

const useStyles = makeStyles({
  iconAdornment: {
    padding: '0 4px',
  },
  searchIcon: {
    width: '1em',
    height: '1em',
  },
  textArea: {
    margin: '0px',
    backgroundColor: '#FFFFFF',
    border: '1px solid #C8C8C8',
    borderRadius: 4,
    width: '100%',
    '& input': {
      fontFamily: 'Roboto',
      fontSize: 13,
      letterSpacing: 0,
      padding: '8px 0',
      '&[type=number]::-webkit-inner-spin-button': {
        WebkitAppearance: 'none',
        margin: '0',
      },
      '&[type=number]::-webkit-outer-spin-button': {
        WebkitAppearance: 'none',
        margin: '0',
      },
    },
  },
  textInput: {
    padding: '5px',
    fontSize: '13px',
    '&.MuiSelect-select:focus': { background: 'none' },
  },
});

// 検索用のテキストフィールド
export default function SearchTextField(props) {
  const { value, placeholder, onChange, onBlur, addStyle, className, type } = props;
  const classes = useStyles();
  const ref = useRef(null);

  // テキストフィールド内のスクロールを防止
  useEffect(() => {
    const ignoreScroll = (e) => {
      e.preventDefault();
    };
    ref.current && ref.current.addEventListener('wheel', ignoreScroll);
  }, [ref]);

  return (
    <TextField
      value={value}
      placeholder={placeholder}
      onChange={onChange}
      onBlur={onBlur}
      className={classNames(classes.textArea, className)}
      style={addStyle}
      type={type}
      ref={ref}
      InputProps={{
        classes: {
          input: classes.textInput,
          adornedStart: classes.iconAdornment,
        },
        startAdornment: (
          <InputAdornment position="start">
            <Search className={classes.searchIcon} />
          </InputAdornment>
        ),
        disableUnderline: true,
      }}
    />
  );
}
