import { Button, makeStyles, Popper, Typography } from '@material-ui/core';
import { DeleteOutline } from '@material-ui/icons';
import commonStyles from '../../styles';

const useStyles = makeStyles(() => ({
  popper: {
    display: 'flex',
    alignItems: 'center',
  },
  btn: { transform: 'scale(0.9)' },
  icon: { width: '16px', marginRight: '4px' },
}));

export default function UnsentSmsMenuPopper(props) {
  const {
    open,
    anchorEl,
    id,
    setOpenDialog2,
  } = props;
  const common = commonStyles();
  const classes = useStyles();

  const onClickCancelBtn = () => {
    setOpenDialog2(true);
  };

  return (
    <Popper
      id={id}
      open={open}
      anchorEl={anchorEl}
      placement="left"
      className={classes.popper}
      transition
    >
      <Button
        className={`${classes.btn} ${common.buttonsSecondary}`}
        onClick={onClickCancelBtn}
      >
        <DeleteOutline className={classes.icon} />
        <Typography className={common.smallStrong}>送信の取り消し</Typography>
      </Button>
    </Popper>
  );
}
