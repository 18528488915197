import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

export default function AreaSentakuCheckBox(props) {
  const {
    id,
    index,
    label,
    cityJisCode,
    onChangeChecked,
    checked,
  } = props;

  const handleChange = (e) => {
    onChangeChecked({
      id,
      index,
      label,
      cityJisCode,
      checked: e.target.checked,
    });
  };

  return (
    <FormGroup row>
      <FormControlLabel
        control={
          <Checkbox
            checked={checked}
            onChange={handleChange}
            color="primary"
          />
        }
        label={label}
      />
    </FormGroup>
  );
}
