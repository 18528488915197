import { useCallback, memo } from 'react';
import { TableRow, makeStyles } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import CustomCell from './customCell';
import { crmPageActions, crmPageSelector } from '../../../store/crmPage';
import { JOIN_DATA_FROM_TYPES } from '../../../constants/crm';
import { deleteHyphenFromPhoneNumber } from '../../../commonFunction';

const useStyles = makeStyles((theme) => ({
  selected: {
    background: `${theme.palette.primaryColor}29 !important`,
  },
  popover: {
    pointerEvents: 'none',
  },
  existing: { background: 'rgba(216, 52, 32, 0.08)' },
  even: { background: '#F3F3F3' },
}));

export const basicFields = [
  { title: 'No.', key: 'index', type: 'center' },
  {
    title: '会員',
    key: 'memberStatusCode',
    type: 'memberStatus',
    fontSize: '10px',
    checkKey: 'isWebMemberId',
  },
  { title: '希望地域', key: 'wishArea' },
  { title: '日時', key: 'receptAt' },
  { title: '担当者', key: 'responsibleUserName' },
  { title: '希望店舗', key: 'wishStoreName' },
  {
    title: '姓',
    key: 'customerLastName',
    fontWeight: '700',
    checkKey: 'isCustomerName',
  },
  {
    title: '名',
    key: 'customerFirstName',
    fontWeight: '700',
    checkKey: 'isCustomerName',
  },
  { title: '住所（市区町村以降）', key: 'address' },
  { title: '住所・建物名', key: 'building' },
  { title: '電話番号', key: 'phoneNumber', checkKey: 'isPhoneNumber' },
  { title: 'Mail', key: 'mail', checkKey: 'isMail' },
  { title: 'Web会員ID', key: 'memberId', type: 'center', checkKey: 'isWebMemberId' },
  { title: '媒体(大)', key: 'largeResponseTypeName', type: 'responseType' },
  { title: '媒体(中)', key: 'mediumResponseTypeName', type: 'responseType' },
  { title: '媒体(小)', key: 'smallResponseTypeName', type: 'responseType' },
  {
    title: '媒体(細)',
    key: 'fineResponseTypeName',
    type: 'responseType',
    lastCell: true,
  },
];

function ResponseRow(props) {
  const { row, handlePopoverOpen } = props;

  const classes = useStyles();

  let className = '';
  if ((row.isWebMemberId || row.isCustomerName || row.isPhoneNumber || row.isMail)) {
    className += ` ${classes.existing}`;
  } else if (row.index % 2) {
    className += ` ${classes.even}`;
  }

  const dispatch = useDispatch();
  const { joinData } = useSelector(crmPageSelector);

  const onCellClick = useCallback((e, { key }, data) => {
    e.stopPropagation();
    // 姓、名、電話番号、メールのCellがクリックされ、
    // かつnullではない場合
    if (
      ['customerLastName', 'customerFirstName', 'phoneNumber', 'mail', 'memberId'].includes(key)
      && row[key]
    ) {
      let searchWord = '';
      if (['customerLastName', 'customerFirstName'].includes(key)) {
        // 姓、名の場合、フルネームをキーワードにする
        searchWord = (row.customerLastName || '') + (row.customerFirstName || '');
      } else if (['phoneNumber'].includes(key)) {
        // 電話番号の場合、ハイフンを除いて検索
        searchWord = deleteHyphenFromPhoneNumber(row[key]);
      } else {
        // メール、Web会員ID
        searchWord = String(row[key]);
      }
      if (!data.isShow) {
        dispatch(crmPageActions.setJoinData({
          webResponseData: row,
          childResponseId: row.responseId,
          isShow: false,
          fromType: JOIN_DATA_FROM_TYPES.WEB_RESPONSE_LIST,
        }));
      }

      dispatch(crmPageActions.setSearchKeyword({ searchWord }));
    } else if (!data.isShow) {
      // それ以外かつデータ結合でない場合には行の選択をキャンセルする
      dispatch(crmPageActions.setJoinData());
    }
  }, []);

  return (
    <TableRow
      id={`idResponse-${row.responseId}`}
      aria-haspopup="true"
      selected={row.responseId === joinData.webResponseData?.responseId}
      className={className}
      classes={{ selected: classes.selected }}
      onMouseEnter={handlePopoverOpen}
    >
      {basicFields.map((obj) => (
        <CustomCell
          key={`${row.responseId}-${obj.key}`}
          obj={obj}
          row={row}
          onClick={(e) => onCellClick(e, obj, joinData)}
        />
      ))}
    </TableRow>
  );
}

export default memo(ResponseRow);
